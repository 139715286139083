import { getNames } from "../components/Analyser.tsx";
import extractEmojisFromCsv from "./emojis.tsx";
import { ComparisonResult, ComparisonStringResult, CountResult, MessagePairResult, Name, NumberResult, Result, StatOption, TimeResult, TopTenResult } from "../models/models.tsx";

const emojiArray = extractEmojisFromCsv();

const processItem = (option: StatOption, jsonData: any[]): Promise<Result> => {
    let result: Result = {
        label: option.label,
        display: option.display,
        measure: option.measure
    };

    return new Promise((resolve, reject) => {
        switch (option.query) {
            case 'msgCount':
                // number of messages sent per person
                var msgCountPersonList: { name: string; count: number }[] = [];
                const msgNames: Name[] = getNames(jsonData);
                for (let i = 0; i < msgNames.length; i++) {
                    const name = msgNames[i];
                    const currName: string = name.detectedName;
                    const count: number = countMessages(currName, jsonData);
                    msgCountPersonList.push({ name: currName, count: count });
                }
                result = {
                    ...result,
                    personList: msgCountPersonList.sort((a, b) => b.count - a.count)
                } as ComparisonResult;
                break;
            case 'emojis':
                // top 10 emojis overall
                var emojisTopResults: { name: string; count: number }[] = [];
                const emojiCount: { emoji: string; count: number }[] = top10Emojis(jsonData);
                for (let i = 0; i < emojiCount.length; i++) {
                    const item = emojiCount[i];
                    emojisTopResults.push({ name: item.emoji, count: item.count });
                }
                result = {
                    ...result,
                    topResults: emojisTopResults
                } as TopTenResult;
                break;
            case 'emojiCount':
                // number of emojis sent per person
                const emojiNames = getNames(jsonData);
                var emojiCountPersonList: { name: string; count: number }[] = [];
                for (let i = 0; i < emojiNames.length; i++) {
                    const name = emojiNames[i];
                    const count: number = countEmojis(name.detectedName, jsonData);
                    emojiCountPersonList.push({ name: name.detectedName, count: count });
                }
                result = {
                    ...result,
                    personList: emojiCountPersonList.sort((a, b) => b.count - a.count)
                } as ComparisonResult;
                break;
            case 'words':
                // top 10 words overall
                var wordsTopResults: { name: string; count: number }[] = [];
                const wordCount: { word: string; count: number }[] = top10Words(jsonData);
                for (let i = 0; i < wordCount.length; i++) {
                    const item = wordCount[i];
                    wordsTopResults.push({ name: item.word, count: item.count });
                }
                result = {
                    ...result,
                    topResults: wordsTopResults
                } as TopTenResult;
                break;
            case 'laughing':
                // number of times laughing emojis were used
                const laughCount: number = laughingCount(jsonData);
                result = {
                    ...result,
                    number: laughCount
                } as NumberResult;
                break;
            case 'custom':
                // custom word count
                if (!option.content) {
                    throw new Error('Custom word not provided');
                }
                const customWordCount: number = customCount(jsonData, option.content);
                result = {
                    ...result,
                    count: { word: option.content, count: customWordCount }
                } as CountResult;
                break;
            case 'hour':
                //call top 10 hours and return the result with hours content
                var hourTopResults: { name: string; count: number }[] = [];
                const topHours: { hour: string; count: number }[] = top10Hours(jsonData);
                for (let i = 0; i < topHours.length; i++) {
                    const item = topHours[i];
                    hourTopResults.push({ name: item.hour, count: item.count });
                }
                result = {
                    ...result,
                    topResults: hourTopResults
                } as TopTenResult;
                break;
            case 'day':
                //  call top 10 days and return the result with days content
                var dayTopResults: { name: string; count: number }[] = [];
                const topDays: { day: string; count: number }[] = top10Days(jsonData);
                for (let i = 0; i < topDays.length; i++) {
                    const item = topDays[i];
                    dayTopResults.push({ name: item.day, count: item.count });
                }

                result = {
                    ...result,
                    topResults: dayTopResults
                } as TopTenResult;
                break;
            case 'firstSender':
                // call the function to find the first sender
                var firstSenderPersonList: { name: string; count: number }[] = [];
                const senderNames = getNames(jsonData);
                for (let i = 0; i < senderNames.length; i++) {
                    const name = senderNames[i];
                    const senderCount: number = firstSender(jsonData, name.detectedName);
                    firstSenderPersonList.push({ name: name.detectedName, count: senderCount });
                }
                result = {
                    ...result,
                    personList: firstSenderPersonList.sort((a, b) => b.count - a.count)
                } as ComparisonResult;
                break;
            case 'firstMsg':
                // call the function to find the first messages
                const firstMessages = firstMsg(jsonData);
                result = {
                    ...result,
                    messages: firstMessages
                } as MessagePairResult;
                break;
            case 'videoCount':
                // call the function to count the number of video calls
                const videoCalls = videoCount(jsonData);
                result = {
                    ...result,
                    number: videoCalls
                } as NumberResult;
                break;
            case 'callerCount':
                // call the function to count the number of video calls made by each person
                var callerCountPersonList: { name: string; count: number }[] = [];
                const callerNames = getNames(jsonData);
                for (let i = 0; i < callerNames.length; i++) {
                    const name = callerNames[i];
                    const count = callerCount(jsonData, name.detectedName);
                    callerCountPersonList.push({ name: name.detectedName, count: count });
                }
                result = {
                    ...result,
                    personList: callerCountPersonList.sort((a, b) => b.count - a.count)
                } as ComparisonResult;
                break;
            case 'callDuration':
                // call the function to find the average call duration
                const avgLength = videoLength(jsonData);
                result = {
                    ...result,
                    time: avgLength
                } as TimeResult;
                break;
            case 'photoCount':
                // call the function to count the number of photos sent by each person
                var photoCountPersonList: { name: string; count: number }[] = [];
                const photoNames = getNames(jsonData);
                for (let i = 0; i < photoNames.length; i++) {
                    const name = photoNames[i];
                    const count = photoCount(jsonData, name.detectedName);
                    photoCountPersonList.push({ name: name.detectedName, count: count });
                }
                result = {
                    ...result,
                    personList: photoCountPersonList.sort((a, b) => b.count - a.count)
                } as ComparisonResult;
                break;
            case 'wordEach':
                // call the function to count the most used word by each person
                var wordEachPersonList: { name: string; word: string }[] = [];
                const wordNames = getNames(jsonData);
                for (let i = 0; i < wordNames.length; i++) {
                    const name = wordNames[i];
                    const word = countWords(jsonData, name.detectedName);
                    wordEachPersonList.push({ name: name.detectedName, word: word });
                }
                result = {
                    ...result,
                    personList: wordEachPersonList
                } as ComparisonStringResult;
                break;
            case 'avgMsgLength':
                // calculate the average message length
                const avgMsgLength = calculateAverageMessageLength(jsonData);
                result = {
                    ...result,
                    time: avgMsgLength
                } as TimeResult;
                break;
            case 'avgResponseTime':
                // calculate the average response time
                const avgResponseTime = calculateAverageResponseTime(jsonData);
                result = {
                    ...result,
                    time: avgResponseTime
                } as TimeResult;
                break;
            default:
                break;
        }

        resolve(result);
    });
}

const commonWords = new Set([
    'the', 'i', 'u', 'a', 'and', 'video', 'call', 'ended', 'to', 'you', 'it', 'is', 'for', 'just', 'do', 'in', 'so', 'yeah', 
    'are', 'my', 'have', 'can', 'we', 'be', 'i\'m','at', 'me', 'but', 'that', 'go', 'get', 'on', 'want', 'your', 'up', 'with', 'i\'ll', 
    'no', 'was', 'if', 'of', 'potato', 'sexiest', 'come', 'what', 'missed', 'i’m'// Add more common words here
]);

// function to calculate the average message length
function calculateAverageMessageLength(jsonData: any[]): string {
    var totalLength = 0
    var count = 0

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            const content = message.content || '';
            totalLength += content.length
            count++
        });
    });

    const avgLength = totalLength / count
    return avgLength.toFixed(2) + " characters"
}

// function to calculate the average response time
function calculateAverageResponseTime(jsonData: any[]): string {
    var totalResponseTime = 0
    var count = 0
    var prevTime = 0

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            const time = message.timestamp_ms
            if (prevTime !== 0 && time - prevTime < 32 * 60 * 60 * 1000) {
                totalResponseTime += time - prevTime
                count++
            }
            prevTime = time
        });
    });

    const avgResponseTime = totalResponseTime / count
    return -(avgResponseTime / 60000).toFixed(2) + " minutes"
}

// function to count the most used word by a person
function countWords(jsonData: any[], name: string): string {
    var wordCountMap: { [word: string]: number } = {};

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            if(message.sender_name === name) {
                const content = message.content?.toLowerCase() || '';
                const words = content.split(/\s+/);

                words.forEach((word) => {
                    if (word && !commonWords.has(word)) {
                        if (word in wordCountMap) {
                            wordCountMap[word] += 1;
                        } else {
                            wordCountMap[word] = 1;
                        }
                    }
                });
            }
        });
    });

    const topWord = Object.entries(wordCountMap)
        .map(([word, count]) => ({ word, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 1);
    return topWord[0].word;
}
// function to count the number of photos sent by a person
function photoCount(jsonData: any[], name: string): number {
    var count = 0

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            if(message.sender_name === name && message.photos) {
                count++
            }
        });
    });

    return count;
}

// function to find the average call duration
function videoLength(jsonData: any[]): string {
    var totalLength = 0
    var count = 0

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            if(message.content === "The video call ended.") {
                const callSeconds = message.call_duration
                totalLength += callSeconds
                count++
            }
        });
    });

    const avgLength = totalLength / count
    const hours = Math.floor(avgLength / 3600)
    const minutes = Math.floor((avgLength % 3600) / 60)
    const seconds = Math.floor(avgLength % 60)

    // do not return hours or minutes if they are 0
    if(hours === 0 && minutes === 0) {
        return seconds + " seconds"
    } else if(hours === 0) {
        return minutes + " minutes " + seconds + " seconds"
    }
    return hours + " hours " + minutes + " minutes " + seconds + " seconds"
}

// function to count who makes the most video calls
function callerCount(jsonData: any[], name:string): number {
    var count = 0

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            if(message.content === "The video call ended." && message.sender_name === name) {
                count++
            }
        });
    });
    return count;
}

//function to count the number of video calls made
function videoCount(jsonData: any[]): number {
    var count = 0

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            if(message.content === "The video call ended.") {
                count++
            }
        });
    });

    return count;
}

//function to get the earliest two timestamped messages accross all data
function firstMsg(jsonData: any[]): { name: string; message: string, time: string}[] {
    var firstMessages: { name: string; message: string, time: string }[] = []

    var firstTime = 169012439212800
    var firstMessage = ""
    var firstName = ""
    var secondTime = 0
    var secondMessage = ""
    var secondName = ""

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        
        currMessages.forEach((message) => {
            const time = message.timestamp_ms
            if(time < firstTime && message.content !== "You are now connected on Messenger") {
                secondTime = firstTime
                secondMessage = firstMessage
                secondName = firstName
                firstTime = time
                firstMessage = message.content
                firstName = message.sender_name
            }
        });
    });

    firstMessages.push({name:firstName,message:firstMessage, time:new Date(firstTime).toLocaleString('en-US', { hour12: true })})
    firstMessages.push({name:secondName,message:secondMessage, time:new Date(secondTime).toLocaleString('en-US', { hour12: true })})

    return firstMessages;
}



// function to count the times a person sends a message more than 6 hours than the previous one
function firstSender(jsonData: any[], name: string): number {
    var count = 0

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        var prevTime = 0
        currMessages.forEach((message) => {
            const time = message.timestamp_ms
            if(message.sender_name === name && prevTime - time > 14400000) {
                count++
            }
            prevTime = time
        });
    });

    return count;
}

// function to count the number of messages in jsonData from each day of the week and return the list in order
function top10Days(jsonData: any[]): { day: string; count: number }[] {
    const dayCountMap: { [day: string]: number } = {};
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            const date = new Date(message.timestamp_ms);
            var day = date.getDay().toString();

            if (days[day] in dayCountMap) {
                dayCountMap[days[day]] += 1;
            } else {
                dayCountMap[days[day]] = 1;
            }
        });
    });

    const topDays = Object.entries(dayCountMap)
        .map(([day, count]) => ({ day, count })) // sort in order of day of the week
        .sort((a, b) => days.indexOf(a.day) - days.indexOf(b.day))
    return topDays;
}

// function to count the number of messages in jsonData from each hour of the day and return the top 10
function top10Hours(jsonData: any[]): { hour: string; count: number }[] {
    const hourCountMap: { [hour: string]: number } = {};
    //array of hours of the day in 12hr
    const hours = ['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am',
        '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm',
        '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm'];

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            const date = new Date(message.timestamp_ms);
            const hour = date.getHours().toString();

            if (hours[hour] in hourCountMap) {
                hourCountMap[hours[hour]] += 1;
            } else {
                hourCountMap[hours[hour]] = 1;
            }
        });
    });

    const topHours = Object.entries(hourCountMap)
        .map(([hour, count]) => ({ hour, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 10);
    return topHours;
}

function top10Words(jsonData: any[]): { word: string; count: number }[] {
    const wordCountMap: { [word: string]: number } = {};

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            const content = message.content?.toLowerCase() || '';
            const words = content.split(/\s+/); // Split content into words

            words.forEach((word) => {
                if (word && !commonWords.has(word) && !word.endsWith('.')) {
                    if (word in wordCountMap) {
                        wordCountMap[word] += 1;
                    } else {
                        wordCountMap[word] = 1;
                    }
                }
            });
        });
    });

    const topWords = Object.entries(wordCountMap)
        .map(([word, count]) => ({ word, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 10);
    return topWords;
}


function top10Emojis(jsonData: any[]): { emoji: string; count: number; }[] {
    const countMap: { [emoji: string]: number } = {};

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            const content = message.content?.toLowerCase() || '';
            const words = content.split(/\s+/);

            words.forEach((word) => {
                if (word && !word.endsWith('.') && emojiArray.includes(word)) {
                    if (word in countMap) {
                        countMap[word] += 1;
                    } else {
                        countMap[word] = 1;
                    }
                }
                else if (emojiArray.some(emoji => word.includes(emoji))) {
                    const emojis = word.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
                    emojis.forEach(emoji => {
                        if (emoji in countMap) {
                            countMap[emoji] += 1;
                        } else {
                            countMap[emoji] = 1;
                        }
                    });
                }
            });
        });
    });

    const top = Object.entries(countMap)
        .map(([emoji, count]) => ({ emoji, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 10);
    return top;
}

function countMessages(name: string, jsonData: any[]): number {
    var count = 0

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            if(message.sender_name === name) {
                count++
            }
        });
    });

    return count;
}

function countEmojis(name: string, jsonData: any[]): number {
    var count = 0
    const emojiArray = extractEmojisFromCsv();

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            if(message.sender_name === name) {
                const content = message.content?.toLowerCase() || '';
                const words = content.split(/\s+/);

                words.forEach((word) => {
                    if (emojiArray.includes(word) || emojiArray.some(emoji => word.includes(emoji))) {
                        count++
                    }
                });
            }
        });
    });

    return count;
}

function laughingCount(jsonData: any[]): number {
    var laughCount = 0
    const laughs = new Set([
        'lol', 'haha', 'hah', 'ha', 'hahah', 'hahaha', 'hahahah', 'hahahaha', 'hahahahah', 'hahahahaha', 'rofl', 'lmao','lmfao',
        'hehe', 'heh', 'heheh', 'hehehe', 'teehee'
    ]);

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            const content = message.content?.toLowerCase() || '';
            const words = content.split(/\s+/); // Split content into words

            words.forEach((word) => {
                if (word && word.slice('.') && laughs.has(word)) {
                    laughCount++
                }
            });
        });
    });
    return laughCount;
}

function customCount(jsonData: any[], phrase: string): number {
    var count = 0

    jsonData.forEach((item) => {
        const currMessages = item.messages;
        currMessages.forEach((message) => {
            const content: string = message.content?.toLowerCase() || '';
            if(content.includes(phrase.toLowerCase())) {
                count++
            }
        });
    });
    return count;
}

export default processItem;