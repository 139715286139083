import React, {useEffect, useState} from 'react';
import { DragDropSnap } from '../../components/DragDrop.tsx'
import StatCheckBoxes from "../../components/stat-check-boxes/StatCheckBoxes.tsx";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { SnapchatName, StatOption } from '../../models/models.tsx';
import GravityItem from '../../components/GravityItems.tsx';
import SnapGuide from './SnapGuide.tsx';
import SnapAnalyser from './SnapAnalyser.tsx';
import ChatSelect from './ChatSelect.tsx';

const SnapchatAnalysisScreen: React.FC = () => {
    const [chatFile, setChatFile] = useState<object[]>([])
    const [queryList, setQueryList] = useState<StatOption[]>([])
    const [statOpen, setStatOpen] = useState<boolean>(false);
    const [chatOpen, setChatOpen] = useState<boolean>(false);
    const [guideOpen, setGuideOpen] = useState<boolean>(false);
    const [error , setError] = useState<string>("")

    const [selectedChat, setSelectedChat] = useState<SnapchatName>({name: "Please upload a file", participants: [{detectedName:"",preferredName:""}], description: "", chatId: ""});
    
    const handleFileUpload = (file) => {
        setError("");
        setChatFile(file);
    };

    useEffect(() => {
        if (chatFile.length === 0) {
            setError("");
        } else if (Object.keys(chatFile[0])[0].hasOwnProperty("From")) {
            setError("File is not in the correct format. Please check the instructions and try again or contact us at chat-stat@outlook.com");
        }
    }, [chatFile]);

    return (
        <div className="App" style={{paddingBottom: "150px"}}>
            <h1 className='snap'>Snapchat</h1>
            <p className='subheading' style={{marginTop: "25px"}}>1. Download your chat history</p>
            <div className="home-uploadButton" style={{backgroundColor: "#f3b25845", outline: "0px", padding: "5px", color: "#33333363"}}onClick={() => setGuideOpen(!guideOpen)}>
                How to download your chat
            </div>
            <Modal open={guideOpen} onClose={() => setGuideOpen(!guideOpen)} center>
                <SnapGuide/>
            </Modal>
            <div className='drop-container'>
                <DragDropSnap onUpload={handleFileUpload}/>
                <p className="error-message" style={{color: "red"}}>{error}</p>
            </div>

            {chatFile.length > 0 &&
                <div>
                    <Modal open={chatOpen} onClose={() => setChatOpen(!chatOpen)} center>
                        <ChatSelect currentSelect={selectedChat.name} chatFile={chatFile} onChange={setSelectedChat} setOpen={setChatOpen}/>
                    </Modal>
                    <p className='subheading'>2. Select the chat to analyse</p>
                    <div className="home-uploadButton" onClick={() => setChatOpen(!chatOpen)}>
                        Select chat
                    </div>
                    {selectedChat.name !== "Please upload a file" && 
                        <div>
                            <p>Detected Usernames (if you want to change them)</p>
                            <div className={selectedChat.participants?.length > 3 ? 'name-list-container-two-columns' : 'name-list-container'}>
                                {selectedChat.participants?.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <input type='text' placeholder={item.preferredName} onChange={(e) => {
                                                let temp = [...selectedChat.participants]
                                                temp[index].preferredName = e.target.placeholder
                                                let newChat: SnapchatName = selectedChat
                                                newChat.participants = temp
                                                setSelectedChat(newChat)
                                            }}/>
                                        </div>
                                    )
                                })}
                            </div>

                        <Modal open={statOpen} onClose={() => setStatOpen(!statOpen)} center>
                            <StatCheckBoxes currentList={queryList} onChange={setQueryList} setOpen={setStatOpen} chatType={"snap"}/>
                        </Modal>
                        <p className='subheading'>3. Select your stats</p>
                        <div className='query-list-container'>
                            <p className='query-list-count'>{queryList.length}/9</p>
                            <div className='hover-button'>
                                <div className="home-uploadButton" onClick={() => setStatOpen(!statOpen)}>
                                    Select stats
                                </div>
                            </div>
                            <GravityItem items={queryList.map(item => {return {name:item.name, colour:getRandomColor()}})}/>
                        </div>
                        <SnapAnalyser jsonData={chatFile} selectedOptions={queryList} selectedChat={selectedChat}/>
                    </div>
                    }
                </div>
            }
        </div>
    );
}

const getRandomColor = () => {
    const colors = [
        'rgba(133, 195, 218, 1)',
        'rgba(222, 77, 89, 1)',
        'rgba(61, 194, 163, 1)',
        'rgba(243, 178, 88, 1)',
        'rgb(249, 209, 255)'
    ];
    return colors[Math.floor(Math.random() * colors.length)];
};

export default SnapchatAnalysisScreen;
