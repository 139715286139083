import React from 'react';
import './GuideModal.css';

const GuideModal: React.FC = () => {
    return (
        <div className="guide-modal">
            <h2 className='facebook'>Download from Facebook</h2>
            <ol>
                <li>Open Facebook on a web browser or phone</li>
                <li>
                    <div style={{display:"flex"}}>
                        <div style={{alignSelf:"center"}}>
                            Select your profile and go to
                        </div>
                    </div>

                </li>
                <div style={{display: "flex",flexDirection: "row", alignSelf: 'center'}}>
                    <div style={{display: "flex", flexDirection:"column", alignItems: "center"}}>
                        <p className='content-label'>Browser</p>
                        {/* eslint-disable-next-line */}
                        <p className='p-facebook'>Settings & Privacy {"\>"} Settings</p>
                    </div>
                    <div style={{display: "flex", flexDirection:"column", alignItems: "center"}}>
                        <p className='content-label'>Phone</p>
                        <p className='p-facebook'>Settings cog in the top right</p>
                    </div>
                </div>
                <li>Scroll down to <p className='p-facebook'>Your Information</p></li>
                <li>Select <p className='p-facebook'>Download your information</p></li>
                <li>Select <p className='p-facebook'>Continue</p> to go to Accounts Center</li>
                <li>Select 
                    <p className='p-facebook'>Download or Transfer information</p>
                </li>
                <li>Select your facebook or instagram account to download</li>
                <li>Select 
                    <p className='p-facebook'>Specific types of information</p>
                </li>
                <li>Select 
                    <p className='p-facebook'>Messages</p>
                </li>
                <li>Select 
                    <p className='p-facebook'> Download to device</p>
                </li>
                <li>Select your date range you want in the analysis</li>
                <li>Change the format to
                    <p className='p-facebook'>JSON</p>
                </li>
                <li>Select
                    <p className='p-facebook'>Create Files</p>
                </li>
                <li>Wait for the notification that your files are ready to download (this might take a while)</li>
                <li>Download the files using the link in the email</li>
                <li>Find where your file was downloaded</li>
                <li>Find your chat: </li>
                <div style={{display: "flex",flexDirection: "row", alignSelf: 'center'}}>
                    {/* eslint-disable-next-line */}
                    <p className='p-facebook'>*Your-downloaded-file* {"\>"} messages {"\>"} inbox {"\>"} *name-of-the-chat-to-analyse*</p>
                </div>
                <li>Upload all message.json files into the chatsstat.com upload box </li>
                <div style={{display: "flex",flexDirection: "row", alignSelf: 'center'}}>
                    <p className='p-facebook'>e.g. message_1.json + message_2.json + message_3.json</p>
                </div>
            </ol>

            <h2 className='instagram'>Download from Instagram</h2>
            <ol>
                <li>Open the Instagram app</li>
                {/* eslint-disable-next-line */}
                <li>Select your <p className='p-instagram'>profile</p> {"\>"} <p className='p-instagram'>Settings and activity (☰)</p></li>
                <li>Scroll down to <p className='p-instagram'>Your Information</p></li>
                <li>Select <p className='p-instagram'>Download your information</p></li>
                <li>Select <p className='p-instagram'>Accounts Center</p></li>
                <li>Select <p className='p-instagram'>Your information and permissions</p></li>
                <li>Select <p className='p-instagram'>Download your information</p></li>
                <li>Select <p className='p-instagram'>Download or transfer information</p></li>
                <li>Select your instagram account to download</li>
                <li>Select 
                    <p className='p-instagram'>Some of your information</p>
                </li>
                <li>Select 
                    <p className='p-instagram'>Messages</p>
                </li>
                <li>Select 
                    <p className='p-instagram'> Download to device</p>
                </li>
                <li>Select your date range you want in the analysis</li>
                <li>Change the format to
                    <p className='p-instagram'>JSON</p>
                </li>
                <li>Select
                    <p className='p-instagram'>Create Files</p>
                </li>
                <li>Wait for the notification that your files are ready to download (this might take a while)</li>
                <li>Download the files using the link in the email</li>
                <li>Find where your file was downloaded</li>
                <li>Find your chat: </li>
                <div style={{display: "flex",flexDirection: "row", alignSelf: 'center'}}>
                    {/* eslint-disable-next-line */}
                    <p className='p-instagram'>*Your-downloaded-file* {"\>"} messages {"\>"} inbox {"\>"} *name-of-the-chat-to-analyse*</p>
                </div>
                <li>Upload all message.json files into the chatsstat.com upload box </li>
                <div style={{display: "flex",flexDirection: "row", alignSelf: 'center'}}>
                    <p className='p-instagram'>e.g. message_1.json + message_2.json + message_3.json</p>
                </div>
            </ol>
        </div>
    );
};

export default GuideModal;