import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'
import './App.css';
import 'react-responsive-modal/styles.css';
import HomeScreen from './screens/HomeScreen.tsx';
import AnalysisScreen from './screens/AnalysisScreen.tsx';
import Footer from './components/footer/footer.tsx';
import PrivacyScreen from './screens/PrivacyScreen.tsx';
import SnapchatAnalysisScreen from './screens/Snapchat/SnapchatAnalysisScreen.tsx';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const error = console.error;
  console.error = (...args) => {
    if (/defaultProps/.test(args[0])) return;
    error(...args);
  };

  return (
    <BrowserRouter id="outer-container">
      <Menu width={ 280 } customBurgerIcon={ false } disableAutoFocus isOpen={isOpen}>  
        <NavLink onClick={() => setIsOpen(!isOpen)} to="/">Home</NavLink>
        <NavLink onClick={() => setIsOpen(!isOpen)} to="/analyse">Facebook + Instagram</NavLink>
        <NavLink onClick={() => setIsOpen(!isOpen)} to="/snap">Snapchat</NavLink>
        <NavLink className="priv" onClick={() => setIsOpen(!isOpen)} to="/privacy-policy">Privacy Policy</NavLink>
      </Menu>
      <header className="App-header">
        <div className="burger-menu" onClick={() => setIsOpen(!isOpen)}>☰</div>
        <nav>
          
          <NavLink className="nav-link-logo" to="/">
            <img src="logo512.png" alt="logo"/>
          </NavLink>
        </nav>
      </header>
      
      <div id="page-wrap">
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/analyse" element={<AnalysisScreen />} />
          <Route path="/snap" element={<SnapchatAnalysisScreen />} />
          <Route path="/privacy-policy" element={<PrivacyScreen />} />
        </Routes>

        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;