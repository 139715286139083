import React from "react";
import "./ChatSelect.css"
import SelectBox from "../../components/select-box/SelectBox.tsx";
import { Name, SnapchatName } from "../../models/models.tsx";

const ChatSelect = ({currentSelect, chatFile, onChange, setOpen}) => {
    const nameOptions = makeChatNameList();

    function makeChatNameList(): SnapchatName[] {
    
        let chatNames: SnapchatName[] = [];

        try{
            Object.keys(chatFile[0]).forEach((chat) => {
                // chat = key of the chat
                let chatId: string = chat;
                let participants: Name[] = [];
                
                chatFile[0][chat].forEach((message) => {
                    const fromText: string = message.From;
                    if (!participants.some((name) => name.detectedName === fromText)) {
                        participants.push({detectedName: fromText, preferredName: fromText});
                    }
                });
    
                let chatTitle: string = participants.map((name) => name.detectedName).join(", ");;
                if(chatFile[0][chat][0]["Conversation Title"] !== null && chatFile[0][chat][0]["Conversation Title"] !== "") {
                    chatTitle = chatFile[0][chat][0]["Conversation Title"];
                }
    
                chatNames.push({
                    name: chatTitle,
                    participants: participants,
                    description: participants.map((name) => name.detectedName).join(", "),
                    chatId: chatId
                });
            })
        }
        catch (e) {
            console.log(e)
        }
    
        return chatNames;
    }

    return (
        <div>
            <h2> Select the chat</h2>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <div className="home-uploadButton" onClick={() => setOpen(false)}>
                    Confirm
                </div>
            </div>
            
            <div className="scroll-list">
                <div className="spacer"/>
                {nameOptions.map((option, index) => {
                    const isSelected = currentSelect === option.name;
                    return (
                        <div key={index}>
                            <SelectBox option={option} isSelected={isSelected} onSelect={onChange} index={index} setCustomWord={undefined}/>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default ChatSelect