export default function extractEmojisFromCsv(): string[] {
    const lines = csvData.split('\n'); // Split by newline character
    const emojis = lines.map((line) => {
        const values = line.split(','); // Split by comma
        return values[4]; // Extract the emoji (fifth value)
    });
    return emojis;
}

const csvData = `
Group,Subgroup,CodePoint,Status,Representation,Name,Section
Activities,event,1F383,fully-qualified,🎃,jack-o-lantern,E0.6
Activities,event,1F384,fully-qualified,🎄,Christmas tree,E0.6
Activities,event,1F386,fully-qualified,🎆,fireworks,E0.6
Activities,event,1F387,fully-qualified,🎇,sparkler,E0.6
Activities,event,1F9E8,fully-qualified,🧨,firecracker,E11.0
Activities,event,2728,fully-qualified,✨,sparkles,E0.6
Activities,event,1F388,fully-qualified,🎈,balloon,E0.6
Activities,event,1F389,fully-qualified,🎉,party popper,E0.6
Activities,event,1F38A,fully-qualified,🎊,confetti ball,E0.6
Activities,event,1F38B,fully-qualified,🎋,tanabata tree,E0.6
Activities,event,1F38D,fully-qualified,🎍,pine decoration,E0.6
Activities,event,1F38E,fully-qualified,🎎,Japanese dolls,E0.6
Activities,event,1F38F,fully-qualified,🎏,carp streamer,E0.6
Activities,event,1F390,fully-qualified,🎐,wind chime,E0.6
Activities,event,1F391,fully-qualified,🎑,moon viewing ceremony,E0.6
Activities,event,1F9E7,fully-qualified,🧧,red envelope,E11.0
Activities,event,1F380,fully-qualified,🎀,ribbon,E0.6
Activities,event,1F381,fully-qualified,🎁,wrapped gift,E0.6
Activities,event,1F397 FE0F,fully-qualified,🎗️,reminder ribbon,E0.7
Activities,event,1F397,unqualified,🎗,reminder ribbon,E0.7
Activities,event,1F39F FE0F,fully-qualified,🎟️,admission tickets,E0.7
Activities,event,1F39F,unqualified,🎟,admission tickets,E0.7
Activities,event,1F3AB,fully-qualified,🎫,ticket,E0.6
Activities,award-medal,1F396 FE0F,fully-qualified,🎖️,military medal,E0.7
Activities,award-medal,1F396,unqualified,🎖,military medal,E0.7
Activities,award-medal,1F3C6,fully-qualified,🏆,trophy,E0.6
Activities,award-medal,1F3C5,fully-qualified,🏅,sports medal,E1.0
Activities,award-medal,1F947,fully-qualified,🥇,1st place medal,E3.0
Activities,award-medal,1F948,fully-qualified,🥈,2nd place medal,E3.0
Activities,award-medal,1F949,fully-qualified,🥉,3rd place medal,E3.0
Activities,sport,26BD,fully-qualified,⚽,soccer ball,E0.6
Activities,sport,26BE,fully-qualified,⚾,baseball,E0.6
Activities,sport,1F94E,fully-qualified,🥎,softball,E11.0
Activities,sport,1F3C0,fully-qualified,🏀,basketball,E0.6
Activities,sport,1F3D0,fully-qualified,🏐,volleyball,E1.0
Activities,sport,1F3C8,fully-qualified,🏈,american football,E0.6
Activities,sport,1F3C9,fully-qualified,🏉,rugby football,E1.0
Activities,sport,1F3BE,fully-qualified,🎾,tennis,E0.6
Activities,sport,1F94F,fully-qualified,🥏,flying disc,E11.0
Activities,sport,1F3B3,fully-qualified,🎳,bowling,E0.6
Activities,sport,1F3CF,fully-qualified,🏏,cricket game,E1.0
Activities,sport,1F3D1,fully-qualified,🏑,field hockey,E1.0
Activities,sport,1F3D2,fully-qualified,🏒,ice hockey,E1.0
Activities,sport,1F94D,fully-qualified,🥍,lacrosse,E11.0
Activities,sport,1F3D3,fully-qualified,🏓,ping pong,E1.0
Activities,sport,1F3F8,fully-qualified,🏸,badminton,E1.0
Activities,sport,1F94A,fully-qualified,🥊,boxing glove,E3.0
Activities,sport,1F94B,fully-qualified,🥋,martial arts uniform,E3.0
Activities,sport,1F945,fully-qualified,🥅,goal net,E3.0
Activities,sport,26F3,fully-qualified,⛳,flag in hole,E0.6
Activities,sport,26F8 FE0F,fully-qualified,⛸️,ice skate,E0.7
Activities,sport,26F8,unqualified,⛸,ice skate,E0.7
Activities,sport,1F3A3,fully-qualified,🎣,fishing pole,E0.6
Activities,sport,1F93F,fully-qualified,🤿,diving mask,E12.0
Activities,sport,1F3BD,fully-qualified,🎽,running shirt,E0.6
Activities,sport,1F3BF,fully-qualified,🎿,skis,E0.6
Activities,sport,1F6F7,fully-qualified,🛷,sled,E5.0
Activities,sport,1F94C,fully-qualified,🥌,curling stone,E5.0
Activities,game,1F3AF,fully-qualified,🎯,bullseye,E0.6
Activities,game,1FA80,fully-qualified,🪀,yo-yo,E12.0
Activities,game,1FA81,fully-qualified,🪁,kite,E12.0
Activities,game,1F52B,fully-qualified,🔫,water pistol,E0.6
Activities,game,1F3B1,fully-qualified,🎱,pool 8 ball,E0.6
Activities,game,1F52E,fully-qualified,🔮,crystal ball,E0.6
Activities,game,1FA84,fully-qualified,🪄,magic wand,E13.0
Activities,game,1F3AE,fully-qualified,🎮,video game,E0.6
Activities,game,1F579 FE0F,fully-qualified,🕹️,joystick,E0.7
Activities,game,1F579,unqualified,🕹,joystick,E0.7
Activities,game,1F3B0,fully-qualified,🎰,slot machine,E0.6
Activities,game,1F3B2,fully-qualified,🎲,game die,E0.6
Activities,game,1F9E9,fully-qualified,🧩,puzzle piece,E11.0
Activities,game,1F9F8,fully-qualified,🧸,teddy bear,E11.0
Activities,game,1FA85,fully-qualified,🪅,piata,E13.0
Activities,game,1FAA9,fully-qualified,🪩,mirror ball,E14.0
Activities,game,1FA86,fully-qualified,🪆,nesting dolls,E13.0
Activities,game,2660 FE0F,fully-qualified,♠️,spade suit,E0.6
Activities,game,2660,unqualified,♠,spade suit,E0.6
Activities,game,2665 FE0F,fully-qualified,♥️,heart suit,E0.6
Activities,game,2665,unqualified,♥,heart suit,E0.6
Activities,game,2666 FE0F,fully-qualified,♦️,diamond suit,E0.6
Activities,game,2666,unqualified,♦,diamond suit,E0.6
Activities,game,2663 FE0F,fully-qualified,♣️,club suit,E0.6
Activities,game,2663,unqualified,♣,club suit,E0.6
Activities,game,265F FE0F,fully-qualified,♟️,chess pawn,E11.0
Activities,game,265F,unqualified,♟,chess pawn,E11.0
Activities,game,1F0CF,fully-qualified,🃏,joker,E0.6
Activities,game,1F004,fully-qualified,🀄,mahjong red dragon,E0.6
Activities,game,1F3B4,fully-qualified,🎴,flower playing cards,E0.6
Activities,arts-crafts,1F3AD,fully-qualified,🎭,performing arts,E0.6
Activities,arts-crafts,1F5BC FE0F,fully-qualified,🖼️,framed picture,E0.7
Activities,arts-crafts,1F5BC,unqualified,🖼,framed picture,E0.7
Activities,arts-crafts,1F3A8,fully-qualified,🎨,artist palette,E0.6
Activities,arts-crafts,1F9F5,fully-qualified,🧵,thread,E11.0
Activities,arts-crafts,1FAA1,fully-qualified,🪡,sewing needle,E13.0
Activities,arts-crafts,1F9F6,fully-qualified,🧶,yarn,E11.0
Activities,arts-crafts,1FAA2,fully-qualified,🪢,knot,E13.0
Animals-Nature,animal-mammal,1F435,fully-qualified,🐵,monkey face,E0.6
Animals-Nature,animal-mammal,1F412,fully-qualified,🐒,monkey,E0.6
Animals-Nature,animal-mammal,1F98D,fully-qualified,🦍,gorilla,E3.0
Animals-Nature,animal-mammal,1F9A7,fully-qualified,🦧,orangutan,E12.0
Animals-Nature,animal-mammal,1F436,fully-qualified,🐶,dog face,E0.6
Animals-Nature,animal-mammal,1F415,fully-qualified,🐕,dog,E0.7
Animals-Nature,animal-mammal,1F9AE,fully-qualified,🦮,guide dog,E12.0
Animals-Nature,animal-mammal,1F415 200D 1F9BA,fully-qualified,🐕‍🦺,service dog,E12.0
Animals-Nature,animal-mammal,1F429,fully-qualified,🐩,poodle,E0.6
Animals-Nature,animal-mammal,1F43A,fully-qualified,🐺,wolf,E0.6
Animals-Nature,animal-mammal,1F98A,fully-qualified,🦊,fox,E3.0
Animals-Nature,animal-mammal,1F99D,fully-qualified,🦝,raccoon,E11.0
Animals-Nature,animal-mammal,1F431,fully-qualified,🐱,cat face,E0.6
Animals-Nature,animal-mammal,1F408,fully-qualified,🐈,cat,E0.7
Animals-Nature,animal-mammal,1F408 200D 2B1B,fully-qualified,🐈‍⬛,black cat,E13.0
Animals-Nature,animal-mammal,1F981,fully-qualified,🦁,lion,E1.0
Animals-Nature,animal-mammal,1F42F,fully-qualified,🐯,tiger face,E0.6
Animals-Nature,animal-mammal,1F405,fully-qualified,🐅,tiger,E1.0
Animals-Nature,animal-mammal,1F406,fully-qualified,🐆,leopard,E1.0
Animals-Nature,animal-mammal,1F434,fully-qualified,🐴,horse face,E0.6
Animals-Nature,animal-mammal,1FACE,fully-qualified,🫎,moose,E15.0
Animals-Nature,animal-mammal,1FACF,fully-qualified,🫏,donkey,E15.0
Animals-Nature,animal-mammal,1F40E,fully-qualified,🐎,horse,E0.6
Animals-Nature,animal-mammal,1F984,fully-qualified,🦄,unicorn,E1.0
Animals-Nature,animal-mammal,1F993,fully-qualified,🦓,zebra,E5.0
Animals-Nature,animal-mammal,1F98C,fully-qualified,🦌,deer,E3.0
Animals-Nature,animal-mammal,1F9AC,fully-qualified,🦬,bison,E13.0
Animals-Nature,animal-mammal,1F42E,fully-qualified,🐮,cow face,E0.6
Animals-Nature,animal-mammal,1F402,fully-qualified,🐂,ox,E1.0
Animals-Nature,animal-mammal,1F403,fully-qualified,🐃,water buffalo,E1.0
Animals-Nature,animal-mammal,1F404,fully-qualified,🐄,cow,E1.0
Animals-Nature,animal-mammal,1F437,fully-qualified,🐷,pig face,E0.6
Animals-Nature,animal-mammal,1F416,fully-qualified,🐖,pig,E1.0
Animals-Nature,animal-mammal,1F417,fully-qualified,🐗,boar,E0.6
Animals-Nature,animal-mammal,1F43D,fully-qualified,🐽,pig nose,E0.6
Animals-Nature,animal-mammal,1F40F,fully-qualified,🐏,ram,E1.0
Animals-Nature,animal-mammal,1F411,fully-qualified,🐑,ewe,E0.6
Animals-Nature,animal-mammal,1F410,fully-qualified,🐐,goat,E1.0
Animals-Nature,animal-mammal,1F42A,fully-qualified,🐪,camel,E1.0
Animals-Nature,animal-mammal,1F42B,fully-qualified,🐫,two-hump camel,E0.6
Animals-Nature,animal-mammal,1F999,fully-qualified,🦙,llama,E11.0
Animals-Nature,animal-mammal,1F992,fully-qualified,🦒,giraffe,E5.0
Animals-Nature,animal-mammal,1F418,fully-qualified,🐘,elephant,E0.6
Animals-Nature,animal-mammal,1F9A3,fully-qualified,🦣,mammoth,E13.0
Animals-Nature,animal-mammal,1F98F,fully-qualified,🦏,rhinoceros,E3.0
Animals-Nature,animal-mammal,1F99B,fully-qualified,🦛,hippopotamus,E11.0
Animals-Nature,animal-mammal,1F42D,fully-qualified,🐭,mouse face,E0.6
Animals-Nature,animal-mammal,1F401,fully-qualified,🐁,mouse,E1.0
Animals-Nature,animal-mammal,1F400,fully-qualified,🐀,rat,E1.0
Animals-Nature,animal-mammal,1F439,fully-qualified,🐹,hamster,E0.6
Animals-Nature,animal-mammal,1F430,fully-qualified,🐰,rabbit face,E0.6
Animals-Nature,animal-mammal,1F407,fully-qualified,🐇,rabbit,E1.0
Animals-Nature,animal-mammal,1F43F FE0F,fully-qualified,🐿️,chipmunk,E0.7
Animals-Nature,animal-mammal,1F43F,unqualified,🐿,chipmunk,E0.7
Animals-Nature,animal-mammal,1F9AB,fully-qualified,🦫,beaver,E13.0
Animals-Nature,animal-mammal,1F994,fully-qualified,🦔,hedgehog,E5.0
Animals-Nature,animal-mammal,1F987,fully-qualified,🦇,bat,E3.0
Animals-Nature,animal-mammal,1F43B,fully-qualified,🐻,bear,E0.6
Animals-Nature,animal-mammal,1F43B 200D 2744 FE0F,fully-qualified,🐻‍❄️,polar bear,E13.0
Animals-Nature,animal-mammal,1F43B 200D 2744,minimally-qualified,🐻‍❄,polar bear,E13.0
Animals-Nature,animal-mammal,1F428,fully-qualified,🐨,koala,E0.6
Animals-Nature,animal-mammal,1F43C,fully-qualified,🐼,panda,E0.6
Animals-Nature,animal-mammal,1F9A5,fully-qualified,🦥,sloth,E12.0
Animals-Nature,animal-mammal,1F9A6,fully-qualified,🦦,otter,E12.0
Animals-Nature,animal-mammal,1F9A8,fully-qualified,🦨,skunk,E12.0
Animals-Nature,animal-mammal,1F998,fully-qualified,🦘,kangaroo,E11.0
Animals-Nature,animal-mammal,1F9A1,fully-qualified,🦡,badger,E11.0
Animals-Nature,animal-mammal,1F43E,fully-qualified,🐾,paw prints,E0.6
Animals-Nature,animal-bird,1F983,fully-qualified,🦃,turkey,E1.0
Animals-Nature,animal-bird,1F414,fully-qualified,🐔,chicken,E0.6
Animals-Nature,animal-bird,1F413,fully-qualified,🐓,rooster,E1.0
Animals-Nature,animal-bird,1F423,fully-qualified,🐣,hatching chick,E0.6
Animals-Nature,animal-bird,1F424,fully-qualified,🐤,baby chick,E0.6
Animals-Nature,animal-bird,1F425,fully-qualified,🐥,front-facing baby chick,E0.6
Animals-Nature,animal-bird,1F426,fully-qualified,🐦,bird,E0.6
Animals-Nature,animal-bird,1F427,fully-qualified,🐧,penguin,E0.6
Animals-Nature,animal-bird,1F54A FE0F,fully-qualified,🕊️,dove,E0.7
Animals-Nature,animal-bird,1F54A,unqualified,🕊,dove,E0.7
Animals-Nature,animal-bird,1F985,fully-qualified,🦅,eagle,E3.0
Animals-Nature,animal-bird,1F986,fully-qualified,🦆,duck,E3.0
Animals-Nature,animal-bird,1F9A2,fully-qualified,🦢,swan,E11.0
Animals-Nature,animal-bird,1F989,fully-qualified,🦉,owl,E3.0
Animals-Nature,animal-bird,1F9A4,fully-qualified,🦤,dodo,E13.0
Animals-Nature,animal-bird,1FAB6,fully-qualified,🪶,feather,E13.0
Animals-Nature,animal-bird,1F9A9,fully-qualified,🦩,flamingo,E12.0
Animals-Nature,animal-bird,1F99A,fully-qualified,🦚,peacock,E11.0
Animals-Nature,animal-bird,1F99C,fully-qualified,🦜,parrot,E11.0
Animals-Nature,animal-bird,1FABD,fully-qualified,🪽,wing,E15.0
Animals-Nature,animal-bird,1F426 200D 2B1B,fully-qualified,🐦‍⬛,black bird,E15.0
Animals-Nature,animal-bird,1FABF,fully-qualified,🪿,goose,E15.0
Animals-Nature,animal-bird,1F426 200D 1F525,fully-qualified,🐦‍🔥,phoenix,E15.1
Animals-Nature,animal-amphibian,1F438,fully-qualified,🐸,frog,E0.6
Animals-Nature,animal-reptile,1F40A,fully-qualified,🐊,crocodile,E1.0
Animals-Nature,animal-reptile,1F422,fully-qualified,🐢,turtle,E0.6
Animals-Nature,animal-reptile,1F98E,fully-qualified,🦎,lizard,E3.0
Animals-Nature,animal-reptile,1F40D,fully-qualified,🐍,snake,E0.6
Animals-Nature,animal-reptile,1F432,fully-qualified,🐲,dragon face,E0.6
Animals-Nature,animal-reptile,1F409,fully-qualified,🐉,dragon,E1.0
Animals-Nature,animal-reptile,1F995,fully-qualified,🦕,sauropod,E5.0
Animals-Nature,animal-reptile,1F996,fully-qualified,🦖,T-Rex,E5.0
Animals-Nature,animal-marine,1F433,fully-qualified,🐳,spouting whale,E0.6
Animals-Nature,animal-marine,1F40B,fully-qualified,🐋,whale,E1.0
Animals-Nature,animal-marine,1F42C,fully-qualified,🐬,dolphin,E0.6
Animals-Nature,animal-marine,1F9AD,fully-qualified,🦭,seal,E13.0
Animals-Nature,animal-marine,1F41F,fully-qualified,🐟,fish,E0.6
Animals-Nature,animal-marine,1F420,fully-qualified,🐠,tropical fish,E0.6
Animals-Nature,animal-marine,1F421,fully-qualified,🐡,blowfish,E0.6
Animals-Nature,animal-marine,1F988,fully-qualified,🦈,shark,E3.0
Animals-Nature,animal-marine,1F419,fully-qualified,🐙,octopus,E0.6
Animals-Nature,animal-marine,1F41A,fully-qualified,🐚,spiral shell,E0.6
Animals-Nature,animal-marine,1FAB8,fully-qualified,🪸,coral,E14.0
Animals-Nature,animal-marine,1FABC,fully-qualified,🪼,jellyfish,E15.0
Animals-Nature,animal-bug,1F40C,fully-qualified,🐌,snail,E0.6
Animals-Nature,animal-bug,1F98B,fully-qualified,🦋,butterfly,E3.0
Animals-Nature,animal-bug,1F41B,fully-qualified,🐛,bug,E0.6
Animals-Nature,animal-bug,1F41C,fully-qualified,🐜,ant,E0.6
Animals-Nature,animal-bug,1F41D,fully-qualified,🐝,honeybee,E0.6
Animals-Nature,animal-bug,1FAB2,fully-qualified,🪲,beetle,E13.0
Animals-Nature,animal-bug,1F41E,fully-qualified,🐞,lady beetle,E0.6
Animals-Nature,animal-bug,1F997,fully-qualified,🦗,cricket,E5.0
Animals-Nature,animal-bug,1FAB3,fully-qualified,🪳,cockroach,E13.0
Animals-Nature,animal-bug,1F577 FE0F,fully-qualified,🕷️,spider,E0.7
Animals-Nature,animal-bug,1F577,unqualified,🕷,spider,E0.7
Animals-Nature,animal-bug,1F578 FE0F,fully-qualified,🕸️,spider web,E0.7
Animals-Nature,animal-bug,1F578,unqualified,🕸,spider web,E0.7
Animals-Nature,animal-bug,1F982,fully-qualified,🦂,scorpion,E1.0
Animals-Nature,animal-bug,1F99F,fully-qualified,🦟,mosquito,E11.0
Animals-Nature,animal-bug,1FAB0,fully-qualified,🪰,fly,E13.0
Animals-Nature,animal-bug,1FAB1,fully-qualified,🪱,worm,E13.0
Animals-Nature,animal-bug,1F9A0,fully-qualified,🦠,microbe,E11.0
Animals-Nature,plant-flower,1F490,fully-qualified,💐,bouquet,E0.6
Animals-Nature,plant-flower,1F338,fully-qualified,🌸,cherry blossom,E0.6
Animals-Nature,plant-flower,1F4AE,fully-qualified,💮,white flower,E0.6
Animals-Nature,plant-flower,1FAB7,fully-qualified,🪷,lotus,E14.0
Animals-Nature,plant-flower,1F3F5 FE0F,fully-qualified,🏵️,rosette,E0.7
Animals-Nature,plant-flower,1F3F5,unqualified,🏵,rosette,E0.7
Animals-Nature,plant-flower,1F339,fully-qualified,🌹,rose,E0.6
Animals-Nature,plant-flower,1F940,fully-qualified,🥀,wilted flower,E3.0
Animals-Nature,plant-flower,1F33A,fully-qualified,🌺,hibiscus,E0.6
Animals-Nature,plant-flower,1F33B,fully-qualified,🌻,sunflower,E0.6
Animals-Nature,plant-flower,1F33C,fully-qualified,🌼,blossom,E0.6
Animals-Nature,plant-flower,1F337,fully-qualified,🌷,tulip,E0.6
Animals-Nature,plant-flower,1FABB,fully-qualified,🪻,hyacinth,E15.0
Animals-Nature,plant-other,1F331,fully-qualified,🌱,seedling,E0.6
Animals-Nature,plant-other,1FAB4,fully-qualified,🪴,potted plant,E13.0
Animals-Nature,plant-other,1F332,fully-qualified,🌲,evergreen tree,E1.0
Animals-Nature,plant-other,1F333,fully-qualified,🌳,deciduous tree,E1.0
Animals-Nature,plant-other,1F334,fully-qualified,🌴,palm tree,E0.6
Animals-Nature,plant-other,1F335,fully-qualified,🌵,cactus,E0.6
Animals-Nature,plant-other,1F33E,fully-qualified,🌾,sheaf of rice,E0.6
Animals-Nature,plant-other,1F33F,fully-qualified,🌿,herb,E0.6
Animals-Nature,plant-other,2618 FE0F,fully-qualified,☘️,shamrock,E1.0
Animals-Nature,plant-other,2618,unqualified,☘,shamrock,E1.0
Animals-Nature,plant-other,1F340,fully-qualified,🍀,four leaf clover,E0.6
Animals-Nature,plant-other,1F341,fully-qualified,🍁,maple leaf,E0.6
Animals-Nature,plant-other,1F342,fully-qualified,🍂,fallen leaf,E0.6
Animals-Nature,plant-other,1F343,fully-qualified,🍃,leaf fluttering in wind,E0.6
Animals-Nature,plant-other,1FAB9,fully-qualified,🪹,empty nest,E14.0
Animals-Nature,plant-other,1FABA,fully-qualified,🪺,nest with eggs,E14.0
Animals-Nature,plant-other,1F344,fully-qualified,🍄,mushroom,E0.6
Component,skin-tone,1F3FB,component,🏻,light skin tone,E1.0
Component,skin-tone,1F3FC,component,🏼,medium-light skin tone,E1.0
Component,skin-tone,1F3FD,component,🏽,medium skin tone,E1.0
Component,skin-tone,1F3FE,component,🏾,medium-dark skin tone,E1.0
Component,skin-tone,1F3FF,component,🏿,dark skin tone,E1.0
Component,hair-style,1F9B0,component,🦰,red hair,E11.0
Component,hair-style,1F9B1,component,🦱,curly hair,E11.0
Component,hair-style,1F9B3,component,🦳,white hair,E11.0
Component,hair-style,1F9B2,component,🦲,bald,E11.0
Flags,flag,1F3C1,fully-qualified,🏁,chequered flag,E0.6
Flags,flag,1F6A9,fully-qualified,🚩,triangular flag,E0.6
Flags,flag,1F38C,fully-qualified,🎌,crossed flags,E0.6
Flags,flag,1F3F4,fully-qualified,🏴,black flag,E1.0
Flags,flag,1F3F3 FE0F,fully-qualified,🏳️,white flag,E0.7
Flags,flag,1F3F3,unqualified,🏳,white flag,E0.7
Flags,flag,1F3F3 FE0F 200D 1F308,fully-qualified,🏳️‍🌈,rainbow flag,E4.0
Flags,flag,1F3F3 200D 1F308,unqualified,🏳‍🌈,rainbow flag,E4.0
Flags,flag,1F3F3 FE0F 200D 26A7 FE0F,fully-qualified,🏳️‍⚧️,transgender flag,E13.0
Flags,flag,1F3F3 200D 26A7 FE0F,unqualified,🏳‍⚧️,transgender flag,E13.0
Flags,flag,1F3F3 FE0F 200D 26A7,minimally-qualified,🏳️‍⚧,transgender flag,E13.0
Flags,flag,1F3F3 200D 26A7,unqualified,🏳‍⚧,transgender flag,E13.0
Flags,flag,1F3F4 200D 2620 FE0F,fully-qualified,🏴‍☠️,pirate flag,E11.0
Flags,flag,1F3F4 200D 2620,minimally-qualified,🏴‍☠,pirate flag,E11.0
Flags,country-flag,1F1E6 1F1E8,fully-qualified,🇦🇨,flag Ascension Island,E2.0
Flags,country-flag,1F1E6 1F1E9,fully-qualified,🇦🇩,flag Andorra,E2.0
Flags,country-flag,1F1E6 1F1EA,fully-qualified,🇦🇪,flag United Arab Emirates,E2.0
Flags,country-flag,1F1E6 1F1EB,fully-qualified,🇦🇫,flag Afghanistan,E2.0
Flags,country-flag,1F1E6 1F1EC,fully-qualified,🇦🇬,flag Antigua  Barbuda,E2.0
Flags,country-flag,1F1E6 1F1EE,fully-qualified,🇦🇮,flag Anguilla,E2.0
Flags,country-flag,1F1E6 1F1F1,fully-qualified,🇦🇱,flag Albania,E2.0
Flags,country-flag,1F1E6 1F1F2,fully-qualified,🇦🇲,flag Armenia,E2.0
Flags,country-flag,1F1E6 1F1F4,fully-qualified,🇦🇴,flag Angola,E2.0
Flags,country-flag,1F1E6 1F1F6,fully-qualified,🇦🇶,flag Antarctica,E2.0
Flags,country-flag,1F1E6 1F1F7,fully-qualified,🇦🇷,flag Argentina,E2.0
Flags,country-flag,1F1E6 1F1F8,fully-qualified,🇦🇸,flag American Samoa,E2.0
Flags,country-flag,1F1E6 1F1F9,fully-qualified,🇦🇹,flag Austria,E2.0
Flags,country-flag,1F1E6 1F1FA,fully-qualified,🇦🇺,flag Australia,E2.0
Flags,country-flag,1F1E6 1F1FC,fully-qualified,🇦🇼,flag Aruba,E2.0
Flags,country-flag,1F1E6 1F1FD,fully-qualified,🇦🇽,flag land Islands,E2.0
Flags,country-flag,1F1E6 1F1FF,fully-qualified,🇦🇿,flag Azerbaijan,E2.0
Flags,country-flag,1F1E7 1F1E6,fully-qualified,🇧🇦,flag Bosnia  Herzegovina,E2.0
Flags,country-flag,1F1E7 1F1E7,fully-qualified,🇧🇧,flag Barbados,E2.0
Flags,country-flag,1F1E7 1F1E9,fully-qualified,🇧🇩,flag Bangladesh,E2.0
Flags,country-flag,1F1E7 1F1EA,fully-qualified,🇧🇪,flag Belgium,E2.0
Flags,country-flag,1F1E7 1F1EB,fully-qualified,🇧🇫,flag Burkina Faso,E2.0
Flags,country-flag,1F1E7 1F1EC,fully-qualified,🇧🇬,flag Bulgaria,E2.0
Flags,country-flag,1F1E7 1F1ED,fully-qualified,🇧🇭,flag Bahrain,E2.0
Flags,country-flag,1F1E7 1F1EE,fully-qualified,🇧🇮,flag Burundi,E2.0
Flags,country-flag,1F1E7 1F1EF,fully-qualified,🇧🇯,flag Benin,E2.0
Flags,country-flag,1F1E7 1F1F1,fully-qualified,🇧🇱,flag St Barthlemy,E2.0
Flags,country-flag,1F1E7 1F1F2,fully-qualified,🇧🇲,flag Bermuda,E2.0
Flags,country-flag,1F1E7 1F1F3,fully-qualified,🇧🇳,flag Brunei,E2.0
Flags,country-flag,1F1E7 1F1F4,fully-qualified,🇧🇴,flag Bolivia,E2.0
Flags,country-flag,1F1E7 1F1F6,fully-qualified,🇧🇶,flag Caribbean Netherlands,E2.0
Flags,country-flag,1F1E7 1F1F7,fully-qualified,🇧🇷,flag Brazil,E2.0
Flags,country-flag,1F1E7 1F1F8,fully-qualified,🇧🇸,flag Bahamas,E2.0
Flags,country-flag,1F1E7 1F1F9,fully-qualified,🇧🇹,flag Bhutan,E2.0
Flags,country-flag,1F1E7 1F1FB,fully-qualified,🇧🇻,flag Bouvet Island,E2.0
Flags,country-flag,1F1E7 1F1FC,fully-qualified,🇧🇼,flag Botswana,E2.0
Flags,country-flag,1F1E7 1F1FE,fully-qualified,🇧🇾,flag Belarus,E2.0
Flags,country-flag,1F1E7 1F1FF,fully-qualified,🇧🇿,flag Belize,E2.0
Flags,country-flag,1F1E8 1F1E6,fully-qualified,🇨🇦,flag Canada,E2.0
Flags,country-flag,1F1E8 1F1E8,fully-qualified,🇨🇨,flag Cocos Keeling Islands,E2.0
Flags,country-flag,1F1E8 1F1E9,fully-qualified,🇨🇩,flag Congo - Kinshasa,E2.0
Flags,country-flag,1F1E8 1F1EB,fully-qualified,🇨🇫,flag Central African Republic,E2.0
Flags,country-flag,1F1E8 1F1EC,fully-qualified,🇨🇬,flag Congo - Brazzaville,E2.0
Flags,country-flag,1F1E8 1F1ED,fully-qualified,🇨🇭,flag Switzerland,E2.0
Flags,country-flag,1F1E8 1F1EE,fully-qualified,🇨🇮,flag Cte dIvoire,E2.0
Flags,country-flag,1F1E8 1F1F0,fully-qualified,🇨🇰,flag Cook Islands,E2.0
Flags,country-flag,1F1E8 1F1F1,fully-qualified,🇨🇱,flag Chile,E2.0
Flags,country-flag,1F1E8 1F1F2,fully-qualified,🇨🇲,flag Cameroon,E2.0
Flags,country-flag,1F1E8 1F1F3,fully-qualified,🇨🇳,flag China,E0.6
Flags,country-flag,1F1E8 1F1F4,fully-qualified,🇨🇴,flag Colombia,E2.0
Flags,country-flag,1F1E8 1F1F5,fully-qualified,🇨🇵,flag Clipperton Island,E2.0
Flags,country-flag,1F1E8 1F1F7,fully-qualified,🇨🇷,flag Costa Rica,E2.0
Flags,country-flag,1F1E8 1F1FA,fully-qualified,🇨🇺,flag Cuba,E2.0
Flags,country-flag,1F1E8 1F1FB,fully-qualified,🇨🇻,flag Cape Verde,E2.0
Flags,country-flag,1F1E8 1F1FC,fully-qualified,🇨🇼,flag Curaao,E2.0
Flags,country-flag,1F1E8 1F1FD,fully-qualified,🇨🇽,flag Christmas Island,E2.0
Flags,country-flag,1F1E8 1F1FE,fully-qualified,🇨🇾,flag Cyprus,E2.0
Flags,country-flag,1F1E8 1F1FF,fully-qualified,🇨🇿,flag Czechia,E2.0
Flags,country-flag,1F1E9 1F1EA,fully-qualified,🇩🇪,flag Germany,E0.6
Flags,country-flag,1F1E9 1F1EC,fully-qualified,🇩🇬,flag Diego Garcia,E2.0
Flags,country-flag,1F1E9 1F1EF,fully-qualified,🇩🇯,flag Djibouti,E2.0
Flags,country-flag,1F1E9 1F1F0,fully-qualified,🇩🇰,flag Denmark,E2.0
Flags,country-flag,1F1E9 1F1F2,fully-qualified,🇩🇲,flag Dominica,E2.0
Flags,country-flag,1F1E9 1F1F4,fully-qualified,🇩🇴,flag Dominican Republic,E2.0
Flags,country-flag,1F1E9 1F1FF,fully-qualified,🇩🇿,flag Algeria,E2.0
Flags,country-flag,1F1EA 1F1E6,fully-qualified,🇪🇦,flag Ceuta  Melilla,E2.0
Flags,country-flag,1F1EA 1F1E8,fully-qualified,🇪🇨,flag Ecuador,E2.0
Flags,country-flag,1F1EA 1F1EA,fully-qualified,🇪🇪,flag Estonia,E2.0
Flags,country-flag,1F1EA 1F1EC,fully-qualified,🇪🇬,flag Egypt,E2.0
Flags,country-flag,1F1EA 1F1ED,fully-qualified,🇪🇭,flag Western Sahara,E2.0
Flags,country-flag,1F1EA 1F1F7,fully-qualified,🇪🇷,flag Eritrea,E2.0
Flags,country-flag,1F1EA 1F1F8,fully-qualified,🇪🇸,flag Spain,E0.6
Flags,country-flag,1F1EA 1F1F9,fully-qualified,🇪🇹,flag Ethiopia,E2.0
Flags,country-flag,1F1EA 1F1FA,fully-qualified,🇪🇺,flag European Union,E2.0
Flags,country-flag,1F1EB 1F1EE,fully-qualified,🇫🇮,flag Finland,E2.0
Flags,country-flag,1F1EB 1F1EF,fully-qualified,🇫🇯,flag Fiji,E2.0
Flags,country-flag,1F1EB 1F1F0,fully-qualified,🇫🇰,flag Falkland Islands,E2.0
Flags,country-flag,1F1EB 1F1F2,fully-qualified,🇫🇲,flag Micronesia,E2.0
Flags,country-flag,1F1EB 1F1F4,fully-qualified,🇫🇴,flag Faroe Islands,E2.0
Flags,country-flag,1F1EB 1F1F7,fully-qualified,🇫🇷,flag France,E0.6
Flags,country-flag,1F1EC 1F1E6,fully-qualified,🇬🇦,flag Gabon,E2.0
Flags,country-flag,1F1EC 1F1E7,fully-qualified,🇬🇧,flag United Kingdom,E0.6
Flags,country-flag,1F1EC 1F1E9,fully-qualified,🇬🇩,flag Grenada,E2.0
Flags,country-flag,1F1EC 1F1EA,fully-qualified,🇬🇪,flag Georgia,E2.0
Flags,country-flag,1F1EC 1F1EB,fully-qualified,🇬🇫,flag French Guiana,E2.0
Flags,country-flag,1F1EC 1F1EC,fully-qualified,🇬🇬,flag Guernsey,E2.0
Flags,country-flag,1F1EC 1F1ED,fully-qualified,🇬🇭,flag Ghana,E2.0
Flags,country-flag,1F1EC 1F1EE,fully-qualified,🇬🇮,flag Gibraltar,E2.0
Flags,country-flag,1F1EC 1F1F1,fully-qualified,🇬🇱,flag Greenland,E2.0
Flags,country-flag,1F1EC 1F1F2,fully-qualified,🇬🇲,flag Gambia,E2.0
Flags,country-flag,1F1EC 1F1F3,fully-qualified,🇬🇳,flag Guinea,E2.0
Flags,country-flag,1F1EC 1F1F5,fully-qualified,🇬🇵,flag Guadeloupe,E2.0
Flags,country-flag,1F1EC 1F1F6,fully-qualified,🇬🇶,flag Equatorial Guinea,E2.0
Flags,country-flag,1F1EC 1F1F7,fully-qualified,🇬🇷,flag Greece,E2.0
Flags,country-flag,1F1EC 1F1F8,fully-qualified,🇬🇸,flag South Georgia  South Sandwich Islands,E2.0
Flags,country-flag,1F1EC 1F1F9,fully-qualified,🇬🇹,flag Guatemala,E2.0
Flags,country-flag,1F1EC 1F1FA,fully-qualified,🇬🇺,flag Guam,E2.0
Flags,country-flag,1F1EC 1F1FC,fully-qualified,🇬🇼,flag Guinea-Bissau,E2.0
Flags,country-flag,1F1EC 1F1FE,fully-qualified,🇬🇾,flag Guyana,E2.0
Flags,country-flag,1F1ED 1F1F0,fully-qualified,🇭🇰,flag Hong Kong SAR China,E2.0
Flags,country-flag,1F1ED 1F1F2,fully-qualified,🇭🇲,flag Heard  McDonald Islands,E2.0
Flags,country-flag,1F1ED 1F1F3,fully-qualified,🇭🇳,flag Honduras,E2.0
Flags,country-flag,1F1ED 1F1F7,fully-qualified,🇭🇷,flag Croatia,E2.0
Flags,country-flag,1F1ED 1F1F9,fully-qualified,🇭🇹,flag Haiti,E2.0
Flags,country-flag,1F1ED 1F1FA,fully-qualified,🇭🇺,flag Hungary,E2.0
Flags,country-flag,1F1EE 1F1E8,fully-qualified,🇮🇨,flag Canary Islands,E2.0
Flags,country-flag,1F1EE 1F1E9,fully-qualified,🇮🇩,flag Indonesia,E2.0
Flags,country-flag,1F1EE 1F1EA,fully-qualified,🇮🇪,flag Ireland,E2.0
Flags,country-flag,1F1EE 1F1F1,fully-qualified,🇮🇱,flag Israel,E2.0
Flags,country-flag,1F1EE 1F1F2,fully-qualified,🇮🇲,flag Isle of Man,E2.0
Flags,country-flag,1F1EE 1F1F3,fully-qualified,🇮🇳,flag India,E2.0
Flags,country-flag,1F1EE 1F1F4,fully-qualified,🇮🇴,flag British Indian Ocean Territory,E2.0
Flags,country-flag,1F1EE 1F1F6,fully-qualified,🇮🇶,flag Iraq,E2.0
Flags,country-flag,1F1EE 1F1F7,fully-qualified,🇮🇷,flag Iran,E2.0
Flags,country-flag,1F1EE 1F1F8,fully-qualified,🇮🇸,flag Iceland,E2.0
Flags,country-flag,1F1EE 1F1F9,fully-qualified,🇮🇹,flag Italy,E0.6
Flags,country-flag,1F1EF 1F1EA,fully-qualified,🇯🇪,flag Jersey,E2.0
Flags,country-flag,1F1EF 1F1F2,fully-qualified,🇯🇲,flag Jamaica,E2.0
Flags,country-flag,1F1EF 1F1F4,fully-qualified,🇯🇴,flag Jordan,E2.0
Flags,country-flag,1F1EF 1F1F5,fully-qualified,🇯🇵,flag Japan,E0.6
Flags,country-flag,1F1F0 1F1EA,fully-qualified,🇰🇪,flag Kenya,E2.0
Flags,country-flag,1F1F0 1F1EC,fully-qualified,🇰🇬,flag Kyrgyzstan,E2.0
Flags,country-flag,1F1F0 1F1ED,fully-qualified,🇰🇭,flag Cambodia,E2.0
Flags,country-flag,1F1F0 1F1EE,fully-qualified,🇰🇮,flag Kiribati,E2.0
Flags,country-flag,1F1F0 1F1F2,fully-qualified,🇰🇲,flag Comoros,E2.0
Flags,country-flag,1F1F0 1F1F3,fully-qualified,🇰🇳,flag St Kitts  Nevis,E2.0
Flags,country-flag,1F1F0 1F1F5,fully-qualified,🇰🇵,flag North Korea,E2.0
Flags,country-flag,1F1F0 1F1F7,fully-qualified,🇰🇷,flag South Korea,E0.6
Flags,country-flag,1F1F0 1F1FC,fully-qualified,🇰🇼,flag Kuwait,E2.0
Flags,country-flag,1F1F0 1F1FE,fully-qualified,🇰🇾,flag Cayman Islands,E2.0
Flags,country-flag,1F1F0 1F1FF,fully-qualified,🇰🇿,flag Kazakhstan,E2.0
Flags,country-flag,1F1F1 1F1E6,fully-qualified,🇱🇦,flag Laos,E2.0
Flags,country-flag,1F1F1 1F1E7,fully-qualified,🇱🇧,flag Lebanon,E2.0
Flags,country-flag,1F1F1 1F1E8,fully-qualified,🇱🇨,flag St Lucia,E2.0
Flags,country-flag,1F1F1 1F1EE,fully-qualified,🇱🇮,flag Liechtenstein,E2.0
Flags,country-flag,1F1F1 1F1F0,fully-qualified,🇱🇰,flag Sri Lanka,E2.0
Flags,country-flag,1F1F1 1F1F7,fully-qualified,🇱🇷,flag Liberia,E2.0
Flags,country-flag,1F1F1 1F1F8,fully-qualified,🇱🇸,flag Lesotho,E2.0
Flags,country-flag,1F1F1 1F1F9,fully-qualified,🇱🇹,flag Lithuania,E2.0
Flags,country-flag,1F1F1 1F1FA,fully-qualified,🇱🇺,flag Luxembourg,E2.0
Flags,country-flag,1F1F1 1F1FB,fully-qualified,🇱🇻,flag Latvia,E2.0
Flags,country-flag,1F1F1 1F1FE,fully-qualified,🇱🇾,flag Libya,E2.0
Flags,country-flag,1F1F2 1F1E6,fully-qualified,🇲🇦,flag Morocco,E2.0
Flags,country-flag,1F1F2 1F1E8,fully-qualified,🇲🇨,flag Monaco,E2.0
Flags,country-flag,1F1F2 1F1E9,fully-qualified,🇲🇩,flag Moldova,E2.0
Flags,country-flag,1F1F2 1F1EA,fully-qualified,🇲🇪,flag Montenegro,E2.0
Flags,country-flag,1F1F2 1F1EB,fully-qualified,🇲🇫,flag St Martin,E2.0
Flags,country-flag,1F1F2 1F1EC,fully-qualified,🇲🇬,flag Madagascar,E2.0
Flags,country-flag,1F1F2 1F1ED,fully-qualified,🇲🇭,flag Marshall Islands,E2.0
Flags,country-flag,1F1F2 1F1F0,fully-qualified,🇲🇰,flag North Macedonia,E2.0
Flags,country-flag,1F1F2 1F1F1,fully-qualified,🇲🇱,flag Mali,E2.0
Flags,country-flag,1F1F2 1F1F2,fully-qualified,🇲🇲,flag Myanmar Burma,E2.0
Flags,country-flag,1F1F2 1F1F3,fully-qualified,🇲🇳,flag Mongolia,E2.0
Flags,country-flag,1F1F2 1F1F4,fully-qualified,🇲🇴,flag Macao SAR China,E2.0
Flags,country-flag,1F1F2 1F1F5,fully-qualified,🇲🇵,flag Northern Mariana Islands,E2.0
Flags,country-flag,1F1F2 1F1F6,fully-qualified,🇲🇶,flag Martinique,E2.0
Flags,country-flag,1F1F2 1F1F7,fully-qualified,🇲🇷,flag Mauritania,E2.0
Flags,country-flag,1F1F2 1F1F8,fully-qualified,🇲🇸,flag Montserrat,E2.0
Flags,country-flag,1F1F2 1F1F9,fully-qualified,🇲🇹,flag Malta,E2.0
Flags,country-flag,1F1F2 1F1FA,fully-qualified,🇲🇺,flag Mauritius,E2.0
Flags,country-flag,1F1F2 1F1FB,fully-qualified,🇲🇻,flag Maldives,E2.0
Flags,country-flag,1F1F2 1F1FC,fully-qualified,🇲🇼,flag Malawi,E2.0
Flags,country-flag,1F1F2 1F1FD,fully-qualified,🇲🇽,flag Mexico,E2.0
Flags,country-flag,1F1F2 1F1FE,fully-qualified,🇲🇾,flag Malaysia,E2.0
Flags,country-flag,1F1F2 1F1FF,fully-qualified,🇲🇿,flag Mozambique,E2.0
Flags,country-flag,1F1F3 1F1E6,fully-qualified,🇳🇦,flag Namibia,E2.0
Flags,country-flag,1F1F3 1F1E8,fully-qualified,🇳🇨,flag New Caledonia,E2.0
Flags,country-flag,1F1F3 1F1EA,fully-qualified,🇳🇪,flag Niger,E2.0
Flags,country-flag,1F1F3 1F1EB,fully-qualified,🇳🇫,flag Norfolk Island,E2.0
Flags,country-flag,1F1F3 1F1EC,fully-qualified,🇳🇬,flag Nigeria,E2.0
Flags,country-flag,1F1F3 1F1EE,fully-qualified,🇳🇮,flag Nicaragua,E2.0
Flags,country-flag,1F1F3 1F1F1,fully-qualified,🇳🇱,flag Netherlands,E2.0
Flags,country-flag,1F1F3 1F1F4,fully-qualified,🇳🇴,flag Norway,E2.0
Flags,country-flag,1F1F3 1F1F5,fully-qualified,🇳🇵,flag Nepal,E2.0
Flags,country-flag,1F1F3 1F1F7,fully-qualified,🇳🇷,flag Nauru,E2.0
Flags,country-flag,1F1F3 1F1FA,fully-qualified,🇳🇺,flag Niue,E2.0
Flags,country-flag,1F1F3 1F1FF,fully-qualified,🇳🇿,flag New Zealand,E2.0
Flags,country-flag,1F1F4 1F1F2,fully-qualified,🇴🇲,flag Oman,E2.0
Flags,country-flag,1F1F5 1F1E6,fully-qualified,🇵🇦,flag Panama,E2.0
Flags,country-flag,1F1F5 1F1EA,fully-qualified,🇵🇪,flag Peru,E2.0
Flags,country-flag,1F1F5 1F1EB,fully-qualified,🇵🇫,flag French Polynesia,E2.0
Flags,country-flag,1F1F5 1F1EC,fully-qualified,🇵🇬,flag Papua New Guinea,E2.0
Flags,country-flag,1F1F5 1F1ED,fully-qualified,🇵🇭,flag Philippines,E2.0
Flags,country-flag,1F1F5 1F1F0,fully-qualified,🇵🇰,flag Pakistan,E2.0
Flags,country-flag,1F1F5 1F1F1,fully-qualified,🇵🇱,flag Poland,E2.0
Flags,country-flag,1F1F5 1F1F2,fully-qualified,🇵🇲,flag St Pierre  Miquelon,E2.0
Flags,country-flag,1F1F5 1F1F3,fully-qualified,🇵🇳,flag Pitcairn Islands,E2.0
Flags,country-flag,1F1F5 1F1F7,fully-qualified,🇵🇷,flag Puerto Rico,E2.0
Flags,country-flag,1F1F5 1F1F8,fully-qualified,🇵🇸,flag Palestinian Territories,E2.0
Flags,country-flag,1F1F5 1F1F9,fully-qualified,🇵🇹,flag Portugal,E2.0
Flags,country-flag,1F1F5 1F1FC,fully-qualified,🇵🇼,flag Palau,E2.0
Flags,country-flag,1F1F5 1F1FE,fully-qualified,🇵🇾,flag Paraguay,E2.0
Flags,country-flag,1F1F6 1F1E6,fully-qualified,🇶🇦,flag Qatar,E2.0
Flags,country-flag,1F1F7 1F1EA,fully-qualified,🇷🇪,flag Runion,E2.0
Flags,country-flag,1F1F7 1F1F4,fully-qualified,🇷🇴,flag Romania,E2.0
Flags,country-flag,1F1F7 1F1F8,fully-qualified,🇷🇸,flag Serbia,E2.0
Flags,country-flag,1F1F7 1F1FA,fully-qualified,🇷🇺,flag Russia,E0.6
Flags,country-flag,1F1F7 1F1FC,fully-qualified,🇷🇼,flag Rwanda,E2.0
Flags,country-flag,1F1F8 1F1E6,fully-qualified,🇸🇦,flag Saudi Arabia,E2.0
Flags,country-flag,1F1F8 1F1E7,fully-qualified,🇸🇧,flag Solomon Islands,E2.0
Flags,country-flag,1F1F8 1F1E8,fully-qualified,🇸🇨,flag Seychelles,E2.0
Flags,country-flag,1F1F8 1F1E9,fully-qualified,🇸🇩,flag Sudan,E2.0
Flags,country-flag,1F1F8 1F1EA,fully-qualified,🇸🇪,flag Sweden,E2.0
Flags,country-flag,1F1F8 1F1EC,fully-qualified,🇸🇬,flag Singapore,E2.0
Flags,country-flag,1F1F8 1F1ED,fully-qualified,🇸🇭,flag St Helena,E2.0
Flags,country-flag,1F1F8 1F1EE,fully-qualified,🇸🇮,flag Slovenia,E2.0
Flags,country-flag,1F1F8 1F1EF,fully-qualified,🇸🇯,flag Svalbard  Jan Mayen,E2.0
Flags,country-flag,1F1F8 1F1F0,fully-qualified,🇸🇰,flag Slovakia,E2.0
Flags,country-flag,1F1F8 1F1F1,fully-qualified,🇸🇱,flag Sierra Leone,E2.0
Flags,country-flag,1F1F8 1F1F2,fully-qualified,🇸🇲,flag San Marino,E2.0
Flags,country-flag,1F1F8 1F1F3,fully-qualified,🇸🇳,flag Senegal,E2.0
Flags,country-flag,1F1F8 1F1F4,fully-qualified,🇸🇴,flag Somalia,E2.0
Flags,country-flag,1F1F8 1F1F7,fully-qualified,🇸🇷,flag Suriname,E2.0
Flags,country-flag,1F1F8 1F1F8,fully-qualified,🇸🇸,flag South Sudan,E2.0
Flags,country-flag,1F1F8 1F1F9,fully-qualified,🇸🇹,flag So Tom  Prncipe,E2.0
Flags,country-flag,1F1F8 1F1FB,fully-qualified,🇸🇻,flag El Salvador,E2.0
Flags,country-flag,1F1F8 1F1FD,fully-qualified,🇸🇽,flag Sint Maarten,E2.0
Flags,country-flag,1F1F8 1F1FE,fully-qualified,🇸🇾,flag Syria,E2.0
Flags,country-flag,1F1F8 1F1FF,fully-qualified,🇸🇿,flag Eswatini,E2.0
Flags,country-flag,1F1F9 1F1E6,fully-qualified,🇹🇦,flag Tristan da Cunha,E2.0
Flags,country-flag,1F1F9 1F1E8,fully-qualified,🇹🇨,flag Turks  Caicos Islands,E2.0
Flags,country-flag,1F1F9 1F1E9,fully-qualified,🇹🇩,flag Chad,E2.0
Flags,country-flag,1F1F9 1F1EB,fully-qualified,🇹🇫,flag French Southern Territories,E2.0
Flags,country-flag,1F1F9 1F1EC,fully-qualified,🇹🇬,flag Togo,E2.0
Flags,country-flag,1F1F9 1F1ED,fully-qualified,🇹🇭,flag Thailand,E2.0
Flags,country-flag,1F1F9 1F1EF,fully-qualified,🇹🇯,flag Tajikistan,E2.0
Flags,country-flag,1F1F9 1F1F0,fully-qualified,🇹🇰,flag Tokelau,E2.0
Flags,country-flag,1F1F9 1F1F1,fully-qualified,🇹🇱,flag Timor-Leste,E2.0
Flags,country-flag,1F1F9 1F1F2,fully-qualified,🇹🇲,flag Turkmenistan,E2.0
Flags,country-flag,1F1F9 1F1F3,fully-qualified,🇹🇳,flag Tunisia,E2.0
Flags,country-flag,1F1F9 1F1F4,fully-qualified,🇹🇴,flag Tonga,E2.0
Flags,country-flag,1F1F9 1F1F7,fully-qualified,🇹🇷,flag Trkiye,E2.0
Flags,country-flag,1F1F9 1F1F9,fully-qualified,🇹🇹,flag Trinidad  Tobago,E2.0
Flags,country-flag,1F1F9 1F1FB,fully-qualified,🇹🇻,flag Tuvalu,E2.0
Flags,country-flag,1F1F9 1F1FC,fully-qualified,🇹🇼,flag Taiwan,E2.0
Flags,country-flag,1F1F9 1F1FF,fully-qualified,🇹🇿,flag Tanzania,E2.0
Flags,country-flag,1F1FA 1F1E6,fully-qualified,🇺🇦,flag Ukraine,E2.0
Flags,country-flag,1F1FA 1F1EC,fully-qualified,🇺🇬,flag Uganda,E2.0
Flags,country-flag,1F1FA 1F1F2,fully-qualified,🇺🇲,flag US Outlying Islands,E2.0
Flags,country-flag,1F1FA 1F1F3,fully-qualified,🇺🇳,flag United Nations,E4.0
Flags,country-flag,1F1FA 1F1F8,fully-qualified,🇺🇸,flag United States,E0.6
Flags,country-flag,1F1FA 1F1FE,fully-qualified,🇺🇾,flag Uruguay,E2.0
Flags,country-flag,1F1FA 1F1FF,fully-qualified,🇺🇿,flag Uzbekistan,E2.0
Flags,country-flag,1F1FB 1F1E6,fully-qualified,🇻🇦,flag Vatican City,E2.0
Flags,country-flag,1F1FB 1F1E8,fully-qualified,🇻🇨,flag St Vincent  Grenadines,E2.0
Flags,country-flag,1F1FB 1F1EA,fully-qualified,🇻🇪,flag Venezuela,E2.0
Flags,country-flag,1F1FB 1F1EC,fully-qualified,🇻🇬,flag British Virgin Islands,E2.0
Flags,country-flag,1F1FB 1F1EE,fully-qualified,🇻🇮,flag US Virgin Islands,E2.0
Flags,country-flag,1F1FB 1F1F3,fully-qualified,🇻🇳,flag Vietnam,E2.0
Flags,country-flag,1F1FB 1F1FA,fully-qualified,🇻🇺,flag Vanuatu,E2.0
Flags,country-flag,1F1FC 1F1EB,fully-qualified,🇼🇫,flag Wallis  Futuna,E2.0
Flags,country-flag,1F1FC 1F1F8,fully-qualified,🇼🇸,flag Samoa,E2.0
Flags,country-flag,1F1FD 1F1F0,fully-qualified,🇽🇰,flag Kosovo,E2.0
Flags,country-flag,1F1FE 1F1EA,fully-qualified,🇾🇪,flag Yemen,E2.0
Flags,country-flag,1F1FE 1F1F9,fully-qualified,🇾🇹,flag Mayotte,E2.0
Flags,country-flag,1F1FF 1F1E6,fully-qualified,🇿🇦,flag South Africa,E2.0
Flags,country-flag,1F1FF 1F1F2,fully-qualified,🇿🇲,flag Zambia,E2.0
Flags,country-flag,1F1FF 1F1FC,fully-qualified,🇿🇼,flag Zimbabwe,E2.0
Flags,subdivision-flag,1F3F4 E0067 E0062 E0065 E006E E0067 E007F,fully-qualified,🏴󠁧󠁢󠁥󠁮󠁧󠁿,flag England,E5.0
Flags,subdivision-flag,1F3F4 E0067 E0062 E0073 E0063 E0074 E007F,fully-qualified,🏴󠁧󠁢󠁳󠁣󠁴󠁿,flag Scotland,E5.0
Flags,subdivision-flag,1F3F4 E0067 E0062 E0077 E006C E0073 E007F,fully-qualified,🏴󠁧󠁢󠁷󠁬󠁳󠁿,flag Wales,E5.0
Food-Drink,food-fruit,1F347,fully-qualified,🍇,grapes,E0.6
Food-Drink,food-fruit,1F348,fully-qualified,🍈,melon,E0.6
Food-Drink,food-fruit,1F349,fully-qualified,🍉,watermelon,E0.6
Food-Drink,food-fruit,1F34A,fully-qualified,🍊,tangerine,E0.6
Food-Drink,food-fruit,1F34B,fully-qualified,🍋,lemon,E1.0
Food-Drink,food-fruit,1F34B 200D 1F7E9,fully-qualified,🍋‍🟩,lime,E15.1
Food-Drink,food-fruit,1F34C,fully-qualified,🍌,banana,E0.6
Food-Drink,food-fruit,1F34D,fully-qualified,🍍,pineapple,E0.6
Food-Drink,food-fruit,1F96D,fully-qualified,🥭,mango,E11.0
Food-Drink,food-fruit,1F34E,fully-qualified,🍎,red apple,E0.6
Food-Drink,food-fruit,1F34F,fully-qualified,🍏,green apple,E0.6
Food-Drink,food-fruit,1F350,fully-qualified,🍐,pear,E1.0
Food-Drink,food-fruit,1F351,fully-qualified,🍑,peach,E0.6
Food-Drink,food-fruit,1F352,fully-qualified,🍒,cherries,E0.6
Food-Drink,food-fruit,1F353,fully-qualified,🍓,strawberry,E0.6
Food-Drink,food-fruit,1FAD0,fully-qualified,🫐,blueberries,E13.0
Food-Drink,food-fruit,1F95D,fully-qualified,🥝,kiwi fruit,E3.0
Food-Drink,food-fruit,1F345,fully-qualified,🍅,tomato,E0.6
Food-Drink,food-fruit,1FAD2,fully-qualified,🫒,olive,E13.0
Food-Drink,food-fruit,1F965,fully-qualified,🥥,coconut,E5.0
Food-Drink,food-vegetable,1F951,fully-qualified,🥑,avocado,E3.0
Food-Drink,food-vegetable,1F346,fully-qualified,🍆,eggplant,E0.6
Food-Drink,food-vegetable,1F954,fully-qualified,🥔,potato,E3.0
Food-Drink,food-vegetable,1F955,fully-qualified,🥕,carrot,E3.0
Food-Drink,food-vegetable,1F33D,fully-qualified,🌽,ear of corn,E0.6
Food-Drink,food-vegetable,1F336 FE0F,fully-qualified,🌶️,hot pepper,E0.7
Food-Drink,food-vegetable,1F336,unqualified,🌶,hot pepper,E0.7
Food-Drink,food-vegetable,1FAD1,fully-qualified,🫑,bell pepper,E13.0
Food-Drink,food-vegetable,1F952,fully-qualified,🥒,cucumber,E3.0
Food-Drink,food-vegetable,1F96C,fully-qualified,🥬,leafy green,E11.0
Food-Drink,food-vegetable,1F966,fully-qualified,🥦,broccoli,E5.0
Food-Drink,food-vegetable,1F9C4,fully-qualified,🧄,garlic,E12.0
Food-Drink,food-vegetable,1F9C5,fully-qualified,🧅,onion,E12.0
Food-Drink,food-vegetable,1F95C,fully-qualified,🥜,peanuts,E3.0
Food-Drink,food-vegetable,1FAD8,fully-qualified,🫘,beans,E14.0
Food-Drink,food-vegetable,1F330,fully-qualified,🌰,chestnut,E0.6
Food-Drink,food-vegetable,1FADA,fully-qualified,🫚,ginger root,E15.0
Food-Drink,food-vegetable,1FADB,fully-qualified,🫛,pea pod,E15.0
Food-Drink,food-vegetable,1F344 200D 1F7EB,fully-qualified,🍄‍🟫,brown mushroom,E15.1
Food-Drink,food-prepared,1F35E,fully-qualified,🍞,bread,E0.6
Food-Drink,food-prepared,1F950,fully-qualified,🥐,croissant,E3.0
Food-Drink,food-prepared,1F956,fully-qualified,🥖,baguette bread,E3.0
Food-Drink,food-prepared,1FAD3,fully-qualified,🫓,flatbread,E13.0
Food-Drink,food-prepared,1F968,fully-qualified,🥨,pretzel,E5.0
Food-Drink,food-prepared,1F96F,fully-qualified,🥯,bagel,E11.0
Food-Drink,food-prepared,1F95E,fully-qualified,🥞,pancakes,E3.0
Food-Drink,food-prepared,1F9C7,fully-qualified,🧇,waffle,E12.0
Food-Drink,food-prepared,1F9C0,fully-qualified,🧀,cheese wedge,E1.0
Food-Drink,food-prepared,1F356,fully-qualified,🍖,meat on bone,E0.6
Food-Drink,food-prepared,1F357,fully-qualified,🍗,poultry leg,E0.6
Food-Drink,food-prepared,1F969,fully-qualified,🥩,cut of meat,E5.0
Food-Drink,food-prepared,1F953,fully-qualified,🥓,bacon,E3.0
Food-Drink,food-prepared,1F354,fully-qualified,🍔,hamburger,E0.6
Food-Drink,food-prepared,1F35F,fully-qualified,🍟,french fries,E0.6
Food-Drink,food-prepared,1F355,fully-qualified,🍕,pizza,E0.6
Food-Drink,food-prepared,1F32D,fully-qualified,🌭,hot dog,E1.0
Food-Drink,food-prepared,1F96A,fully-qualified,🥪,sandwich,E5.0
Food-Drink,food-prepared,1F32E,fully-qualified,🌮,taco,E1.0
Food-Drink,food-prepared,1F32F,fully-qualified,🌯,burrito,E1.0
Food-Drink,food-prepared,1FAD4,fully-qualified,🫔,tamale,E13.0
Food-Drink,food-prepared,1F959,fully-qualified,🥙,stuffed flatbread,E3.0
Food-Drink,food-prepared,1F9C6,fully-qualified,🧆,falafel,E12.0
Food-Drink,food-prepared,1F95A,fully-qualified,🥚,egg,E3.0
Food-Drink,food-prepared,1F373,fully-qualified,🍳,cooking,E0.6
Food-Drink,food-prepared,1F958,fully-qualified,🥘,shallow pan of food,E3.0
Food-Drink,food-prepared,1F372,fully-qualified,🍲,pot of food,E0.6
Food-Drink,food-prepared,1FAD5,fully-qualified,🫕,fondue,E13.0
Food-Drink,food-prepared,1F963,fully-qualified,🥣,bowl with spoon,E5.0
Food-Drink,food-prepared,1F957,fully-qualified,🥗,green salad,E3.0
Food-Drink,food-prepared,1F37F,fully-qualified,🍿,popcorn,E1.0
Food-Drink,food-prepared,1F9C8,fully-qualified,🧈,butter,E12.0
Food-Drink,food-prepared,1F9C2,fully-qualified,🧂,salt,E11.0
Food-Drink,food-prepared,1F96B,fully-qualified,🥫,canned food,E5.0
Food-Drink,food-asian,1F371,fully-qualified,🍱,bento box,E0.6
Food-Drink,food-asian,1F358,fully-qualified,🍘,rice cracker,E0.6
Food-Drink,food-asian,1F359,fully-qualified,🍙,rice ball,E0.6
Food-Drink,food-asian,1F35A,fully-qualified,🍚,cooked rice,E0.6
Food-Drink,food-asian,1F35B,fully-qualified,🍛,curry rice,E0.6
Food-Drink,food-asian,1F35C,fully-qualified,🍜,steaming bowl,E0.6
Food-Drink,food-asian,1F35D,fully-qualified,🍝,spaghetti,E0.6
Food-Drink,food-asian,1F360,fully-qualified,🍠,roasted sweet potato,E0.6
Food-Drink,food-asian,1F362,fully-qualified,🍢,oden,E0.6
Food-Drink,food-asian,1F363,fully-qualified,🍣,sushi,E0.6
Food-Drink,food-asian,1F364,fully-qualified,🍤,fried shrimp,E0.6
Food-Drink,food-asian,1F365,fully-qualified,🍥,fish cake with swirl,E0.6
Food-Drink,food-asian,1F96E,fully-qualified,🥮,moon cake,E11.0
Food-Drink,food-asian,1F361,fully-qualified,🍡,dango,E0.6
Food-Drink,food-asian,1F95F,fully-qualified,🥟,dumpling,E5.0
Food-Drink,food-asian,1F960,fully-qualified,🥠,fortune cookie,E5.0
Food-Drink,food-asian,1F961,fully-qualified,🥡,takeout box,E5.0
Food-Drink,food-marine,1F980,fully-qualified,🦀,crab,E1.0
Food-Drink,food-marine,1F99E,fully-qualified,🦞,lobster,E11.0
Food-Drink,food-marine,1F990,fully-qualified,🦐,shrimp,E3.0
Food-Drink,food-marine,1F991,fully-qualified,🦑,squid,E3.0
Food-Drink,food-marine,1F9AA,fully-qualified,🦪,oyster,E12.0
Food-Drink,food-sweet,1F366,fully-qualified,🍦,soft ice cream,E0.6
Food-Drink,food-sweet,1F367,fully-qualified,🍧,shaved ice,E0.6
Food-Drink,food-sweet,1F368,fully-qualified,🍨,ice cream,E0.6
Food-Drink,food-sweet,1F369,fully-qualified,🍩,doughnut,E0.6
Food-Drink,food-sweet,1F36A,fully-qualified,🍪,cookie,E0.6
Food-Drink,food-sweet,1F382,fully-qualified,🎂,birthday cake,E0.6
Food-Drink,food-sweet,1F370,fully-qualified,🍰,shortcake,E0.6
Food-Drink,food-sweet,1F9C1,fully-qualified,🧁,cupcake,E11.0
Food-Drink,food-sweet,1F967,fully-qualified,🥧,pie,E5.0
Food-Drink,food-sweet,1F36B,fully-qualified,🍫,chocolate bar,E0.6
Food-Drink,food-sweet,1F36C,fully-qualified,🍬,candy,E0.6
Food-Drink,food-sweet,1F36D,fully-qualified,🍭,lollipop,E0.6
Food-Drink,food-sweet,1F36E,fully-qualified,🍮,custard,E0.6
Food-Drink,food-sweet,1F36F,fully-qualified,🍯,honey pot,E0.6
Food-Drink,drink,1F37C,fully-qualified,🍼,baby bottle,E1.0
Food-Drink,drink,1F95B,fully-qualified,🥛,glass of milk,E3.0
Food-Drink,drink,2615,fully-qualified,☕,hot beverage,E0.6
Food-Drink,drink,1FAD6,fully-qualified,🫖,teapot,E13.0
Food-Drink,drink,1F375,fully-qualified,🍵,teacup without handle,E0.6
Food-Drink,drink,1F376,fully-qualified,🍶,sake,E0.6
Food-Drink,drink,1F37E,fully-qualified,🍾,bottle with popping cork,E1.0
Food-Drink,drink,1F377,fully-qualified,🍷,wine glass,E0.6
Food-Drink,drink,1F378,fully-qualified,🍸,cocktail glass,E0.6
Food-Drink,drink,1F379,fully-qualified,🍹,tropical drink,E0.6
Food-Drink,drink,1F37A,fully-qualified,🍺,beer mug,E0.6
Food-Drink,drink,1F37B,fully-qualified,🍻,clinking beer mugs,E0.6
Food-Drink,drink,1F942,fully-qualified,🥂,clinking glasses,E3.0
Food-Drink,drink,1F943,fully-qualified,🥃,tumbler glass,E3.0
Food-Drink,drink,1FAD7,fully-qualified,🫗,pouring liquid,E14.0
Food-Drink,drink,1F964,fully-qualified,🥤,cup with straw,E5.0
Food-Drink,drink,1F9CB,fully-qualified,🧋,bubble tea,E13.0
Food-Drink,drink,1F9C3,fully-qualified,🧃,beverage box,E12.0
Food-Drink,drink,1F9C9,fully-qualified,🧉,mate,E12.0
Food-Drink,drink,1F9CA,fully-qualified,🧊,ice,E12.0
Food-Drink,dishware,1F962,fully-qualified,🥢,chopsticks,E5.0
Food-Drink,dishware,1F37D FE0F,fully-qualified,🍽️,fork and knife with plate,E0.7
Food-Drink,dishware,1F37D,unqualified,🍽,fork and knife with plate,E0.7
Food-Drink,dishware,1F374,fully-qualified,🍴,fork and knife,E0.6
Food-Drink,dishware,1F944,fully-qualified,🥄,spoon,E3.0
Food-Drink,dishware,1F52A,fully-qualified,🔪,kitchen knife,E0.6
Food-Drink,dishware,1FAD9,fully-qualified,🫙,jar,E14.0
Food-Drink,dishware,1F3FA,fully-qualified,🏺,amphora,E1.0
Objects,clothing,1F453,fully-qualified,👓,glasses,E0.6
Objects,clothing,1F576 FE0F,fully-qualified,🕶️,sunglasses,E0.7
Objects,clothing,1F576,unqualified,🕶,sunglasses,E0.7
Objects,clothing,1F97D,fully-qualified,🥽,goggles,E11.0
Objects,clothing,1F97C,fully-qualified,🥼,lab coat,E11.0
Objects,clothing,1F9BA,fully-qualified,🦺,safety vest,E12.0
Objects,clothing,1F454,fully-qualified,👔,necktie,E0.6
Objects,clothing,1F455,fully-qualified,👕,t-shirt,E0.6
Objects,clothing,1F456,fully-qualified,👖,jeans,E0.6
Objects,clothing,1F9E3,fully-qualified,🧣,scarf,E5.0
Objects,clothing,1F9E4,fully-qualified,🧤,gloves,E5.0
Objects,clothing,1F9E5,fully-qualified,🧥,coat,E5.0
Objects,clothing,1F9E6,fully-qualified,🧦,socks,E5.0
Objects,clothing,1F457,fully-qualified,👗,dress,E0.6
Objects,clothing,1F458,fully-qualified,👘,kimono,E0.6
Objects,clothing,1F97B,fully-qualified,🥻,sari,E12.0
Objects,clothing,1FA71,fully-qualified,🩱,one-piece swimsuit,E12.0
Objects,clothing,1FA72,fully-qualified,🩲,briefs,E12.0
Objects,clothing,1FA73,fully-qualified,🩳,shorts,E12.0
Objects,clothing,1F459,fully-qualified,👙,bikini,E0.6
Objects,clothing,1F45A,fully-qualified,👚,womans clothes,E0.6
Objects,clothing,1FAAD,fully-qualified,🪭,folding hand fan,E15.0
Objects,clothing,1F45B,fully-qualified,👛,purse,E0.6
Objects,clothing,1F45C,fully-qualified,👜,handbag,E0.6
Objects,clothing,1F45D,fully-qualified,👝,clutch bag,E0.6
Objects,clothing,1F6CD FE0F,fully-qualified,🛍️,shopping bags,E0.7
Objects,clothing,1F6CD,unqualified,🛍,shopping bags,E0.7
Objects,clothing,1F392,fully-qualified,🎒,backpack,E0.6
Objects,clothing,1FA74,fully-qualified,🩴,thong sandal,E13.0
Objects,clothing,1F45E,fully-qualified,👞,mans shoe,E0.6
Objects,clothing,1F45F,fully-qualified,👟,running shoe,E0.6
Objects,clothing,1F97E,fully-qualified,🥾,hiking boot,E11.0
Objects,clothing,1F97F,fully-qualified,🥿,flat shoe,E11.0
Objects,clothing,1F460,fully-qualified,👠,high-heeled shoe,E0.6
Objects,clothing,1F461,fully-qualified,👡,womans sandal,E0.6
Objects,clothing,1FA70,fully-qualified,🩰,ballet shoes,E12.0
Objects,clothing,1F462,fully-qualified,👢,womans boot,E0.6
Objects,clothing,1FAAE,fully-qualified,🪮,hair pick,E15.0
Objects,clothing,1F451,fully-qualified,👑,crown,E0.6
Objects,clothing,1F452,fully-qualified,👒,womans hat,E0.6
Objects,clothing,1F3A9,fully-qualified,🎩,top hat,E0.6
Objects,clothing,1F393,fully-qualified,🎓,graduation cap,E0.6
Objects,clothing,1F9E2,fully-qualified,🧢,billed cap,E5.0
Objects,clothing,1FA96,fully-qualified,🪖,military helmet,E13.0
Objects,clothing,26D1 FE0F,fully-qualified,⛑️,rescue workers helmet,E0.7
Objects,clothing,26D1,unqualified,⛑,rescue workers helmet,E0.7
Objects,clothing,1F4FF,fully-qualified,📿,prayer beads,E1.0
Objects,clothing,1F484,fully-qualified,💄,lipstick,E0.6
Objects,clothing,1F48D,fully-qualified,💍,ring,E0.6
Objects,clothing,1F48E,fully-qualified,💎,gem stone,E0.6
Objects,sound,1F507,fully-qualified,🔇,muted speaker,E1.0
Objects,sound,1F508,fully-qualified,🔈,speaker low volume,E0.7
Objects,sound,1F509,fully-qualified,🔉,speaker medium volume,E1.0
Objects,sound,1F50A,fully-qualified,🔊,speaker high volume,E0.6
Objects,sound,1F4E2,fully-qualified,📢,loudspeaker,E0.6
Objects,sound,1F4E3,fully-qualified,📣,megaphone,E0.6
Objects,sound,1F4EF,fully-qualified,📯,postal horn,E1.0
Objects,sound,1F514,fully-qualified,🔔,bell,E0.6
Objects,sound,1F515,fully-qualified,🔕,bell with slash,E1.0
Objects,music,1F3BC,fully-qualified,🎼,musical score,E0.6
Objects,music,1F3B5,fully-qualified,🎵,musical note,E0.6
Objects,music,1F3B6,fully-qualified,🎶,musical notes,E0.6
Objects,music,1F399 FE0F,fully-qualified,🎙️,studio microphone,E0.7
Objects,music,1F399,unqualified,🎙,studio microphone,E0.7
Objects,music,1F39A FE0F,fully-qualified,🎚️,level slider,E0.7
Objects,music,1F39A,unqualified,🎚,level slider,E0.7
Objects,music,1F39B FE0F,fully-qualified,🎛️,control knobs,E0.7
Objects,music,1F39B,unqualified,🎛,control knobs,E0.7
Objects,music,1F3A4,fully-qualified,🎤,microphone,E0.6
Objects,music,1F3A7,fully-qualified,🎧,headphone,E0.6
Objects,music,1F4FB,fully-qualified,📻,radio,E0.6
Objects,musical-instrument,1F3B7,fully-qualified,🎷,saxophone,E0.6
Objects,musical-instrument,1FA97,fully-qualified,🪗,accordion,E13.0
Objects,musical-instrument,1F3B8,fully-qualified,🎸,guitar,E0.6
Objects,musical-instrument,1F3B9,fully-qualified,🎹,musical keyboard,E0.6
Objects,musical-instrument,1F3BA,fully-qualified,🎺,trumpet,E0.6
Objects,musical-instrument,1F3BB,fully-qualified,🎻,violin,E0.6
Objects,musical-instrument,1FA95,fully-qualified,🪕,banjo,E12.0
Objects,musical-instrument,1F941,fully-qualified,🥁,drum,E3.0
Objects,musical-instrument,1FA98,fully-qualified,🪘,long drum,E13.0
Objects,musical-instrument,1FA87,fully-qualified,🪇,maracas,E15.0
Objects,musical-instrument,1FA88,fully-qualified,🪈,flute,E15.0
Objects,phone,1F4F1,fully-qualified,📱,mobile phone,E0.6
Objects,phone,1F4F2,fully-qualified,📲,mobile phone with arrow,E0.6
Objects,phone,260E FE0F,fully-qualified,☎️,telephone,E0.6
Objects,phone,260E,unqualified,☎,telephone,E0.6
Objects,phone,1F4DE,fully-qualified,📞,telephone receiver,E0.6
Objects,phone,1F4DF,fully-qualified,📟,pager,E0.6
Objects,phone,1F4E0,fully-qualified,📠,fax machine,E0.6
Objects,computer,1F50B,fully-qualified,🔋,battery,E0.6
Objects,computer,1FAAB,fully-qualified,🪫,low battery,E14.0
Objects,computer,1F50C,fully-qualified,🔌,electric plug,E0.6
Objects,computer,1F4BB,fully-qualified,💻,laptop,E0.6
Objects,computer,1F5A5 FE0F,fully-qualified,🖥️,desktop computer,E0.7
Objects,computer,1F5A5,unqualified,🖥,desktop computer,E0.7
Objects,computer,1F5A8 FE0F,fully-qualified,🖨️,printer,E0.7
Objects,computer,1F5A8,unqualified,🖨,printer,E0.7
Objects,computer,2328 FE0F,fully-qualified,⌨️,keyboard,E1.0
Objects,computer,2328,unqualified,⌨,keyboard,E1.0
Objects,computer,1F5B1 FE0F,fully-qualified,🖱️,computer mouse,E0.7
Objects,computer,1F5B1,unqualified,🖱,computer mouse,E0.7
Objects,computer,1F5B2 FE0F,fully-qualified,🖲️,trackball,E0.7
Objects,computer,1F5B2,unqualified,🖲,trackball,E0.7
Objects,computer,1F4BD,fully-qualified,💽,computer disk,E0.6
Objects,computer,1F4BE,fully-qualified,💾,floppy disk,E0.6
Objects,computer,1F4BF,fully-qualified,💿,optical disk,E0.6
Objects,computer,1F4C0,fully-qualified,📀,dvd,E0.6
Objects,computer,1F9EE,fully-qualified,🧮,abacus,E11.0
Objects,light-video,1F3A5,fully-qualified,🎥,movie camera,E0.6
Objects,light-video,1F39E FE0F,fully-qualified,🎞️,film frames,E0.7
Objects,light-video,1F39E,unqualified,🎞,film frames,E0.7
Objects,light-video,1F4FD FE0F,fully-qualified,📽️,film projector,E0.7
Objects,light-video,1F4FD,unqualified,📽,film projector,E0.7
Objects,light-video,1F3AC,fully-qualified,🎬,clapper board,E0.6
Objects,light-video,1F4FA,fully-qualified,📺,television,E0.6
Objects,light-video,1F4F7,fully-qualified,📷,camera,E0.6
Objects,light-video,1F4F8,fully-qualified,📸,camera with flash,E1.0
Objects,light-video,1F4F9,fully-qualified,📹,video camera,E0.6
Objects,light-video,1F4FC,fully-qualified,📼,videocassette,E0.6
Objects,light-video,1F50D,fully-qualified,🔍,magnifying glass tilted left,E0.6
Objects,light-video,1F50E,fully-qualified,🔎,magnifying glass tilted right,E0.6
Objects,light-video,1F56F FE0F,fully-qualified,🕯️,candle,E0.7
Objects,light-video,1F56F,unqualified,🕯,candle,E0.7
Objects,light-video,1F4A1,fully-qualified,💡,light bulb,E0.6
Objects,light-video,1F526,fully-qualified,🔦,flashlight,E0.6
Objects,light-video,1F3EE,fully-qualified,🏮,red paper lantern,E0.6
Objects,light-video,1FA94,fully-qualified,🪔,diya lamp,E12.0
Objects,book-paper,1F4D4,fully-qualified,📔,notebook with decorative cover,E0.6
Objects,book-paper,1F4D5,fully-qualified,📕,closed book,E0.6
Objects,book-paper,1F4D6,fully-qualified,📖,open book,E0.6
Objects,book-paper,1F4D7,fully-qualified,📗,green book,E0.6
Objects,book-paper,1F4D8,fully-qualified,📘,blue book,E0.6
Objects,book-paper,1F4D9,fully-qualified,📙,orange book,E0.6
Objects,book-paper,1F4DA,fully-qualified,📚,books,E0.6
Objects,book-paper,1F4D3,fully-qualified,📓,notebook,E0.6
Objects,book-paper,1F4D2,fully-qualified,📒,ledger,E0.6
Objects,book-paper,1F4C3,fully-qualified,📃,page with curl,E0.6
Objects,book-paper,1F4DC,fully-qualified,📜,scroll,E0.6
Objects,book-paper,1F4C4,fully-qualified,📄,page facing up,E0.6
Objects,book-paper,1F4F0,fully-qualified,📰,newspaper,E0.6
Objects,book-paper,1F5DE FE0F,fully-qualified,🗞️,rolled-up newspaper,E0.7
Objects,book-paper,1F5DE,unqualified,🗞,rolled-up newspaper,E0.7
Objects,book-paper,1F4D1,fully-qualified,📑,bookmark tabs,E0.6
Objects,book-paper,1F516,fully-qualified,🔖,bookmark,E0.6
Objects,book-paper,1F3F7 FE0F,fully-qualified,🏷️,label,E0.7
Objects,book-paper,1F3F7,unqualified,🏷,label,E0.7
Objects,money,1F4B0,fully-qualified,💰,money bag,E0.6
Objects,money,1FA99,fully-qualified,🪙,coin,E13.0
Objects,money,1F4B4,fully-qualified,💴,yen banknote,E0.6
Objects,money,1F4B5,fully-qualified,💵,dollar banknote,E0.6
Objects,money,1F4B6,fully-qualified,💶,euro banknote,E1.0
Objects,money,1F4B7,fully-qualified,💷,pound banknote,E1.0
Objects,money,1F4B8,fully-qualified,💸,money with wings,E0.6
Objects,money,1F4B3,fully-qualified,💳,credit card,E0.6
Objects,money,1F9FE,fully-qualified,🧾,receipt,E11.0
Objects,money,1F4B9,fully-qualified,💹,chart increasing with yen,E0.6
Objects,mail,2709 FE0F,fully-qualified,✉️,envelope,E0.6
Objects,mail,2709,unqualified,✉,envelope,E0.6
Objects,mail,1F4E7,fully-qualified,📧,e-mail,E0.6
Objects,mail,1F4E8,fully-qualified,📨,incoming envelope,E0.6
Objects,mail,1F4E9,fully-qualified,📩,envelope with arrow,E0.6
Objects,mail,1F4E4,fully-qualified,📤,outbox tray,E0.6
Objects,mail,1F4E5,fully-qualified,📥,inbox tray,E0.6
Objects,mail,1F4E6,fully-qualified,📦,package,E0.6
Objects,mail,1F4EB,fully-qualified,📫,closed mailbox with raised flag,E0.6
Objects,mail,1F4EA,fully-qualified,📪,closed mailbox with lowered flag,E0.6
Objects,mail,1F4EC,fully-qualified,📬,open mailbox with raised flag,E0.7
Objects,mail,1F4ED,fully-qualified,📭,open mailbox with lowered flag,E0.7
Objects,mail,1F4EE,fully-qualified,📮,postbox,E0.6
Objects,mail,1F5F3 FE0F,fully-qualified,🗳️,ballot box with ballot,E0.7
Objects,mail,1F5F3,unqualified,🗳,ballot box with ballot,E0.7
Objects,writing,270F FE0F,fully-qualified,✏️,pencil,E0.6
Objects,writing,270F,unqualified,✏,pencil,E0.6
Objects,writing,2712 FE0F,fully-qualified,✒️,black nib,E0.6
Objects,writing,2712,unqualified,✒,black nib,E0.6
Objects,writing,1F58B FE0F,fully-qualified,🖋️,fountain pen,E0.7
Objects,writing,1F58B,unqualified,🖋,fountain pen,E0.7
Objects,writing,1F58A FE0F,fully-qualified,🖊️,pen,E0.7
Objects,writing,1F58A,unqualified,🖊,pen,E0.7
Objects,writing,1F58C FE0F,fully-qualified,🖌️,paintbrush,E0.7
Objects,writing,1F58C,unqualified,🖌,paintbrush,E0.7
Objects,writing,1F58D FE0F,fully-qualified,🖍️,crayon,E0.7
Objects,writing,1F58D,unqualified,🖍,crayon,E0.7
Objects,writing,1F4DD,fully-qualified,📝,memo,E0.6
Objects,office,1F4BC,fully-qualified,💼,briefcase,E0.6
Objects,office,1F4C1,fully-qualified,📁,file folder,E0.6
Objects,office,1F4C2,fully-qualified,📂,open file folder,E0.6
Objects,office,1F5C2 FE0F,fully-qualified,🗂️,card index dividers,E0.7
Objects,office,1F5C2,unqualified,🗂,card index dividers,E0.7
Objects,office,1F4C5,fully-qualified,📅,calendar,E0.6
Objects,office,1F4C6,fully-qualified,📆,tear-off calendar,E0.6
Objects,office,1F5D2 FE0F,fully-qualified,🗒️,spiral notepad,E0.7
Objects,office,1F5D2,unqualified,🗒,spiral notepad,E0.7
Objects,office,1F5D3 FE0F,fully-qualified,🗓️,spiral calendar,E0.7
Objects,office,1F5D3,unqualified,🗓,spiral calendar,E0.7
Objects,office,1F4C7,fully-qualified,📇,card index,E0.6
Objects,office,1F4C8,fully-qualified,📈,chart increasing,E0.6
Objects,office,1F4C9,fully-qualified,📉,chart decreasing,E0.6
Objects,office,1F4CA,fully-qualified,📊,bar chart,E0.6
Objects,office,1F4CB,fully-qualified,📋,clipboard,E0.6
Objects,office,1F4CC,fully-qualified,📌,pushpin,E0.6
Objects,office,1F4CD,fully-qualified,📍,round pushpin,E0.6
Objects,office,1F4CE,fully-qualified,📎,paperclip,E0.6
Objects,office,1F587 FE0F,fully-qualified,🖇️,linked paperclips,E0.7
Objects,office,1F587,unqualified,🖇,linked paperclips,E0.7
Objects,office,1F4CF,fully-qualified,📏,straight ruler,E0.6
Objects,office,1F4D0,fully-qualified,📐,triangular ruler,E0.6
Objects,office,2702 FE0F,fully-qualified,✂️,scissors,E0.6
Objects,office,2702,unqualified,✂,scissors,E0.6
Objects,office,1F5C3 FE0F,fully-qualified,🗃️,card file box,E0.7
Objects,office,1F5C3,unqualified,🗃,card file box,E0.7
Objects,office,1F5C4 FE0F,fully-qualified,🗄️,file cabinet,E0.7
Objects,office,1F5C4,unqualified,🗄,file cabinet,E0.7
Objects,office,1F5D1 FE0F,fully-qualified,🗑️,wastebasket,E0.7
Objects,office,1F5D1,unqualified,🗑,wastebasket,E0.7
Objects,lock,1F512,fully-qualified,🔒,locked,E0.6
Objects,lock,1F513,fully-qualified,🔓,unlocked,E0.6
Objects,lock,1F50F,fully-qualified,🔏,locked with pen,E0.6
Objects,lock,1F510,fully-qualified,🔐,locked with key,E0.6
Objects,lock,1F511,fully-qualified,🔑,key,E0.6
Objects,lock,1F5DD FE0F,fully-qualified,🗝️,old key,E0.7
Objects,lock,1F5DD,unqualified,🗝,old key,E0.7
Objects,tool,1F528,fully-qualified,🔨,hammer,E0.6
Objects,tool,1FA93,fully-qualified,🪓,axe,E12.0
Objects,tool,26CF FE0F,fully-qualified,⛏️,pick,E0.7
Objects,tool,26CF,unqualified,⛏,pick,E0.7
Objects,tool,2692 FE0F,fully-qualified,⚒️,hammer and pick,E1.0
Objects,tool,2692,unqualified,⚒,hammer and pick,E1.0
Objects,tool,1F6E0 FE0F,fully-qualified,🛠️,hammer and wrench,E0.7
Objects,tool,1F6E0,unqualified,🛠,hammer and wrench,E0.7
Objects,tool,1F5E1 FE0F,fully-qualified,🗡️,dagger,E0.7
Objects,tool,1F5E1,unqualified,🗡,dagger,E0.7
Objects,tool,2694 FE0F,fully-qualified,⚔️,crossed swords,E1.0
Objects,tool,2694,unqualified,⚔,crossed swords,E1.0
Objects,tool,1F4A3,fully-qualified,💣,bomb,E0.6
Objects,tool,1FA83,fully-qualified,🪃,boomerang,E13.0
Objects,tool,1F3F9,fully-qualified,🏹,bow and arrow,E1.0
Objects,tool,1F6E1 FE0F,fully-qualified,🛡️,shield,E0.7
Objects,tool,1F6E1,unqualified,🛡,shield,E0.7
Objects,tool,1FA9A,fully-qualified,🪚,carpentry saw,E13.0
Objects,tool,1F527,fully-qualified,🔧,wrench,E0.6
Objects,tool,1FA9B,fully-qualified,🪛,screwdriver,E13.0
Objects,tool,1F529,fully-qualified,🔩,nut and bolt,E0.6
Objects,tool,2699 FE0F,fully-qualified,⚙️,gear,E1.0
Objects,tool,2699,unqualified,⚙,gear,E1.0
Objects,tool,1F5DC FE0F,fully-qualified,🗜️,clamp,E0.7
Objects,tool,1F5DC,unqualified,🗜,clamp,E0.7
Objects,tool,2696 FE0F,fully-qualified,⚖️,balance scale,E1.0
Objects,tool,2696,unqualified,⚖,balance scale,E1.0
Objects,tool,1F9AF,fully-qualified,🦯,white cane,E12.0
Objects,tool,1F517,fully-qualified,🔗,link,E0.6
Objects,tool,26D3 FE0F 200D 1F4A5,fully-qualified,⛓️‍💥,broken chain,E15.1
Objects,tool,26D3 200D 1F4A5,unqualified,⛓‍💥,broken chain,E15.1
Objects,tool,26D3 FE0F,fully-qualified,⛓️,chains,E0.7
Objects,tool,26D3,unqualified,⛓,chains,E0.7
Objects,tool,1FA9D,fully-qualified,🪝,hook,E13.0
Objects,tool,1F9F0,fully-qualified,🧰,toolbox,E11.0
Objects,tool,1F9F2,fully-qualified,🧲,magnet,E11.0
Objects,tool,1FA9C,fully-qualified,🪜,ladder,E13.0
Objects,science,2697 FE0F,fully-qualified,⚗️,alembic,E1.0
Objects,science,2697,unqualified,⚗,alembic,E1.0
Objects,science,1F9EA,fully-qualified,🧪,test tube,E11.0
Objects,science,1F9EB,fully-qualified,🧫,petri dish,E11.0
Objects,science,1F9EC,fully-qualified,🧬,dna,E11.0
Objects,science,1F52C,fully-qualified,🔬,microscope,E1.0
Objects,science,1F52D,fully-qualified,🔭,telescope,E1.0
Objects,science,1F4E1,fully-qualified,📡,satellite antenna,E0.6
Objects,medical,1F489,fully-qualified,💉,syringe,E0.6
Objects,medical,1FA78,fully-qualified,🩸,drop of blood,E12.0
Objects,medical,1F48A,fully-qualified,💊,pill,E0.6
Objects,medical,1FA79,fully-qualified,🩹,adhesive bandage,E12.0
Objects,medical,1FA7C,fully-qualified,🩼,crutch,E14.0
Objects,medical,1FA7A,fully-qualified,🩺,stethoscope,E12.0
Objects,medical,1FA7B,fully-qualified,🩻,x-ray,E14.0
Objects,household,1F6AA,fully-qualified,🚪,door,E0.6
Objects,household,1F6D7,fully-qualified,🛗,elevator,E13.0
Objects,household,1FA9E,fully-qualified,🪞,mirror,E13.0
Objects,household,1FA9F,fully-qualified,🪟,window,E13.0
Objects,household,1F6CF FE0F,fully-qualified,🛏️,bed,E0.7
Objects,household,1F6CF,unqualified,🛏,bed,E0.7
Objects,household,1F6CB FE0F,fully-qualified,🛋️,couch and lamp,E0.7
Objects,household,1F6CB,unqualified,🛋,couch and lamp,E0.7
Objects,household,1FA91,fully-qualified,🪑,chair,E12.0
Objects,household,1F6BD,fully-qualified,🚽,toilet,E0.6
Objects,household,1FAA0,fully-qualified,🪠,plunger,E13.0
Objects,household,1F6BF,fully-qualified,🚿,shower,E1.0
Objects,household,1F6C1,fully-qualified,🛁,bathtub,E1.0
Objects,household,1FAA4,fully-qualified,🪤,mouse trap,E13.0
Objects,household,1FA92,fully-qualified,🪒,razor,E12.0
Objects,household,1F9F4,fully-qualified,🧴,lotion bottle,E11.0
Objects,household,1F9F7,fully-qualified,🧷,safety pin,E11.0
Objects,household,1F9F9,fully-qualified,🧹,broom,E11.0
Objects,household,1F9FA,fully-qualified,🧺,basket,E11.0
Objects,household,1F9FB,fully-qualified,🧻,roll of paper,E11.0
Objects,household,1FAA3,fully-qualified,🪣,bucket,E13.0
Objects,household,1F9FC,fully-qualified,🧼,soap,E11.0
Objects,household,1FAE7,fully-qualified,🫧,bubbles,E14.0
Objects,household,1FAA5,fully-qualified,🪥,toothbrush,E13.0
Objects,household,1F9FD,fully-qualified,🧽,sponge,E11.0
Objects,household,1F9EF,fully-qualified,🧯,fire extinguisher,E11.0
Objects,household,1F6D2,fully-qualified,🛒,shopping cart,E3.0
Objects,other-object,1F6AC,fully-qualified,🚬,cigarette,E0.6
Objects,other-object,26B0 FE0F,fully-qualified,⚰️,coffin,E1.0
Objects,other-object,26B0,unqualified,⚰,coffin,E1.0
Objects,other-object,1FAA6,fully-qualified,🪦,headstone,E13.0
Objects,other-object,26B1 FE0F,fully-qualified,⚱️,funeral urn,E1.0
Objects,other-object,26B1,unqualified,⚱,funeral urn,E1.0
Objects,other-object,1F9FF,fully-qualified,🧿,nazar amulet,E11.0
Objects,other-object,1FAAC,fully-qualified,🪬,hamsa,E14.0
Objects,other-object,1F5FF,fully-qualified,🗿,moai,E0.6
Objects,other-object,1FAA7,fully-qualified,🪧,placard,E13.0
Objects,other-object,1FAAA,fully-qualified,🪪,identification card,E14.0
People-Body,hand-fingers-open,1F44B,fully-qualified,👋,waving hand,E0.6
People-Body,hand-fingers-open,1F44B 1F3FB,fully-qualified,👋🏻,waving hand light skin tone,E1.0
People-Body,hand-fingers-open,1F44B 1F3FC,fully-qualified,👋🏼,waving hand medium-light skin tone,E1.0
People-Body,hand-fingers-open,1F44B 1F3FD,fully-qualified,👋🏽,waving hand medium skin tone,E1.0
People-Body,hand-fingers-open,1F44B 1F3FE,fully-qualified,👋🏾,waving hand medium-dark skin tone,E1.0
People-Body,hand-fingers-open,1F44B 1F3FF,fully-qualified,👋🏿,waving hand dark skin tone,E1.0
People-Body,hand-fingers-open,1F91A,fully-qualified,🤚,raised back of hand,E3.0
People-Body,hand-fingers-open,1F91A 1F3FB,fully-qualified,🤚🏻,raised back of hand light skin tone,E3.0
People-Body,hand-fingers-open,1F91A 1F3FC,fully-qualified,🤚🏼,raised back of hand medium-light skin tone,E3.0
People-Body,hand-fingers-open,1F91A 1F3FD,fully-qualified,🤚🏽,raised back of hand medium skin tone,E3.0
People-Body,hand-fingers-open,1F91A 1F3FE,fully-qualified,🤚🏾,raised back of hand medium-dark skin tone,E3.0
People-Body,hand-fingers-open,1F91A 1F3FF,fully-qualified,🤚🏿,raised back of hand dark skin tone,E3.0
People-Body,hand-fingers-open,1F590 FE0F,fully-qualified,🖐️,hand with fingers splayed,E0.7
People-Body,hand-fingers-open,1F590,unqualified,🖐,hand with fingers splayed,E0.7
People-Body,hand-fingers-open,1F590 1F3FB,fully-qualified,🖐🏻,hand with fingers splayed light skin tone,E1.0
People-Body,hand-fingers-open,1F590 1F3FC,fully-qualified,🖐🏼,hand with fingers splayed medium-light skin tone,E1.0
People-Body,hand-fingers-open,1F590 1F3FD,fully-qualified,🖐🏽,hand with fingers splayed medium skin tone,E1.0
People-Body,hand-fingers-open,1F590 1F3FE,fully-qualified,🖐🏾,hand with fingers splayed medium-dark skin tone,E1.0
People-Body,hand-fingers-open,1F590 1F3FF,fully-qualified,🖐🏿,hand with fingers splayed dark skin tone,E1.0
People-Body,hand-fingers-open,270B,fully-qualified,✋,raised hand,E0.6
People-Body,hand-fingers-open,270B 1F3FB,fully-qualified,✋🏻,raised hand light skin tone,E1.0
People-Body,hand-fingers-open,270B 1F3FC,fully-qualified,✋🏼,raised hand medium-light skin tone,E1.0
People-Body,hand-fingers-open,270B 1F3FD,fully-qualified,✋🏽,raised hand medium skin tone,E1.0
People-Body,hand-fingers-open,270B 1F3FE,fully-qualified,✋🏾,raised hand medium-dark skin tone,E1.0
People-Body,hand-fingers-open,270B 1F3FF,fully-qualified,✋🏿,raised hand dark skin tone,E1.0
People-Body,hand-fingers-open,1F596,fully-qualified,🖖,vulcan salute,E1.0
People-Body,hand-fingers-open,1F596 1F3FB,fully-qualified,🖖🏻,vulcan salute light skin tone,E1.0
People-Body,hand-fingers-open,1F596 1F3FC,fully-qualified,🖖🏼,vulcan salute medium-light skin tone,E1.0
People-Body,hand-fingers-open,1F596 1F3FD,fully-qualified,🖖🏽,vulcan salute medium skin tone,E1.0
People-Body,hand-fingers-open,1F596 1F3FE,fully-qualified,🖖🏾,vulcan salute medium-dark skin tone,E1.0
People-Body,hand-fingers-open,1F596 1F3FF,fully-qualified,🖖🏿,vulcan salute dark skin tone,E1.0
People-Body,hand-fingers-open,1FAF1,fully-qualified,🫱,rightwards hand,E14.0
People-Body,hand-fingers-open,1FAF1 1F3FB,fully-qualified,🫱🏻,rightwards hand light skin tone,E14.0
People-Body,hand-fingers-open,1FAF1 1F3FC,fully-qualified,🫱🏼,rightwards hand medium-light skin tone,E14.0
People-Body,hand-fingers-open,1FAF1 1F3FD,fully-qualified,🫱🏽,rightwards hand medium skin tone,E14.0
People-Body,hand-fingers-open,1FAF1 1F3FE,fully-qualified,🫱🏾,rightwards hand medium-dark skin tone,E14.0
People-Body,hand-fingers-open,1FAF1 1F3FF,fully-qualified,🫱🏿,rightwards hand dark skin tone,E14.0
People-Body,hand-fingers-open,1FAF2,fully-qualified,🫲,leftwards hand,E14.0
People-Body,hand-fingers-open,1FAF2 1F3FB,fully-qualified,🫲🏻,leftwards hand light skin tone,E14.0
People-Body,hand-fingers-open,1FAF2 1F3FC,fully-qualified,🫲🏼,leftwards hand medium-light skin tone,E14.0
People-Body,hand-fingers-open,1FAF2 1F3FD,fully-qualified,🫲🏽,leftwards hand medium skin tone,E14.0
People-Body,hand-fingers-open,1FAF2 1F3FE,fully-qualified,🫲🏾,leftwards hand medium-dark skin tone,E14.0
People-Body,hand-fingers-open,1FAF2 1F3FF,fully-qualified,🫲🏿,leftwards hand dark skin tone,E14.0
People-Body,hand-fingers-open,1FAF3,fully-qualified,🫳,palm down hand,E14.0
People-Body,hand-fingers-open,1FAF3 1F3FB,fully-qualified,🫳🏻,palm down hand light skin tone,E14.0
People-Body,hand-fingers-open,1FAF3 1F3FC,fully-qualified,🫳🏼,palm down hand medium-light skin tone,E14.0
People-Body,hand-fingers-open,1FAF3 1F3FD,fully-qualified,🫳🏽,palm down hand medium skin tone,E14.0
People-Body,hand-fingers-open,1FAF3 1F3FE,fully-qualified,🫳🏾,palm down hand medium-dark skin tone,E14.0
People-Body,hand-fingers-open,1FAF3 1F3FF,fully-qualified,🫳🏿,palm down hand dark skin tone,E14.0
People-Body,hand-fingers-open,1FAF4,fully-qualified,🫴,palm up hand,E14.0
People-Body,hand-fingers-open,1FAF4 1F3FB,fully-qualified,🫴🏻,palm up hand light skin tone,E14.0
People-Body,hand-fingers-open,1FAF4 1F3FC,fully-qualified,🫴🏼,palm up hand medium-light skin tone,E14.0
People-Body,hand-fingers-open,1FAF4 1F3FD,fully-qualified,🫴🏽,palm up hand medium skin tone,E14.0
People-Body,hand-fingers-open,1FAF4 1F3FE,fully-qualified,🫴🏾,palm up hand medium-dark skin tone,E14.0
People-Body,hand-fingers-open,1FAF4 1F3FF,fully-qualified,🫴🏿,palm up hand dark skin tone,E14.0
People-Body,hand-fingers-open,1FAF7,fully-qualified,🫷,leftwards pushing hand,E15.0
People-Body,hand-fingers-open,1FAF7 1F3FB,fully-qualified,🫷🏻,leftwards pushing hand light skin tone,E15.0
People-Body,hand-fingers-open,1FAF7 1F3FC,fully-qualified,🫷🏼,leftwards pushing hand medium-light skin tone,E15.0
People-Body,hand-fingers-open,1FAF7 1F3FD,fully-qualified,🫷🏽,leftwards pushing hand medium skin tone,E15.0
People-Body,hand-fingers-open,1FAF7 1F3FE,fully-qualified,🫷🏾,leftwards pushing hand medium-dark skin tone,E15.0
People-Body,hand-fingers-open,1FAF7 1F3FF,fully-qualified,🫷🏿,leftwards pushing hand dark skin tone,E15.0
People-Body,hand-fingers-open,1FAF8,fully-qualified,🫸,rightwards pushing hand,E15.0
People-Body,hand-fingers-open,1FAF8 1F3FB,fully-qualified,🫸🏻,rightwards pushing hand light skin tone,E15.0
People-Body,hand-fingers-open,1FAF8 1F3FC,fully-qualified,🫸🏼,rightwards pushing hand medium-light skin tone,E15.0
People-Body,hand-fingers-open,1FAF8 1F3FD,fully-qualified,🫸🏽,rightwards pushing hand medium skin tone,E15.0
People-Body,hand-fingers-open,1FAF8 1F3FE,fully-qualified,🫸🏾,rightwards pushing hand medium-dark skin tone,E15.0
People-Body,hand-fingers-open,1FAF8 1F3FF,fully-qualified,🫸🏿,rightwards pushing hand dark skin tone,E15.0
People-Body,hand-fingers-partial,1F44C,fully-qualified,👌,OK hand,E0.6
People-Body,hand-fingers-partial,1F44C 1F3FB,fully-qualified,👌🏻,OK hand light skin tone,E1.0
People-Body,hand-fingers-partial,1F44C 1F3FC,fully-qualified,👌🏼,OK hand medium-light skin tone,E1.0
People-Body,hand-fingers-partial,1F44C 1F3FD,fully-qualified,👌🏽,OK hand medium skin tone,E1.0
People-Body,hand-fingers-partial,1F44C 1F3FE,fully-qualified,👌🏾,OK hand medium-dark skin tone,E1.0
People-Body,hand-fingers-partial,1F44C 1F3FF,fully-qualified,👌🏿,OK hand dark skin tone,E1.0
People-Body,hand-fingers-partial,1F90C,fully-qualified,🤌,pinched fingers,E13.0
People-Body,hand-fingers-partial,1F90C 1F3FB,fully-qualified,🤌🏻,pinched fingers light skin tone,E13.0
People-Body,hand-fingers-partial,1F90C 1F3FC,fully-qualified,🤌🏼,pinched fingers medium-light skin tone,E13.0
People-Body,hand-fingers-partial,1F90C 1F3FD,fully-qualified,🤌🏽,pinched fingers medium skin tone,E13.0
People-Body,hand-fingers-partial,1F90C 1F3FE,fully-qualified,🤌🏾,pinched fingers medium-dark skin tone,E13.0
People-Body,hand-fingers-partial,1F90C 1F3FF,fully-qualified,🤌🏿,pinched fingers dark skin tone,E13.0
People-Body,hand-fingers-partial,1F90F,fully-qualified,🤏,pinching hand,E12.0
People-Body,hand-fingers-partial,1F90F 1F3FB,fully-qualified,🤏🏻,pinching hand light skin tone,E12.0
People-Body,hand-fingers-partial,1F90F 1F3FC,fully-qualified,🤏🏼,pinching hand medium-light skin tone,E12.0
People-Body,hand-fingers-partial,1F90F 1F3FD,fully-qualified,🤏🏽,pinching hand medium skin tone,E12.0
People-Body,hand-fingers-partial,1F90F 1F3FE,fully-qualified,🤏🏾,pinching hand medium-dark skin tone,E12.0
People-Body,hand-fingers-partial,1F90F 1F3FF,fully-qualified,🤏🏿,pinching hand dark skin tone,E12.0
People-Body,hand-fingers-partial,270C FE0F,fully-qualified,✌️,victory hand,E0.6
People-Body,hand-fingers-partial,270C,unqualified,✌,victory hand,E0.6
People-Body,hand-fingers-partial,270C 1F3FB,fully-qualified,✌🏻,victory hand light skin tone,E1.0
People-Body,hand-fingers-partial,270C 1F3FC,fully-qualified,✌🏼,victory hand medium-light skin tone,E1.0
People-Body,hand-fingers-partial,270C 1F3FD,fully-qualified,✌🏽,victory hand medium skin tone,E1.0
People-Body,hand-fingers-partial,270C 1F3FE,fully-qualified,✌🏾,victory hand medium-dark skin tone,E1.0
People-Body,hand-fingers-partial,270C 1F3FF,fully-qualified,✌🏿,victory hand dark skin tone,E1.0
People-Body,hand-fingers-partial,1F91E,fully-qualified,🤞,crossed fingers,E3.0
People-Body,hand-fingers-partial,1F91E 1F3FB,fully-qualified,🤞🏻,crossed fingers light skin tone,E3.0
People-Body,hand-fingers-partial,1F91E 1F3FC,fully-qualified,🤞🏼,crossed fingers medium-light skin tone,E3.0
People-Body,hand-fingers-partial,1F91E 1F3FD,fully-qualified,🤞🏽,crossed fingers medium skin tone,E3.0
People-Body,hand-fingers-partial,1F91E 1F3FE,fully-qualified,🤞🏾,crossed fingers medium-dark skin tone,E3.0
People-Body,hand-fingers-partial,1F91E 1F3FF,fully-qualified,🤞🏿,crossed fingers dark skin tone,E3.0
People-Body,hand-fingers-partial,1FAF0,fully-qualified,🫰,hand with index finger and thumb crossed,E14.0
People-Body,hand-fingers-partial,1FAF0 1F3FB,fully-qualified,🫰🏻,hand with index finger and thumb crossed light skin tone,E14.0
People-Body,hand-fingers-partial,1FAF0 1F3FC,fully-qualified,🫰🏼,hand with index finger and thumb crossed medium-light skin tone,E14.0
People-Body,hand-fingers-partial,1FAF0 1F3FD,fully-qualified,🫰🏽,hand with index finger and thumb crossed medium skin tone,E14.0
People-Body,hand-fingers-partial,1FAF0 1F3FE,fully-qualified,🫰🏾,hand with index finger and thumb crossed medium-dark skin tone,E14.0
People-Body,hand-fingers-partial,1FAF0 1F3FF,fully-qualified,🫰🏿,hand with index finger and thumb crossed dark skin tone,E14.0
People-Body,hand-fingers-partial,1F91F,fully-qualified,🤟,love-you gesture,E5.0
People-Body,hand-fingers-partial,1F91F 1F3FB,fully-qualified,🤟🏻,love-you gesture light skin tone,E5.0
People-Body,hand-fingers-partial,1F91F 1F3FC,fully-qualified,🤟🏼,love-you gesture medium-light skin tone,E5.0
People-Body,hand-fingers-partial,1F91F 1F3FD,fully-qualified,🤟🏽,love-you gesture medium skin tone,E5.0
People-Body,hand-fingers-partial,1F91F 1F3FE,fully-qualified,🤟🏾,love-you gesture medium-dark skin tone,E5.0
People-Body,hand-fingers-partial,1F91F 1F3FF,fully-qualified,🤟🏿,love-you gesture dark skin tone,E5.0
People-Body,hand-fingers-partial,1F918,fully-qualified,🤘,sign of the horns,E1.0
People-Body,hand-fingers-partial,1F918 1F3FB,fully-qualified,🤘🏻,sign of the horns light skin tone,E1.0
People-Body,hand-fingers-partial,1F918 1F3FC,fully-qualified,🤘🏼,sign of the horns medium-light skin tone,E1.0
People-Body,hand-fingers-partial,1F918 1F3FD,fully-qualified,🤘🏽,sign of the horns medium skin tone,E1.0
People-Body,hand-fingers-partial,1F918 1F3FE,fully-qualified,🤘🏾,sign of the horns medium-dark skin tone,E1.0
People-Body,hand-fingers-partial,1F918 1F3FF,fully-qualified,🤘🏿,sign of the horns dark skin tone,E1.0
People-Body,hand-fingers-partial,1F919,fully-qualified,🤙,call me hand,E3.0
People-Body,hand-fingers-partial,1F919 1F3FB,fully-qualified,🤙🏻,call me hand light skin tone,E3.0
People-Body,hand-fingers-partial,1F919 1F3FC,fully-qualified,🤙🏼,call me hand medium-light skin tone,E3.0
People-Body,hand-fingers-partial,1F919 1F3FD,fully-qualified,🤙🏽,call me hand medium skin tone,E3.0
People-Body,hand-fingers-partial,1F919 1F3FE,fully-qualified,🤙🏾,call me hand medium-dark skin tone,E3.0
People-Body,hand-fingers-partial,1F919 1F3FF,fully-qualified,🤙🏿,call me hand dark skin tone,E3.0
People-Body,hand-single-finger,1F448,fully-qualified,👈,backhand index pointing left,E0.6
People-Body,hand-single-finger,1F448 1F3FB,fully-qualified,👈🏻,backhand index pointing left light skin tone,E1.0
People-Body,hand-single-finger,1F448 1F3FC,fully-qualified,👈🏼,backhand index pointing left medium-light skin tone,E1.0
People-Body,hand-single-finger,1F448 1F3FD,fully-qualified,👈🏽,backhand index pointing left medium skin tone,E1.0
People-Body,hand-single-finger,1F448 1F3FE,fully-qualified,👈🏾,backhand index pointing left medium-dark skin tone,E1.0
People-Body,hand-single-finger,1F448 1F3FF,fully-qualified,👈🏿,backhand index pointing left dark skin tone,E1.0
People-Body,hand-single-finger,1F449,fully-qualified,👉,backhand index pointing right,E0.6
People-Body,hand-single-finger,1F449 1F3FB,fully-qualified,👉🏻,backhand index pointing right light skin tone,E1.0
People-Body,hand-single-finger,1F449 1F3FC,fully-qualified,👉🏼,backhand index pointing right medium-light skin tone,E1.0
People-Body,hand-single-finger,1F449 1F3FD,fully-qualified,👉🏽,backhand index pointing right medium skin tone,E1.0
People-Body,hand-single-finger,1F449 1F3FE,fully-qualified,👉🏾,backhand index pointing right medium-dark skin tone,E1.0
People-Body,hand-single-finger,1F449 1F3FF,fully-qualified,👉🏿,backhand index pointing right dark skin tone,E1.0
People-Body,hand-single-finger,1F446,fully-qualified,👆,backhand index pointing up,E0.6
People-Body,hand-single-finger,1F446 1F3FB,fully-qualified,👆🏻,backhand index pointing up light skin tone,E1.0
People-Body,hand-single-finger,1F446 1F3FC,fully-qualified,👆🏼,backhand index pointing up medium-light skin tone,E1.0
People-Body,hand-single-finger,1F446 1F3FD,fully-qualified,👆🏽,backhand index pointing up medium skin tone,E1.0
People-Body,hand-single-finger,1F446 1F3FE,fully-qualified,👆🏾,backhand index pointing up medium-dark skin tone,E1.0
People-Body,hand-single-finger,1F446 1F3FF,fully-qualified,👆🏿,backhand index pointing up dark skin tone,E1.0
People-Body,hand-single-finger,1F595,fully-qualified,🖕,middle finger,E1.0
People-Body,hand-single-finger,1F595 1F3FB,fully-qualified,🖕🏻,middle finger light skin tone,E1.0
People-Body,hand-single-finger,1F595 1F3FC,fully-qualified,🖕🏼,middle finger medium-light skin tone,E1.0
People-Body,hand-single-finger,1F595 1F3FD,fully-qualified,🖕🏽,middle finger medium skin tone,E1.0
People-Body,hand-single-finger,1F595 1F3FE,fully-qualified,🖕🏾,middle finger medium-dark skin tone,E1.0
People-Body,hand-single-finger,1F595 1F3FF,fully-qualified,🖕🏿,middle finger dark skin tone,E1.0
People-Body,hand-single-finger,1F447,fully-qualified,👇,backhand index pointing down,E0.6
People-Body,hand-single-finger,1F447 1F3FB,fully-qualified,👇🏻,backhand index pointing down light skin tone,E1.0
People-Body,hand-single-finger,1F447 1F3FC,fully-qualified,👇🏼,backhand index pointing down medium-light skin tone,E1.0
People-Body,hand-single-finger,1F447 1F3FD,fully-qualified,👇🏽,backhand index pointing down medium skin tone,E1.0
People-Body,hand-single-finger,1F447 1F3FE,fully-qualified,👇🏾,backhand index pointing down medium-dark skin tone,E1.0
People-Body,hand-single-finger,1F447 1F3FF,fully-qualified,👇🏿,backhand index pointing down dark skin tone,E1.0
People-Body,hand-single-finger,261D FE0F,fully-qualified,☝️,index pointing up,E0.6
People-Body,hand-single-finger,261D,unqualified,☝,index pointing up,E0.6
People-Body,hand-single-finger,261D 1F3FB,fully-qualified,☝🏻,index pointing up light skin tone,E1.0
People-Body,hand-single-finger,261D 1F3FC,fully-qualified,☝🏼,index pointing up medium-light skin tone,E1.0
People-Body,hand-single-finger,261D 1F3FD,fully-qualified,☝🏽,index pointing up medium skin tone,E1.0
People-Body,hand-single-finger,261D 1F3FE,fully-qualified,☝🏾,index pointing up medium-dark skin tone,E1.0
People-Body,hand-single-finger,261D 1F3FF,fully-qualified,☝🏿,index pointing up dark skin tone,E1.0
People-Body,hand-single-finger,1FAF5,fully-qualified,🫵,index pointing at the viewer,E14.0
People-Body,hand-single-finger,1FAF5 1F3FB,fully-qualified,🫵🏻,index pointing at the viewer light skin tone,E14.0
People-Body,hand-single-finger,1FAF5 1F3FC,fully-qualified,🫵🏼,index pointing at the viewer medium-light skin tone,E14.0
People-Body,hand-single-finger,1FAF5 1F3FD,fully-qualified,🫵🏽,index pointing at the viewer medium skin tone,E14.0
People-Body,hand-single-finger,1FAF5 1F3FE,fully-qualified,🫵🏾,index pointing at the viewer medium-dark skin tone,E14.0
People-Body,hand-single-finger,1FAF5 1F3FF,fully-qualified,🫵🏿,index pointing at the viewer dark skin tone,E14.0
People-Body,hand-fingers-closed,1F44D,fully-qualified,👍,thumbs up,E0.6
People-Body,hand-fingers-closed,1F44D 1F3FB,fully-qualified,👍🏻,thumbs up light skin tone,E1.0
People-Body,hand-fingers-closed,1F44D 1F3FC,fully-qualified,👍🏼,thumbs up medium-light skin tone,E1.0
People-Body,hand-fingers-closed,1F44D 1F3FD,fully-qualified,👍🏽,thumbs up medium skin tone,E1.0
People-Body,hand-fingers-closed,1F44D 1F3FE,fully-qualified,👍🏾,thumbs up medium-dark skin tone,E1.0
People-Body,hand-fingers-closed,1F44D 1F3FF,fully-qualified,👍🏿,thumbs up dark skin tone,E1.0
People-Body,hand-fingers-closed,1F44E,fully-qualified,👎,thumbs down,E0.6
People-Body,hand-fingers-closed,1F44E 1F3FB,fully-qualified,👎🏻,thumbs down light skin tone,E1.0
People-Body,hand-fingers-closed,1F44E 1F3FC,fully-qualified,👎🏼,thumbs down medium-light skin tone,E1.0
People-Body,hand-fingers-closed,1F44E 1F3FD,fully-qualified,👎🏽,thumbs down medium skin tone,E1.0
People-Body,hand-fingers-closed,1F44E 1F3FE,fully-qualified,👎🏾,thumbs down medium-dark skin tone,E1.0
People-Body,hand-fingers-closed,1F44E 1F3FF,fully-qualified,👎🏿,thumbs down dark skin tone,E1.0
People-Body,hand-fingers-closed,270A,fully-qualified,✊,raised fist,E0.6
People-Body,hand-fingers-closed,270A 1F3FB,fully-qualified,✊🏻,raised fist light skin tone,E1.0
People-Body,hand-fingers-closed,270A 1F3FC,fully-qualified,✊🏼,raised fist medium-light skin tone,E1.0
People-Body,hand-fingers-closed,270A 1F3FD,fully-qualified,✊🏽,raised fist medium skin tone,E1.0
People-Body,hand-fingers-closed,270A 1F3FE,fully-qualified,✊🏾,raised fist medium-dark skin tone,E1.0
People-Body,hand-fingers-closed,270A 1F3FF,fully-qualified,✊🏿,raised fist dark skin tone,E1.0
People-Body,hand-fingers-closed,1F44A,fully-qualified,👊,oncoming fist,E0.6
People-Body,hand-fingers-closed,1F44A 1F3FB,fully-qualified,👊🏻,oncoming fist light skin tone,E1.0
People-Body,hand-fingers-closed,1F44A 1F3FC,fully-qualified,👊🏼,oncoming fist medium-light skin tone,E1.0
People-Body,hand-fingers-closed,1F44A 1F3FD,fully-qualified,👊🏽,oncoming fist medium skin tone,E1.0
People-Body,hand-fingers-closed,1F44A 1F3FE,fully-qualified,👊🏾,oncoming fist medium-dark skin tone,E1.0
People-Body,hand-fingers-closed,1F44A 1F3FF,fully-qualified,👊🏿,oncoming fist dark skin tone,E1.0
People-Body,hand-fingers-closed,1F91B,fully-qualified,🤛,left-facing fist,E3.0
People-Body,hand-fingers-closed,1F91B 1F3FB,fully-qualified,🤛🏻,left-facing fist light skin tone,E3.0
People-Body,hand-fingers-closed,1F91B 1F3FC,fully-qualified,🤛🏼,left-facing fist medium-light skin tone,E3.0
People-Body,hand-fingers-closed,1F91B 1F3FD,fully-qualified,🤛🏽,left-facing fist medium skin tone,E3.0
People-Body,hand-fingers-closed,1F91B 1F3FE,fully-qualified,🤛🏾,left-facing fist medium-dark skin tone,E3.0
People-Body,hand-fingers-closed,1F91B 1F3FF,fully-qualified,🤛🏿,left-facing fist dark skin tone,E3.0
People-Body,hand-fingers-closed,1F91C,fully-qualified,🤜,right-facing fist,E3.0
People-Body,hand-fingers-closed,1F91C 1F3FB,fully-qualified,🤜🏻,right-facing fist light skin tone,E3.0
People-Body,hand-fingers-closed,1F91C 1F3FC,fully-qualified,🤜🏼,right-facing fist medium-light skin tone,E3.0
People-Body,hand-fingers-closed,1F91C 1F3FD,fully-qualified,🤜🏽,right-facing fist medium skin tone,E3.0
People-Body,hand-fingers-closed,1F91C 1F3FE,fully-qualified,🤜🏾,right-facing fist medium-dark skin tone,E3.0
People-Body,hand-fingers-closed,1F91C 1F3FF,fully-qualified,🤜🏿,right-facing fist dark skin tone,E3.0
People-Body,hands,1F44F,fully-qualified,👏,clapping hands,E0.6
People-Body,hands,1F44F 1F3FB,fully-qualified,👏🏻,clapping hands light skin tone,E1.0
People-Body,hands,1F44F 1F3FC,fully-qualified,👏🏼,clapping hands medium-light skin tone,E1.0
People-Body,hands,1F44F 1F3FD,fully-qualified,👏🏽,clapping hands medium skin tone,E1.0
People-Body,hands,1F44F 1F3FE,fully-qualified,👏🏾,clapping hands medium-dark skin tone,E1.0
People-Body,hands,1F44F 1F3FF,fully-qualified,👏🏿,clapping hands dark skin tone,E1.0
People-Body,hands,1F64C,fully-qualified,🙌,raising hands,E0.6
People-Body,hands,1F64C 1F3FB,fully-qualified,🙌🏻,raising hands light skin tone,E1.0
People-Body,hands,1F64C 1F3FC,fully-qualified,🙌🏼,raising hands medium-light skin tone,E1.0
People-Body,hands,1F64C 1F3FD,fully-qualified,🙌🏽,raising hands medium skin tone,E1.0
People-Body,hands,1F64C 1F3FE,fully-qualified,🙌🏾,raising hands medium-dark skin tone,E1.0
People-Body,hands,1F64C 1F3FF,fully-qualified,🙌🏿,raising hands dark skin tone,E1.0
People-Body,hands,1FAF6,fully-qualified,🫶,heart hands,E14.0
People-Body,hands,1FAF6 1F3FB,fully-qualified,🫶🏻,heart hands light skin tone,E14.0
People-Body,hands,1FAF6 1F3FC,fully-qualified,🫶🏼,heart hands medium-light skin tone,E14.0
People-Body,hands,1FAF6 1F3FD,fully-qualified,🫶🏽,heart hands medium skin tone,E14.0
People-Body,hands,1FAF6 1F3FE,fully-qualified,🫶🏾,heart hands medium-dark skin tone,E14.0
People-Body,hands,1FAF6 1F3FF,fully-qualified,🫶🏿,heart hands dark skin tone,E14.0
People-Body,hands,1F450,fully-qualified,👐,open hands,E0.6
People-Body,hands,1F450 1F3FB,fully-qualified,👐🏻,open hands light skin tone,E1.0
People-Body,hands,1F450 1F3FC,fully-qualified,👐🏼,open hands medium-light skin tone,E1.0
People-Body,hands,1F450 1F3FD,fully-qualified,👐🏽,open hands medium skin tone,E1.0
People-Body,hands,1F450 1F3FE,fully-qualified,👐🏾,open hands medium-dark skin tone,E1.0
People-Body,hands,1F450 1F3FF,fully-qualified,👐🏿,open hands dark skin tone,E1.0
People-Body,hands,1F932,fully-qualified,🤲,palms up together,E5.0
People-Body,hands,1F932 1F3FB,fully-qualified,🤲🏻,palms up together light skin tone,E5.0
People-Body,hands,1F932 1F3FC,fully-qualified,🤲🏼,palms up together medium-light skin tone,E5.0
People-Body,hands,1F932 1F3FD,fully-qualified,🤲🏽,palms up together medium skin tone,E5.0
People-Body,hands,1F932 1F3FE,fully-qualified,🤲🏾,palms up together medium-dark skin tone,E5.0
People-Body,hands,1F932 1F3FF,fully-qualified,🤲🏿,palms up together dark skin tone,E5.0
People-Body,hands,1F91D,fully-qualified,🤝,handshake,E3.0
People-Body,hands,1F91D 1F3FB,fully-qualified,🤝🏻,handshake light skin tone,E14.0
People-Body,hands,1F91D 1F3FC,fully-qualified,🤝🏼,handshake medium-light skin tone,E14.0
People-Body,hands,1F91D 1F3FD,fully-qualified,🤝🏽,handshake medium skin tone,E14.0
People-Body,hands,1F91D 1F3FE,fully-qualified,🤝🏾,handshake medium-dark skin tone,E14.0
People-Body,hands,1F91D 1F3FF,fully-qualified,🤝🏿,handshake dark skin tone,E14.0
People-Body,hands,1FAF1 1F3FB 200D 1FAF2 1F3FC,fully-qualified,🫱🏻‍🫲🏼,handshake light skin tone medium-light skin tone,E14.0
People-Body,hands,1FAF1 1F3FB 200D 1FAF2 1F3FD,fully-qualified,🫱🏻‍🫲🏽,handshake light skin tone medium skin tone,E14.0
People-Body,hands,1FAF1 1F3FB 200D 1FAF2 1F3FE,fully-qualified,🫱🏻‍🫲🏾,handshake light skin tone medium-dark skin tone,E14.0
People-Body,hands,1FAF1 1F3FB 200D 1FAF2 1F3FF,fully-qualified,🫱🏻‍🫲🏿,handshake light skin tone dark skin tone,E14.0
People-Body,hands,1FAF1 1F3FC 200D 1FAF2 1F3FB,fully-qualified,🫱🏼‍🫲🏻,handshake medium-light skin tone light skin tone,E14.0
People-Body,hands,1FAF1 1F3FC 200D 1FAF2 1F3FD,fully-qualified,🫱🏼‍🫲🏽,handshake medium-light skin tone medium skin tone,E14.0
People-Body,hands,1FAF1 1F3FC 200D 1FAF2 1F3FE,fully-qualified,🫱🏼‍🫲🏾,handshake medium-light skin tone medium-dark skin tone,E14.0
People-Body,hands,1FAF1 1F3FC 200D 1FAF2 1F3FF,fully-qualified,🫱🏼‍🫲🏿,handshake medium-light skin tone dark skin tone,E14.0
People-Body,hands,1FAF1 1F3FD 200D 1FAF2 1F3FB,fully-qualified,🫱🏽‍🫲🏻,handshake medium skin tone light skin tone,E14.0
People-Body,hands,1FAF1 1F3FD 200D 1FAF2 1F3FC,fully-qualified,🫱🏽‍🫲🏼,handshake medium skin tone medium-light skin tone,E14.0
People-Body,hands,1FAF1 1F3FD 200D 1FAF2 1F3FE,fully-qualified,🫱🏽‍🫲🏾,handshake medium skin tone medium-dark skin tone,E14.0
People-Body,hands,1FAF1 1F3FD 200D 1FAF2 1F3FF,fully-qualified,🫱🏽‍🫲🏿,handshake medium skin tone dark skin tone,E14.0
People-Body,hands,1FAF1 1F3FE 200D 1FAF2 1F3FB,fully-qualified,🫱🏾‍🫲🏻,handshake medium-dark skin tone light skin tone,E14.0
People-Body,hands,1FAF1 1F3FE 200D 1FAF2 1F3FC,fully-qualified,🫱🏾‍🫲🏼,handshake medium-dark skin tone medium-light skin tone,E14.0
People-Body,hands,1FAF1 1F3FE 200D 1FAF2 1F3FD,fully-qualified,🫱🏾‍🫲🏽,handshake medium-dark skin tone medium skin tone,E14.0
People-Body,hands,1FAF1 1F3FE 200D 1FAF2 1F3FF,fully-qualified,🫱🏾‍🫲🏿,handshake medium-dark skin tone dark skin tone,E14.0
People-Body,hands,1FAF1 1F3FF 200D 1FAF2 1F3FB,fully-qualified,🫱🏿‍🫲🏻,handshake dark skin tone light skin tone,E14.0
People-Body,hands,1FAF1 1F3FF 200D 1FAF2 1F3FC,fully-qualified,🫱🏿‍🫲🏼,handshake dark skin tone medium-light skin tone,E14.0
People-Body,hands,1FAF1 1F3FF 200D 1FAF2 1F3FD,fully-qualified,🫱🏿‍🫲🏽,handshake dark skin tone medium skin tone,E14.0
People-Body,hands,1FAF1 1F3FF 200D 1FAF2 1F3FE,fully-qualified,🫱🏿‍🫲🏾,handshake dark skin tone medium-dark skin tone,E14.0
People-Body,hands,1F64F,fully-qualified,🙏,folded hands,E0.6
People-Body,hands,1F64F 1F3FB,fully-qualified,🙏🏻,folded hands light skin tone,E1.0
People-Body,hands,1F64F 1F3FC,fully-qualified,🙏🏼,folded hands medium-light skin tone,E1.0
People-Body,hands,1F64F 1F3FD,fully-qualified,🙏🏽,folded hands medium skin tone,E1.0
People-Body,hands,1F64F 1F3FE,fully-qualified,🙏🏾,folded hands medium-dark skin tone,E1.0
People-Body,hands,1F64F 1F3FF,fully-qualified,🙏🏿,folded hands dark skin tone,E1.0
People-Body,hand-prop,270D FE0F,fully-qualified,✍️,writing hand,E0.7
People-Body,hand-prop,270D,unqualified,✍,writing hand,E0.7
People-Body,hand-prop,270D 1F3FB,fully-qualified,✍🏻,writing hand light skin tone,E1.0
People-Body,hand-prop,270D 1F3FC,fully-qualified,✍🏼,writing hand medium-light skin tone,E1.0
People-Body,hand-prop,270D 1F3FD,fully-qualified,✍🏽,writing hand medium skin tone,E1.0
People-Body,hand-prop,270D 1F3FE,fully-qualified,✍🏾,writing hand medium-dark skin tone,E1.0
People-Body,hand-prop,270D 1F3FF,fully-qualified,✍🏿,writing hand dark skin tone,E1.0
People-Body,hand-prop,1F485,fully-qualified,💅,nail polish,E0.6
People-Body,hand-prop,1F485 1F3FB,fully-qualified,💅🏻,nail polish light skin tone,E1.0
People-Body,hand-prop,1F485 1F3FC,fully-qualified,💅🏼,nail polish medium-light skin tone,E1.0
People-Body,hand-prop,1F485 1F3FD,fully-qualified,💅🏽,nail polish medium skin tone,E1.0
People-Body,hand-prop,1F485 1F3FE,fully-qualified,💅🏾,nail polish medium-dark skin tone,E1.0
People-Body,hand-prop,1F485 1F3FF,fully-qualified,💅🏿,nail polish dark skin tone,E1.0
People-Body,hand-prop,1F933,fully-qualified,🤳,selfie,E3.0
People-Body,hand-prop,1F933 1F3FB,fully-qualified,🤳🏻,selfie light skin tone,E3.0
People-Body,hand-prop,1F933 1F3FC,fully-qualified,🤳🏼,selfie medium-light skin tone,E3.0
People-Body,hand-prop,1F933 1F3FD,fully-qualified,🤳🏽,selfie medium skin tone,E3.0
People-Body,hand-prop,1F933 1F3FE,fully-qualified,🤳🏾,selfie medium-dark skin tone,E3.0
People-Body,hand-prop,1F933 1F3FF,fully-qualified,🤳🏿,selfie dark skin tone,E3.0
People-Body,body-parts,1F4AA,fully-qualified,💪,flexed biceps,E0.6
People-Body,body-parts,1F4AA 1F3FB,fully-qualified,💪🏻,flexed biceps light skin tone,E1.0
People-Body,body-parts,1F4AA 1F3FC,fully-qualified,💪🏼,flexed biceps medium-light skin tone,E1.0
People-Body,body-parts,1F4AA 1F3FD,fully-qualified,💪🏽,flexed biceps medium skin tone,E1.0
People-Body,body-parts,1F4AA 1F3FE,fully-qualified,💪🏾,flexed biceps medium-dark skin tone,E1.0
People-Body,body-parts,1F4AA 1F3FF,fully-qualified,💪🏿,flexed biceps dark skin tone,E1.0
People-Body,body-parts,1F9BE,fully-qualified,🦾,mechanical arm,E12.0
People-Body,body-parts,1F9BF,fully-qualified,🦿,mechanical leg,E12.0
People-Body,body-parts,1F9B5,fully-qualified,🦵,leg,E11.0
People-Body,body-parts,1F9B5 1F3FB,fully-qualified,🦵🏻,leg light skin tone,E11.0
People-Body,body-parts,1F9B5 1F3FC,fully-qualified,🦵🏼,leg medium-light skin tone,E11.0
People-Body,body-parts,1F9B5 1F3FD,fully-qualified,🦵🏽,leg medium skin tone,E11.0
People-Body,body-parts,1F9B5 1F3FE,fully-qualified,🦵🏾,leg medium-dark skin tone,E11.0
People-Body,body-parts,1F9B5 1F3FF,fully-qualified,🦵🏿,leg dark skin tone,E11.0
People-Body,body-parts,1F9B6,fully-qualified,🦶,foot,E11.0
People-Body,body-parts,1F9B6 1F3FB,fully-qualified,🦶🏻,foot light skin tone,E11.0
People-Body,body-parts,1F9B6 1F3FC,fully-qualified,🦶🏼,foot medium-light skin tone,E11.0
People-Body,body-parts,1F9B6 1F3FD,fully-qualified,🦶🏽,foot medium skin tone,E11.0
People-Body,body-parts,1F9B6 1F3FE,fully-qualified,🦶🏾,foot medium-dark skin tone,E11.0
People-Body,body-parts,1F9B6 1F3FF,fully-qualified,🦶🏿,foot dark skin tone,E11.0
People-Body,body-parts,1F442,fully-qualified,👂,ear,E0.6
People-Body,body-parts,1F442 1F3FB,fully-qualified,👂🏻,ear light skin tone,E1.0
People-Body,body-parts,1F442 1F3FC,fully-qualified,👂🏼,ear medium-light skin tone,E1.0
People-Body,body-parts,1F442 1F3FD,fully-qualified,👂🏽,ear medium skin tone,E1.0
People-Body,body-parts,1F442 1F3FE,fully-qualified,👂🏾,ear medium-dark skin tone,E1.0
People-Body,body-parts,1F442 1F3FF,fully-qualified,👂🏿,ear dark skin tone,E1.0
People-Body,body-parts,1F9BB,fully-qualified,🦻,ear with hearing aid,E12.0
People-Body,body-parts,1F9BB 1F3FB,fully-qualified,🦻🏻,ear with hearing aid light skin tone,E12.0
People-Body,body-parts,1F9BB 1F3FC,fully-qualified,🦻🏼,ear with hearing aid medium-light skin tone,E12.0
People-Body,body-parts,1F9BB 1F3FD,fully-qualified,🦻🏽,ear with hearing aid medium skin tone,E12.0
People-Body,body-parts,1F9BB 1F3FE,fully-qualified,🦻🏾,ear with hearing aid medium-dark skin tone,E12.0
People-Body,body-parts,1F9BB 1F3FF,fully-qualified,🦻🏿,ear with hearing aid dark skin tone,E12.0
People-Body,body-parts,1F443,fully-qualified,👃,nose,E0.6
People-Body,body-parts,1F443 1F3FB,fully-qualified,👃🏻,nose light skin tone,E1.0
People-Body,body-parts,1F443 1F3FC,fully-qualified,👃🏼,nose medium-light skin tone,E1.0
People-Body,body-parts,1F443 1F3FD,fully-qualified,👃🏽,nose medium skin tone,E1.0
People-Body,body-parts,1F443 1F3FE,fully-qualified,👃🏾,nose medium-dark skin tone,E1.0
People-Body,body-parts,1F443 1F3FF,fully-qualified,👃🏿,nose dark skin tone,E1.0
People-Body,body-parts,1F9E0,fully-qualified,🧠,brain,E5.0
People-Body,body-parts,1FAC0,fully-qualified,🫀,anatomical heart,E13.0
People-Body,body-parts,1FAC1,fully-qualified,🫁,lungs,E13.0
People-Body,body-parts,1F9B7,fully-qualified,🦷,tooth,E11.0
People-Body,body-parts,1F9B4,fully-qualified,🦴,bone,E11.0
People-Body,body-parts,1F440,fully-qualified,👀,eyes,E0.6
People-Body,body-parts,1F441 FE0F,fully-qualified,👁️,eye,E0.7
People-Body,body-parts,1F441,unqualified,👁,eye,E0.7
People-Body,body-parts,1F445,fully-qualified,👅,tongue,E0.6
People-Body,body-parts,1F444,fully-qualified,👄,mouth,E0.6
People-Body,body-parts,1FAE6,fully-qualified,🫦,biting lip,E14.0
People-Body,person,1F476,fully-qualified,👶,baby,E0.6
People-Body,person,1F476 1F3FB,fully-qualified,👶🏻,baby light skin tone,E1.0
People-Body,person,1F476 1F3FC,fully-qualified,👶🏼,baby medium-light skin tone,E1.0
People-Body,person,1F476 1F3FD,fully-qualified,👶🏽,baby medium skin tone,E1.0
People-Body,person,1F476 1F3FE,fully-qualified,👶🏾,baby medium-dark skin tone,E1.0
People-Body,person,1F476 1F3FF,fully-qualified,👶🏿,baby dark skin tone,E1.0
People-Body,person,1F9D2,fully-qualified,🧒,child,E5.0
People-Body,person,1F9D2 1F3FB,fully-qualified,🧒🏻,child light skin tone,E5.0
People-Body,person,1F9D2 1F3FC,fully-qualified,🧒🏼,child medium-light skin tone,E5.0
People-Body,person,1F9D2 1F3FD,fully-qualified,🧒🏽,child medium skin tone,E5.0
People-Body,person,1F9D2 1F3FE,fully-qualified,🧒🏾,child medium-dark skin tone,E5.0
People-Body,person,1F9D2 1F3FF,fully-qualified,🧒🏿,child dark skin tone,E5.0
People-Body,person,1F466,fully-qualified,👦,boy,E0.6
People-Body,person,1F466 1F3FB,fully-qualified,👦🏻,boy light skin tone,E1.0
People-Body,person,1F466 1F3FC,fully-qualified,👦🏼,boy medium-light skin tone,E1.0
People-Body,person,1F466 1F3FD,fully-qualified,👦🏽,boy medium skin tone,E1.0
People-Body,person,1F466 1F3FE,fully-qualified,👦🏾,boy medium-dark skin tone,E1.0
People-Body,person,1F466 1F3FF,fully-qualified,👦🏿,boy dark skin tone,E1.0
People-Body,person,1F467,fully-qualified,👧,girl,E0.6
People-Body,person,1F467 1F3FB,fully-qualified,👧🏻,girl light skin tone,E1.0
People-Body,person,1F467 1F3FC,fully-qualified,👧🏼,girl medium-light skin tone,E1.0
People-Body,person,1F467 1F3FD,fully-qualified,👧🏽,girl medium skin tone,E1.0
People-Body,person,1F467 1F3FE,fully-qualified,👧🏾,girl medium-dark skin tone,E1.0
People-Body,person,1F467 1F3FF,fully-qualified,👧🏿,girl dark skin tone,E1.0
People-Body,person,1F9D1,fully-qualified,🧑,person,E5.0
People-Body,person,1F9D1 1F3FB,fully-qualified,🧑🏻,person light skin tone,E5.0
People-Body,person,1F9D1 1F3FC,fully-qualified,🧑🏼,person medium-light skin tone,E5.0
People-Body,person,1F9D1 1F3FD,fully-qualified,🧑🏽,person medium skin tone,E5.0
People-Body,person,1F9D1 1F3FE,fully-qualified,🧑🏾,person medium-dark skin tone,E5.0
People-Body,person,1F9D1 1F3FF,fully-qualified,🧑🏿,person dark skin tone,E5.0
People-Body,person,1F471,fully-qualified,👱,person blond hair,E0.6
People-Body,person,1F471 1F3FB,fully-qualified,👱🏻,person light skin tone blond hair,E1.0
People-Body,person,1F471 1F3FC,fully-qualified,👱🏼,person medium-light skin tone blond hair,E1.0
People-Body,person,1F471 1F3FD,fully-qualified,👱🏽,person medium skin tone blond hair,E1.0
People-Body,person,1F471 1F3FE,fully-qualified,👱🏾,person medium-dark skin tone blond hair,E1.0
People-Body,person,1F471 1F3FF,fully-qualified,👱🏿,person dark skin tone blond hair,E1.0
People-Body,person,1F468,fully-qualified,👨,man,E0.6
People-Body,person,1F468 1F3FB,fully-qualified,👨🏻,man light skin tone,E1.0
People-Body,person,1F468 1F3FC,fully-qualified,👨🏼,man medium-light skin tone,E1.0
People-Body,person,1F468 1F3FD,fully-qualified,👨🏽,man medium skin tone,E1.0
People-Body,person,1F468 1F3FE,fully-qualified,👨🏾,man medium-dark skin tone,E1.0
People-Body,person,1F468 1F3FF,fully-qualified,👨🏿,man dark skin tone,E1.0
People-Body,person,1F9D4,fully-qualified,🧔,person beard,E5.0
People-Body,person,1F9D4 1F3FB,fully-qualified,🧔🏻,person light skin tone beard,E5.0
People-Body,person,1F9D4 1F3FC,fully-qualified,🧔🏼,person medium-light skin tone beard,E5.0
People-Body,person,1F9D4 1F3FD,fully-qualified,🧔🏽,person medium skin tone beard,E5.0
People-Body,person,1F9D4 1F3FE,fully-qualified,🧔🏾,person medium-dark skin tone beard,E5.0
People-Body,person,1F9D4 1F3FF,fully-qualified,🧔🏿,person dark skin tone beard,E5.0
People-Body,person,1F9D4 200D 2642 FE0F,fully-qualified,🧔‍♂️,man beard,E13.1
People-Body,person,1F9D4 200D 2642,minimally-qualified,🧔‍♂,man beard,E13.1
People-Body,person,1F9D4 1F3FB 200D 2642 FE0F,fully-qualified,🧔🏻‍♂️,man light skin tone beard,E13.1
People-Body,person,1F9D4 1F3FB 200D 2642,minimally-qualified,🧔🏻‍♂,man light skin tone beard,E13.1
People-Body,person,1F9D4 1F3FC 200D 2642 FE0F,fully-qualified,🧔🏼‍♂️,man medium-light skin tone beard,E13.1
People-Body,person,1F9D4 1F3FC 200D 2642,minimally-qualified,🧔🏼‍♂,man medium-light skin tone beard,E13.1
People-Body,person,1F9D4 1F3FD 200D 2642 FE0F,fully-qualified,🧔🏽‍♂️,man medium skin tone beard,E13.1
People-Body,person,1F9D4 1F3FD 200D 2642,minimally-qualified,🧔🏽‍♂,man medium skin tone beard,E13.1
People-Body,person,1F9D4 1F3FE 200D 2642 FE0F,fully-qualified,🧔🏾‍♂️,man medium-dark skin tone beard,E13.1
People-Body,person,1F9D4 1F3FE 200D 2642,minimally-qualified,🧔🏾‍♂,man medium-dark skin tone beard,E13.1
People-Body,person,1F9D4 1F3FF 200D 2642 FE0F,fully-qualified,🧔🏿‍♂️,man dark skin tone beard,E13.1
People-Body,person,1F9D4 1F3FF 200D 2642,minimally-qualified,🧔🏿‍♂,man dark skin tone beard,E13.1
People-Body,person,1F9D4 200D 2640 FE0F,fully-qualified,🧔‍♀️,woman beard,E13.1
People-Body,person,1F9D4 200D 2640,minimally-qualified,🧔‍♀,woman beard,E13.1
People-Body,person,1F9D4 1F3FB 200D 2640 FE0F,fully-qualified,🧔🏻‍♀️,woman light skin tone beard,E13.1
People-Body,person,1F9D4 1F3FB 200D 2640,minimally-qualified,🧔🏻‍♀,woman light skin tone beard,E13.1
People-Body,person,1F9D4 1F3FC 200D 2640 FE0F,fully-qualified,🧔🏼‍♀️,woman medium-light skin tone beard,E13.1
People-Body,person,1F9D4 1F3FC 200D 2640,minimally-qualified,🧔🏼‍♀,woman medium-light skin tone beard,E13.1
People-Body,person,1F9D4 1F3FD 200D 2640 FE0F,fully-qualified,🧔🏽‍♀️,woman medium skin tone beard,E13.1
People-Body,person,1F9D4 1F3FD 200D 2640,minimally-qualified,🧔🏽‍♀,woman medium skin tone beard,E13.1
People-Body,person,1F9D4 1F3FE 200D 2640 FE0F,fully-qualified,🧔🏾‍♀️,woman medium-dark skin tone beard,E13.1
People-Body,person,1F9D4 1F3FE 200D 2640,minimally-qualified,🧔🏾‍♀,woman medium-dark skin tone beard,E13.1
People-Body,person,1F9D4 1F3FF 200D 2640 FE0F,fully-qualified,🧔🏿‍♀️,woman dark skin tone beard,E13.1
People-Body,person,1F9D4 1F3FF 200D 2640,minimally-qualified,🧔🏿‍♀,woman dark skin tone beard,E13.1
People-Body,person,1F468 200D 1F9B0,fully-qualified,👨‍🦰,man red hair,E11.0
People-Body,person,1F468 1F3FB 200D 1F9B0,fully-qualified,👨🏻‍🦰,man light skin tone red hair,E11.0
People-Body,person,1F468 1F3FC 200D 1F9B0,fully-qualified,👨🏼‍🦰,man medium-light skin tone red hair,E11.0
People-Body,person,1F468 1F3FD 200D 1F9B0,fully-qualified,👨🏽‍🦰,man medium skin tone red hair,E11.0
People-Body,person,1F468 1F3FE 200D 1F9B0,fully-qualified,👨🏾‍🦰,man medium-dark skin tone red hair,E11.0
People-Body,person,1F468 1F3FF 200D 1F9B0,fully-qualified,👨🏿‍🦰,man dark skin tone red hair,E11.0
People-Body,person,1F468 200D 1F9B1,fully-qualified,👨‍🦱,man curly hair,E11.0
People-Body,person,1F468 1F3FB 200D 1F9B1,fully-qualified,👨🏻‍🦱,man light skin tone curly hair,E11.0
People-Body,person,1F468 1F3FC 200D 1F9B1,fully-qualified,👨🏼‍🦱,man medium-light skin tone curly hair,E11.0
People-Body,person,1F468 1F3FD 200D 1F9B1,fully-qualified,👨🏽‍🦱,man medium skin tone curly hair,E11.0
People-Body,person,1F468 1F3FE 200D 1F9B1,fully-qualified,👨🏾‍🦱,man medium-dark skin tone curly hair,E11.0
People-Body,person,1F468 1F3FF 200D 1F9B1,fully-qualified,👨🏿‍🦱,man dark skin tone curly hair,E11.0
People-Body,person,1F468 200D 1F9B3,fully-qualified,👨‍🦳,man white hair,E11.0
People-Body,person,1F468 1F3FB 200D 1F9B3,fully-qualified,👨🏻‍🦳,man light skin tone white hair,E11.0
People-Body,person,1F468 1F3FC 200D 1F9B3,fully-qualified,👨🏼‍🦳,man medium-light skin tone white hair,E11.0
People-Body,person,1F468 1F3FD 200D 1F9B3,fully-qualified,👨🏽‍🦳,man medium skin tone white hair,E11.0
People-Body,person,1F468 1F3FE 200D 1F9B3,fully-qualified,👨🏾‍🦳,man medium-dark skin tone white hair,E11.0
People-Body,person,1F468 1F3FF 200D 1F9B3,fully-qualified,👨🏿‍🦳,man dark skin tone white hair,E11.0
People-Body,person,1F468 200D 1F9B2,fully-qualified,👨‍🦲,man bald,E11.0
People-Body,person,1F468 1F3FB 200D 1F9B2,fully-qualified,👨🏻‍🦲,man light skin tone bald,E11.0
People-Body,person,1F468 1F3FC 200D 1F9B2,fully-qualified,👨🏼‍🦲,man medium-light skin tone bald,E11.0
People-Body,person,1F468 1F3FD 200D 1F9B2,fully-qualified,👨🏽‍🦲,man medium skin tone bald,E11.0
People-Body,person,1F468 1F3FE 200D 1F9B2,fully-qualified,👨🏾‍🦲,man medium-dark skin tone bald,E11.0
People-Body,person,1F468 1F3FF 200D 1F9B2,fully-qualified,👨🏿‍🦲,man dark skin tone bald,E11.0
People-Body,person,1F469,fully-qualified,👩,woman,E0.6
People-Body,person,1F469 1F3FB,fully-qualified,👩🏻,woman light skin tone,E1.0
People-Body,person,1F469 1F3FC,fully-qualified,👩🏼,woman medium-light skin tone,E1.0
People-Body,person,1F469 1F3FD,fully-qualified,👩🏽,woman medium skin tone,E1.0
People-Body,person,1F469 1F3FE,fully-qualified,👩🏾,woman medium-dark skin tone,E1.0
People-Body,person,1F469 1F3FF,fully-qualified,👩🏿,woman dark skin tone,E1.0
People-Body,person,1F469 200D 1F9B0,fully-qualified,👩‍🦰,woman red hair,E11.0
People-Body,person,1F469 1F3FB 200D 1F9B0,fully-qualified,👩🏻‍🦰,woman light skin tone red hair,E11.0
People-Body,person,1F469 1F3FC 200D 1F9B0,fully-qualified,👩🏼‍🦰,woman medium-light skin tone red hair,E11.0
People-Body,person,1F469 1F3FD 200D 1F9B0,fully-qualified,👩🏽‍🦰,woman medium skin tone red hair,E11.0
People-Body,person,1F469 1F3FE 200D 1F9B0,fully-qualified,👩🏾‍🦰,woman medium-dark skin tone red hair,E11.0
People-Body,person,1F469 1F3FF 200D 1F9B0,fully-qualified,👩🏿‍🦰,woman dark skin tone red hair,E11.0
People-Body,person,1F9D1 200D 1F9B0,fully-qualified,🧑‍🦰,person red hair,E12.1
People-Body,person,1F9D1 1F3FB 200D 1F9B0,fully-qualified,🧑🏻‍🦰,person light skin tone red hair,E12.1
People-Body,person,1F9D1 1F3FC 200D 1F9B0,fully-qualified,🧑🏼‍🦰,person medium-light skin tone red hair,E12.1
People-Body,person,1F9D1 1F3FD 200D 1F9B0,fully-qualified,🧑🏽‍🦰,person medium skin tone red hair,E12.1
People-Body,person,1F9D1 1F3FE 200D 1F9B0,fully-qualified,🧑🏾‍🦰,person medium-dark skin tone red hair,E12.1
People-Body,person,1F9D1 1F3FF 200D 1F9B0,fully-qualified,🧑🏿‍🦰,person dark skin tone red hair,E12.1
People-Body,person,1F469 200D 1F9B1,fully-qualified,👩‍🦱,woman curly hair,E11.0
People-Body,person,1F469 1F3FB 200D 1F9B1,fully-qualified,👩🏻‍🦱,woman light skin tone curly hair,E11.0
People-Body,person,1F469 1F3FC 200D 1F9B1,fully-qualified,👩🏼‍🦱,woman medium-light skin tone curly hair,E11.0
People-Body,person,1F469 1F3FD 200D 1F9B1,fully-qualified,👩🏽‍🦱,woman medium skin tone curly hair,E11.0
People-Body,person,1F469 1F3FE 200D 1F9B1,fully-qualified,👩🏾‍🦱,woman medium-dark skin tone curly hair,E11.0
People-Body,person,1F469 1F3FF 200D 1F9B1,fully-qualified,👩🏿‍🦱,woman dark skin tone curly hair,E11.0
People-Body,person,1F9D1 200D 1F9B1,fully-qualified,🧑‍🦱,person curly hair,E12.1
People-Body,person,1F9D1 1F3FB 200D 1F9B1,fully-qualified,🧑🏻‍🦱,person light skin tone curly hair,E12.1
People-Body,person,1F9D1 1F3FC 200D 1F9B1,fully-qualified,🧑🏼‍🦱,person medium-light skin tone curly hair,E12.1
People-Body,person,1F9D1 1F3FD 200D 1F9B1,fully-qualified,🧑🏽‍🦱,person medium skin tone curly hair,E12.1
People-Body,person,1F9D1 1F3FE 200D 1F9B1,fully-qualified,🧑🏾‍🦱,person medium-dark skin tone curly hair,E12.1
People-Body,person,1F9D1 1F3FF 200D 1F9B1,fully-qualified,🧑🏿‍🦱,person dark skin tone curly hair,E12.1
People-Body,person,1F469 200D 1F9B3,fully-qualified,👩‍🦳,woman white hair,E11.0
People-Body,person,1F469 1F3FB 200D 1F9B3,fully-qualified,👩🏻‍🦳,woman light skin tone white hair,E11.0
People-Body,person,1F469 1F3FC 200D 1F9B3,fully-qualified,👩🏼‍🦳,woman medium-light skin tone white hair,E11.0
People-Body,person,1F469 1F3FD 200D 1F9B3,fully-qualified,👩🏽‍🦳,woman medium skin tone white hair,E11.0
People-Body,person,1F469 1F3FE 200D 1F9B3,fully-qualified,👩🏾‍🦳,woman medium-dark skin tone white hair,E11.0
People-Body,person,1F469 1F3FF 200D 1F9B3,fully-qualified,👩🏿‍🦳,woman dark skin tone white hair,E11.0
People-Body,person,1F9D1 200D 1F9B3,fully-qualified,🧑‍🦳,person white hair,E12.1
People-Body,person,1F9D1 1F3FB 200D 1F9B3,fully-qualified,🧑🏻‍🦳,person light skin tone white hair,E12.1
People-Body,person,1F9D1 1F3FC 200D 1F9B3,fully-qualified,🧑🏼‍🦳,person medium-light skin tone white hair,E12.1
People-Body,person,1F9D1 1F3FD 200D 1F9B3,fully-qualified,🧑🏽‍🦳,person medium skin tone white hair,E12.1
People-Body,person,1F9D1 1F3FE 200D 1F9B3,fully-qualified,🧑🏾‍🦳,person medium-dark skin tone white hair,E12.1
People-Body,person,1F9D1 1F3FF 200D 1F9B3,fully-qualified,🧑🏿‍🦳,person dark skin tone white hair,E12.1
People-Body,person,1F469 200D 1F9B2,fully-qualified,👩‍🦲,woman bald,E11.0
People-Body,person,1F469 1F3FB 200D 1F9B2,fully-qualified,👩🏻‍🦲,woman light skin tone bald,E11.0
People-Body,person,1F469 1F3FC 200D 1F9B2,fully-qualified,👩🏼‍🦲,woman medium-light skin tone bald,E11.0
People-Body,person,1F469 1F3FD 200D 1F9B2,fully-qualified,👩🏽‍🦲,woman medium skin tone bald,E11.0
People-Body,person,1F469 1F3FE 200D 1F9B2,fully-qualified,👩🏾‍🦲,woman medium-dark skin tone bald,E11.0
People-Body,person,1F469 1F3FF 200D 1F9B2,fully-qualified,👩🏿‍🦲,woman dark skin tone bald,E11.0
People-Body,person,1F9D1 200D 1F9B2,fully-qualified,🧑‍🦲,person bald,E12.1
People-Body,person,1F9D1 1F3FB 200D 1F9B2,fully-qualified,🧑🏻‍🦲,person light skin tone bald,E12.1
People-Body,person,1F9D1 1F3FC 200D 1F9B2,fully-qualified,🧑🏼‍🦲,person medium-light skin tone bald,E12.1
People-Body,person,1F9D1 1F3FD 200D 1F9B2,fully-qualified,🧑🏽‍🦲,person medium skin tone bald,E12.1
People-Body,person,1F9D1 1F3FE 200D 1F9B2,fully-qualified,🧑🏾‍🦲,person medium-dark skin tone bald,E12.1
People-Body,person,1F9D1 1F3FF 200D 1F9B2,fully-qualified,🧑🏿‍🦲,person dark skin tone bald,E12.1
People-Body,person,1F471 200D 2640 FE0F,fully-qualified,👱‍♀️,woman blond hair,E4.0
People-Body,person,1F471 200D 2640,minimally-qualified,👱‍♀,woman blond hair,E4.0
People-Body,person,1F471 1F3FB 200D 2640 FE0F,fully-qualified,👱🏻‍♀️,woman light skin tone blond hair,E4.0
People-Body,person,1F471 1F3FB 200D 2640,minimally-qualified,👱🏻‍♀,woman light skin tone blond hair,E4.0
People-Body,person,1F471 1F3FC 200D 2640 FE0F,fully-qualified,👱🏼‍♀️,woman medium-light skin tone blond hair,E4.0
People-Body,person,1F471 1F3FC 200D 2640,minimally-qualified,👱🏼‍♀,woman medium-light skin tone blond hair,E4.0
People-Body,person,1F471 1F3FD 200D 2640 FE0F,fully-qualified,👱🏽‍♀️,woman medium skin tone blond hair,E4.0
People-Body,person,1F471 1F3FD 200D 2640,minimally-qualified,👱🏽‍♀,woman medium skin tone blond hair,E4.0
People-Body,person,1F471 1F3FE 200D 2640 FE0F,fully-qualified,👱🏾‍♀️,woman medium-dark skin tone blond hair,E4.0
People-Body,person,1F471 1F3FE 200D 2640,minimally-qualified,👱🏾‍♀,woman medium-dark skin tone blond hair,E4.0
People-Body,person,1F471 1F3FF 200D 2640 FE0F,fully-qualified,👱🏿‍♀️,woman dark skin tone blond hair,E4.0
People-Body,person,1F471 1F3FF 200D 2640,minimally-qualified,👱🏿‍♀,woman dark skin tone blond hair,E4.0
People-Body,person,1F471 200D 2642 FE0F,fully-qualified,👱‍♂️,man blond hair,E4.0
People-Body,person,1F471 200D 2642,minimally-qualified,👱‍♂,man blond hair,E4.0
People-Body,person,1F471 1F3FB 200D 2642 FE0F,fully-qualified,👱🏻‍♂️,man light skin tone blond hair,E4.0
People-Body,person,1F471 1F3FB 200D 2642,minimally-qualified,👱🏻‍♂,man light skin tone blond hair,E4.0
People-Body,person,1F471 1F3FC 200D 2642 FE0F,fully-qualified,👱🏼‍♂️,man medium-light skin tone blond hair,E4.0
People-Body,person,1F471 1F3FC 200D 2642,minimally-qualified,👱🏼‍♂,man medium-light skin tone blond hair,E4.0
People-Body,person,1F471 1F3FD 200D 2642 FE0F,fully-qualified,👱🏽‍♂️,man medium skin tone blond hair,E4.0
People-Body,person,1F471 1F3FD 200D 2642,minimally-qualified,👱🏽‍♂,man medium skin tone blond hair,E4.0
People-Body,person,1F471 1F3FE 200D 2642 FE0F,fully-qualified,👱🏾‍♂️,man medium-dark skin tone blond hair,E4.0
People-Body,person,1F471 1F3FE 200D 2642,minimally-qualified,👱🏾‍♂,man medium-dark skin tone blond hair,E4.0
People-Body,person,1F471 1F3FF 200D 2642 FE0F,fully-qualified,👱🏿‍♂️,man dark skin tone blond hair,E4.0
People-Body,person,1F471 1F3FF 200D 2642,minimally-qualified,👱🏿‍♂,man dark skin tone blond hair,E4.0
People-Body,person,1F9D3,fully-qualified,🧓,older person,E5.0
People-Body,person,1F9D3 1F3FB,fully-qualified,🧓🏻,older person light skin tone,E5.0
People-Body,person,1F9D3 1F3FC,fully-qualified,🧓🏼,older person medium-light skin tone,E5.0
People-Body,person,1F9D3 1F3FD,fully-qualified,🧓🏽,older person medium skin tone,E5.0
People-Body,person,1F9D3 1F3FE,fully-qualified,🧓🏾,older person medium-dark skin tone,E5.0
People-Body,person,1F9D3 1F3FF,fully-qualified,🧓🏿,older person dark skin tone,E5.0
People-Body,person,1F474,fully-qualified,👴,old man,E0.6
People-Body,person,1F474 1F3FB,fully-qualified,👴🏻,old man light skin tone,E1.0
People-Body,person,1F474 1F3FC,fully-qualified,👴🏼,old man medium-light skin tone,E1.0
People-Body,person,1F474 1F3FD,fully-qualified,👴🏽,old man medium skin tone,E1.0
People-Body,person,1F474 1F3FE,fully-qualified,👴🏾,old man medium-dark skin tone,E1.0
People-Body,person,1F474 1F3FF,fully-qualified,👴🏿,old man dark skin tone,E1.0
People-Body,person,1F475,fully-qualified,👵,old woman,E0.6
People-Body,person,1F475 1F3FB,fully-qualified,👵🏻,old woman light skin tone,E1.0
People-Body,person,1F475 1F3FC,fully-qualified,👵🏼,old woman medium-light skin tone,E1.0
People-Body,person,1F475 1F3FD,fully-qualified,👵🏽,old woman medium skin tone,E1.0
People-Body,person,1F475 1F3FE,fully-qualified,👵🏾,old woman medium-dark skin tone,E1.0
People-Body,person,1F475 1F3FF,fully-qualified,👵🏿,old woman dark skin tone,E1.0
People-Body,person-gesture,1F64D,fully-qualified,🙍,person frowning,E0.6
People-Body,person-gesture,1F64D 1F3FB,fully-qualified,🙍🏻,person frowning light skin tone,E1.0
People-Body,person-gesture,1F64D 1F3FC,fully-qualified,🙍🏼,person frowning medium-light skin tone,E1.0
People-Body,person-gesture,1F64D 1F3FD,fully-qualified,🙍🏽,person frowning medium skin tone,E1.0
People-Body,person-gesture,1F64D 1F3FE,fully-qualified,🙍🏾,person frowning medium-dark skin tone,E1.0
People-Body,person-gesture,1F64D 1F3FF,fully-qualified,🙍🏿,person frowning dark skin tone,E1.0
People-Body,person-gesture,1F64D 200D 2642 FE0F,fully-qualified,🙍‍♂️,man frowning,E4.0
People-Body,person-gesture,1F64D 200D 2642,minimally-qualified,🙍‍♂,man frowning,E4.0
People-Body,person-gesture,1F64D 1F3FB 200D 2642 FE0F,fully-qualified,🙍🏻‍♂️,man frowning light skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FB 200D 2642,minimally-qualified,🙍🏻‍♂,man frowning light skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FC 200D 2642 FE0F,fully-qualified,🙍🏼‍♂️,man frowning medium-light skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FC 200D 2642,minimally-qualified,🙍🏼‍♂,man frowning medium-light skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FD 200D 2642 FE0F,fully-qualified,🙍🏽‍♂️,man frowning medium skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FD 200D 2642,minimally-qualified,🙍🏽‍♂,man frowning medium skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FE 200D 2642 FE0F,fully-qualified,🙍🏾‍♂️,man frowning medium-dark skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FE 200D 2642,minimally-qualified,🙍🏾‍♂,man frowning medium-dark skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FF 200D 2642 FE0F,fully-qualified,🙍🏿‍♂️,man frowning dark skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FF 200D 2642,minimally-qualified,🙍🏿‍♂,man frowning dark skin tone,E4.0
People-Body,person-gesture,1F64D 200D 2640 FE0F,fully-qualified,🙍‍♀️,woman frowning,E4.0
People-Body,person-gesture,1F64D 200D 2640,minimally-qualified,🙍‍♀,woman frowning,E4.0
People-Body,person-gesture,1F64D 1F3FB 200D 2640 FE0F,fully-qualified,🙍🏻‍♀️,woman frowning light skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FB 200D 2640,minimally-qualified,🙍🏻‍♀,woman frowning light skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FC 200D 2640 FE0F,fully-qualified,🙍🏼‍♀️,woman frowning medium-light skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FC 200D 2640,minimally-qualified,🙍🏼‍♀,woman frowning medium-light skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FD 200D 2640 FE0F,fully-qualified,🙍🏽‍♀️,woman frowning medium skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FD 200D 2640,minimally-qualified,🙍🏽‍♀,woman frowning medium skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FE 200D 2640 FE0F,fully-qualified,🙍🏾‍♀️,woman frowning medium-dark skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FE 200D 2640,minimally-qualified,🙍🏾‍♀,woman frowning medium-dark skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FF 200D 2640 FE0F,fully-qualified,🙍🏿‍♀️,woman frowning dark skin tone,E4.0
People-Body,person-gesture,1F64D 1F3FF 200D 2640,minimally-qualified,🙍🏿‍♀,woman frowning dark skin tone,E4.0
People-Body,person-gesture,1F64E,fully-qualified,🙎,person pouting,E0.6
People-Body,person-gesture,1F64E 1F3FB,fully-qualified,🙎🏻,person pouting light skin tone,E1.0
People-Body,person-gesture,1F64E 1F3FC,fully-qualified,🙎🏼,person pouting medium-light skin tone,E1.0
People-Body,person-gesture,1F64E 1F3FD,fully-qualified,🙎🏽,person pouting medium skin tone,E1.0
People-Body,person-gesture,1F64E 1F3FE,fully-qualified,🙎🏾,person pouting medium-dark skin tone,E1.0
People-Body,person-gesture,1F64E 1F3FF,fully-qualified,🙎🏿,person pouting dark skin tone,E1.0
People-Body,person-gesture,1F64E 200D 2642 FE0F,fully-qualified,🙎‍♂️,man pouting,E4.0
People-Body,person-gesture,1F64E 200D 2642,minimally-qualified,🙎‍♂,man pouting,E4.0
People-Body,person-gesture,1F64E 1F3FB 200D 2642 FE0F,fully-qualified,🙎🏻‍♂️,man pouting light skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FB 200D 2642,minimally-qualified,🙎🏻‍♂,man pouting light skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FC 200D 2642 FE0F,fully-qualified,🙎🏼‍♂️,man pouting medium-light skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FC 200D 2642,minimally-qualified,🙎🏼‍♂,man pouting medium-light skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FD 200D 2642 FE0F,fully-qualified,🙎🏽‍♂️,man pouting medium skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FD 200D 2642,minimally-qualified,🙎🏽‍♂,man pouting medium skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FE 200D 2642 FE0F,fully-qualified,🙎🏾‍♂️,man pouting medium-dark skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FE 200D 2642,minimally-qualified,🙎🏾‍♂,man pouting medium-dark skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FF 200D 2642 FE0F,fully-qualified,🙎🏿‍♂️,man pouting dark skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FF 200D 2642,minimally-qualified,🙎🏿‍♂,man pouting dark skin tone,E4.0
People-Body,person-gesture,1F64E 200D 2640 FE0F,fully-qualified,🙎‍♀️,woman pouting,E4.0
People-Body,person-gesture,1F64E 200D 2640,minimally-qualified,🙎‍♀,woman pouting,E4.0
People-Body,person-gesture,1F64E 1F3FB 200D 2640 FE0F,fully-qualified,🙎🏻‍♀️,woman pouting light skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FB 200D 2640,minimally-qualified,🙎🏻‍♀,woman pouting light skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FC 200D 2640 FE0F,fully-qualified,🙎🏼‍♀️,woman pouting medium-light skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FC 200D 2640,minimally-qualified,🙎🏼‍♀,woman pouting medium-light skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FD 200D 2640 FE0F,fully-qualified,🙎🏽‍♀️,woman pouting medium skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FD 200D 2640,minimally-qualified,🙎🏽‍♀,woman pouting medium skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FE 200D 2640 FE0F,fully-qualified,🙎🏾‍♀️,woman pouting medium-dark skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FE 200D 2640,minimally-qualified,🙎🏾‍♀,woman pouting medium-dark skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FF 200D 2640 FE0F,fully-qualified,🙎🏿‍♀️,woman pouting dark skin tone,E4.0
People-Body,person-gesture,1F64E 1F3FF 200D 2640,minimally-qualified,🙎🏿‍♀,woman pouting dark skin tone,E4.0
People-Body,person-gesture,1F645,fully-qualified,🙅,person gesturing NO,E0.6
People-Body,person-gesture,1F645 1F3FB,fully-qualified,🙅🏻,person gesturing NO light skin tone,E1.0
People-Body,person-gesture,1F645 1F3FC,fully-qualified,🙅🏼,person gesturing NO medium-light skin tone,E1.0
People-Body,person-gesture,1F645 1F3FD,fully-qualified,🙅🏽,person gesturing NO medium skin tone,E1.0
People-Body,person-gesture,1F645 1F3FE,fully-qualified,🙅🏾,person gesturing NO medium-dark skin tone,E1.0
People-Body,person-gesture,1F645 1F3FF,fully-qualified,🙅🏿,person gesturing NO dark skin tone,E1.0
People-Body,person-gesture,1F645 200D 2642 FE0F,fully-qualified,🙅‍♂️,man gesturing NO,E4.0
People-Body,person-gesture,1F645 200D 2642,minimally-qualified,🙅‍♂,man gesturing NO,E4.0
People-Body,person-gesture,1F645 1F3FB 200D 2642 FE0F,fully-qualified,🙅🏻‍♂️,man gesturing NO light skin tone,E4.0
People-Body,person-gesture,1F645 1F3FB 200D 2642,minimally-qualified,🙅🏻‍♂,man gesturing NO light skin tone,E4.0
People-Body,person-gesture,1F645 1F3FC 200D 2642 FE0F,fully-qualified,🙅🏼‍♂️,man gesturing NO medium-light skin tone,E4.0
People-Body,person-gesture,1F645 1F3FC 200D 2642,minimally-qualified,🙅🏼‍♂,man gesturing NO medium-light skin tone,E4.0
People-Body,person-gesture,1F645 1F3FD 200D 2642 FE0F,fully-qualified,🙅🏽‍♂️,man gesturing NO medium skin tone,E4.0
People-Body,person-gesture,1F645 1F3FD 200D 2642,minimally-qualified,🙅🏽‍♂,man gesturing NO medium skin tone,E4.0
People-Body,person-gesture,1F645 1F3FE 200D 2642 FE0F,fully-qualified,🙅🏾‍♂️,man gesturing NO medium-dark skin tone,E4.0
People-Body,person-gesture,1F645 1F3FE 200D 2642,minimally-qualified,🙅🏾‍♂,man gesturing NO medium-dark skin tone,E4.0
People-Body,person-gesture,1F645 1F3FF 200D 2642 FE0F,fully-qualified,🙅🏿‍♂️,man gesturing NO dark skin tone,E4.0
People-Body,person-gesture,1F645 1F3FF 200D 2642,minimally-qualified,🙅🏿‍♂,man gesturing NO dark skin tone,E4.0
People-Body,person-gesture,1F645 200D 2640 FE0F,fully-qualified,🙅‍♀️,woman gesturing NO,E4.0
People-Body,person-gesture,1F645 200D 2640,minimally-qualified,🙅‍♀,woman gesturing NO,E4.0
People-Body,person-gesture,1F645 1F3FB 200D 2640 FE0F,fully-qualified,🙅🏻‍♀️,woman gesturing NO light skin tone,E4.0
People-Body,person-gesture,1F645 1F3FB 200D 2640,minimally-qualified,🙅🏻‍♀,woman gesturing NO light skin tone,E4.0
People-Body,person-gesture,1F645 1F3FC 200D 2640 FE0F,fully-qualified,🙅🏼‍♀️,woman gesturing NO medium-light skin tone,E4.0
People-Body,person-gesture,1F645 1F3FC 200D 2640,minimally-qualified,🙅🏼‍♀,woman gesturing NO medium-light skin tone,E4.0
People-Body,person-gesture,1F645 1F3FD 200D 2640 FE0F,fully-qualified,🙅🏽‍♀️,woman gesturing NO medium skin tone,E4.0
People-Body,person-gesture,1F645 1F3FD 200D 2640,minimally-qualified,🙅🏽‍♀,woman gesturing NO medium skin tone,E4.0
People-Body,person-gesture,1F645 1F3FE 200D 2640 FE0F,fully-qualified,🙅🏾‍♀️,woman gesturing NO medium-dark skin tone,E4.0
People-Body,person-gesture,1F645 1F3FE 200D 2640,minimally-qualified,🙅🏾‍♀,woman gesturing NO medium-dark skin tone,E4.0
People-Body,person-gesture,1F645 1F3FF 200D 2640 FE0F,fully-qualified,🙅🏿‍♀️,woman gesturing NO dark skin tone,E4.0
People-Body,person-gesture,1F645 1F3FF 200D 2640,minimally-qualified,🙅🏿‍♀,woman gesturing NO dark skin tone,E4.0
People-Body,person-gesture,1F646,fully-qualified,🙆,person gesturing OK,E0.6
People-Body,person-gesture,1F646 1F3FB,fully-qualified,🙆🏻,person gesturing OK light skin tone,E1.0
People-Body,person-gesture,1F646 1F3FC,fully-qualified,🙆🏼,person gesturing OK medium-light skin tone,E1.0
People-Body,person-gesture,1F646 1F3FD,fully-qualified,🙆🏽,person gesturing OK medium skin tone,E1.0
People-Body,person-gesture,1F646 1F3FE,fully-qualified,🙆🏾,person gesturing OK medium-dark skin tone,E1.0
People-Body,person-gesture,1F646 1F3FF,fully-qualified,🙆🏿,person gesturing OK dark skin tone,E1.0
People-Body,person-gesture,1F646 200D 2642 FE0F,fully-qualified,🙆‍♂️,man gesturing OK,E4.0
People-Body,person-gesture,1F646 200D 2642,minimally-qualified,🙆‍♂,man gesturing OK,E4.0
People-Body,person-gesture,1F646 1F3FB 200D 2642 FE0F,fully-qualified,🙆🏻‍♂️,man gesturing OK light skin tone,E4.0
People-Body,person-gesture,1F646 1F3FB 200D 2642,minimally-qualified,🙆🏻‍♂,man gesturing OK light skin tone,E4.0
People-Body,person-gesture,1F646 1F3FC 200D 2642 FE0F,fully-qualified,🙆🏼‍♂️,man gesturing OK medium-light skin tone,E4.0
People-Body,person-gesture,1F646 1F3FC 200D 2642,minimally-qualified,🙆🏼‍♂,man gesturing OK medium-light skin tone,E4.0
People-Body,person-gesture,1F646 1F3FD 200D 2642 FE0F,fully-qualified,🙆🏽‍♂️,man gesturing OK medium skin tone,E4.0
People-Body,person-gesture,1F646 1F3FD 200D 2642,minimally-qualified,🙆🏽‍♂,man gesturing OK medium skin tone,E4.0
People-Body,person-gesture,1F646 1F3FE 200D 2642 FE0F,fully-qualified,🙆🏾‍♂️,man gesturing OK medium-dark skin tone,E4.0
People-Body,person-gesture,1F646 1F3FE 200D 2642,minimally-qualified,🙆🏾‍♂,man gesturing OK medium-dark skin tone,E4.0
People-Body,person-gesture,1F646 1F3FF 200D 2642 FE0F,fully-qualified,🙆🏿‍♂️,man gesturing OK dark skin tone,E4.0
People-Body,person-gesture,1F646 1F3FF 200D 2642,minimally-qualified,🙆🏿‍♂,man gesturing OK dark skin tone,E4.0
People-Body,person-gesture,1F646 200D 2640 FE0F,fully-qualified,🙆‍♀️,woman gesturing OK,E4.0
People-Body,person-gesture,1F646 200D 2640,minimally-qualified,🙆‍♀,woman gesturing OK,E4.0
People-Body,person-gesture,1F646 1F3FB 200D 2640 FE0F,fully-qualified,🙆🏻‍♀️,woman gesturing OK light skin tone,E4.0
People-Body,person-gesture,1F646 1F3FB 200D 2640,minimally-qualified,🙆🏻‍♀,woman gesturing OK light skin tone,E4.0
People-Body,person-gesture,1F646 1F3FC 200D 2640 FE0F,fully-qualified,🙆🏼‍♀️,woman gesturing OK medium-light skin tone,E4.0
People-Body,person-gesture,1F646 1F3FC 200D 2640,minimally-qualified,🙆🏼‍♀,woman gesturing OK medium-light skin tone,E4.0
People-Body,person-gesture,1F646 1F3FD 200D 2640 FE0F,fully-qualified,🙆🏽‍♀️,woman gesturing OK medium skin tone,E4.0
People-Body,person-gesture,1F646 1F3FD 200D 2640,minimally-qualified,🙆🏽‍♀,woman gesturing OK medium skin tone,E4.0
People-Body,person-gesture,1F646 1F3FE 200D 2640 FE0F,fully-qualified,🙆🏾‍♀️,woman gesturing OK medium-dark skin tone,E4.0
People-Body,person-gesture,1F646 1F3FE 200D 2640,minimally-qualified,🙆🏾‍♀,woman gesturing OK medium-dark skin tone,E4.0
People-Body,person-gesture,1F646 1F3FF 200D 2640 FE0F,fully-qualified,🙆🏿‍♀️,woman gesturing OK dark skin tone,E4.0
People-Body,person-gesture,1F646 1F3FF 200D 2640,minimally-qualified,🙆🏿‍♀,woman gesturing OK dark skin tone,E4.0
People-Body,person-gesture,1F481,fully-qualified,💁,person tipping hand,E0.6
People-Body,person-gesture,1F481 1F3FB,fully-qualified,💁🏻,person tipping hand light skin tone,E1.0
People-Body,person-gesture,1F481 1F3FC,fully-qualified,💁🏼,person tipping hand medium-light skin tone,E1.0
People-Body,person-gesture,1F481 1F3FD,fully-qualified,💁🏽,person tipping hand medium skin tone,E1.0
People-Body,person-gesture,1F481 1F3FE,fully-qualified,💁🏾,person tipping hand medium-dark skin tone,E1.0
People-Body,person-gesture,1F481 1F3FF,fully-qualified,💁🏿,person tipping hand dark skin tone,E1.0
People-Body,person-gesture,1F481 200D 2642 FE0F,fully-qualified,💁‍♂️,man tipping hand,E4.0
People-Body,person-gesture,1F481 200D 2642,minimally-qualified,💁‍♂,man tipping hand,E4.0
People-Body,person-gesture,1F481 1F3FB 200D 2642 FE0F,fully-qualified,💁🏻‍♂️,man tipping hand light skin tone,E4.0
People-Body,person-gesture,1F481 1F3FB 200D 2642,minimally-qualified,💁🏻‍♂,man tipping hand light skin tone,E4.0
People-Body,person-gesture,1F481 1F3FC 200D 2642 FE0F,fully-qualified,💁🏼‍♂️,man tipping hand medium-light skin tone,E4.0
People-Body,person-gesture,1F481 1F3FC 200D 2642,minimally-qualified,💁🏼‍♂,man tipping hand medium-light skin tone,E4.0
People-Body,person-gesture,1F481 1F3FD 200D 2642 FE0F,fully-qualified,💁🏽‍♂️,man tipping hand medium skin tone,E4.0
People-Body,person-gesture,1F481 1F3FD 200D 2642,minimally-qualified,💁🏽‍♂,man tipping hand medium skin tone,E4.0
People-Body,person-gesture,1F481 1F3FE 200D 2642 FE0F,fully-qualified,💁🏾‍♂️,man tipping hand medium-dark skin tone,E4.0
People-Body,person-gesture,1F481 1F3FE 200D 2642,minimally-qualified,💁🏾‍♂,man tipping hand medium-dark skin tone,E4.0
People-Body,person-gesture,1F481 1F3FF 200D 2642 FE0F,fully-qualified,💁🏿‍♂️,man tipping hand dark skin tone,E4.0
People-Body,person-gesture,1F481 1F3FF 200D 2642,minimally-qualified,💁🏿‍♂,man tipping hand dark skin tone,E4.0
People-Body,person-gesture,1F481 200D 2640 FE0F,fully-qualified,💁‍♀️,woman tipping hand,E4.0
People-Body,person-gesture,1F481 200D 2640,minimally-qualified,💁‍♀,woman tipping hand,E4.0
People-Body,person-gesture,1F481 1F3FB 200D 2640 FE0F,fully-qualified,💁🏻‍♀️,woman tipping hand light skin tone,E4.0
People-Body,person-gesture,1F481 1F3FB 200D 2640,minimally-qualified,💁🏻‍♀,woman tipping hand light skin tone,E4.0
People-Body,person-gesture,1F481 1F3FC 200D 2640 FE0F,fully-qualified,💁🏼‍♀️,woman tipping hand medium-light skin tone,E4.0
People-Body,person-gesture,1F481 1F3FC 200D 2640,minimally-qualified,💁🏼‍♀,woman tipping hand medium-light skin tone,E4.0
People-Body,person-gesture,1F481 1F3FD 200D 2640 FE0F,fully-qualified,💁🏽‍♀️,woman tipping hand medium skin tone,E4.0
People-Body,person-gesture,1F481 1F3FD 200D 2640,minimally-qualified,💁🏽‍♀,woman tipping hand medium skin tone,E4.0
People-Body,person-gesture,1F481 1F3FE 200D 2640 FE0F,fully-qualified,💁🏾‍♀️,woman tipping hand medium-dark skin tone,E4.0
People-Body,person-gesture,1F481 1F3FE 200D 2640,minimally-qualified,💁🏾‍♀,woman tipping hand medium-dark skin tone,E4.0
People-Body,person-gesture,1F481 1F3FF 200D 2640 FE0F,fully-qualified,💁🏿‍♀️,woman tipping hand dark skin tone,E4.0
People-Body,person-gesture,1F481 1F3FF 200D 2640,minimally-qualified,💁🏿‍♀,woman tipping hand dark skin tone,E4.0
People-Body,person-gesture,1F64B,fully-qualified,🙋,person raising hand,E0.6
People-Body,person-gesture,1F64B 1F3FB,fully-qualified,🙋🏻,person raising hand light skin tone,E1.0
People-Body,person-gesture,1F64B 1F3FC,fully-qualified,🙋🏼,person raising hand medium-light skin tone,E1.0
People-Body,person-gesture,1F64B 1F3FD,fully-qualified,🙋🏽,person raising hand medium skin tone,E1.0
People-Body,person-gesture,1F64B 1F3FE,fully-qualified,🙋🏾,person raising hand medium-dark skin tone,E1.0
People-Body,person-gesture,1F64B 1F3FF,fully-qualified,🙋🏿,person raising hand dark skin tone,E1.0
People-Body,person-gesture,1F64B 200D 2642 FE0F,fully-qualified,🙋‍♂️,man raising hand,E4.0
People-Body,person-gesture,1F64B 200D 2642,minimally-qualified,🙋‍♂,man raising hand,E4.0
People-Body,person-gesture,1F64B 1F3FB 200D 2642 FE0F,fully-qualified,🙋🏻‍♂️,man raising hand light skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FB 200D 2642,minimally-qualified,🙋🏻‍♂,man raising hand light skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FC 200D 2642 FE0F,fully-qualified,🙋🏼‍♂️,man raising hand medium-light skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FC 200D 2642,minimally-qualified,🙋🏼‍♂,man raising hand medium-light skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FD 200D 2642 FE0F,fully-qualified,🙋🏽‍♂️,man raising hand medium skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FD 200D 2642,minimally-qualified,🙋🏽‍♂,man raising hand medium skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FE 200D 2642 FE0F,fully-qualified,🙋🏾‍♂️,man raising hand medium-dark skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FE 200D 2642,minimally-qualified,🙋🏾‍♂,man raising hand medium-dark skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FF 200D 2642 FE0F,fully-qualified,🙋🏿‍♂️,man raising hand dark skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FF 200D 2642,minimally-qualified,🙋🏿‍♂,man raising hand dark skin tone,E4.0
People-Body,person-gesture,1F64B 200D 2640 FE0F,fully-qualified,🙋‍♀️,woman raising hand,E4.0
People-Body,person-gesture,1F64B 200D 2640,minimally-qualified,🙋‍♀,woman raising hand,E4.0
People-Body,person-gesture,1F64B 1F3FB 200D 2640 FE0F,fully-qualified,🙋🏻‍♀️,woman raising hand light skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FB 200D 2640,minimally-qualified,🙋🏻‍♀,woman raising hand light skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FC 200D 2640 FE0F,fully-qualified,🙋🏼‍♀️,woman raising hand medium-light skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FC 200D 2640,minimally-qualified,🙋🏼‍♀,woman raising hand medium-light skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FD 200D 2640 FE0F,fully-qualified,🙋🏽‍♀️,woman raising hand medium skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FD 200D 2640,minimally-qualified,🙋🏽‍♀,woman raising hand medium skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FE 200D 2640 FE0F,fully-qualified,🙋🏾‍♀️,woman raising hand medium-dark skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FE 200D 2640,minimally-qualified,🙋🏾‍♀,woman raising hand medium-dark skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FF 200D 2640 FE0F,fully-qualified,🙋🏿‍♀️,woman raising hand dark skin tone,E4.0
People-Body,person-gesture,1F64B 1F3FF 200D 2640,minimally-qualified,🙋🏿‍♀,woman raising hand dark skin tone,E4.0
People-Body,person-gesture,1F9CF,fully-qualified,🧏,deaf person,E12.0
People-Body,person-gesture,1F9CF 1F3FB,fully-qualified,🧏🏻,deaf person light skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FC,fully-qualified,🧏🏼,deaf person medium-light skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FD,fully-qualified,🧏🏽,deaf person medium skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FE,fully-qualified,🧏🏾,deaf person medium-dark skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FF,fully-qualified,🧏🏿,deaf person dark skin tone,E12.0
People-Body,person-gesture,1F9CF 200D 2642 FE0F,fully-qualified,🧏‍♂️,deaf man,E12.0
People-Body,person-gesture,1F9CF 200D 2642,minimally-qualified,🧏‍♂,deaf man,E12.0
People-Body,person-gesture,1F9CF 1F3FB 200D 2642 FE0F,fully-qualified,🧏🏻‍♂️,deaf man light skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FB 200D 2642,minimally-qualified,🧏🏻‍♂,deaf man light skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FC 200D 2642 FE0F,fully-qualified,🧏🏼‍♂️,deaf man medium-light skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FC 200D 2642,minimally-qualified,🧏🏼‍♂,deaf man medium-light skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FD 200D 2642 FE0F,fully-qualified,🧏🏽‍♂️,deaf man medium skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FD 200D 2642,minimally-qualified,🧏🏽‍♂,deaf man medium skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FE 200D 2642 FE0F,fully-qualified,🧏🏾‍♂️,deaf man medium-dark skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FE 200D 2642,minimally-qualified,🧏🏾‍♂,deaf man medium-dark skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FF 200D 2642 FE0F,fully-qualified,🧏🏿‍♂️,deaf man dark skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FF 200D 2642,minimally-qualified,🧏🏿‍♂,deaf man dark skin tone,E12.0
People-Body,person-gesture,1F9CF 200D 2640 FE0F,fully-qualified,🧏‍♀️,deaf woman,E12.0
People-Body,person-gesture,1F9CF 200D 2640,minimally-qualified,🧏‍♀,deaf woman,E12.0
People-Body,person-gesture,1F9CF 1F3FB 200D 2640 FE0F,fully-qualified,🧏🏻‍♀️,deaf woman light skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FB 200D 2640,minimally-qualified,🧏🏻‍♀,deaf woman light skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FC 200D 2640 FE0F,fully-qualified,🧏🏼‍♀️,deaf woman medium-light skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FC 200D 2640,minimally-qualified,🧏🏼‍♀,deaf woman medium-light skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FD 200D 2640 FE0F,fully-qualified,🧏🏽‍♀️,deaf woman medium skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FD 200D 2640,minimally-qualified,🧏🏽‍♀,deaf woman medium skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FE 200D 2640 FE0F,fully-qualified,🧏🏾‍♀️,deaf woman medium-dark skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FE 200D 2640,minimally-qualified,🧏🏾‍♀,deaf woman medium-dark skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FF 200D 2640 FE0F,fully-qualified,🧏🏿‍♀️,deaf woman dark skin tone,E12.0
People-Body,person-gesture,1F9CF 1F3FF 200D 2640,minimally-qualified,🧏🏿‍♀,deaf woman dark skin tone,E12.0
People-Body,person-gesture,1F647,fully-qualified,🙇,person bowing,E0.6
People-Body,person-gesture,1F647 1F3FB,fully-qualified,🙇🏻,person bowing light skin tone,E1.0
People-Body,person-gesture,1F647 1F3FC,fully-qualified,🙇🏼,person bowing medium-light skin tone,E1.0
People-Body,person-gesture,1F647 1F3FD,fully-qualified,🙇🏽,person bowing medium skin tone,E1.0
People-Body,person-gesture,1F647 1F3FE,fully-qualified,🙇🏾,person bowing medium-dark skin tone,E1.0
People-Body,person-gesture,1F647 1F3FF,fully-qualified,🙇🏿,person bowing dark skin tone,E1.0
People-Body,person-gesture,1F647 200D 2642 FE0F,fully-qualified,🙇‍♂️,man bowing,E4.0
People-Body,person-gesture,1F647 200D 2642,minimally-qualified,🙇‍♂,man bowing,E4.0
People-Body,person-gesture,1F647 1F3FB 200D 2642 FE0F,fully-qualified,🙇🏻‍♂️,man bowing light skin tone,E4.0
People-Body,person-gesture,1F647 1F3FB 200D 2642,minimally-qualified,🙇🏻‍♂,man bowing light skin tone,E4.0
People-Body,person-gesture,1F647 1F3FC 200D 2642 FE0F,fully-qualified,🙇🏼‍♂️,man bowing medium-light skin tone,E4.0
People-Body,person-gesture,1F647 1F3FC 200D 2642,minimally-qualified,🙇🏼‍♂,man bowing medium-light skin tone,E4.0
People-Body,person-gesture,1F647 1F3FD 200D 2642 FE0F,fully-qualified,🙇🏽‍♂️,man bowing medium skin tone,E4.0
People-Body,person-gesture,1F647 1F3FD 200D 2642,minimally-qualified,🙇🏽‍♂,man bowing medium skin tone,E4.0
People-Body,person-gesture,1F647 1F3FE 200D 2642 FE0F,fully-qualified,🙇🏾‍♂️,man bowing medium-dark skin tone,E4.0
People-Body,person-gesture,1F647 1F3FE 200D 2642,minimally-qualified,🙇🏾‍♂,man bowing medium-dark skin tone,E4.0
People-Body,person-gesture,1F647 1F3FF 200D 2642 FE0F,fully-qualified,🙇🏿‍♂️,man bowing dark skin tone,E4.0
People-Body,person-gesture,1F647 1F3FF 200D 2642,minimally-qualified,🙇🏿‍♂,man bowing dark skin tone,E4.0
People-Body,person-gesture,1F647 200D 2640 FE0F,fully-qualified,🙇‍♀️,woman bowing,E4.0
People-Body,person-gesture,1F647 200D 2640,minimally-qualified,🙇‍♀,woman bowing,E4.0
People-Body,person-gesture,1F647 1F3FB 200D 2640 FE0F,fully-qualified,🙇🏻‍♀️,woman bowing light skin tone,E4.0
People-Body,person-gesture,1F647 1F3FB 200D 2640,minimally-qualified,🙇🏻‍♀,woman bowing light skin tone,E4.0
People-Body,person-gesture,1F647 1F3FC 200D 2640 FE0F,fully-qualified,🙇🏼‍♀️,woman bowing medium-light skin tone,E4.0
People-Body,person-gesture,1F647 1F3FC 200D 2640,minimally-qualified,🙇🏼‍♀,woman bowing medium-light skin tone,E4.0
People-Body,person-gesture,1F647 1F3FD 200D 2640 FE0F,fully-qualified,🙇🏽‍♀️,woman bowing medium skin tone,E4.0
People-Body,person-gesture,1F647 1F3FD 200D 2640,minimally-qualified,🙇🏽‍♀,woman bowing medium skin tone,E4.0
People-Body,person-gesture,1F647 1F3FE 200D 2640 FE0F,fully-qualified,🙇🏾‍♀️,woman bowing medium-dark skin tone,E4.0
People-Body,person-gesture,1F647 1F3FE 200D 2640,minimally-qualified,🙇🏾‍♀,woman bowing medium-dark skin tone,E4.0
People-Body,person-gesture,1F647 1F3FF 200D 2640 FE0F,fully-qualified,🙇🏿‍♀️,woman bowing dark skin tone,E4.0
People-Body,person-gesture,1F647 1F3FF 200D 2640,minimally-qualified,🙇🏿‍♀,woman bowing dark skin tone,E4.0
People-Body,person-gesture,1F926,fully-qualified,🤦,person facepalming,E3.0
People-Body,person-gesture,1F926 1F3FB,fully-qualified,🤦🏻,person facepalming light skin tone,E3.0
People-Body,person-gesture,1F926 1F3FC,fully-qualified,🤦🏼,person facepalming medium-light skin tone,E3.0
People-Body,person-gesture,1F926 1F3FD,fully-qualified,🤦🏽,person facepalming medium skin tone,E3.0
People-Body,person-gesture,1F926 1F3FE,fully-qualified,🤦🏾,person facepalming medium-dark skin tone,E3.0
People-Body,person-gesture,1F926 1F3FF,fully-qualified,🤦🏿,person facepalming dark skin tone,E3.0
People-Body,person-gesture,1F926 200D 2642 FE0F,fully-qualified,🤦‍♂️,man facepalming,E4.0
People-Body,person-gesture,1F926 200D 2642,minimally-qualified,🤦‍♂,man facepalming,E4.0
People-Body,person-gesture,1F926 1F3FB 200D 2642 FE0F,fully-qualified,🤦🏻‍♂️,man facepalming light skin tone,E4.0
People-Body,person-gesture,1F926 1F3FB 200D 2642,minimally-qualified,🤦🏻‍♂,man facepalming light skin tone,E4.0
People-Body,person-gesture,1F926 1F3FC 200D 2642 FE0F,fully-qualified,🤦🏼‍♂️,man facepalming medium-light skin tone,E4.0
People-Body,person-gesture,1F926 1F3FC 200D 2642,minimally-qualified,🤦🏼‍♂,man facepalming medium-light skin tone,E4.0
People-Body,person-gesture,1F926 1F3FD 200D 2642 FE0F,fully-qualified,🤦🏽‍♂️,man facepalming medium skin tone,E4.0
People-Body,person-gesture,1F926 1F3FD 200D 2642,minimally-qualified,🤦🏽‍♂,man facepalming medium skin tone,E4.0
People-Body,person-gesture,1F926 1F3FE 200D 2642 FE0F,fully-qualified,🤦🏾‍♂️,man facepalming medium-dark skin tone,E4.0
People-Body,person-gesture,1F926 1F3FE 200D 2642,minimally-qualified,🤦🏾‍♂,man facepalming medium-dark skin tone,E4.0
People-Body,person-gesture,1F926 1F3FF 200D 2642 FE0F,fully-qualified,🤦🏿‍♂️,man facepalming dark skin tone,E4.0
People-Body,person-gesture,1F926 1F3FF 200D 2642,minimally-qualified,🤦🏿‍♂,man facepalming dark skin tone,E4.0
People-Body,person-gesture,1F926 200D 2640 FE0F,fully-qualified,🤦‍♀️,woman facepalming,E4.0
People-Body,person-gesture,1F926 200D 2640,minimally-qualified,🤦‍♀,woman facepalming,E4.0
People-Body,person-gesture,1F926 1F3FB 200D 2640 FE0F,fully-qualified,🤦🏻‍♀️,woman facepalming light skin tone,E4.0
People-Body,person-gesture,1F926 1F3FB 200D 2640,minimally-qualified,🤦🏻‍♀,woman facepalming light skin tone,E4.0
People-Body,person-gesture,1F926 1F3FC 200D 2640 FE0F,fully-qualified,🤦🏼‍♀️,woman facepalming medium-light skin tone,E4.0
People-Body,person-gesture,1F926 1F3FC 200D 2640,minimally-qualified,🤦🏼‍♀,woman facepalming medium-light skin tone,E4.0
People-Body,person-gesture,1F926 1F3FD 200D 2640 FE0F,fully-qualified,🤦🏽‍♀️,woman facepalming medium skin tone,E4.0
People-Body,person-gesture,1F926 1F3FD 200D 2640,minimally-qualified,🤦🏽‍♀,woman facepalming medium skin tone,E4.0
People-Body,person-gesture,1F926 1F3FE 200D 2640 FE0F,fully-qualified,🤦🏾‍♀️,woman facepalming medium-dark skin tone,E4.0
People-Body,person-gesture,1F926 1F3FE 200D 2640,minimally-qualified,🤦🏾‍♀,woman facepalming medium-dark skin tone,E4.0
People-Body,person-gesture,1F926 1F3FF 200D 2640 FE0F,fully-qualified,🤦🏿‍♀️,woman facepalming dark skin tone,E4.0
People-Body,person-gesture,1F926 1F3FF 200D 2640,minimally-qualified,🤦🏿‍♀,woman facepalming dark skin tone,E4.0
People-Body,person-gesture,1F937,fully-qualified,🤷,person shrugging,E3.0
People-Body,person-gesture,1F937 1F3FB,fully-qualified,🤷🏻,person shrugging light skin tone,E3.0
People-Body,person-gesture,1F937 1F3FC,fully-qualified,🤷🏼,person shrugging medium-light skin tone,E3.0
People-Body,person-gesture,1F937 1F3FD,fully-qualified,🤷🏽,person shrugging medium skin tone,E3.0
People-Body,person-gesture,1F937 1F3FE,fully-qualified,🤷🏾,person shrugging medium-dark skin tone,E3.0
People-Body,person-gesture,1F937 1F3FF,fully-qualified,🤷🏿,person shrugging dark skin tone,E3.0
People-Body,person-gesture,1F937 200D 2642 FE0F,fully-qualified,🤷‍♂️,man shrugging,E4.0
People-Body,person-gesture,1F937 200D 2642,minimally-qualified,🤷‍♂,man shrugging,E4.0
People-Body,person-gesture,1F937 1F3FB 200D 2642 FE0F,fully-qualified,🤷🏻‍♂️,man shrugging light skin tone,E4.0
People-Body,person-gesture,1F937 1F3FB 200D 2642,minimally-qualified,🤷🏻‍♂,man shrugging light skin tone,E4.0
People-Body,person-gesture,1F937 1F3FC 200D 2642 FE0F,fully-qualified,🤷🏼‍♂️,man shrugging medium-light skin tone,E4.0
People-Body,person-gesture,1F937 1F3FC 200D 2642,minimally-qualified,🤷🏼‍♂,man shrugging medium-light skin tone,E4.0
People-Body,person-gesture,1F937 1F3FD 200D 2642 FE0F,fully-qualified,🤷🏽‍♂️,man shrugging medium skin tone,E4.0
People-Body,person-gesture,1F937 1F3FD 200D 2642,minimally-qualified,🤷🏽‍♂,man shrugging medium skin tone,E4.0
People-Body,person-gesture,1F937 1F3FE 200D 2642 FE0F,fully-qualified,🤷🏾‍♂️,man shrugging medium-dark skin tone,E4.0
People-Body,person-gesture,1F937 1F3FE 200D 2642,minimally-qualified,🤷🏾‍♂,man shrugging medium-dark skin tone,E4.0
People-Body,person-gesture,1F937 1F3FF 200D 2642 FE0F,fully-qualified,🤷🏿‍♂️,man shrugging dark skin tone,E4.0
People-Body,person-gesture,1F937 1F3FF 200D 2642,minimally-qualified,🤷🏿‍♂,man shrugging dark skin tone,E4.0
People-Body,person-gesture,1F937 200D 2640 FE0F,fully-qualified,🤷‍♀️,woman shrugging,E4.0
People-Body,person-gesture,1F937 200D 2640,minimally-qualified,🤷‍♀,woman shrugging,E4.0
People-Body,person-gesture,1F937 1F3FB 200D 2640 FE0F,fully-qualified,🤷🏻‍♀️,woman shrugging light skin tone,E4.0
People-Body,person-gesture,1F937 1F3FB 200D 2640,minimally-qualified,🤷🏻‍♀,woman shrugging light skin tone,E4.0
People-Body,person-gesture,1F937 1F3FC 200D 2640 FE0F,fully-qualified,🤷🏼‍♀️,woman shrugging medium-light skin tone,E4.0
People-Body,person-gesture,1F937 1F3FC 200D 2640,minimally-qualified,🤷🏼‍♀,woman shrugging medium-light skin tone,E4.0
People-Body,person-gesture,1F937 1F3FD 200D 2640 FE0F,fully-qualified,🤷🏽‍♀️,woman shrugging medium skin tone,E4.0
People-Body,person-gesture,1F937 1F3FD 200D 2640,minimally-qualified,🤷🏽‍♀,woman shrugging medium skin tone,E4.0
People-Body,person-gesture,1F937 1F3FE 200D 2640 FE0F,fully-qualified,🤷🏾‍♀️,woman shrugging medium-dark skin tone,E4.0
People-Body,person-gesture,1F937 1F3FE 200D 2640,minimally-qualified,🤷🏾‍♀,woman shrugging medium-dark skin tone,E4.0
People-Body,person-gesture,1F937 1F3FF 200D 2640 FE0F,fully-qualified,🤷🏿‍♀️,woman shrugging dark skin tone,E4.0
People-Body,person-gesture,1F937 1F3FF 200D 2640,minimally-qualified,🤷🏿‍♀,woman shrugging dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 2695 FE0F,fully-qualified,🧑‍⚕️,health worker,E12.1
People-Body,person-role,1F9D1 200D 2695,minimally-qualified,🧑‍⚕,health worker,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 2695 FE0F,fully-qualified,🧑🏻‍⚕️,health worker light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 2695,minimally-qualified,🧑🏻‍⚕,health worker light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 2695 FE0F,fully-qualified,🧑🏼‍⚕️,health worker medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 2695,minimally-qualified,🧑🏼‍⚕,health worker medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 2695 FE0F,fully-qualified,🧑🏽‍⚕️,health worker medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 2695,minimally-qualified,🧑🏽‍⚕,health worker medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 2695 FE0F,fully-qualified,🧑🏾‍⚕️,health worker medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 2695,minimally-qualified,🧑🏾‍⚕,health worker medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 2695 FE0F,fully-qualified,🧑🏿‍⚕️,health worker dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 2695,minimally-qualified,🧑🏿‍⚕,health worker dark skin tone,E12.1
People-Body,person-role,1F468 200D 2695 FE0F,fully-qualified,👨‍⚕️,man health worker,E4.0
People-Body,person-role,1F468 200D 2695,minimally-qualified,👨‍⚕,man health worker,E4.0
People-Body,person-role,1F468 1F3FB 200D 2695 FE0F,fully-qualified,👨🏻‍⚕️,man health worker light skin tone,E4.0
People-Body,person-role,1F468 1F3FB 200D 2695,minimally-qualified,👨🏻‍⚕,man health worker light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 2695 FE0F,fully-qualified,👨🏼‍⚕️,man health worker medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 2695,minimally-qualified,👨🏼‍⚕,man health worker medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 2695 FE0F,fully-qualified,👨🏽‍⚕️,man health worker medium skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 2695,minimally-qualified,👨🏽‍⚕,man health worker medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 2695 FE0F,fully-qualified,👨🏾‍⚕️,man health worker medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 2695,minimally-qualified,👨🏾‍⚕,man health worker medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 2695 FE0F,fully-qualified,👨🏿‍⚕️,man health worker dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 2695,minimally-qualified,👨🏿‍⚕,man health worker dark skin tone,E4.0
People-Body,person-role,1F469 200D 2695 FE0F,fully-qualified,👩‍⚕️,woman health worker,E4.0
People-Body,person-role,1F469 200D 2695,minimally-qualified,👩‍⚕,woman health worker,E4.0
People-Body,person-role,1F469 1F3FB 200D 2695 FE0F,fully-qualified,👩🏻‍⚕️,woman health worker light skin tone,E4.0
People-Body,person-role,1F469 1F3FB 200D 2695,minimally-qualified,👩🏻‍⚕,woman health worker light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 2695 FE0F,fully-qualified,👩🏼‍⚕️,woman health worker medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 2695,minimally-qualified,👩🏼‍⚕,woman health worker medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 2695 FE0F,fully-qualified,👩🏽‍⚕️,woman health worker medium skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 2695,minimally-qualified,👩🏽‍⚕,woman health worker medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 2695 FE0F,fully-qualified,👩🏾‍⚕️,woman health worker medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 2695,minimally-qualified,👩🏾‍⚕,woman health worker medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 2695 FE0F,fully-qualified,👩🏿‍⚕️,woman health worker dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 2695,minimally-qualified,👩🏿‍⚕,woman health worker dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F393,fully-qualified,🧑‍🎓,student,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F393,fully-qualified,🧑🏻‍🎓,student light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F393,fully-qualified,🧑🏼‍🎓,student medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F393,fully-qualified,🧑🏽‍🎓,student medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F393,fully-qualified,🧑🏾‍🎓,student medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F393,fully-qualified,🧑🏿‍🎓,student dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F393,fully-qualified,👨‍🎓,man student,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F393,fully-qualified,👨🏻‍🎓,man student light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F393,fully-qualified,👨🏼‍🎓,man student medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F393,fully-qualified,👨🏽‍🎓,man student medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F393,fully-qualified,👨🏾‍🎓,man student medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F393,fully-qualified,👨🏿‍🎓,man student dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F393,fully-qualified,👩‍🎓,woman student,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F393,fully-qualified,👩🏻‍🎓,woman student light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F393,fully-qualified,👩🏼‍🎓,woman student medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F393,fully-qualified,👩🏽‍🎓,woman student medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F393,fully-qualified,👩🏾‍🎓,woman student medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F393,fully-qualified,👩🏿‍🎓,woman student dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F3EB,fully-qualified,🧑‍🏫,teacher,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F3EB,fully-qualified,🧑🏻‍🏫,teacher light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F3EB,fully-qualified,🧑🏼‍🏫,teacher medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F3EB,fully-qualified,🧑🏽‍🏫,teacher medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F3EB,fully-qualified,🧑🏾‍🏫,teacher medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F3EB,fully-qualified,🧑🏿‍🏫,teacher dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F3EB,fully-qualified,👨‍🏫,man teacher,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F3EB,fully-qualified,👨🏻‍🏫,man teacher light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F3EB,fully-qualified,👨🏼‍🏫,man teacher medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F3EB,fully-qualified,👨🏽‍🏫,man teacher medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F3EB,fully-qualified,👨🏾‍🏫,man teacher medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F3EB,fully-qualified,👨🏿‍🏫,man teacher dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F3EB,fully-qualified,👩‍🏫,woman teacher,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F3EB,fully-qualified,👩🏻‍🏫,woman teacher light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F3EB,fully-qualified,👩🏼‍🏫,woman teacher medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F3EB,fully-qualified,👩🏽‍🏫,woman teacher medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F3EB,fully-qualified,👩🏾‍🏫,woman teacher medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F3EB,fully-qualified,👩🏿‍🏫,woman teacher dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 2696 FE0F,fully-qualified,🧑‍⚖️,judge,E12.1
People-Body,person-role,1F9D1 200D 2696,minimally-qualified,🧑‍⚖,judge,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 2696 FE0F,fully-qualified,🧑🏻‍⚖️,judge light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 2696,minimally-qualified,🧑🏻‍⚖,judge light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 2696 FE0F,fully-qualified,🧑🏼‍⚖️,judge medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 2696,minimally-qualified,🧑🏼‍⚖,judge medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 2696 FE0F,fully-qualified,🧑🏽‍⚖️,judge medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 2696,minimally-qualified,🧑🏽‍⚖,judge medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 2696 FE0F,fully-qualified,🧑🏾‍⚖️,judge medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 2696,minimally-qualified,🧑🏾‍⚖,judge medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 2696 FE0F,fully-qualified,🧑🏿‍⚖️,judge dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 2696,minimally-qualified,🧑🏿‍⚖,judge dark skin tone,E12.1
People-Body,person-role,1F468 200D 2696 FE0F,fully-qualified,👨‍⚖️,man judge,E4.0
People-Body,person-role,1F468 200D 2696,minimally-qualified,👨‍⚖,man judge,E4.0
People-Body,person-role,1F468 1F3FB 200D 2696 FE0F,fully-qualified,👨🏻‍⚖️,man judge light skin tone,E4.0
People-Body,person-role,1F468 1F3FB 200D 2696,minimally-qualified,👨🏻‍⚖,man judge light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 2696 FE0F,fully-qualified,👨🏼‍⚖️,man judge medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 2696,minimally-qualified,👨🏼‍⚖,man judge medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 2696 FE0F,fully-qualified,👨🏽‍⚖️,man judge medium skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 2696,minimally-qualified,👨🏽‍⚖,man judge medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 2696 FE0F,fully-qualified,👨🏾‍⚖️,man judge medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 2696,minimally-qualified,👨🏾‍⚖,man judge medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 2696 FE0F,fully-qualified,👨🏿‍⚖️,man judge dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 2696,minimally-qualified,👨🏿‍⚖,man judge dark skin tone,E4.0
People-Body,person-role,1F469 200D 2696 FE0F,fully-qualified,👩‍⚖️,woman judge,E4.0
People-Body,person-role,1F469 200D 2696,minimally-qualified,👩‍⚖,woman judge,E4.0
People-Body,person-role,1F469 1F3FB 200D 2696 FE0F,fully-qualified,👩🏻‍⚖️,woman judge light skin tone,E4.0
People-Body,person-role,1F469 1F3FB 200D 2696,minimally-qualified,👩🏻‍⚖,woman judge light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 2696 FE0F,fully-qualified,👩🏼‍⚖️,woman judge medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 2696,minimally-qualified,👩🏼‍⚖,woman judge medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 2696 FE0F,fully-qualified,👩🏽‍⚖️,woman judge medium skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 2696,minimally-qualified,👩🏽‍⚖,woman judge medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 2696 FE0F,fully-qualified,👩🏾‍⚖️,woman judge medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 2696,minimally-qualified,👩🏾‍⚖,woman judge medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 2696 FE0F,fully-qualified,👩🏿‍⚖️,woman judge dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 2696,minimally-qualified,👩🏿‍⚖,woman judge dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F33E,fully-qualified,🧑‍🌾,farmer,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F33E,fully-qualified,🧑🏻‍🌾,farmer light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F33E,fully-qualified,🧑🏼‍🌾,farmer medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F33E,fully-qualified,🧑🏽‍🌾,farmer medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F33E,fully-qualified,🧑🏾‍🌾,farmer medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F33E,fully-qualified,🧑🏿‍🌾,farmer dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F33E,fully-qualified,👨‍🌾,man farmer,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F33E,fully-qualified,👨🏻‍🌾,man farmer light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F33E,fully-qualified,👨🏼‍🌾,man farmer medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F33E,fully-qualified,👨🏽‍🌾,man farmer medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F33E,fully-qualified,👨🏾‍🌾,man farmer medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F33E,fully-qualified,👨🏿‍🌾,man farmer dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F33E,fully-qualified,👩‍🌾,woman farmer,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F33E,fully-qualified,👩🏻‍🌾,woman farmer light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F33E,fully-qualified,👩🏼‍🌾,woman farmer medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F33E,fully-qualified,👩🏽‍🌾,woman farmer medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F33E,fully-qualified,👩🏾‍🌾,woman farmer medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F33E,fully-qualified,👩🏿‍🌾,woman farmer dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F373,fully-qualified,🧑‍🍳,cook,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F373,fully-qualified,🧑🏻‍🍳,cook light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F373,fully-qualified,🧑🏼‍🍳,cook medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F373,fully-qualified,🧑🏽‍🍳,cook medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F373,fully-qualified,🧑🏾‍🍳,cook medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F373,fully-qualified,🧑🏿‍🍳,cook dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F373,fully-qualified,👨‍🍳,man cook,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F373,fully-qualified,👨🏻‍🍳,man cook light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F373,fully-qualified,👨🏼‍🍳,man cook medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F373,fully-qualified,👨🏽‍🍳,man cook medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F373,fully-qualified,👨🏾‍🍳,man cook medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F373,fully-qualified,👨🏿‍🍳,man cook dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F373,fully-qualified,👩‍🍳,woman cook,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F373,fully-qualified,👩🏻‍🍳,woman cook light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F373,fully-qualified,👩🏼‍🍳,woman cook medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F373,fully-qualified,👩🏽‍🍳,woman cook medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F373,fully-qualified,👩🏾‍🍳,woman cook medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F373,fully-qualified,👩🏿‍🍳,woman cook dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F527,fully-qualified,🧑‍🔧,mechanic,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F527,fully-qualified,🧑🏻‍🔧,mechanic light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F527,fully-qualified,🧑🏼‍🔧,mechanic medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F527,fully-qualified,🧑🏽‍🔧,mechanic medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F527,fully-qualified,🧑🏾‍🔧,mechanic medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F527,fully-qualified,🧑🏿‍🔧,mechanic dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F527,fully-qualified,👨‍🔧,man mechanic,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F527,fully-qualified,👨🏻‍🔧,man mechanic light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F527,fully-qualified,👨🏼‍🔧,man mechanic medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F527,fully-qualified,👨🏽‍🔧,man mechanic medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F527,fully-qualified,👨🏾‍🔧,man mechanic medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F527,fully-qualified,👨🏿‍🔧,man mechanic dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F527,fully-qualified,👩‍🔧,woman mechanic,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F527,fully-qualified,👩🏻‍🔧,woman mechanic light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F527,fully-qualified,👩🏼‍🔧,woman mechanic medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F527,fully-qualified,👩🏽‍🔧,woman mechanic medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F527,fully-qualified,👩🏾‍🔧,woman mechanic medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F527,fully-qualified,👩🏿‍🔧,woman mechanic dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F3ED,fully-qualified,🧑‍🏭,factory worker,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F3ED,fully-qualified,🧑🏻‍🏭,factory worker light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F3ED,fully-qualified,🧑🏼‍🏭,factory worker medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F3ED,fully-qualified,🧑🏽‍🏭,factory worker medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F3ED,fully-qualified,🧑🏾‍🏭,factory worker medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F3ED,fully-qualified,🧑🏿‍🏭,factory worker dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F3ED,fully-qualified,👨‍🏭,man factory worker,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F3ED,fully-qualified,👨🏻‍🏭,man factory worker light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F3ED,fully-qualified,👨🏼‍🏭,man factory worker medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F3ED,fully-qualified,👨🏽‍🏭,man factory worker medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F3ED,fully-qualified,👨🏾‍🏭,man factory worker medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F3ED,fully-qualified,👨🏿‍🏭,man factory worker dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F3ED,fully-qualified,👩‍🏭,woman factory worker,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F3ED,fully-qualified,👩🏻‍🏭,woman factory worker light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F3ED,fully-qualified,👩🏼‍🏭,woman factory worker medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F3ED,fully-qualified,👩🏽‍🏭,woman factory worker medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F3ED,fully-qualified,👩🏾‍🏭,woman factory worker medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F3ED,fully-qualified,👩🏿‍🏭,woman factory worker dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F4BC,fully-qualified,🧑‍💼,office worker,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F4BC,fully-qualified,🧑🏻‍💼,office worker light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F4BC,fully-qualified,🧑🏼‍💼,office worker medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F4BC,fully-qualified,🧑🏽‍💼,office worker medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F4BC,fully-qualified,🧑🏾‍💼,office worker medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F4BC,fully-qualified,🧑🏿‍💼,office worker dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F4BC,fully-qualified,👨‍💼,man office worker,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F4BC,fully-qualified,👨🏻‍💼,man office worker light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F4BC,fully-qualified,👨🏼‍💼,man office worker medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F4BC,fully-qualified,👨🏽‍💼,man office worker medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F4BC,fully-qualified,👨🏾‍💼,man office worker medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F4BC,fully-qualified,👨🏿‍💼,man office worker dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F4BC,fully-qualified,👩‍💼,woman office worker,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F4BC,fully-qualified,👩🏻‍💼,woman office worker light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F4BC,fully-qualified,👩🏼‍💼,woman office worker medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F4BC,fully-qualified,👩🏽‍💼,woman office worker medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F4BC,fully-qualified,👩🏾‍💼,woman office worker medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F4BC,fully-qualified,👩🏿‍💼,woman office worker dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F52C,fully-qualified,🧑‍🔬,scientist,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F52C,fully-qualified,🧑🏻‍🔬,scientist light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F52C,fully-qualified,🧑🏼‍🔬,scientist medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F52C,fully-qualified,🧑🏽‍🔬,scientist medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F52C,fully-qualified,🧑🏾‍🔬,scientist medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F52C,fully-qualified,🧑🏿‍🔬,scientist dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F52C,fully-qualified,👨‍🔬,man scientist,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F52C,fully-qualified,👨🏻‍🔬,man scientist light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F52C,fully-qualified,👨🏼‍🔬,man scientist medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F52C,fully-qualified,👨🏽‍🔬,man scientist medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F52C,fully-qualified,👨🏾‍🔬,man scientist medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F52C,fully-qualified,👨🏿‍🔬,man scientist dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F52C,fully-qualified,👩‍🔬,woman scientist,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F52C,fully-qualified,👩🏻‍🔬,woman scientist light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F52C,fully-qualified,👩🏼‍🔬,woman scientist medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F52C,fully-qualified,👩🏽‍🔬,woman scientist medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F52C,fully-qualified,👩🏾‍🔬,woman scientist medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F52C,fully-qualified,👩🏿‍🔬,woman scientist dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F4BB,fully-qualified,🧑‍💻,technologist,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F4BB,fully-qualified,🧑🏻‍💻,technologist light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F4BB,fully-qualified,🧑🏼‍💻,technologist medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F4BB,fully-qualified,🧑🏽‍💻,technologist medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F4BB,fully-qualified,🧑🏾‍💻,technologist medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F4BB,fully-qualified,🧑🏿‍💻,technologist dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F4BB,fully-qualified,👨‍💻,man technologist,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F4BB,fully-qualified,👨🏻‍💻,man technologist light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F4BB,fully-qualified,👨🏼‍💻,man technologist medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F4BB,fully-qualified,👨🏽‍💻,man technologist medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F4BB,fully-qualified,👨🏾‍💻,man technologist medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F4BB,fully-qualified,👨🏿‍💻,man technologist dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F4BB,fully-qualified,👩‍💻,woman technologist,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F4BB,fully-qualified,👩🏻‍💻,woman technologist light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F4BB,fully-qualified,👩🏼‍💻,woman technologist medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F4BB,fully-qualified,👩🏽‍💻,woman technologist medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F4BB,fully-qualified,👩🏾‍💻,woman technologist medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F4BB,fully-qualified,👩🏿‍💻,woman technologist dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F3A4,fully-qualified,🧑‍🎤,singer,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F3A4,fully-qualified,🧑🏻‍🎤,singer light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F3A4,fully-qualified,🧑🏼‍🎤,singer medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F3A4,fully-qualified,🧑🏽‍🎤,singer medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F3A4,fully-qualified,🧑🏾‍🎤,singer medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F3A4,fully-qualified,🧑🏿‍🎤,singer dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F3A4,fully-qualified,👨‍🎤,man singer,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F3A4,fully-qualified,👨🏻‍🎤,man singer light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F3A4,fully-qualified,👨🏼‍🎤,man singer medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F3A4,fully-qualified,👨🏽‍🎤,man singer medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F3A4,fully-qualified,👨🏾‍🎤,man singer medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F3A4,fully-qualified,👨🏿‍🎤,man singer dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F3A4,fully-qualified,👩‍🎤,woman singer,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F3A4,fully-qualified,👩🏻‍🎤,woman singer light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F3A4,fully-qualified,👩🏼‍🎤,woman singer medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F3A4,fully-qualified,👩🏽‍🎤,woman singer medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F3A4,fully-qualified,👩🏾‍🎤,woman singer medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F3A4,fully-qualified,👩🏿‍🎤,woman singer dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F3A8,fully-qualified,🧑‍🎨,artist,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F3A8,fully-qualified,🧑🏻‍🎨,artist light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F3A8,fully-qualified,🧑🏼‍🎨,artist medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F3A8,fully-qualified,🧑🏽‍🎨,artist medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F3A8,fully-qualified,🧑🏾‍🎨,artist medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F3A8,fully-qualified,🧑🏿‍🎨,artist dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F3A8,fully-qualified,👨‍🎨,man artist,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F3A8,fully-qualified,👨🏻‍🎨,man artist light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F3A8,fully-qualified,👨🏼‍🎨,man artist medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F3A8,fully-qualified,👨🏽‍🎨,man artist medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F3A8,fully-qualified,👨🏾‍🎨,man artist medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F3A8,fully-qualified,👨🏿‍🎨,man artist dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F3A8,fully-qualified,👩‍🎨,woman artist,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F3A8,fully-qualified,👩🏻‍🎨,woman artist light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F3A8,fully-qualified,👩🏼‍🎨,woman artist medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F3A8,fully-qualified,👩🏽‍🎨,woman artist medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F3A8,fully-qualified,👩🏾‍🎨,woman artist medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F3A8,fully-qualified,👩🏿‍🎨,woman artist dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 2708 FE0F,fully-qualified,🧑‍✈️,pilot,E12.1
People-Body,person-role,1F9D1 200D 2708,minimally-qualified,🧑‍✈,pilot,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 2708 FE0F,fully-qualified,🧑🏻‍✈️,pilot light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 2708,minimally-qualified,🧑🏻‍✈,pilot light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 2708 FE0F,fully-qualified,🧑🏼‍✈️,pilot medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 2708,minimally-qualified,🧑🏼‍✈,pilot medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 2708 FE0F,fully-qualified,🧑🏽‍✈️,pilot medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 2708,minimally-qualified,🧑🏽‍✈,pilot medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 2708 FE0F,fully-qualified,🧑🏾‍✈️,pilot medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 2708,minimally-qualified,🧑🏾‍✈,pilot medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 2708 FE0F,fully-qualified,🧑🏿‍✈️,pilot dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 2708,minimally-qualified,🧑🏿‍✈,pilot dark skin tone,E12.1
People-Body,person-role,1F468 200D 2708 FE0F,fully-qualified,👨‍✈️,man pilot,E4.0
People-Body,person-role,1F468 200D 2708,minimally-qualified,👨‍✈,man pilot,E4.0
People-Body,person-role,1F468 1F3FB 200D 2708 FE0F,fully-qualified,👨🏻‍✈️,man pilot light skin tone,E4.0
People-Body,person-role,1F468 1F3FB 200D 2708,minimally-qualified,👨🏻‍✈,man pilot light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 2708 FE0F,fully-qualified,👨🏼‍✈️,man pilot medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 2708,minimally-qualified,👨🏼‍✈,man pilot medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 2708 FE0F,fully-qualified,👨🏽‍✈️,man pilot medium skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 2708,minimally-qualified,👨🏽‍✈,man pilot medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 2708 FE0F,fully-qualified,👨🏾‍✈️,man pilot medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 2708,minimally-qualified,👨🏾‍✈,man pilot medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 2708 FE0F,fully-qualified,👨🏿‍✈️,man pilot dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 2708,minimally-qualified,👨🏿‍✈,man pilot dark skin tone,E4.0
People-Body,person-role,1F469 200D 2708 FE0F,fully-qualified,👩‍✈️,woman pilot,E4.0
People-Body,person-role,1F469 200D 2708,minimally-qualified,👩‍✈,woman pilot,E4.0
People-Body,person-role,1F469 1F3FB 200D 2708 FE0F,fully-qualified,👩🏻‍✈️,woman pilot light skin tone,E4.0
People-Body,person-role,1F469 1F3FB 200D 2708,minimally-qualified,👩🏻‍✈,woman pilot light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 2708 FE0F,fully-qualified,👩🏼‍✈️,woman pilot medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 2708,minimally-qualified,👩🏼‍✈,woman pilot medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 2708 FE0F,fully-qualified,👩🏽‍✈️,woman pilot medium skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 2708,minimally-qualified,👩🏽‍✈,woman pilot medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 2708 FE0F,fully-qualified,👩🏾‍✈️,woman pilot medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 2708,minimally-qualified,👩🏾‍✈,woman pilot medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 2708 FE0F,fully-qualified,👩🏿‍✈️,woman pilot dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 2708,minimally-qualified,👩🏿‍✈,woman pilot dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F680,fully-qualified,🧑‍🚀,astronaut,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F680,fully-qualified,🧑🏻‍🚀,astronaut light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F680,fully-qualified,🧑🏼‍🚀,astronaut medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F680,fully-qualified,🧑🏽‍🚀,astronaut medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F680,fully-qualified,🧑🏾‍🚀,astronaut medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F680,fully-qualified,🧑🏿‍🚀,astronaut dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F680,fully-qualified,👨‍🚀,man astronaut,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F680,fully-qualified,👨🏻‍🚀,man astronaut light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F680,fully-qualified,👨🏼‍🚀,man astronaut medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F680,fully-qualified,👨🏽‍🚀,man astronaut medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F680,fully-qualified,👨🏾‍🚀,man astronaut medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F680,fully-qualified,👨🏿‍🚀,man astronaut dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F680,fully-qualified,👩‍🚀,woman astronaut,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F680,fully-qualified,👩🏻‍🚀,woman astronaut light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F680,fully-qualified,👩🏼‍🚀,woman astronaut medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F680,fully-qualified,👩🏽‍🚀,woman astronaut medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F680,fully-qualified,👩🏾‍🚀,woman astronaut medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F680,fully-qualified,👩🏿‍🚀,woman astronaut dark skin tone,E4.0
People-Body,person-role,1F9D1 200D 1F692,fully-qualified,🧑‍🚒,firefighter,E12.1
People-Body,person-role,1F9D1 1F3FB 200D 1F692,fully-qualified,🧑🏻‍🚒,firefighter light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FC 200D 1F692,fully-qualified,🧑🏼‍🚒,firefighter medium-light skin tone,E12.1
People-Body,person-role,1F9D1 1F3FD 200D 1F692,fully-qualified,🧑🏽‍🚒,firefighter medium skin tone,E12.1
People-Body,person-role,1F9D1 1F3FE 200D 1F692,fully-qualified,🧑🏾‍🚒,firefighter medium-dark skin tone,E12.1
People-Body,person-role,1F9D1 1F3FF 200D 1F692,fully-qualified,🧑🏿‍🚒,firefighter dark skin tone,E12.1
People-Body,person-role,1F468 200D 1F692,fully-qualified,👨‍🚒,man firefighter,E4.0
People-Body,person-role,1F468 1F3FB 200D 1F692,fully-qualified,👨🏻‍🚒,man firefighter light skin tone,E4.0
People-Body,person-role,1F468 1F3FC 200D 1F692,fully-qualified,👨🏼‍🚒,man firefighter medium-light skin tone,E4.0
People-Body,person-role,1F468 1F3FD 200D 1F692,fully-qualified,👨🏽‍🚒,man firefighter medium skin tone,E4.0
People-Body,person-role,1F468 1F3FE 200D 1F692,fully-qualified,👨🏾‍🚒,man firefighter medium-dark skin tone,E4.0
People-Body,person-role,1F468 1F3FF 200D 1F692,fully-qualified,👨🏿‍🚒,man firefighter dark skin tone,E4.0
People-Body,person-role,1F469 200D 1F692,fully-qualified,👩‍🚒,woman firefighter,E4.0
People-Body,person-role,1F469 1F3FB 200D 1F692,fully-qualified,👩🏻‍🚒,woman firefighter light skin tone,E4.0
People-Body,person-role,1F469 1F3FC 200D 1F692,fully-qualified,👩🏼‍🚒,woman firefighter medium-light skin tone,E4.0
People-Body,person-role,1F469 1F3FD 200D 1F692,fully-qualified,👩🏽‍🚒,woman firefighter medium skin tone,E4.0
People-Body,person-role,1F469 1F3FE 200D 1F692,fully-qualified,👩🏾‍🚒,woman firefighter medium-dark skin tone,E4.0
People-Body,person-role,1F469 1F3FF 200D 1F692,fully-qualified,👩🏿‍🚒,woman firefighter dark skin tone,E4.0
People-Body,person-role,1F46E,fully-qualified,👮,police officer,E0.6
People-Body,person-role,1F46E 1F3FB,fully-qualified,👮🏻,police officer light skin tone,E1.0
People-Body,person-role,1F46E 1F3FC,fully-qualified,👮🏼,police officer medium-light skin tone,E1.0
People-Body,person-role,1F46E 1F3FD,fully-qualified,👮🏽,police officer medium skin tone,E1.0
People-Body,person-role,1F46E 1F3FE,fully-qualified,👮🏾,police officer medium-dark skin tone,E1.0
People-Body,person-role,1F46E 1F3FF,fully-qualified,👮🏿,police officer dark skin tone,E1.0
People-Body,person-role,1F46E 200D 2642 FE0F,fully-qualified,👮‍♂️,man police officer,E4.0
People-Body,person-role,1F46E 200D 2642,minimally-qualified,👮‍♂,man police officer,E4.0
People-Body,person-role,1F46E 1F3FB 200D 2642 FE0F,fully-qualified,👮🏻‍♂️,man police officer light skin tone,E4.0
People-Body,person-role,1F46E 1F3FB 200D 2642,minimally-qualified,👮🏻‍♂,man police officer light skin tone,E4.0
People-Body,person-role,1F46E 1F3FC 200D 2642 FE0F,fully-qualified,👮🏼‍♂️,man police officer medium-light skin tone,E4.0
People-Body,person-role,1F46E 1F3FC 200D 2642,minimally-qualified,👮🏼‍♂,man police officer medium-light skin tone,E4.0
People-Body,person-role,1F46E 1F3FD 200D 2642 FE0F,fully-qualified,👮🏽‍♂️,man police officer medium skin tone,E4.0
People-Body,person-role,1F46E 1F3FD 200D 2642,minimally-qualified,👮🏽‍♂,man police officer medium skin tone,E4.0
People-Body,person-role,1F46E 1F3FE 200D 2642 FE0F,fully-qualified,👮🏾‍♂️,man police officer medium-dark skin tone,E4.0
People-Body,person-role,1F46E 1F3FE 200D 2642,minimally-qualified,👮🏾‍♂,man police officer medium-dark skin tone,E4.0
People-Body,person-role,1F46E 1F3FF 200D 2642 FE0F,fully-qualified,👮🏿‍♂️,man police officer dark skin tone,E4.0
People-Body,person-role,1F46E 1F3FF 200D 2642,minimally-qualified,👮🏿‍♂,man police officer dark skin tone,E4.0
People-Body,person-role,1F46E 200D 2640 FE0F,fully-qualified,👮‍♀️,woman police officer,E4.0
People-Body,person-role,1F46E 200D 2640,minimally-qualified,👮‍♀,woman police officer,E4.0
People-Body,person-role,1F46E 1F3FB 200D 2640 FE0F,fully-qualified,👮🏻‍♀️,woman police officer light skin tone,E4.0
People-Body,person-role,1F46E 1F3FB 200D 2640,minimally-qualified,👮🏻‍♀,woman police officer light skin tone,E4.0
People-Body,person-role,1F46E 1F3FC 200D 2640 FE0F,fully-qualified,👮🏼‍♀️,woman police officer medium-light skin tone,E4.0
People-Body,person-role,1F46E 1F3FC 200D 2640,minimally-qualified,👮🏼‍♀,woman police officer medium-light skin tone,E4.0
People-Body,person-role,1F46E 1F3FD 200D 2640 FE0F,fully-qualified,👮🏽‍♀️,woman police officer medium skin tone,E4.0
People-Body,person-role,1F46E 1F3FD 200D 2640,minimally-qualified,👮🏽‍♀,woman police officer medium skin tone,E4.0
People-Body,person-role,1F46E 1F3FE 200D 2640 FE0F,fully-qualified,👮🏾‍♀️,woman police officer medium-dark skin tone,E4.0
People-Body,person-role,1F46E 1F3FE 200D 2640,minimally-qualified,👮🏾‍♀,woman police officer medium-dark skin tone,E4.0
People-Body,person-role,1F46E 1F3FF 200D 2640 FE0F,fully-qualified,👮🏿‍♀️,woman police officer dark skin tone,E4.0
People-Body,person-role,1F46E 1F3FF 200D 2640,minimally-qualified,👮🏿‍♀,woman police officer dark skin tone,E4.0
People-Body,person-role,1F575 FE0F,fully-qualified,🕵️,detective,E0.7
People-Body,person-role,1F575,unqualified,🕵,detective,E0.7
People-Body,person-role,1F575 1F3FB,fully-qualified,🕵🏻,detective light skin tone,E2.0
People-Body,person-role,1F575 1F3FC,fully-qualified,🕵🏼,detective medium-light skin tone,E2.0
People-Body,person-role,1F575 1F3FD,fully-qualified,🕵🏽,detective medium skin tone,E2.0
People-Body,person-role,1F575 1F3FE,fully-qualified,🕵🏾,detective medium-dark skin tone,E2.0
People-Body,person-role,1F575 1F3FF,fully-qualified,🕵🏿,detective dark skin tone,E2.0
People-Body,person-role,1F575 FE0F 200D 2642 FE0F,fully-qualified,🕵️‍♂️,man detective,E4.0
People-Body,person-role,1F575 200D 2642 FE0F,unqualified,🕵‍♂️,man detective,E4.0
People-Body,person-role,1F575 FE0F 200D 2642,minimally-qualified,🕵️‍♂,man detective,E4.0
People-Body,person-role,1F575 200D 2642,unqualified,🕵‍♂,man detective,E4.0
People-Body,person-role,1F575 1F3FB 200D 2642 FE0F,fully-qualified,🕵🏻‍♂️,man detective light skin tone,E4.0
People-Body,person-role,1F575 1F3FB 200D 2642,minimally-qualified,🕵🏻‍♂,man detective light skin tone,E4.0
People-Body,person-role,1F575 1F3FC 200D 2642 FE0F,fully-qualified,🕵🏼‍♂️,man detective medium-light skin tone,E4.0
People-Body,person-role,1F575 1F3FC 200D 2642,minimally-qualified,🕵🏼‍♂,man detective medium-light skin tone,E4.0
People-Body,person-role,1F575 1F3FD 200D 2642 FE0F,fully-qualified,🕵🏽‍♂️,man detective medium skin tone,E4.0
People-Body,person-role,1F575 1F3FD 200D 2642,minimally-qualified,🕵🏽‍♂,man detective medium skin tone,E4.0
People-Body,person-role,1F575 1F3FE 200D 2642 FE0F,fully-qualified,🕵🏾‍♂️,man detective medium-dark skin tone,E4.0
People-Body,person-role,1F575 1F3FE 200D 2642,minimally-qualified,🕵🏾‍♂,man detective medium-dark skin tone,E4.0
People-Body,person-role,1F575 1F3FF 200D 2642 FE0F,fully-qualified,🕵🏿‍♂️,man detective dark skin tone,E4.0
People-Body,person-role,1F575 1F3FF 200D 2642,minimally-qualified,🕵🏿‍♂,man detective dark skin tone,E4.0
People-Body,person-role,1F575 FE0F 200D 2640 FE0F,fully-qualified,🕵️‍♀️,woman detective,E4.0
People-Body,person-role,1F575 200D 2640 FE0F,unqualified,🕵‍♀️,woman detective,E4.0
People-Body,person-role,1F575 FE0F 200D 2640,minimally-qualified,🕵️‍♀,woman detective,E4.0
People-Body,person-role,1F575 200D 2640,unqualified,🕵‍♀,woman detective,E4.0
People-Body,person-role,1F575 1F3FB 200D 2640 FE0F,fully-qualified,🕵🏻‍♀️,woman detective light skin tone,E4.0
People-Body,person-role,1F575 1F3FB 200D 2640,minimally-qualified,🕵🏻‍♀,woman detective light skin tone,E4.0
People-Body,person-role,1F575 1F3FC 200D 2640 FE0F,fully-qualified,🕵🏼‍♀️,woman detective medium-light skin tone,E4.0
People-Body,person-role,1F575 1F3FC 200D 2640,minimally-qualified,🕵🏼‍♀,woman detective medium-light skin tone,E4.0
People-Body,person-role,1F575 1F3FD 200D 2640 FE0F,fully-qualified,🕵🏽‍♀️,woman detective medium skin tone,E4.0
People-Body,person-role,1F575 1F3FD 200D 2640,minimally-qualified,🕵🏽‍♀,woman detective medium skin tone,E4.0
People-Body,person-role,1F575 1F3FE 200D 2640 FE0F,fully-qualified,🕵🏾‍♀️,woman detective medium-dark skin tone,E4.0
People-Body,person-role,1F575 1F3FE 200D 2640,minimally-qualified,🕵🏾‍♀,woman detective medium-dark skin tone,E4.0
People-Body,person-role,1F575 1F3FF 200D 2640 FE0F,fully-qualified,🕵🏿‍♀️,woman detective dark skin tone,E4.0
People-Body,person-role,1F575 1F3FF 200D 2640,minimally-qualified,🕵🏿‍♀,woman detective dark skin tone,E4.0
People-Body,person-role,1F482,fully-qualified,💂,guard,E0.6
People-Body,person-role,1F482 1F3FB,fully-qualified,💂🏻,guard light skin tone,E1.0
People-Body,person-role,1F482 1F3FC,fully-qualified,💂🏼,guard medium-light skin tone,E1.0
People-Body,person-role,1F482 1F3FD,fully-qualified,💂🏽,guard medium skin tone,E1.0
People-Body,person-role,1F482 1F3FE,fully-qualified,💂🏾,guard medium-dark skin tone,E1.0
People-Body,person-role,1F482 1F3FF,fully-qualified,💂🏿,guard dark skin tone,E1.0
People-Body,person-role,1F482 200D 2642 FE0F,fully-qualified,💂‍♂️,man guard,E4.0
People-Body,person-role,1F482 200D 2642,minimally-qualified,💂‍♂,man guard,E4.0
People-Body,person-role,1F482 1F3FB 200D 2642 FE0F,fully-qualified,💂🏻‍♂️,man guard light skin tone,E4.0
People-Body,person-role,1F482 1F3FB 200D 2642,minimally-qualified,💂🏻‍♂,man guard light skin tone,E4.0
People-Body,person-role,1F482 1F3FC 200D 2642 FE0F,fully-qualified,💂🏼‍♂️,man guard medium-light skin tone,E4.0
People-Body,person-role,1F482 1F3FC 200D 2642,minimally-qualified,💂🏼‍♂,man guard medium-light skin tone,E4.0
People-Body,person-role,1F482 1F3FD 200D 2642 FE0F,fully-qualified,💂🏽‍♂️,man guard medium skin tone,E4.0
People-Body,person-role,1F482 1F3FD 200D 2642,minimally-qualified,💂🏽‍♂,man guard medium skin tone,E4.0
People-Body,person-role,1F482 1F3FE 200D 2642 FE0F,fully-qualified,💂🏾‍♂️,man guard medium-dark skin tone,E4.0
People-Body,person-role,1F482 1F3FE 200D 2642,minimally-qualified,💂🏾‍♂,man guard medium-dark skin tone,E4.0
People-Body,person-role,1F482 1F3FF 200D 2642 FE0F,fully-qualified,💂🏿‍♂️,man guard dark skin tone,E4.0
People-Body,person-role,1F482 1F3FF 200D 2642,minimally-qualified,💂🏿‍♂,man guard dark skin tone,E4.0
People-Body,person-role,1F482 200D 2640 FE0F,fully-qualified,💂‍♀️,woman guard,E4.0
People-Body,person-role,1F482 200D 2640,minimally-qualified,💂‍♀,woman guard,E4.0
People-Body,person-role,1F482 1F3FB 200D 2640 FE0F,fully-qualified,💂🏻‍♀️,woman guard light skin tone,E4.0
People-Body,person-role,1F482 1F3FB 200D 2640,minimally-qualified,💂🏻‍♀,woman guard light skin tone,E4.0
People-Body,person-role,1F482 1F3FC 200D 2640 FE0F,fully-qualified,💂🏼‍♀️,woman guard medium-light skin tone,E4.0
People-Body,person-role,1F482 1F3FC 200D 2640,minimally-qualified,💂🏼‍♀,woman guard medium-light skin tone,E4.0
People-Body,person-role,1F482 1F3FD 200D 2640 FE0F,fully-qualified,💂🏽‍♀️,woman guard medium skin tone,E4.0
People-Body,person-role,1F482 1F3FD 200D 2640,minimally-qualified,💂🏽‍♀,woman guard medium skin tone,E4.0
People-Body,person-role,1F482 1F3FE 200D 2640 FE0F,fully-qualified,💂🏾‍♀️,woman guard medium-dark skin tone,E4.0
People-Body,person-role,1F482 1F3FE 200D 2640,minimally-qualified,💂🏾‍♀,woman guard medium-dark skin tone,E4.0
People-Body,person-role,1F482 1F3FF 200D 2640 FE0F,fully-qualified,💂🏿‍♀️,woman guard dark skin tone,E4.0
People-Body,person-role,1F482 1F3FF 200D 2640,minimally-qualified,💂🏿‍♀,woman guard dark skin tone,E4.0
People-Body,person-role,1F977,fully-qualified,🥷,ninja,E13.0
People-Body,person-role,1F977 1F3FB,fully-qualified,🥷🏻,ninja light skin tone,E13.0
People-Body,person-role,1F977 1F3FC,fully-qualified,🥷🏼,ninja medium-light skin tone,E13.0
People-Body,person-role,1F977 1F3FD,fully-qualified,🥷🏽,ninja medium skin tone,E13.0
People-Body,person-role,1F977 1F3FE,fully-qualified,🥷🏾,ninja medium-dark skin tone,E13.0
People-Body,person-role,1F977 1F3FF,fully-qualified,🥷🏿,ninja dark skin tone,E13.0
People-Body,person-role,1F477,fully-qualified,👷,construction worker,E0.6
People-Body,person-role,1F477 1F3FB,fully-qualified,👷🏻,construction worker light skin tone,E1.0
People-Body,person-role,1F477 1F3FC,fully-qualified,👷🏼,construction worker medium-light skin tone,E1.0
People-Body,person-role,1F477 1F3FD,fully-qualified,👷🏽,construction worker medium skin tone,E1.0
People-Body,person-role,1F477 1F3FE,fully-qualified,👷🏾,construction worker medium-dark skin tone,E1.0
People-Body,person-role,1F477 1F3FF,fully-qualified,👷🏿,construction worker dark skin tone,E1.0
People-Body,person-role,1F477 200D 2642 FE0F,fully-qualified,👷‍♂️,man construction worker,E4.0
People-Body,person-role,1F477 200D 2642,minimally-qualified,👷‍♂,man construction worker,E4.0
People-Body,person-role,1F477 1F3FB 200D 2642 FE0F,fully-qualified,👷🏻‍♂️,man construction worker light skin tone,E4.0
People-Body,person-role,1F477 1F3FB 200D 2642,minimally-qualified,👷🏻‍♂,man construction worker light skin tone,E4.0
People-Body,person-role,1F477 1F3FC 200D 2642 FE0F,fully-qualified,👷🏼‍♂️,man construction worker medium-light skin tone,E4.0
People-Body,person-role,1F477 1F3FC 200D 2642,minimally-qualified,👷🏼‍♂,man construction worker medium-light skin tone,E4.0
People-Body,person-role,1F477 1F3FD 200D 2642 FE0F,fully-qualified,👷🏽‍♂️,man construction worker medium skin tone,E4.0
People-Body,person-role,1F477 1F3FD 200D 2642,minimally-qualified,👷🏽‍♂,man construction worker medium skin tone,E4.0
People-Body,person-role,1F477 1F3FE 200D 2642 FE0F,fully-qualified,👷🏾‍♂️,man construction worker medium-dark skin tone,E4.0
People-Body,person-role,1F477 1F3FE 200D 2642,minimally-qualified,👷🏾‍♂,man construction worker medium-dark skin tone,E4.0
People-Body,person-role,1F477 1F3FF 200D 2642 FE0F,fully-qualified,👷🏿‍♂️,man construction worker dark skin tone,E4.0
People-Body,person-role,1F477 1F3FF 200D 2642,minimally-qualified,👷🏿‍♂,man construction worker dark skin tone,E4.0
People-Body,person-role,1F477 200D 2640 FE0F,fully-qualified,👷‍♀️,woman construction worker,E4.0
People-Body,person-role,1F477 200D 2640,minimally-qualified,👷‍♀,woman construction worker,E4.0
People-Body,person-role,1F477 1F3FB 200D 2640 FE0F,fully-qualified,👷🏻‍♀️,woman construction worker light skin tone,E4.0
People-Body,person-role,1F477 1F3FB 200D 2640,minimally-qualified,👷🏻‍♀,woman construction worker light skin tone,E4.0
People-Body,person-role,1F477 1F3FC 200D 2640 FE0F,fully-qualified,👷🏼‍♀️,woman construction worker medium-light skin tone,E4.0
People-Body,person-role,1F477 1F3FC 200D 2640,minimally-qualified,👷🏼‍♀,woman construction worker medium-light skin tone,E4.0
People-Body,person-role,1F477 1F3FD 200D 2640 FE0F,fully-qualified,👷🏽‍♀️,woman construction worker medium skin tone,E4.0
People-Body,person-role,1F477 1F3FD 200D 2640,minimally-qualified,👷🏽‍♀,woman construction worker medium skin tone,E4.0
People-Body,person-role,1F477 1F3FE 200D 2640 FE0F,fully-qualified,👷🏾‍♀️,woman construction worker medium-dark skin tone,E4.0
People-Body,person-role,1F477 1F3FE 200D 2640,minimally-qualified,👷🏾‍♀,woman construction worker medium-dark skin tone,E4.0
People-Body,person-role,1F477 1F3FF 200D 2640 FE0F,fully-qualified,👷🏿‍♀️,woman construction worker dark skin tone,E4.0
People-Body,person-role,1F477 1F3FF 200D 2640,minimally-qualified,👷🏿‍♀,woman construction worker dark skin tone,E4.0
People-Body,person-role,1FAC5,fully-qualified,🫅,person with crown,E14.0
People-Body,person-role,1FAC5 1F3FB,fully-qualified,🫅🏻,person with crown light skin tone,E14.0
People-Body,person-role,1FAC5 1F3FC,fully-qualified,🫅🏼,person with crown medium-light skin tone,E14.0
People-Body,person-role,1FAC5 1F3FD,fully-qualified,🫅🏽,person with crown medium skin tone,E14.0
People-Body,person-role,1FAC5 1F3FE,fully-qualified,🫅🏾,person with crown medium-dark skin tone,E14.0
People-Body,person-role,1FAC5 1F3FF,fully-qualified,🫅🏿,person with crown dark skin tone,E14.0
People-Body,person-role,1F934,fully-qualified,🤴,prince,E3.0
People-Body,person-role,1F934 1F3FB,fully-qualified,🤴🏻,prince light skin tone,E3.0
People-Body,person-role,1F934 1F3FC,fully-qualified,🤴🏼,prince medium-light skin tone,E3.0
People-Body,person-role,1F934 1F3FD,fully-qualified,🤴🏽,prince medium skin tone,E3.0
People-Body,person-role,1F934 1F3FE,fully-qualified,🤴🏾,prince medium-dark skin tone,E3.0
People-Body,person-role,1F934 1F3FF,fully-qualified,🤴🏿,prince dark skin tone,E3.0
People-Body,person-role,1F478,fully-qualified,👸,princess,E0.6
People-Body,person-role,1F478 1F3FB,fully-qualified,👸🏻,princess light skin tone,E1.0
People-Body,person-role,1F478 1F3FC,fully-qualified,👸🏼,princess medium-light skin tone,E1.0
People-Body,person-role,1F478 1F3FD,fully-qualified,👸🏽,princess medium skin tone,E1.0
People-Body,person-role,1F478 1F3FE,fully-qualified,👸🏾,princess medium-dark skin tone,E1.0
People-Body,person-role,1F478 1F3FF,fully-qualified,👸🏿,princess dark skin tone,E1.0
People-Body,person-role,1F473,fully-qualified,👳,person wearing turban,E0.6
People-Body,person-role,1F473 1F3FB,fully-qualified,👳🏻,person wearing turban light skin tone,E1.0
People-Body,person-role,1F473 1F3FC,fully-qualified,👳🏼,person wearing turban medium-light skin tone,E1.0
People-Body,person-role,1F473 1F3FD,fully-qualified,👳🏽,person wearing turban medium skin tone,E1.0
People-Body,person-role,1F473 1F3FE,fully-qualified,👳🏾,person wearing turban medium-dark skin tone,E1.0
People-Body,person-role,1F473 1F3FF,fully-qualified,👳🏿,person wearing turban dark skin tone,E1.0
People-Body,person-role,1F473 200D 2642 FE0F,fully-qualified,👳‍♂️,man wearing turban,E4.0
People-Body,person-role,1F473 200D 2642,minimally-qualified,👳‍♂,man wearing turban,E4.0
People-Body,person-role,1F473 1F3FB 200D 2642 FE0F,fully-qualified,👳🏻‍♂️,man wearing turban light skin tone,E4.0
People-Body,person-role,1F473 1F3FB 200D 2642,minimally-qualified,👳🏻‍♂,man wearing turban light skin tone,E4.0
People-Body,person-role,1F473 1F3FC 200D 2642 FE0F,fully-qualified,👳🏼‍♂️,man wearing turban medium-light skin tone,E4.0
People-Body,person-role,1F473 1F3FC 200D 2642,minimally-qualified,👳🏼‍♂,man wearing turban medium-light skin tone,E4.0
People-Body,person-role,1F473 1F3FD 200D 2642 FE0F,fully-qualified,👳🏽‍♂️,man wearing turban medium skin tone,E4.0
People-Body,person-role,1F473 1F3FD 200D 2642,minimally-qualified,👳🏽‍♂,man wearing turban medium skin tone,E4.0
People-Body,person-role,1F473 1F3FE 200D 2642 FE0F,fully-qualified,👳🏾‍♂️,man wearing turban medium-dark skin tone,E4.0
People-Body,person-role,1F473 1F3FE 200D 2642,minimally-qualified,👳🏾‍♂,man wearing turban medium-dark skin tone,E4.0
People-Body,person-role,1F473 1F3FF 200D 2642 FE0F,fully-qualified,👳🏿‍♂️,man wearing turban dark skin tone,E4.0
People-Body,person-role,1F473 1F3FF 200D 2642,minimally-qualified,👳🏿‍♂,man wearing turban dark skin tone,E4.0
People-Body,person-role,1F473 200D 2640 FE0F,fully-qualified,👳‍♀️,woman wearing turban,E4.0
People-Body,person-role,1F473 200D 2640,minimally-qualified,👳‍♀,woman wearing turban,E4.0
People-Body,person-role,1F473 1F3FB 200D 2640 FE0F,fully-qualified,👳🏻‍♀️,woman wearing turban light skin tone,E4.0
People-Body,person-role,1F473 1F3FB 200D 2640,minimally-qualified,👳🏻‍♀,woman wearing turban light skin tone,E4.0
People-Body,person-role,1F473 1F3FC 200D 2640 FE0F,fully-qualified,👳🏼‍♀️,woman wearing turban medium-light skin tone,E4.0
People-Body,person-role,1F473 1F3FC 200D 2640,minimally-qualified,👳🏼‍♀,woman wearing turban medium-light skin tone,E4.0
People-Body,person-role,1F473 1F3FD 200D 2640 FE0F,fully-qualified,👳🏽‍♀️,woman wearing turban medium skin tone,E4.0
People-Body,person-role,1F473 1F3FD 200D 2640,minimally-qualified,👳🏽‍♀,woman wearing turban medium skin tone,E4.0
People-Body,person-role,1F473 1F3FE 200D 2640 FE0F,fully-qualified,👳🏾‍♀️,woman wearing turban medium-dark skin tone,E4.0
People-Body,person-role,1F473 1F3FE 200D 2640,minimally-qualified,👳🏾‍♀,woman wearing turban medium-dark skin tone,E4.0
People-Body,person-role,1F473 1F3FF 200D 2640 FE0F,fully-qualified,👳🏿‍♀️,woman wearing turban dark skin tone,E4.0
People-Body,person-role,1F473 1F3FF 200D 2640,minimally-qualified,👳🏿‍♀,woman wearing turban dark skin tone,E4.0
People-Body,person-role,1F472,fully-qualified,👲,person with skullcap,E0.6
People-Body,person-role,1F472 1F3FB,fully-qualified,👲🏻,person with skullcap light skin tone,E1.0
People-Body,person-role,1F472 1F3FC,fully-qualified,👲🏼,person with skullcap medium-light skin tone,E1.0
People-Body,person-role,1F472 1F3FD,fully-qualified,👲🏽,person with skullcap medium skin tone,E1.0
People-Body,person-role,1F472 1F3FE,fully-qualified,👲🏾,person with skullcap medium-dark skin tone,E1.0
People-Body,person-role,1F472 1F3FF,fully-qualified,👲🏿,person with skullcap dark skin tone,E1.0
People-Body,person-role,1F9D5,fully-qualified,🧕,woman with headscarf,E5.0
People-Body,person-role,1F9D5 1F3FB,fully-qualified,🧕🏻,woman with headscarf light skin tone,E5.0
People-Body,person-role,1F9D5 1F3FC,fully-qualified,🧕🏼,woman with headscarf medium-light skin tone,E5.0
People-Body,person-role,1F9D5 1F3FD,fully-qualified,🧕🏽,woman with headscarf medium skin tone,E5.0
People-Body,person-role,1F9D5 1F3FE,fully-qualified,🧕🏾,woman with headscarf medium-dark skin tone,E5.0
People-Body,person-role,1F9D5 1F3FF,fully-qualified,🧕🏿,woman with headscarf dark skin tone,E5.0
People-Body,person-role,1F935,fully-qualified,🤵,person in tuxedo,E3.0
People-Body,person-role,1F935 1F3FB,fully-qualified,🤵🏻,person in tuxedo light skin tone,E3.0
People-Body,person-role,1F935 1F3FC,fully-qualified,🤵🏼,person in tuxedo medium-light skin tone,E3.0
People-Body,person-role,1F935 1F3FD,fully-qualified,🤵🏽,person in tuxedo medium skin tone,E3.0
People-Body,person-role,1F935 1F3FE,fully-qualified,🤵🏾,person in tuxedo medium-dark skin tone,E3.0
People-Body,person-role,1F935 1F3FF,fully-qualified,🤵🏿,person in tuxedo dark skin tone,E3.0
People-Body,person-role,1F935 200D 2642 FE0F,fully-qualified,🤵‍♂️,man in tuxedo,E13.0
People-Body,person-role,1F935 200D 2642,minimally-qualified,🤵‍♂,man in tuxedo,E13.0
People-Body,person-role,1F935 1F3FB 200D 2642 FE0F,fully-qualified,🤵🏻‍♂️,man in tuxedo light skin tone,E13.0
People-Body,person-role,1F935 1F3FB 200D 2642,minimally-qualified,🤵🏻‍♂,man in tuxedo light skin tone,E13.0
People-Body,person-role,1F935 1F3FC 200D 2642 FE0F,fully-qualified,🤵🏼‍♂️,man in tuxedo medium-light skin tone,E13.0
People-Body,person-role,1F935 1F3FC 200D 2642,minimally-qualified,🤵🏼‍♂,man in tuxedo medium-light skin tone,E13.0
People-Body,person-role,1F935 1F3FD 200D 2642 FE0F,fully-qualified,🤵🏽‍♂️,man in tuxedo medium skin tone,E13.0
People-Body,person-role,1F935 1F3FD 200D 2642,minimally-qualified,🤵🏽‍♂,man in tuxedo medium skin tone,E13.0
People-Body,person-role,1F935 1F3FE 200D 2642 FE0F,fully-qualified,🤵🏾‍♂️,man in tuxedo medium-dark skin tone,E13.0
People-Body,person-role,1F935 1F3FE 200D 2642,minimally-qualified,🤵🏾‍♂,man in tuxedo medium-dark skin tone,E13.0
People-Body,person-role,1F935 1F3FF 200D 2642 FE0F,fully-qualified,🤵🏿‍♂️,man in tuxedo dark skin tone,E13.0
People-Body,person-role,1F935 1F3FF 200D 2642,minimally-qualified,🤵🏿‍♂,man in tuxedo dark skin tone,E13.0
People-Body,person-role,1F935 200D 2640 FE0F,fully-qualified,🤵‍♀️,woman in tuxedo,E13.0
People-Body,person-role,1F935 200D 2640,minimally-qualified,🤵‍♀,woman in tuxedo,E13.0
People-Body,person-role,1F935 1F3FB 200D 2640 FE0F,fully-qualified,🤵🏻‍♀️,woman in tuxedo light skin tone,E13.0
People-Body,person-role,1F935 1F3FB 200D 2640,minimally-qualified,🤵🏻‍♀,woman in tuxedo light skin tone,E13.0
People-Body,person-role,1F935 1F3FC 200D 2640 FE0F,fully-qualified,🤵🏼‍♀️,woman in tuxedo medium-light skin tone,E13.0
People-Body,person-role,1F935 1F3FC 200D 2640,minimally-qualified,🤵🏼‍♀,woman in tuxedo medium-light skin tone,E13.0
People-Body,person-role,1F935 1F3FD 200D 2640 FE0F,fully-qualified,🤵🏽‍♀️,woman in tuxedo medium skin tone,E13.0
People-Body,person-role,1F935 1F3FD 200D 2640,minimally-qualified,🤵🏽‍♀,woman in tuxedo medium skin tone,E13.0
People-Body,person-role,1F935 1F3FE 200D 2640 FE0F,fully-qualified,🤵🏾‍♀️,woman in tuxedo medium-dark skin tone,E13.0
People-Body,person-role,1F935 1F3FE 200D 2640,minimally-qualified,🤵🏾‍♀,woman in tuxedo medium-dark skin tone,E13.0
People-Body,person-role,1F935 1F3FF 200D 2640 FE0F,fully-qualified,🤵🏿‍♀️,woman in tuxedo dark skin tone,E13.0
People-Body,person-role,1F935 1F3FF 200D 2640,minimally-qualified,🤵🏿‍♀,woman in tuxedo dark skin tone,E13.0
People-Body,person-role,1F470,fully-qualified,👰,person with veil,E0.6
People-Body,person-role,1F470 1F3FB,fully-qualified,👰🏻,person with veil light skin tone,E1.0
People-Body,person-role,1F470 1F3FC,fully-qualified,👰🏼,person with veil medium-light skin tone,E1.0
People-Body,person-role,1F470 1F3FD,fully-qualified,👰🏽,person with veil medium skin tone,E1.0
People-Body,person-role,1F470 1F3FE,fully-qualified,👰🏾,person with veil medium-dark skin tone,E1.0
People-Body,person-role,1F470 1F3FF,fully-qualified,👰🏿,person with veil dark skin tone,E1.0
People-Body,person-role,1F470 200D 2642 FE0F,fully-qualified,👰‍♂️,man with veil,E13.0
People-Body,person-role,1F470 200D 2642,minimally-qualified,👰‍♂,man with veil,E13.0
People-Body,person-role,1F470 1F3FB 200D 2642 FE0F,fully-qualified,👰🏻‍♂️,man with veil light skin tone,E13.0
People-Body,person-role,1F470 1F3FB 200D 2642,minimally-qualified,👰🏻‍♂,man with veil light skin tone,E13.0
People-Body,person-role,1F470 1F3FC 200D 2642 FE0F,fully-qualified,👰🏼‍♂️,man with veil medium-light skin tone,E13.0
People-Body,person-role,1F470 1F3FC 200D 2642,minimally-qualified,👰🏼‍♂,man with veil medium-light skin tone,E13.0
People-Body,person-role,1F470 1F3FD 200D 2642 FE0F,fully-qualified,👰🏽‍♂️,man with veil medium skin tone,E13.0
People-Body,person-role,1F470 1F3FD 200D 2642,minimally-qualified,👰🏽‍♂,man with veil medium skin tone,E13.0
People-Body,person-role,1F470 1F3FE 200D 2642 FE0F,fully-qualified,👰🏾‍♂️,man with veil medium-dark skin tone,E13.0
People-Body,person-role,1F470 1F3FE 200D 2642,minimally-qualified,👰🏾‍♂,man with veil medium-dark skin tone,E13.0
People-Body,person-role,1F470 1F3FF 200D 2642 FE0F,fully-qualified,👰🏿‍♂️,man with veil dark skin tone,E13.0
People-Body,person-role,1F470 1F3FF 200D 2642,minimally-qualified,👰🏿‍♂,man with veil dark skin tone,E13.0
People-Body,person-role,1F470 200D 2640 FE0F,fully-qualified,👰‍♀️,woman with veil,E13.0
People-Body,person-role,1F470 200D 2640,minimally-qualified,👰‍♀,woman with veil,E13.0
People-Body,person-role,1F470 1F3FB 200D 2640 FE0F,fully-qualified,👰🏻‍♀️,woman with veil light skin tone,E13.0
People-Body,person-role,1F470 1F3FB 200D 2640,minimally-qualified,👰🏻‍♀,woman with veil light skin tone,E13.0
People-Body,person-role,1F470 1F3FC 200D 2640 FE0F,fully-qualified,👰🏼‍♀️,woman with veil medium-light skin tone,E13.0
People-Body,person-role,1F470 1F3FC 200D 2640,minimally-qualified,👰🏼‍♀,woman with veil medium-light skin tone,E13.0
People-Body,person-role,1F470 1F3FD 200D 2640 FE0F,fully-qualified,👰🏽‍♀️,woman with veil medium skin tone,E13.0
People-Body,person-role,1F470 1F3FD 200D 2640,minimally-qualified,👰🏽‍♀,woman with veil medium skin tone,E13.0
People-Body,person-role,1F470 1F3FE 200D 2640 FE0F,fully-qualified,👰🏾‍♀️,woman with veil medium-dark skin tone,E13.0
People-Body,person-role,1F470 1F3FE 200D 2640,minimally-qualified,👰🏾‍♀,woman with veil medium-dark skin tone,E13.0
People-Body,person-role,1F470 1F3FF 200D 2640 FE0F,fully-qualified,👰🏿‍♀️,woman with veil dark skin tone,E13.0
People-Body,person-role,1F470 1F3FF 200D 2640,minimally-qualified,👰🏿‍♀,woman with veil dark skin tone,E13.0
People-Body,person-role,1F930,fully-qualified,🤰,pregnant woman,E3.0
People-Body,person-role,1F930 1F3FB,fully-qualified,🤰🏻,pregnant woman light skin tone,E3.0
People-Body,person-role,1F930 1F3FC,fully-qualified,🤰🏼,pregnant woman medium-light skin tone,E3.0
People-Body,person-role,1F930 1F3FD,fully-qualified,🤰🏽,pregnant woman medium skin tone,E3.0
People-Body,person-role,1F930 1F3FE,fully-qualified,🤰🏾,pregnant woman medium-dark skin tone,E3.0
People-Body,person-role,1F930 1F3FF,fully-qualified,🤰🏿,pregnant woman dark skin tone,E3.0
People-Body,person-role,1FAC3,fully-qualified,🫃,pregnant man,E14.0
People-Body,person-role,1FAC3 1F3FB,fully-qualified,🫃🏻,pregnant man light skin tone,E14.0
People-Body,person-role,1FAC3 1F3FC,fully-qualified,🫃🏼,pregnant man medium-light skin tone,E14.0
People-Body,person-role,1FAC3 1F3FD,fully-qualified,🫃🏽,pregnant man medium skin tone,E14.0
People-Body,person-role,1FAC3 1F3FE,fully-qualified,🫃🏾,pregnant man medium-dark skin tone,E14.0
People-Body,person-role,1FAC3 1F3FF,fully-qualified,🫃🏿,pregnant man dark skin tone,E14.0
People-Body,person-role,1FAC4,fully-qualified,🫄,pregnant person,E14.0
People-Body,person-role,1FAC4 1F3FB,fully-qualified,🫄🏻,pregnant person light skin tone,E14.0
People-Body,person-role,1FAC4 1F3FC,fully-qualified,🫄🏼,pregnant person medium-light skin tone,E14.0
People-Body,person-role,1FAC4 1F3FD,fully-qualified,🫄🏽,pregnant person medium skin tone,E14.0
People-Body,person-role,1FAC4 1F3FE,fully-qualified,🫄🏾,pregnant person medium-dark skin tone,E14.0
People-Body,person-role,1FAC4 1F3FF,fully-qualified,🫄🏿,pregnant person dark skin tone,E14.0
People-Body,person-role,1F931,fully-qualified,🤱,breast-feeding,E5.0
People-Body,person-role,1F931 1F3FB,fully-qualified,🤱🏻,breast-feeding light skin tone,E5.0
People-Body,person-role,1F931 1F3FC,fully-qualified,🤱🏼,breast-feeding medium-light skin tone,E5.0
People-Body,person-role,1F931 1F3FD,fully-qualified,🤱🏽,breast-feeding medium skin tone,E5.0
People-Body,person-role,1F931 1F3FE,fully-qualified,🤱🏾,breast-feeding medium-dark skin tone,E5.0
People-Body,person-role,1F931 1F3FF,fully-qualified,🤱🏿,breast-feeding dark skin tone,E5.0
People-Body,person-role,1F469 200D 1F37C,fully-qualified,👩‍🍼,woman feeding baby,E13.0
People-Body,person-role,1F469 1F3FB 200D 1F37C,fully-qualified,👩🏻‍🍼,woman feeding baby light skin tone,E13.0
People-Body,person-role,1F469 1F3FC 200D 1F37C,fully-qualified,👩🏼‍🍼,woman feeding baby medium-light skin tone,E13.0
People-Body,person-role,1F469 1F3FD 200D 1F37C,fully-qualified,👩🏽‍🍼,woman feeding baby medium skin tone,E13.0
People-Body,person-role,1F469 1F3FE 200D 1F37C,fully-qualified,👩🏾‍🍼,woman feeding baby medium-dark skin tone,E13.0
People-Body,person-role,1F469 1F3FF 200D 1F37C,fully-qualified,👩🏿‍🍼,woman feeding baby dark skin tone,E13.0
People-Body,person-role,1F468 200D 1F37C,fully-qualified,👨‍🍼,man feeding baby,E13.0
People-Body,person-role,1F468 1F3FB 200D 1F37C,fully-qualified,👨🏻‍🍼,man feeding baby light skin tone,E13.0
People-Body,person-role,1F468 1F3FC 200D 1F37C,fully-qualified,👨🏼‍🍼,man feeding baby medium-light skin tone,E13.0
People-Body,person-role,1F468 1F3FD 200D 1F37C,fully-qualified,👨🏽‍🍼,man feeding baby medium skin tone,E13.0
People-Body,person-role,1F468 1F3FE 200D 1F37C,fully-qualified,👨🏾‍🍼,man feeding baby medium-dark skin tone,E13.0
People-Body,person-role,1F468 1F3FF 200D 1F37C,fully-qualified,👨🏿‍🍼,man feeding baby dark skin tone,E13.0
People-Body,person-role,1F9D1 200D 1F37C,fully-qualified,🧑‍🍼,person feeding baby,E13.0
People-Body,person-role,1F9D1 1F3FB 200D 1F37C,fully-qualified,🧑🏻‍🍼,person feeding baby light skin tone,E13.0
People-Body,person-role,1F9D1 1F3FC 200D 1F37C,fully-qualified,🧑🏼‍🍼,person feeding baby medium-light skin tone,E13.0
People-Body,person-role,1F9D1 1F3FD 200D 1F37C,fully-qualified,🧑🏽‍🍼,person feeding baby medium skin tone,E13.0
People-Body,person-role,1F9D1 1F3FE 200D 1F37C,fully-qualified,🧑🏾‍🍼,person feeding baby medium-dark skin tone,E13.0
People-Body,person-role,1F9D1 1F3FF 200D 1F37C,fully-qualified,🧑🏿‍🍼,person feeding baby dark skin tone,E13.0
People-Body,person-fantasy,1F47C,fully-qualified,👼,baby angel,E0.6
People-Body,person-fantasy,1F47C 1F3FB,fully-qualified,👼🏻,baby angel light skin tone,E1.0
People-Body,person-fantasy,1F47C 1F3FC,fully-qualified,👼🏼,baby angel medium-light skin tone,E1.0
People-Body,person-fantasy,1F47C 1F3FD,fully-qualified,👼🏽,baby angel medium skin tone,E1.0
People-Body,person-fantasy,1F47C 1F3FE,fully-qualified,👼🏾,baby angel medium-dark skin tone,E1.0
People-Body,person-fantasy,1F47C 1F3FF,fully-qualified,👼🏿,baby angel dark skin tone,E1.0
People-Body,person-fantasy,1F385,fully-qualified,🎅,Santa Claus,E0.6
People-Body,person-fantasy,1F385 1F3FB,fully-qualified,🎅🏻,Santa Claus light skin tone,E1.0
People-Body,person-fantasy,1F385 1F3FC,fully-qualified,🎅🏼,Santa Claus medium-light skin tone,E1.0
People-Body,person-fantasy,1F385 1F3FD,fully-qualified,🎅🏽,Santa Claus medium skin tone,E1.0
People-Body,person-fantasy,1F385 1F3FE,fully-qualified,🎅🏾,Santa Claus medium-dark skin tone,E1.0
People-Body,person-fantasy,1F385 1F3FF,fully-qualified,🎅🏿,Santa Claus dark skin tone,E1.0
People-Body,person-fantasy,1F936,fully-qualified,🤶,Mrs Claus,E3.0
People-Body,person-fantasy,1F936 1F3FB,fully-qualified,🤶🏻,Mrs Claus light skin tone,E3.0
People-Body,person-fantasy,1F936 1F3FC,fully-qualified,🤶🏼,Mrs Claus medium-light skin tone,E3.0
People-Body,person-fantasy,1F936 1F3FD,fully-qualified,🤶🏽,Mrs Claus medium skin tone,E3.0
People-Body,person-fantasy,1F936 1F3FE,fully-qualified,🤶🏾,Mrs Claus medium-dark skin tone,E3.0
People-Body,person-fantasy,1F936 1F3FF,fully-qualified,🤶🏿,Mrs Claus dark skin tone,E3.0
People-Body,person-fantasy,1F9D1 200D 1F384,fully-qualified,🧑‍🎄,mx claus,E13.0
People-Body,person-fantasy,1F9D1 1F3FB 200D 1F384,fully-qualified,🧑🏻‍🎄,mx claus light skin tone,E13.0
People-Body,person-fantasy,1F9D1 1F3FC 200D 1F384,fully-qualified,🧑🏼‍🎄,mx claus medium-light skin tone,E13.0
People-Body,person-fantasy,1F9D1 1F3FD 200D 1F384,fully-qualified,🧑🏽‍🎄,mx claus medium skin tone,E13.0
People-Body,person-fantasy,1F9D1 1F3FE 200D 1F384,fully-qualified,🧑🏾‍🎄,mx claus medium-dark skin tone,E13.0
People-Body,person-fantasy,1F9D1 1F3FF 200D 1F384,fully-qualified,🧑🏿‍🎄,mx claus dark skin tone,E13.0
People-Body,person-fantasy,1F9B8,fully-qualified,🦸,superhero,E11.0
People-Body,person-fantasy,1F9B8 1F3FB,fully-qualified,🦸🏻,superhero light skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FC,fully-qualified,🦸🏼,superhero medium-light skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FD,fully-qualified,🦸🏽,superhero medium skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FE,fully-qualified,🦸🏾,superhero medium-dark skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FF,fully-qualified,🦸🏿,superhero dark skin tone,E11.0
People-Body,person-fantasy,1F9B8 200D 2642 FE0F,fully-qualified,🦸‍♂️,man superhero,E11.0
People-Body,person-fantasy,1F9B8 200D 2642,minimally-qualified,🦸‍♂,man superhero,E11.0
People-Body,person-fantasy,1F9B8 1F3FB 200D 2642 FE0F,fully-qualified,🦸🏻‍♂️,man superhero light skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FB 200D 2642,minimally-qualified,🦸🏻‍♂,man superhero light skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FC 200D 2642 FE0F,fully-qualified,🦸🏼‍♂️,man superhero medium-light skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FC 200D 2642,minimally-qualified,🦸🏼‍♂,man superhero medium-light skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FD 200D 2642 FE0F,fully-qualified,🦸🏽‍♂️,man superhero medium skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FD 200D 2642,minimally-qualified,🦸🏽‍♂,man superhero medium skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FE 200D 2642 FE0F,fully-qualified,🦸🏾‍♂️,man superhero medium-dark skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FE 200D 2642,minimally-qualified,🦸🏾‍♂,man superhero medium-dark skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FF 200D 2642 FE0F,fully-qualified,🦸🏿‍♂️,man superhero dark skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FF 200D 2642,minimally-qualified,🦸🏿‍♂,man superhero dark skin tone,E11.0
People-Body,person-fantasy,1F9B8 200D 2640 FE0F,fully-qualified,🦸‍♀️,woman superhero,E11.0
People-Body,person-fantasy,1F9B8 200D 2640,minimally-qualified,🦸‍♀,woman superhero,E11.0
People-Body,person-fantasy,1F9B8 1F3FB 200D 2640 FE0F,fully-qualified,🦸🏻‍♀️,woman superhero light skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FB 200D 2640,minimally-qualified,🦸🏻‍♀,woman superhero light skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FC 200D 2640 FE0F,fully-qualified,🦸🏼‍♀️,woman superhero medium-light skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FC 200D 2640,minimally-qualified,🦸🏼‍♀,woman superhero medium-light skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FD 200D 2640 FE0F,fully-qualified,🦸🏽‍♀️,woman superhero medium skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FD 200D 2640,minimally-qualified,🦸🏽‍♀,woman superhero medium skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FE 200D 2640 FE0F,fully-qualified,🦸🏾‍♀️,woman superhero medium-dark skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FE 200D 2640,minimally-qualified,🦸🏾‍♀,woman superhero medium-dark skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FF 200D 2640 FE0F,fully-qualified,🦸🏿‍♀️,woman superhero dark skin tone,E11.0
People-Body,person-fantasy,1F9B8 1F3FF 200D 2640,minimally-qualified,🦸🏿‍♀,woman superhero dark skin tone,E11.0
People-Body,person-fantasy,1F9B9,fully-qualified,🦹,supervillain,E11.0
People-Body,person-fantasy,1F9B9 1F3FB,fully-qualified,🦹🏻,supervillain light skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FC,fully-qualified,🦹🏼,supervillain medium-light skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FD,fully-qualified,🦹🏽,supervillain medium skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FE,fully-qualified,🦹🏾,supervillain medium-dark skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FF,fully-qualified,🦹🏿,supervillain dark skin tone,E11.0
People-Body,person-fantasy,1F9B9 200D 2642 FE0F,fully-qualified,🦹‍♂️,man supervillain,E11.0
People-Body,person-fantasy,1F9B9 200D 2642,minimally-qualified,🦹‍♂,man supervillain,E11.0
People-Body,person-fantasy,1F9B9 1F3FB 200D 2642 FE0F,fully-qualified,🦹🏻‍♂️,man supervillain light skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FB 200D 2642,minimally-qualified,🦹🏻‍♂,man supervillain light skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FC 200D 2642 FE0F,fully-qualified,🦹🏼‍♂️,man supervillain medium-light skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FC 200D 2642,minimally-qualified,🦹🏼‍♂,man supervillain medium-light skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FD 200D 2642 FE0F,fully-qualified,🦹🏽‍♂️,man supervillain medium skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FD 200D 2642,minimally-qualified,🦹🏽‍♂,man supervillain medium skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FE 200D 2642 FE0F,fully-qualified,🦹🏾‍♂️,man supervillain medium-dark skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FE 200D 2642,minimally-qualified,🦹🏾‍♂,man supervillain medium-dark skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FF 200D 2642 FE0F,fully-qualified,🦹🏿‍♂️,man supervillain dark skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FF 200D 2642,minimally-qualified,🦹🏿‍♂,man supervillain dark skin tone,E11.0
People-Body,person-fantasy,1F9B9 200D 2640 FE0F,fully-qualified,🦹‍♀️,woman supervillain,E11.0
People-Body,person-fantasy,1F9B9 200D 2640,minimally-qualified,🦹‍♀,woman supervillain,E11.0
People-Body,person-fantasy,1F9B9 1F3FB 200D 2640 FE0F,fully-qualified,🦹🏻‍♀️,woman supervillain light skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FB 200D 2640,minimally-qualified,🦹🏻‍♀,woman supervillain light skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FC 200D 2640 FE0F,fully-qualified,🦹🏼‍♀️,woman supervillain medium-light skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FC 200D 2640,minimally-qualified,🦹🏼‍♀,woman supervillain medium-light skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FD 200D 2640 FE0F,fully-qualified,🦹🏽‍♀️,woman supervillain medium skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FD 200D 2640,minimally-qualified,🦹🏽‍♀,woman supervillain medium skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FE 200D 2640 FE0F,fully-qualified,🦹🏾‍♀️,woman supervillain medium-dark skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FE 200D 2640,minimally-qualified,🦹🏾‍♀,woman supervillain medium-dark skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FF 200D 2640 FE0F,fully-qualified,🦹🏿‍♀️,woman supervillain dark skin tone,E11.0
People-Body,person-fantasy,1F9B9 1F3FF 200D 2640,minimally-qualified,🦹🏿‍♀,woman supervillain dark skin tone,E11.0
People-Body,person-fantasy,1F9D9,fully-qualified,🧙,mage,E5.0
People-Body,person-fantasy,1F9D9 1F3FB,fully-qualified,🧙🏻,mage light skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FC,fully-qualified,🧙🏼,mage medium-light skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FD,fully-qualified,🧙🏽,mage medium skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FE,fully-qualified,🧙🏾,mage medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FF,fully-qualified,🧙🏿,mage dark skin tone,E5.0
People-Body,person-fantasy,1F9D9 200D 2642 FE0F,fully-qualified,🧙‍♂️,man mage,E5.0
People-Body,person-fantasy,1F9D9 200D 2642,minimally-qualified,🧙‍♂,man mage,E5.0
People-Body,person-fantasy,1F9D9 1F3FB 200D 2642 FE0F,fully-qualified,🧙🏻‍♂️,man mage light skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FB 200D 2642,minimally-qualified,🧙🏻‍♂,man mage light skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FC 200D 2642 FE0F,fully-qualified,🧙🏼‍♂️,man mage medium-light skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FC 200D 2642,minimally-qualified,🧙🏼‍♂,man mage medium-light skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FD 200D 2642 FE0F,fully-qualified,🧙🏽‍♂️,man mage medium skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FD 200D 2642,minimally-qualified,🧙🏽‍♂,man mage medium skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FE 200D 2642 FE0F,fully-qualified,🧙🏾‍♂️,man mage medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FE 200D 2642,minimally-qualified,🧙🏾‍♂,man mage medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FF 200D 2642 FE0F,fully-qualified,🧙🏿‍♂️,man mage dark skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FF 200D 2642,minimally-qualified,🧙🏿‍♂,man mage dark skin tone,E5.0
People-Body,person-fantasy,1F9D9 200D 2640 FE0F,fully-qualified,🧙‍♀️,woman mage,E5.0
People-Body,person-fantasy,1F9D9 200D 2640,minimally-qualified,🧙‍♀,woman mage,E5.0
People-Body,person-fantasy,1F9D9 1F3FB 200D 2640 FE0F,fully-qualified,🧙🏻‍♀️,woman mage light skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FB 200D 2640,minimally-qualified,🧙🏻‍♀,woman mage light skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FC 200D 2640 FE0F,fully-qualified,🧙🏼‍♀️,woman mage medium-light skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FC 200D 2640,minimally-qualified,🧙🏼‍♀,woman mage medium-light skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FD 200D 2640 FE0F,fully-qualified,🧙🏽‍♀️,woman mage medium skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FD 200D 2640,minimally-qualified,🧙🏽‍♀,woman mage medium skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FE 200D 2640 FE0F,fully-qualified,🧙🏾‍♀️,woman mage medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FE 200D 2640,minimally-qualified,🧙🏾‍♀,woman mage medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FF 200D 2640 FE0F,fully-qualified,🧙🏿‍♀️,woman mage dark skin tone,E5.0
People-Body,person-fantasy,1F9D9 1F3FF 200D 2640,minimally-qualified,🧙🏿‍♀,woman mage dark skin tone,E5.0
People-Body,person-fantasy,1F9DA,fully-qualified,🧚,fairy,E5.0
People-Body,person-fantasy,1F9DA 1F3FB,fully-qualified,🧚🏻,fairy light skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FC,fully-qualified,🧚🏼,fairy medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FD,fully-qualified,🧚🏽,fairy medium skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FE,fully-qualified,🧚🏾,fairy medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FF,fully-qualified,🧚🏿,fairy dark skin tone,E5.0
People-Body,person-fantasy,1F9DA 200D 2642 FE0F,fully-qualified,🧚‍♂️,man fairy,E5.0
People-Body,person-fantasy,1F9DA 200D 2642,minimally-qualified,🧚‍♂,man fairy,E5.0
People-Body,person-fantasy,1F9DA 1F3FB 200D 2642 FE0F,fully-qualified,🧚🏻‍♂️,man fairy light skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FB 200D 2642,minimally-qualified,🧚🏻‍♂,man fairy light skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FC 200D 2642 FE0F,fully-qualified,🧚🏼‍♂️,man fairy medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FC 200D 2642,minimally-qualified,🧚🏼‍♂,man fairy medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FD 200D 2642 FE0F,fully-qualified,🧚🏽‍♂️,man fairy medium skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FD 200D 2642,minimally-qualified,🧚🏽‍♂,man fairy medium skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FE 200D 2642 FE0F,fully-qualified,🧚🏾‍♂️,man fairy medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FE 200D 2642,minimally-qualified,🧚🏾‍♂,man fairy medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FF 200D 2642 FE0F,fully-qualified,🧚🏿‍♂️,man fairy dark skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FF 200D 2642,minimally-qualified,🧚🏿‍♂,man fairy dark skin tone,E5.0
People-Body,person-fantasy,1F9DA 200D 2640 FE0F,fully-qualified,🧚‍♀️,woman fairy,E5.0
People-Body,person-fantasy,1F9DA 200D 2640,minimally-qualified,🧚‍♀,woman fairy,E5.0
People-Body,person-fantasy,1F9DA 1F3FB 200D 2640 FE0F,fully-qualified,🧚🏻‍♀️,woman fairy light skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FB 200D 2640,minimally-qualified,🧚🏻‍♀,woman fairy light skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FC 200D 2640 FE0F,fully-qualified,🧚🏼‍♀️,woman fairy medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FC 200D 2640,minimally-qualified,🧚🏼‍♀,woman fairy medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FD 200D 2640 FE0F,fully-qualified,🧚🏽‍♀️,woman fairy medium skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FD 200D 2640,minimally-qualified,🧚🏽‍♀,woman fairy medium skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FE 200D 2640 FE0F,fully-qualified,🧚🏾‍♀️,woman fairy medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FE 200D 2640,minimally-qualified,🧚🏾‍♀,woman fairy medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FF 200D 2640 FE0F,fully-qualified,🧚🏿‍♀️,woman fairy dark skin tone,E5.0
People-Body,person-fantasy,1F9DA 1F3FF 200D 2640,minimally-qualified,🧚🏿‍♀,woman fairy dark skin tone,E5.0
People-Body,person-fantasy,1F9DB,fully-qualified,🧛,vampire,E5.0
People-Body,person-fantasy,1F9DB 1F3FB,fully-qualified,🧛🏻,vampire light skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FC,fully-qualified,🧛🏼,vampire medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FD,fully-qualified,🧛🏽,vampire medium skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FE,fully-qualified,🧛🏾,vampire medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FF,fully-qualified,🧛🏿,vampire dark skin tone,E5.0
People-Body,person-fantasy,1F9DB 200D 2642 FE0F,fully-qualified,🧛‍♂️,man vampire,E5.0
People-Body,person-fantasy,1F9DB 200D 2642,minimally-qualified,🧛‍♂,man vampire,E5.0
People-Body,person-fantasy,1F9DB 1F3FB 200D 2642 FE0F,fully-qualified,🧛🏻‍♂️,man vampire light skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FB 200D 2642,minimally-qualified,🧛🏻‍♂,man vampire light skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FC 200D 2642 FE0F,fully-qualified,🧛🏼‍♂️,man vampire medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FC 200D 2642,minimally-qualified,🧛🏼‍♂,man vampire medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FD 200D 2642 FE0F,fully-qualified,🧛🏽‍♂️,man vampire medium skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FD 200D 2642,minimally-qualified,🧛🏽‍♂,man vampire medium skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FE 200D 2642 FE0F,fully-qualified,🧛🏾‍♂️,man vampire medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FE 200D 2642,minimally-qualified,🧛🏾‍♂,man vampire medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FF 200D 2642 FE0F,fully-qualified,🧛🏿‍♂️,man vampire dark skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FF 200D 2642,minimally-qualified,🧛🏿‍♂,man vampire dark skin tone,E5.0
People-Body,person-fantasy,1F9DB 200D 2640 FE0F,fully-qualified,🧛‍♀️,woman vampire,E5.0
People-Body,person-fantasy,1F9DB 200D 2640,minimally-qualified,🧛‍♀,woman vampire,E5.0
People-Body,person-fantasy,1F9DB 1F3FB 200D 2640 FE0F,fully-qualified,🧛🏻‍♀️,woman vampire light skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FB 200D 2640,minimally-qualified,🧛🏻‍♀,woman vampire light skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FC 200D 2640 FE0F,fully-qualified,🧛🏼‍♀️,woman vampire medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FC 200D 2640,minimally-qualified,🧛🏼‍♀,woman vampire medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FD 200D 2640 FE0F,fully-qualified,🧛🏽‍♀️,woman vampire medium skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FD 200D 2640,minimally-qualified,🧛🏽‍♀,woman vampire medium skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FE 200D 2640 FE0F,fully-qualified,🧛🏾‍♀️,woman vampire medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FE 200D 2640,minimally-qualified,🧛🏾‍♀,woman vampire medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FF 200D 2640 FE0F,fully-qualified,🧛🏿‍♀️,woman vampire dark skin tone,E5.0
People-Body,person-fantasy,1F9DB 1F3FF 200D 2640,minimally-qualified,🧛🏿‍♀,woman vampire dark skin tone,E5.0
People-Body,person-fantasy,1F9DC,fully-qualified,🧜,merperson,E5.0
People-Body,person-fantasy,1F9DC 1F3FB,fully-qualified,🧜🏻,merperson light skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FC,fully-qualified,🧜🏼,merperson medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FD,fully-qualified,🧜🏽,merperson medium skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FE,fully-qualified,🧜🏾,merperson medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FF,fully-qualified,🧜🏿,merperson dark skin tone,E5.0
People-Body,person-fantasy,1F9DC 200D 2642 FE0F,fully-qualified,🧜‍♂️,merman,E5.0
People-Body,person-fantasy,1F9DC 200D 2642,minimally-qualified,🧜‍♂,merman,E5.0
People-Body,person-fantasy,1F9DC 1F3FB 200D 2642 FE0F,fully-qualified,🧜🏻‍♂️,merman light skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FB 200D 2642,minimally-qualified,🧜🏻‍♂,merman light skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FC 200D 2642 FE0F,fully-qualified,🧜🏼‍♂️,merman medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FC 200D 2642,minimally-qualified,🧜🏼‍♂,merman medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FD 200D 2642 FE0F,fully-qualified,🧜🏽‍♂️,merman medium skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FD 200D 2642,minimally-qualified,🧜🏽‍♂,merman medium skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FE 200D 2642 FE0F,fully-qualified,🧜🏾‍♂️,merman medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FE 200D 2642,minimally-qualified,🧜🏾‍♂,merman medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FF 200D 2642 FE0F,fully-qualified,🧜🏿‍♂️,merman dark skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FF 200D 2642,minimally-qualified,🧜🏿‍♂,merman dark skin tone,E5.0
People-Body,person-fantasy,1F9DC 200D 2640 FE0F,fully-qualified,🧜‍♀️,mermaid,E5.0
People-Body,person-fantasy,1F9DC 200D 2640,minimally-qualified,🧜‍♀,mermaid,E5.0
People-Body,person-fantasy,1F9DC 1F3FB 200D 2640 FE0F,fully-qualified,🧜🏻‍♀️,mermaid light skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FB 200D 2640,minimally-qualified,🧜🏻‍♀,mermaid light skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FC 200D 2640 FE0F,fully-qualified,🧜🏼‍♀️,mermaid medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FC 200D 2640,minimally-qualified,🧜🏼‍♀,mermaid medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FD 200D 2640 FE0F,fully-qualified,🧜🏽‍♀️,mermaid medium skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FD 200D 2640,minimally-qualified,🧜🏽‍♀,mermaid medium skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FE 200D 2640 FE0F,fully-qualified,🧜🏾‍♀️,mermaid medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FE 200D 2640,minimally-qualified,🧜🏾‍♀,mermaid medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FF 200D 2640 FE0F,fully-qualified,🧜🏿‍♀️,mermaid dark skin tone,E5.0
People-Body,person-fantasy,1F9DC 1F3FF 200D 2640,minimally-qualified,🧜🏿‍♀,mermaid dark skin tone,E5.0
People-Body,person-fantasy,1F9DD,fully-qualified,🧝,elf,E5.0
People-Body,person-fantasy,1F9DD 1F3FB,fully-qualified,🧝🏻,elf light skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FC,fully-qualified,🧝🏼,elf medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FD,fully-qualified,🧝🏽,elf medium skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FE,fully-qualified,🧝🏾,elf medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FF,fully-qualified,🧝🏿,elf dark skin tone,E5.0
People-Body,person-fantasy,1F9DD 200D 2642 FE0F,fully-qualified,🧝‍♂️,man elf,E5.0
People-Body,person-fantasy,1F9DD 200D 2642,minimally-qualified,🧝‍♂,man elf,E5.0
People-Body,person-fantasy,1F9DD 1F3FB 200D 2642 FE0F,fully-qualified,🧝🏻‍♂️,man elf light skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FB 200D 2642,minimally-qualified,🧝🏻‍♂,man elf light skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FC 200D 2642 FE0F,fully-qualified,🧝🏼‍♂️,man elf medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FC 200D 2642,minimally-qualified,🧝🏼‍♂,man elf medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FD 200D 2642 FE0F,fully-qualified,🧝🏽‍♂️,man elf medium skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FD 200D 2642,minimally-qualified,🧝🏽‍♂,man elf medium skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FE 200D 2642 FE0F,fully-qualified,🧝🏾‍♂️,man elf medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FE 200D 2642,minimally-qualified,🧝🏾‍♂,man elf medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FF 200D 2642 FE0F,fully-qualified,🧝🏿‍♂️,man elf dark skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FF 200D 2642,minimally-qualified,🧝🏿‍♂,man elf dark skin tone,E5.0
People-Body,person-fantasy,1F9DD 200D 2640 FE0F,fully-qualified,🧝‍♀️,woman elf,E5.0
People-Body,person-fantasy,1F9DD 200D 2640,minimally-qualified,🧝‍♀,woman elf,E5.0
People-Body,person-fantasy,1F9DD 1F3FB 200D 2640 FE0F,fully-qualified,🧝🏻‍♀️,woman elf light skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FB 200D 2640,minimally-qualified,🧝🏻‍♀,woman elf light skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FC 200D 2640 FE0F,fully-qualified,🧝🏼‍♀️,woman elf medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FC 200D 2640,minimally-qualified,🧝🏼‍♀,woman elf medium-light skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FD 200D 2640 FE0F,fully-qualified,🧝🏽‍♀️,woman elf medium skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FD 200D 2640,minimally-qualified,🧝🏽‍♀,woman elf medium skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FE 200D 2640 FE0F,fully-qualified,🧝🏾‍♀️,woman elf medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FE 200D 2640,minimally-qualified,🧝🏾‍♀,woman elf medium-dark skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FF 200D 2640 FE0F,fully-qualified,🧝🏿‍♀️,woman elf dark skin tone,E5.0
People-Body,person-fantasy,1F9DD 1F3FF 200D 2640,minimally-qualified,🧝🏿‍♀,woman elf dark skin tone,E5.0
People-Body,person-fantasy,1F9DE,fully-qualified,🧞,genie,E5.0
People-Body,person-fantasy,1F9DE 200D 2642 FE0F,fully-qualified,🧞‍♂️,man genie,E5.0
People-Body,person-fantasy,1F9DE 200D 2642,minimally-qualified,🧞‍♂,man genie,E5.0
People-Body,person-fantasy,1F9DE 200D 2640 FE0F,fully-qualified,🧞‍♀️,woman genie,E5.0
People-Body,person-fantasy,1F9DE 200D 2640,minimally-qualified,🧞‍♀,woman genie,E5.0
People-Body,person-fantasy,1F9DF,fully-qualified,🧟,zombie,E5.0
People-Body,person-fantasy,1F9DF 200D 2642 FE0F,fully-qualified,🧟‍♂️,man zombie,E5.0
People-Body,person-fantasy,1F9DF 200D 2642,minimally-qualified,🧟‍♂,man zombie,E5.0
People-Body,person-fantasy,1F9DF 200D 2640 FE0F,fully-qualified,🧟‍♀️,woman zombie,E5.0
People-Body,person-fantasy,1F9DF 200D 2640,minimally-qualified,🧟‍♀,woman zombie,E5.0
People-Body,person-fantasy,1F9CC,fully-qualified,🧌,troll,E14.0
People-Body,person-activity,1F486,fully-qualified,💆,person getting massage,E0.6
People-Body,person-activity,1F486 1F3FB,fully-qualified,💆🏻,person getting massage light skin tone,E1.0
People-Body,person-activity,1F486 1F3FC,fully-qualified,💆🏼,person getting massage medium-light skin tone,E1.0
People-Body,person-activity,1F486 1F3FD,fully-qualified,💆🏽,person getting massage medium skin tone,E1.0
People-Body,person-activity,1F486 1F3FE,fully-qualified,💆🏾,person getting massage medium-dark skin tone,E1.0
People-Body,person-activity,1F486 1F3FF,fully-qualified,💆🏿,person getting massage dark skin tone,E1.0
People-Body,person-activity,1F486 200D 2642 FE0F,fully-qualified,💆‍♂️,man getting massage,E4.0
People-Body,person-activity,1F486 200D 2642,minimally-qualified,💆‍♂,man getting massage,E4.0
People-Body,person-activity,1F486 1F3FB 200D 2642 FE0F,fully-qualified,💆🏻‍♂️,man getting massage light skin tone,E4.0
People-Body,person-activity,1F486 1F3FB 200D 2642,minimally-qualified,💆🏻‍♂,man getting massage light skin tone,E4.0
People-Body,person-activity,1F486 1F3FC 200D 2642 FE0F,fully-qualified,💆🏼‍♂️,man getting massage medium-light skin tone,E4.0
People-Body,person-activity,1F486 1F3FC 200D 2642,minimally-qualified,💆🏼‍♂,man getting massage medium-light skin tone,E4.0
People-Body,person-activity,1F486 1F3FD 200D 2642 FE0F,fully-qualified,💆🏽‍♂️,man getting massage medium skin tone,E4.0
People-Body,person-activity,1F486 1F3FD 200D 2642,minimally-qualified,💆🏽‍♂,man getting massage medium skin tone,E4.0
People-Body,person-activity,1F486 1F3FE 200D 2642 FE0F,fully-qualified,💆🏾‍♂️,man getting massage medium-dark skin tone,E4.0
People-Body,person-activity,1F486 1F3FE 200D 2642,minimally-qualified,💆🏾‍♂,man getting massage medium-dark skin tone,E4.0
People-Body,person-activity,1F486 1F3FF 200D 2642 FE0F,fully-qualified,💆🏿‍♂️,man getting massage dark skin tone,E4.0
People-Body,person-activity,1F486 1F3FF 200D 2642,minimally-qualified,💆🏿‍♂,man getting massage dark skin tone,E4.0
People-Body,person-activity,1F486 200D 2640 FE0F,fully-qualified,💆‍♀️,woman getting massage,E4.0
People-Body,person-activity,1F486 200D 2640,minimally-qualified,💆‍♀,woman getting massage,E4.0
People-Body,person-activity,1F486 1F3FB 200D 2640 FE0F,fully-qualified,💆🏻‍♀️,woman getting massage light skin tone,E4.0
People-Body,person-activity,1F486 1F3FB 200D 2640,minimally-qualified,💆🏻‍♀,woman getting massage light skin tone,E4.0
People-Body,person-activity,1F486 1F3FC 200D 2640 FE0F,fully-qualified,💆🏼‍♀️,woman getting massage medium-light skin tone,E4.0
People-Body,person-activity,1F486 1F3FC 200D 2640,minimally-qualified,💆🏼‍♀,woman getting massage medium-light skin tone,E4.0
People-Body,person-activity,1F486 1F3FD 200D 2640 FE0F,fully-qualified,💆🏽‍♀️,woman getting massage medium skin tone,E4.0
People-Body,person-activity,1F486 1F3FD 200D 2640,minimally-qualified,💆🏽‍♀,woman getting massage medium skin tone,E4.0
People-Body,person-activity,1F486 1F3FE 200D 2640 FE0F,fully-qualified,💆🏾‍♀️,woman getting massage medium-dark skin tone,E4.0
People-Body,person-activity,1F486 1F3FE 200D 2640,minimally-qualified,💆🏾‍♀,woman getting massage medium-dark skin tone,E4.0
People-Body,person-activity,1F486 1F3FF 200D 2640 FE0F,fully-qualified,💆🏿‍♀️,woman getting massage dark skin tone,E4.0
People-Body,person-activity,1F486 1F3FF 200D 2640,minimally-qualified,💆🏿‍♀,woman getting massage dark skin tone,E4.0
People-Body,person-activity,1F487,fully-qualified,💇,person getting haircut,E0.6
People-Body,person-activity,1F487 1F3FB,fully-qualified,💇🏻,person getting haircut light skin tone,E1.0
People-Body,person-activity,1F487 1F3FC,fully-qualified,💇🏼,person getting haircut medium-light skin tone,E1.0
People-Body,person-activity,1F487 1F3FD,fully-qualified,💇🏽,person getting haircut medium skin tone,E1.0
People-Body,person-activity,1F487 1F3FE,fully-qualified,💇🏾,person getting haircut medium-dark skin tone,E1.0
People-Body,person-activity,1F487 1F3FF,fully-qualified,💇🏿,person getting haircut dark skin tone,E1.0
People-Body,person-activity,1F487 200D 2642 FE0F,fully-qualified,💇‍♂️,man getting haircut,E4.0
People-Body,person-activity,1F487 200D 2642,minimally-qualified,💇‍♂,man getting haircut,E4.0
People-Body,person-activity,1F487 1F3FB 200D 2642 FE0F,fully-qualified,💇🏻‍♂️,man getting haircut light skin tone,E4.0
People-Body,person-activity,1F487 1F3FB 200D 2642,minimally-qualified,💇🏻‍♂,man getting haircut light skin tone,E4.0
People-Body,person-activity,1F487 1F3FC 200D 2642 FE0F,fully-qualified,💇🏼‍♂️,man getting haircut medium-light skin tone,E4.0
People-Body,person-activity,1F487 1F3FC 200D 2642,minimally-qualified,💇🏼‍♂,man getting haircut medium-light skin tone,E4.0
People-Body,person-activity,1F487 1F3FD 200D 2642 FE0F,fully-qualified,💇🏽‍♂️,man getting haircut medium skin tone,E4.0
People-Body,person-activity,1F487 1F3FD 200D 2642,minimally-qualified,💇🏽‍♂,man getting haircut medium skin tone,E4.0
People-Body,person-activity,1F487 1F3FE 200D 2642 FE0F,fully-qualified,💇🏾‍♂️,man getting haircut medium-dark skin tone,E4.0
People-Body,person-activity,1F487 1F3FE 200D 2642,minimally-qualified,💇🏾‍♂,man getting haircut medium-dark skin tone,E4.0
People-Body,person-activity,1F487 1F3FF 200D 2642 FE0F,fully-qualified,💇🏿‍♂️,man getting haircut dark skin tone,E4.0
People-Body,person-activity,1F487 1F3FF 200D 2642,minimally-qualified,💇🏿‍♂,man getting haircut dark skin tone,E4.0
People-Body,person-activity,1F487 200D 2640 FE0F,fully-qualified,💇‍♀️,woman getting haircut,E4.0
People-Body,person-activity,1F487 200D 2640,minimally-qualified,💇‍♀,woman getting haircut,E4.0
People-Body,person-activity,1F487 1F3FB 200D 2640 FE0F,fully-qualified,💇🏻‍♀️,woman getting haircut light skin tone,E4.0
People-Body,person-activity,1F487 1F3FB 200D 2640,minimally-qualified,💇🏻‍♀,woman getting haircut light skin tone,E4.0
People-Body,person-activity,1F487 1F3FC 200D 2640 FE0F,fully-qualified,💇🏼‍♀️,woman getting haircut medium-light skin tone,E4.0
People-Body,person-activity,1F487 1F3FC 200D 2640,minimally-qualified,💇🏼‍♀,woman getting haircut medium-light skin tone,E4.0
People-Body,person-activity,1F487 1F3FD 200D 2640 FE0F,fully-qualified,💇🏽‍♀️,woman getting haircut medium skin tone,E4.0
People-Body,person-activity,1F487 1F3FD 200D 2640,minimally-qualified,💇🏽‍♀,woman getting haircut medium skin tone,E4.0
People-Body,person-activity,1F487 1F3FE 200D 2640 FE0F,fully-qualified,💇🏾‍♀️,woman getting haircut medium-dark skin tone,E4.0
People-Body,person-activity,1F487 1F3FE 200D 2640,minimally-qualified,💇🏾‍♀,woman getting haircut medium-dark skin tone,E4.0
People-Body,person-activity,1F487 1F3FF 200D 2640 FE0F,fully-qualified,💇🏿‍♀️,woman getting haircut dark skin tone,E4.0
People-Body,person-activity,1F487 1F3FF 200D 2640,minimally-qualified,💇🏿‍♀,woman getting haircut dark skin tone,E4.0
People-Body,person-activity,1F6B6,fully-qualified,🚶,person walking,E0.6
People-Body,person-activity,1F6B6 1F3FB,fully-qualified,🚶🏻,person walking light skin tone,E1.0
People-Body,person-activity,1F6B6 1F3FC,fully-qualified,🚶🏼,person walking medium-light skin tone,E1.0
People-Body,person-activity,1F6B6 1F3FD,fully-qualified,🚶🏽,person walking medium skin tone,E1.0
People-Body,person-activity,1F6B6 1F3FE,fully-qualified,🚶🏾,person walking medium-dark skin tone,E1.0
People-Body,person-activity,1F6B6 1F3FF,fully-qualified,🚶🏿,person walking dark skin tone,E1.0
People-Body,person-activity,1F6B6 200D 2642 FE0F,fully-qualified,🚶‍♂️,man walking,E4.0
People-Body,person-activity,1F6B6 200D 2642,minimally-qualified,🚶‍♂,man walking,E4.0
People-Body,person-activity,1F6B6 1F3FB 200D 2642 FE0F,fully-qualified,🚶🏻‍♂️,man walking light skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FB 200D 2642,minimally-qualified,🚶🏻‍♂,man walking light skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FC 200D 2642 FE0F,fully-qualified,🚶🏼‍♂️,man walking medium-light skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FC 200D 2642,minimally-qualified,🚶🏼‍♂,man walking medium-light skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FD 200D 2642 FE0F,fully-qualified,🚶🏽‍♂️,man walking medium skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FD 200D 2642,minimally-qualified,🚶🏽‍♂,man walking medium skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FE 200D 2642 FE0F,fully-qualified,🚶🏾‍♂️,man walking medium-dark skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FE 200D 2642,minimally-qualified,🚶🏾‍♂,man walking medium-dark skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FF 200D 2642 FE0F,fully-qualified,🚶🏿‍♂️,man walking dark skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FF 200D 2642,minimally-qualified,🚶🏿‍♂,man walking dark skin tone,E4.0
People-Body,person-activity,1F6B6 200D 2640 FE0F,fully-qualified,🚶‍♀️,woman walking,E4.0
People-Body,person-activity,1F6B6 200D 2640,minimally-qualified,🚶‍♀,woman walking,E4.0
People-Body,person-activity,1F6B6 1F3FB 200D 2640 FE0F,fully-qualified,🚶🏻‍♀️,woman walking light skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FB 200D 2640,minimally-qualified,🚶🏻‍♀,woman walking light skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FC 200D 2640 FE0F,fully-qualified,🚶🏼‍♀️,woman walking medium-light skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FC 200D 2640,minimally-qualified,🚶🏼‍♀,woman walking medium-light skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FD 200D 2640 FE0F,fully-qualified,🚶🏽‍♀️,woman walking medium skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FD 200D 2640,minimally-qualified,🚶🏽‍♀,woman walking medium skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FE 200D 2640 FE0F,fully-qualified,🚶🏾‍♀️,woman walking medium-dark skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FE 200D 2640,minimally-qualified,🚶🏾‍♀,woman walking medium-dark skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FF 200D 2640 FE0F,fully-qualified,🚶🏿‍♀️,woman walking dark skin tone,E4.0
People-Body,person-activity,1F6B6 1F3FF 200D 2640,minimally-qualified,🚶🏿‍♀,woman walking dark skin tone,E4.0
People-Body,person-activity,1F6B6 200D 27A1 FE0F,fully-qualified,🚶‍➡️,person walking facing right,E15.1
People-Body,person-activity,1F6B6 200D 27A1,minimally-qualified,🚶‍➡,person walking facing right,E15.1
People-Body,person-activity,1F6B6 1F3FB 200D 27A1 FE0F,fully-qualified,🚶🏻‍➡️,person walking facing right light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FB 200D 27A1,minimally-qualified,🚶🏻‍➡,person walking facing right light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FC 200D 27A1 FE0F,fully-qualified,🚶🏼‍➡️,person walking facing right medium-light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FC 200D 27A1,minimally-qualified,🚶🏼‍➡,person walking facing right medium-light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FD 200D 27A1 FE0F,fully-qualified,🚶🏽‍➡️,person walking facing right medium skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FD 200D 27A1,minimally-qualified,🚶🏽‍➡,person walking facing right medium skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FE 200D 27A1 FE0F,fully-qualified,🚶🏾‍➡️,person walking facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FE 200D 27A1,minimally-qualified,🚶🏾‍➡,person walking facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FF 200D 27A1 FE0F,fully-qualified,🚶🏿‍➡️,person walking facing right dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FF 200D 27A1,minimally-qualified,🚶🏿‍➡,person walking facing right dark skin tone,E15.1
People-Body,person-activity,1F6B6 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🚶‍♀️‍➡️,woman walking facing right,E15.1
People-Body,person-activity,1F6B6 200D 2640 200D 27A1 FE0F,minimally-qualified,🚶‍♀‍➡️,woman walking facing right,E15.1
People-Body,person-activity,1F6B6 200D 2640 FE0F 200D 27A1,minimally-qualified,🚶‍♀️‍➡,woman walking facing right,E15.1
People-Body,person-activity,1F6B6 200D 2640 200D 27A1,minimally-qualified,🚶‍♀‍➡,woman walking facing right,E15.1
People-Body,person-activity,1F6B6 1F3FB 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🚶🏻‍♀️‍➡️,woman walking facing right light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FB 200D 2640 200D 27A1 FE0F,minimally-qualified,🚶🏻‍♀‍➡️,woman walking facing right light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FB 200D 2640 FE0F 200D 27A1,minimally-qualified,🚶🏻‍♀️‍➡,woman walking facing right light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FB 200D 2640 200D 27A1,minimally-qualified,🚶🏻‍♀‍➡,woman walking facing right light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FC 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🚶🏼‍♀️‍➡️,woman walking facing right medium-light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FC 200D 2640 200D 27A1 FE0F,minimally-qualified,🚶🏼‍♀‍➡️,woman walking facing right medium-light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FC 200D 2640 FE0F 200D 27A1,minimally-qualified,🚶🏼‍♀️‍➡,woman walking facing right medium-light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FC 200D 2640 200D 27A1,minimally-qualified,🚶🏼‍♀‍➡,woman walking facing right medium-light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FD 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🚶🏽‍♀️‍➡️,woman walking facing right medium skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FD 200D 2640 200D 27A1 FE0F,minimally-qualified,🚶🏽‍♀‍➡️,woman walking facing right medium skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FD 200D 2640 FE0F 200D 27A1,minimally-qualified,🚶🏽‍♀️‍➡,woman walking facing right medium skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FD 200D 2640 200D 27A1,minimally-qualified,🚶🏽‍♀‍➡,woman walking facing right medium skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FE 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🚶🏾‍♀️‍➡️,woman walking facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FE 200D 2640 200D 27A1 FE0F,minimally-qualified,🚶🏾‍♀‍➡️,woman walking facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FE 200D 2640 FE0F 200D 27A1,minimally-qualified,🚶🏾‍♀️‍➡,woman walking facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FE 200D 2640 200D 27A1,minimally-qualified,🚶🏾‍♀‍➡,woman walking facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FF 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🚶🏿‍♀️‍➡️,woman walking facing right dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FF 200D 2640 200D 27A1 FE0F,minimally-qualified,🚶🏿‍♀‍➡️,woman walking facing right dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FF 200D 2640 FE0F 200D 27A1,minimally-qualified,🚶🏿‍♀️‍➡,woman walking facing right dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FF 200D 2640 200D 27A1,minimally-qualified,🚶🏿‍♀‍➡,woman walking facing right dark skin tone,E15.1
People-Body,person-activity,1F6B6 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🚶‍♂️‍➡️,man walking facing right,E15.1
People-Body,person-activity,1F6B6 200D 2642 200D 27A1 FE0F,minimally-qualified,🚶‍♂‍➡️,man walking facing right,E15.1
People-Body,person-activity,1F6B6 200D 2642 FE0F 200D 27A1,minimally-qualified,🚶‍♂️‍➡,man walking facing right,E15.1
People-Body,person-activity,1F6B6 200D 2642 200D 27A1,minimally-qualified,🚶‍♂‍➡,man walking facing right,E15.1
People-Body,person-activity,1F6B6 1F3FB 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🚶🏻‍♂️‍➡️,man walking facing right light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FB 200D 2642 200D 27A1 FE0F,minimally-qualified,🚶🏻‍♂‍➡️,man walking facing right light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FB 200D 2642 FE0F 200D 27A1,minimally-qualified,🚶🏻‍♂️‍➡,man walking facing right light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FB 200D 2642 200D 27A1,minimally-qualified,🚶🏻‍♂‍➡,man walking facing right light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FC 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🚶🏼‍♂️‍➡️,man walking facing right medium-light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FC 200D 2642 200D 27A1 FE0F,minimally-qualified,🚶🏼‍♂‍➡️,man walking facing right medium-light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FC 200D 2642 FE0F 200D 27A1,minimally-qualified,🚶🏼‍♂️‍➡,man walking facing right medium-light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FC 200D 2642 200D 27A1,minimally-qualified,🚶🏼‍♂‍➡,man walking facing right medium-light skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FD 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🚶🏽‍♂️‍➡️,man walking facing right medium skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FD 200D 2642 200D 27A1 FE0F,minimally-qualified,🚶🏽‍♂‍➡️,man walking facing right medium skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FD 200D 2642 FE0F 200D 27A1,minimally-qualified,🚶🏽‍♂️‍➡,man walking facing right medium skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FD 200D 2642 200D 27A1,minimally-qualified,🚶🏽‍♂‍➡,man walking facing right medium skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FE 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🚶🏾‍♂️‍➡️,man walking facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FE 200D 2642 200D 27A1 FE0F,minimally-qualified,🚶🏾‍♂‍➡️,man walking facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FE 200D 2642 FE0F 200D 27A1,minimally-qualified,🚶🏾‍♂️‍➡,man walking facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FE 200D 2642 200D 27A1,minimally-qualified,🚶🏾‍♂‍➡,man walking facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FF 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🚶🏿‍♂️‍➡️,man walking facing right dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FF 200D 2642 200D 27A1 FE0F,minimally-qualified,🚶🏿‍♂‍➡️,man walking facing right dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FF 200D 2642 FE0F 200D 27A1,minimally-qualified,🚶🏿‍♂️‍➡,man walking facing right dark skin tone,E15.1
People-Body,person-activity,1F6B6 1F3FF 200D 2642 200D 27A1,minimally-qualified,🚶🏿‍♂‍➡,man walking facing right dark skin tone,E15.1
People-Body,person-activity,1F9CD,fully-qualified,🧍,person standing,E12.0
People-Body,person-activity,1F9CD 1F3FB,fully-qualified,🧍🏻,person standing light skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FC,fully-qualified,🧍🏼,person standing medium-light skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FD,fully-qualified,🧍🏽,person standing medium skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FE,fully-qualified,🧍🏾,person standing medium-dark skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FF,fully-qualified,🧍🏿,person standing dark skin tone,E12.0
People-Body,person-activity,1F9CD 200D 2642 FE0F,fully-qualified,🧍‍♂️,man standing,E12.0
People-Body,person-activity,1F9CD 200D 2642,minimally-qualified,🧍‍♂,man standing,E12.0
People-Body,person-activity,1F9CD 1F3FB 200D 2642 FE0F,fully-qualified,🧍🏻‍♂️,man standing light skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FB 200D 2642,minimally-qualified,🧍🏻‍♂,man standing light skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FC 200D 2642 FE0F,fully-qualified,🧍🏼‍♂️,man standing medium-light skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FC 200D 2642,minimally-qualified,🧍🏼‍♂,man standing medium-light skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FD 200D 2642 FE0F,fully-qualified,🧍🏽‍♂️,man standing medium skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FD 200D 2642,minimally-qualified,🧍🏽‍♂,man standing medium skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FE 200D 2642 FE0F,fully-qualified,🧍🏾‍♂️,man standing medium-dark skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FE 200D 2642,minimally-qualified,🧍🏾‍♂,man standing medium-dark skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FF 200D 2642 FE0F,fully-qualified,🧍🏿‍♂️,man standing dark skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FF 200D 2642,minimally-qualified,🧍🏿‍♂,man standing dark skin tone,E12.0
People-Body,person-activity,1F9CD 200D 2640 FE0F,fully-qualified,🧍‍♀️,woman standing,E12.0
People-Body,person-activity,1F9CD 200D 2640,minimally-qualified,🧍‍♀,woman standing,E12.0
People-Body,person-activity,1F9CD 1F3FB 200D 2640 FE0F,fully-qualified,🧍🏻‍♀️,woman standing light skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FB 200D 2640,minimally-qualified,🧍🏻‍♀,woman standing light skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FC 200D 2640 FE0F,fully-qualified,🧍🏼‍♀️,woman standing medium-light skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FC 200D 2640,minimally-qualified,🧍🏼‍♀,woman standing medium-light skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FD 200D 2640 FE0F,fully-qualified,🧍🏽‍♀️,woman standing medium skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FD 200D 2640,minimally-qualified,🧍🏽‍♀,woman standing medium skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FE 200D 2640 FE0F,fully-qualified,🧍🏾‍♀️,woman standing medium-dark skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FE 200D 2640,minimally-qualified,🧍🏾‍♀,woman standing medium-dark skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FF 200D 2640 FE0F,fully-qualified,🧍🏿‍♀️,woman standing dark skin tone,E12.0
People-Body,person-activity,1F9CD 1F3FF 200D 2640,minimally-qualified,🧍🏿‍♀,woman standing dark skin tone,E12.0
People-Body,person-activity,1F9CE,fully-qualified,🧎,person kneeling,E12.0
People-Body,person-activity,1F9CE 1F3FB,fully-qualified,🧎🏻,person kneeling light skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FC,fully-qualified,🧎🏼,person kneeling medium-light skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FD,fully-qualified,🧎🏽,person kneeling medium skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FE,fully-qualified,🧎🏾,person kneeling medium-dark skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FF,fully-qualified,🧎🏿,person kneeling dark skin tone,E12.0
People-Body,person-activity,1F9CE 200D 2642 FE0F,fully-qualified,🧎‍♂️,man kneeling,E12.0
People-Body,person-activity,1F9CE 200D 2642,minimally-qualified,🧎‍♂,man kneeling,E12.0
People-Body,person-activity,1F9CE 1F3FB 200D 2642 FE0F,fully-qualified,🧎🏻‍♂️,man kneeling light skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FB 200D 2642,minimally-qualified,🧎🏻‍♂,man kneeling light skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FC 200D 2642 FE0F,fully-qualified,🧎🏼‍♂️,man kneeling medium-light skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FC 200D 2642,minimally-qualified,🧎🏼‍♂,man kneeling medium-light skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FD 200D 2642 FE0F,fully-qualified,🧎🏽‍♂️,man kneeling medium skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FD 200D 2642,minimally-qualified,🧎🏽‍♂,man kneeling medium skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FE 200D 2642 FE0F,fully-qualified,🧎🏾‍♂️,man kneeling medium-dark skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FE 200D 2642,minimally-qualified,🧎🏾‍♂,man kneeling medium-dark skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FF 200D 2642 FE0F,fully-qualified,🧎🏿‍♂️,man kneeling dark skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FF 200D 2642,minimally-qualified,🧎🏿‍♂,man kneeling dark skin tone,E12.0
People-Body,person-activity,1F9CE 200D 2640 FE0F,fully-qualified,🧎‍♀️,woman kneeling,E12.0
People-Body,person-activity,1F9CE 200D 2640,minimally-qualified,🧎‍♀,woman kneeling,E12.0
People-Body,person-activity,1F9CE 1F3FB 200D 2640 FE0F,fully-qualified,🧎🏻‍♀️,woman kneeling light skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FB 200D 2640,minimally-qualified,🧎🏻‍♀,woman kneeling light skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FC 200D 2640 FE0F,fully-qualified,🧎🏼‍♀️,woman kneeling medium-light skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FC 200D 2640,minimally-qualified,🧎🏼‍♀,woman kneeling medium-light skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FD 200D 2640 FE0F,fully-qualified,🧎🏽‍♀️,woman kneeling medium skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FD 200D 2640,minimally-qualified,🧎🏽‍♀,woman kneeling medium skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FE 200D 2640 FE0F,fully-qualified,🧎🏾‍♀️,woman kneeling medium-dark skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FE 200D 2640,minimally-qualified,🧎🏾‍♀,woman kneeling medium-dark skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FF 200D 2640 FE0F,fully-qualified,🧎🏿‍♀️,woman kneeling dark skin tone,E12.0
People-Body,person-activity,1F9CE 1F3FF 200D 2640,minimally-qualified,🧎🏿‍♀,woman kneeling dark skin tone,E12.0
People-Body,person-activity,1F9CE 200D 27A1 FE0F,fully-qualified,🧎‍➡️,person kneeling facing right,E15.1
People-Body,person-activity,1F9CE 200D 27A1,minimally-qualified,🧎‍➡,person kneeling facing right,E15.1
People-Body,person-activity,1F9CE 1F3FB 200D 27A1 FE0F,fully-qualified,🧎🏻‍➡️,person kneeling facing right light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FB 200D 27A1,minimally-qualified,🧎🏻‍➡,person kneeling facing right light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FC 200D 27A1 FE0F,fully-qualified,🧎🏼‍➡️,person kneeling facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FC 200D 27A1,minimally-qualified,🧎🏼‍➡,person kneeling facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FD 200D 27A1 FE0F,fully-qualified,🧎🏽‍➡️,person kneeling facing right medium skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FD 200D 27A1,minimally-qualified,🧎🏽‍➡,person kneeling facing right medium skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FE 200D 27A1 FE0F,fully-qualified,🧎🏾‍➡️,person kneeling facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FE 200D 27A1,minimally-qualified,🧎🏾‍➡,person kneeling facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FF 200D 27A1 FE0F,fully-qualified,🧎🏿‍➡️,person kneeling facing right dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FF 200D 27A1,minimally-qualified,🧎🏿‍➡,person kneeling facing right dark skin tone,E15.1
People-Body,person-activity,1F9CE 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🧎‍♀️‍➡️,woman kneeling facing right,E15.1
People-Body,person-activity,1F9CE 200D 2640 200D 27A1 FE0F,minimally-qualified,🧎‍♀‍➡️,woman kneeling facing right,E15.1
People-Body,person-activity,1F9CE 200D 2640 FE0F 200D 27A1,minimally-qualified,🧎‍♀️‍➡,woman kneeling facing right,E15.1
People-Body,person-activity,1F9CE 200D 2640 200D 27A1,minimally-qualified,🧎‍♀‍➡,woman kneeling facing right,E15.1
People-Body,person-activity,1F9CE 1F3FB 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🧎🏻‍♀️‍➡️,woman kneeling facing right light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FB 200D 2640 200D 27A1 FE0F,minimally-qualified,🧎🏻‍♀‍➡️,woman kneeling facing right light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FB 200D 2640 FE0F 200D 27A1,minimally-qualified,🧎🏻‍♀️‍➡,woman kneeling facing right light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FB 200D 2640 200D 27A1,minimally-qualified,🧎🏻‍♀‍➡,woman kneeling facing right light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FC 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🧎🏼‍♀️‍➡️,woman kneeling facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FC 200D 2640 200D 27A1 FE0F,minimally-qualified,🧎🏼‍♀‍➡️,woman kneeling facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FC 200D 2640 FE0F 200D 27A1,minimally-qualified,🧎🏼‍♀️‍➡,woman kneeling facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FC 200D 2640 200D 27A1,minimally-qualified,🧎🏼‍♀‍➡,woman kneeling facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FD 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🧎🏽‍♀️‍➡️,woman kneeling facing right medium skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FD 200D 2640 200D 27A1 FE0F,minimally-qualified,🧎🏽‍♀‍➡️,woman kneeling facing right medium skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FD 200D 2640 FE0F 200D 27A1,minimally-qualified,🧎🏽‍♀️‍➡,woman kneeling facing right medium skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FD 200D 2640 200D 27A1,minimally-qualified,🧎🏽‍♀‍➡,woman kneeling facing right medium skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FE 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🧎🏾‍♀️‍➡️,woman kneeling facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FE 200D 2640 200D 27A1 FE0F,minimally-qualified,🧎🏾‍♀‍➡️,woman kneeling facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FE 200D 2640 FE0F 200D 27A1,minimally-qualified,🧎🏾‍♀️‍➡,woman kneeling facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FE 200D 2640 200D 27A1,minimally-qualified,🧎🏾‍♀‍➡,woman kneeling facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FF 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🧎🏿‍♀️‍➡️,woman kneeling facing right dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FF 200D 2640 200D 27A1 FE0F,minimally-qualified,🧎🏿‍♀‍➡️,woman kneeling facing right dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FF 200D 2640 FE0F 200D 27A1,minimally-qualified,🧎🏿‍♀️‍➡,woman kneeling facing right dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FF 200D 2640 200D 27A1,minimally-qualified,🧎🏿‍♀‍➡,woman kneeling facing right dark skin tone,E15.1
People-Body,person-activity,1F9CE 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🧎‍♂️‍➡️,man kneeling facing right,E15.1
People-Body,person-activity,1F9CE 200D 2642 200D 27A1 FE0F,minimally-qualified,🧎‍♂‍➡️,man kneeling facing right,E15.1
People-Body,person-activity,1F9CE 200D 2642 FE0F 200D 27A1,minimally-qualified,🧎‍♂️‍➡,man kneeling facing right,E15.1
People-Body,person-activity,1F9CE 200D 2642 200D 27A1,minimally-qualified,🧎‍♂‍➡,man kneeling facing right,E15.1
People-Body,person-activity,1F9CE 1F3FB 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🧎🏻‍♂️‍➡️,man kneeling facing right light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FB 200D 2642 200D 27A1 FE0F,minimally-qualified,🧎🏻‍♂‍➡️,man kneeling facing right light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FB 200D 2642 FE0F 200D 27A1,minimally-qualified,🧎🏻‍♂️‍➡,man kneeling facing right light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FB 200D 2642 200D 27A1,minimally-qualified,🧎🏻‍♂‍➡,man kneeling facing right light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FC 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🧎🏼‍♂️‍➡️,man kneeling facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FC 200D 2642 200D 27A1 FE0F,minimally-qualified,🧎🏼‍♂‍➡️,man kneeling facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FC 200D 2642 FE0F 200D 27A1,minimally-qualified,🧎🏼‍♂️‍➡,man kneeling facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FC 200D 2642 200D 27A1,minimally-qualified,🧎🏼‍♂‍➡,man kneeling facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FD 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🧎🏽‍♂️‍➡️,man kneeling facing right medium skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FD 200D 2642 200D 27A1 FE0F,minimally-qualified,🧎🏽‍♂‍➡️,man kneeling facing right medium skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FD 200D 2642 FE0F 200D 27A1,minimally-qualified,🧎🏽‍♂️‍➡,man kneeling facing right medium skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FD 200D 2642 200D 27A1,minimally-qualified,🧎🏽‍♂‍➡,man kneeling facing right medium skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FE 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🧎🏾‍♂️‍➡️,man kneeling facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FE 200D 2642 200D 27A1 FE0F,minimally-qualified,🧎🏾‍♂‍➡️,man kneeling facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FE 200D 2642 FE0F 200D 27A1,minimally-qualified,🧎🏾‍♂️‍➡,man kneeling facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FE 200D 2642 200D 27A1,minimally-qualified,🧎🏾‍♂‍➡,man kneeling facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FF 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🧎🏿‍♂️‍➡️,man kneeling facing right dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FF 200D 2642 200D 27A1 FE0F,minimally-qualified,🧎🏿‍♂‍➡️,man kneeling facing right dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FF 200D 2642 FE0F 200D 27A1,minimally-qualified,🧎🏿‍♂️‍➡,man kneeling facing right dark skin tone,E15.1
People-Body,person-activity,1F9CE 1F3FF 200D 2642 200D 27A1,minimally-qualified,🧎🏿‍♂‍➡,man kneeling facing right dark skin tone,E15.1
People-Body,person-activity,1F9D1 200D 1F9AF,fully-qualified,🧑‍🦯,person with white cane,E12.1
People-Body,person-activity,1F9D1 1F3FB 200D 1F9AF,fully-qualified,🧑🏻‍🦯,person with white cane light skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FC 200D 1F9AF,fully-qualified,🧑🏼‍🦯,person with white cane medium-light skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FD 200D 1F9AF,fully-qualified,🧑🏽‍🦯,person with white cane medium skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FE 200D 1F9AF,fully-qualified,🧑🏾‍🦯,person with white cane medium-dark skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FF 200D 1F9AF,fully-qualified,🧑🏿‍🦯,person with white cane dark skin tone,E12.1
People-Body,person-activity,1F9D1 200D 1F9AF 200D 27A1 FE0F,fully-qualified,🧑‍🦯‍➡️,person with white cane facing right,E15.1
People-Body,person-activity,1F9D1 200D 1F9AF 200D 27A1,minimally-qualified,🧑‍🦯‍➡,person with white cane facing right,E15.1
People-Body,person-activity,1F9D1 1F3FB 200D 1F9AF 200D 27A1 FE0F,fully-qualified,🧑🏻‍🦯‍➡️,person with white cane facing right light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FB 200D 1F9AF 200D 27A1,minimally-qualified,🧑🏻‍🦯‍➡,person with white cane facing right light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FC 200D 1F9AF 200D 27A1 FE0F,fully-qualified,🧑🏼‍🦯‍➡️,person with white cane facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FC 200D 1F9AF 200D 27A1,minimally-qualified,🧑🏼‍🦯‍➡,person with white cane facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FD 200D 1F9AF 200D 27A1 FE0F,fully-qualified,🧑🏽‍🦯‍➡️,person with white cane facing right medium skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FD 200D 1F9AF 200D 27A1,minimally-qualified,🧑🏽‍🦯‍➡,person with white cane facing right medium skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FE 200D 1F9AF 200D 27A1 FE0F,fully-qualified,🧑🏾‍🦯‍➡️,person with white cane facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FE 200D 1F9AF 200D 27A1,minimally-qualified,🧑🏾‍🦯‍➡,person with white cane facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FF 200D 1F9AF 200D 27A1 FE0F,fully-qualified,🧑🏿‍🦯‍➡️,person with white cane facing right dark skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FF 200D 1F9AF 200D 27A1,minimally-qualified,🧑🏿‍🦯‍➡,person with white cane facing right dark skin tone,E15.1
People-Body,person-activity,1F468 200D 1F9AF,fully-qualified,👨‍🦯,man with white cane,E12.0
People-Body,person-activity,1F468 1F3FB 200D 1F9AF,fully-qualified,👨🏻‍🦯,man with white cane light skin tone,E12.0
People-Body,person-activity,1F468 1F3FC 200D 1F9AF,fully-qualified,👨🏼‍🦯,man with white cane medium-light skin tone,E12.0
People-Body,person-activity,1F468 1F3FD 200D 1F9AF,fully-qualified,👨🏽‍🦯,man with white cane medium skin tone,E12.0
People-Body,person-activity,1F468 1F3FE 200D 1F9AF,fully-qualified,👨🏾‍🦯,man with white cane medium-dark skin tone,E12.0
People-Body,person-activity,1F468 1F3FF 200D 1F9AF,fully-qualified,👨🏿‍🦯,man with white cane dark skin tone,E12.0
People-Body,person-activity,1F468 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👨‍🦯‍➡️,man with white cane facing right,E15.1
People-Body,person-activity,1F468 200D 1F9AF 200D 27A1,minimally-qualified,👨‍🦯‍➡,man with white cane facing right,E15.1
People-Body,person-activity,1F468 1F3FB 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👨🏻‍🦯‍➡️,man with white cane facing right light skin tone,E15.1
People-Body,person-activity,1F468 1F3FB 200D 1F9AF 200D 27A1,minimally-qualified,👨🏻‍🦯‍➡,man with white cane facing right light skin tone,E15.1
People-Body,person-activity,1F468 1F3FC 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👨🏼‍🦯‍➡️,man with white cane facing right medium-light skin tone,E15.1
People-Body,person-activity,1F468 1F3FC 200D 1F9AF 200D 27A1,minimally-qualified,👨🏼‍🦯‍➡,man with white cane facing right medium-light skin tone,E15.1
People-Body,person-activity,1F468 1F3FD 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👨🏽‍🦯‍➡️,man with white cane facing right medium skin tone,E15.1
People-Body,person-activity,1F468 1F3FD 200D 1F9AF 200D 27A1,minimally-qualified,👨🏽‍🦯‍➡,man with white cane facing right medium skin tone,E15.1
People-Body,person-activity,1F468 1F3FE 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👨🏾‍🦯‍➡️,man with white cane facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F468 1F3FE 200D 1F9AF 200D 27A1,minimally-qualified,👨🏾‍🦯‍➡,man with white cane facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F468 1F3FF 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👨🏿‍🦯‍➡️,man with white cane facing right dark skin tone,E15.1
People-Body,person-activity,1F468 1F3FF 200D 1F9AF 200D 27A1,minimally-qualified,👨🏿‍🦯‍➡,man with white cane facing right dark skin tone,E15.1
People-Body,person-activity,1F469 200D 1F9AF,fully-qualified,👩‍🦯,woman with white cane,E12.0
People-Body,person-activity,1F469 1F3FB 200D 1F9AF,fully-qualified,👩🏻‍🦯,woman with white cane light skin tone,E12.0
People-Body,person-activity,1F469 1F3FC 200D 1F9AF,fully-qualified,👩🏼‍🦯,woman with white cane medium-light skin tone,E12.0
People-Body,person-activity,1F469 1F3FD 200D 1F9AF,fully-qualified,👩🏽‍🦯,woman with white cane medium skin tone,E12.0
People-Body,person-activity,1F469 1F3FE 200D 1F9AF,fully-qualified,👩🏾‍🦯,woman with white cane medium-dark skin tone,E12.0
People-Body,person-activity,1F469 1F3FF 200D 1F9AF,fully-qualified,👩🏿‍🦯,woman with white cane dark skin tone,E12.0
People-Body,person-activity,1F469 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👩‍🦯‍➡️,woman with white cane facing right,E15.1
People-Body,person-activity,1F469 200D 1F9AF 200D 27A1,minimally-qualified,👩‍🦯‍➡,woman with white cane facing right,E15.1
People-Body,person-activity,1F469 1F3FB 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👩🏻‍🦯‍➡️,woman with white cane facing right light skin tone,E15.1
People-Body,person-activity,1F469 1F3FB 200D 1F9AF 200D 27A1,minimally-qualified,👩🏻‍🦯‍➡,woman with white cane facing right light skin tone,E15.1
People-Body,person-activity,1F469 1F3FC 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👩🏼‍🦯‍➡️,woman with white cane facing right medium-light skin tone,E15.1
People-Body,person-activity,1F469 1F3FC 200D 1F9AF 200D 27A1,minimally-qualified,👩🏼‍🦯‍➡,woman with white cane facing right medium-light skin tone,E15.1
People-Body,person-activity,1F469 1F3FD 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👩🏽‍🦯‍➡️,woman with white cane facing right medium skin tone,E15.1
People-Body,person-activity,1F469 1F3FD 200D 1F9AF 200D 27A1,minimally-qualified,👩🏽‍🦯‍➡,woman with white cane facing right medium skin tone,E15.1
People-Body,person-activity,1F469 1F3FE 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👩🏾‍🦯‍➡️,woman with white cane facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F469 1F3FE 200D 1F9AF 200D 27A1,minimally-qualified,👩🏾‍🦯‍➡,woman with white cane facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F469 1F3FF 200D 1F9AF 200D 27A1 FE0F,fully-qualified,👩🏿‍🦯‍➡️,woman with white cane facing right dark skin tone,E15.1
People-Body,person-activity,1F469 1F3FF 200D 1F9AF 200D 27A1,minimally-qualified,👩🏿‍🦯‍➡,woman with white cane facing right dark skin tone,E15.1
People-Body,person-activity,1F9D1 200D 1F9BC,fully-qualified,🧑‍🦼,person in motorized wheelchair,E12.1
People-Body,person-activity,1F9D1 1F3FB 200D 1F9BC,fully-qualified,🧑🏻‍🦼,person in motorized wheelchair light skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FC 200D 1F9BC,fully-qualified,🧑🏼‍🦼,person in motorized wheelchair medium-light skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FD 200D 1F9BC,fully-qualified,🧑🏽‍🦼,person in motorized wheelchair medium skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FE 200D 1F9BC,fully-qualified,🧑🏾‍🦼,person in motorized wheelchair medium-dark skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FF 200D 1F9BC,fully-qualified,🧑🏿‍🦼,person in motorized wheelchair dark skin tone,E12.1
People-Body,person-activity,1F9D1 200D 1F9BC 200D 27A1 FE0F,fully-qualified,🧑‍🦼‍➡️,person in motorized wheelchair facing right,E15.1
People-Body,person-activity,1F9D1 200D 1F9BC 200D 27A1,minimally-qualified,🧑‍🦼‍➡,person in motorized wheelchair facing right,E15.1
People-Body,person-activity,1F9D1 1F3FB 200D 1F9BC 200D 27A1 FE0F,fully-qualified,🧑🏻‍🦼‍➡️,person in motorized wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FB 200D 1F9BC 200D 27A1,minimally-qualified,🧑🏻‍🦼‍➡,person in motorized wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FC 200D 1F9BC 200D 27A1 FE0F,fully-qualified,🧑🏼‍🦼‍➡️,person in motorized wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FC 200D 1F9BC 200D 27A1,minimally-qualified,🧑🏼‍🦼‍➡,person in motorized wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FD 200D 1F9BC 200D 27A1 FE0F,fully-qualified,🧑🏽‍🦼‍➡️,person in motorized wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FD 200D 1F9BC 200D 27A1,minimally-qualified,🧑🏽‍🦼‍➡,person in motorized wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FE 200D 1F9BC 200D 27A1 FE0F,fully-qualified,🧑🏾‍🦼‍➡️,person in motorized wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FE 200D 1F9BC 200D 27A1,minimally-qualified,🧑🏾‍🦼‍➡,person in motorized wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FF 200D 1F9BC 200D 27A1 FE0F,fully-qualified,🧑🏿‍🦼‍➡️,person in motorized wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FF 200D 1F9BC 200D 27A1,minimally-qualified,🧑🏿‍🦼‍➡,person in motorized wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F468 200D 1F9BC,fully-qualified,👨‍🦼,man in motorized wheelchair,E12.0
People-Body,person-activity,1F468 1F3FB 200D 1F9BC,fully-qualified,👨🏻‍🦼,man in motorized wheelchair light skin tone,E12.0
People-Body,person-activity,1F468 1F3FC 200D 1F9BC,fully-qualified,👨🏼‍🦼,man in motorized wheelchair medium-light skin tone,E12.0
People-Body,person-activity,1F468 1F3FD 200D 1F9BC,fully-qualified,👨🏽‍🦼,man in motorized wheelchair medium skin tone,E12.0
People-Body,person-activity,1F468 1F3FE 200D 1F9BC,fully-qualified,👨🏾‍🦼,man in motorized wheelchair medium-dark skin tone,E12.0
People-Body,person-activity,1F468 1F3FF 200D 1F9BC,fully-qualified,👨🏿‍🦼,man in motorized wheelchair dark skin tone,E12.0
People-Body,person-activity,1F468 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👨‍🦼‍➡️,man in motorized wheelchair facing right,E15.1
People-Body,person-activity,1F468 200D 1F9BC 200D 27A1,minimally-qualified,👨‍🦼‍➡,man in motorized wheelchair facing right,E15.1
People-Body,person-activity,1F468 1F3FB 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👨🏻‍🦼‍➡️,man in motorized wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F468 1F3FB 200D 1F9BC 200D 27A1,minimally-qualified,👨🏻‍🦼‍➡,man in motorized wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F468 1F3FC 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👨🏼‍🦼‍➡️,man in motorized wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F468 1F3FC 200D 1F9BC 200D 27A1,minimally-qualified,👨🏼‍🦼‍➡,man in motorized wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F468 1F3FD 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👨🏽‍🦼‍➡️,man in motorized wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F468 1F3FD 200D 1F9BC 200D 27A1,minimally-qualified,👨🏽‍🦼‍➡,man in motorized wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F468 1F3FE 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👨🏾‍🦼‍➡️,man in motorized wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F468 1F3FE 200D 1F9BC 200D 27A1,minimally-qualified,👨🏾‍🦼‍➡,man in motorized wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F468 1F3FF 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👨🏿‍🦼‍➡️,man in motorized wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F468 1F3FF 200D 1F9BC 200D 27A1,minimally-qualified,👨🏿‍🦼‍➡,man in motorized wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F469 200D 1F9BC,fully-qualified,👩‍🦼,woman in motorized wheelchair,E12.0
People-Body,person-activity,1F469 1F3FB 200D 1F9BC,fully-qualified,👩🏻‍🦼,woman in motorized wheelchair light skin tone,E12.0
People-Body,person-activity,1F469 1F3FC 200D 1F9BC,fully-qualified,👩🏼‍🦼,woman in motorized wheelchair medium-light skin tone,E12.0
People-Body,person-activity,1F469 1F3FD 200D 1F9BC,fully-qualified,👩🏽‍🦼,woman in motorized wheelchair medium skin tone,E12.0
People-Body,person-activity,1F469 1F3FE 200D 1F9BC,fully-qualified,👩🏾‍🦼,woman in motorized wheelchair medium-dark skin tone,E12.0
People-Body,person-activity,1F469 1F3FF 200D 1F9BC,fully-qualified,👩🏿‍🦼,woman in motorized wheelchair dark skin tone,E12.0
People-Body,person-activity,1F469 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👩‍🦼‍➡️,woman in motorized wheelchair facing right,E15.1
People-Body,person-activity,1F469 200D 1F9BC 200D 27A1,minimally-qualified,👩‍🦼‍➡,woman in motorized wheelchair facing right,E15.1
People-Body,person-activity,1F469 1F3FB 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👩🏻‍🦼‍➡️,woman in motorized wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F469 1F3FB 200D 1F9BC 200D 27A1,minimally-qualified,👩🏻‍🦼‍➡,woman in motorized wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F469 1F3FC 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👩🏼‍🦼‍➡️,woman in motorized wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F469 1F3FC 200D 1F9BC 200D 27A1,minimally-qualified,👩🏼‍🦼‍➡,woman in motorized wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F469 1F3FD 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👩🏽‍🦼‍➡️,woman in motorized wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F469 1F3FD 200D 1F9BC 200D 27A1,minimally-qualified,👩🏽‍🦼‍➡,woman in motorized wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F469 1F3FE 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👩🏾‍🦼‍➡️,woman in motorized wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F469 1F3FE 200D 1F9BC 200D 27A1,minimally-qualified,👩🏾‍🦼‍➡,woman in motorized wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F469 1F3FF 200D 1F9BC 200D 27A1 FE0F,fully-qualified,👩🏿‍🦼‍➡️,woman in motorized wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F469 1F3FF 200D 1F9BC 200D 27A1,minimally-qualified,👩🏿‍🦼‍➡,woman in motorized wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F9D1 200D 1F9BD,fully-qualified,🧑‍🦽,person in manual wheelchair,E12.1
People-Body,person-activity,1F9D1 1F3FB 200D 1F9BD,fully-qualified,🧑🏻‍🦽,person in manual wheelchair light skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FC 200D 1F9BD,fully-qualified,🧑🏼‍🦽,person in manual wheelchair medium-light skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FD 200D 1F9BD,fully-qualified,🧑🏽‍🦽,person in manual wheelchair medium skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FE 200D 1F9BD,fully-qualified,🧑🏾‍🦽,person in manual wheelchair medium-dark skin tone,E12.1
People-Body,person-activity,1F9D1 1F3FF 200D 1F9BD,fully-qualified,🧑🏿‍🦽,person in manual wheelchair dark skin tone,E12.1
People-Body,person-activity,1F9D1 200D 1F9BD 200D 27A1 FE0F,fully-qualified,🧑‍🦽‍➡️,person in manual wheelchair facing right,E15.1
People-Body,person-activity,1F9D1 200D 1F9BD 200D 27A1,minimally-qualified,🧑‍🦽‍➡,person in manual wheelchair facing right,E15.1
People-Body,person-activity,1F9D1 1F3FB 200D 1F9BD 200D 27A1 FE0F,fully-qualified,🧑🏻‍🦽‍➡️,person in manual wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FB 200D 1F9BD 200D 27A1,minimally-qualified,🧑🏻‍🦽‍➡,person in manual wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FC 200D 1F9BD 200D 27A1 FE0F,fully-qualified,🧑🏼‍🦽‍➡️,person in manual wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FC 200D 1F9BD 200D 27A1,minimally-qualified,🧑🏼‍🦽‍➡,person in manual wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FD 200D 1F9BD 200D 27A1 FE0F,fully-qualified,🧑🏽‍🦽‍➡️,person in manual wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FD 200D 1F9BD 200D 27A1,minimally-qualified,🧑🏽‍🦽‍➡,person in manual wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FE 200D 1F9BD 200D 27A1 FE0F,fully-qualified,🧑🏾‍🦽‍➡️,person in manual wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FE 200D 1F9BD 200D 27A1,minimally-qualified,🧑🏾‍🦽‍➡,person in manual wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FF 200D 1F9BD 200D 27A1 FE0F,fully-qualified,🧑🏿‍🦽‍➡️,person in manual wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F9D1 1F3FF 200D 1F9BD 200D 27A1,minimally-qualified,🧑🏿‍🦽‍➡,person in manual wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F468 200D 1F9BD,fully-qualified,👨‍🦽,man in manual wheelchair,E12.0
People-Body,person-activity,1F468 1F3FB 200D 1F9BD,fully-qualified,👨🏻‍🦽,man in manual wheelchair light skin tone,E12.0
People-Body,person-activity,1F468 1F3FC 200D 1F9BD,fully-qualified,👨🏼‍🦽,man in manual wheelchair medium-light skin tone,E12.0
People-Body,person-activity,1F468 1F3FD 200D 1F9BD,fully-qualified,👨🏽‍🦽,man in manual wheelchair medium skin tone,E12.0
People-Body,person-activity,1F468 1F3FE 200D 1F9BD,fully-qualified,👨🏾‍🦽,man in manual wheelchair medium-dark skin tone,E12.0
People-Body,person-activity,1F468 1F3FF 200D 1F9BD,fully-qualified,👨🏿‍🦽,man in manual wheelchair dark skin tone,E12.0
People-Body,person-activity,1F468 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👨‍🦽‍➡️,man in manual wheelchair facing right,E15.1
People-Body,person-activity,1F468 200D 1F9BD 200D 27A1,minimally-qualified,👨‍🦽‍➡,man in manual wheelchair facing right,E15.1
People-Body,person-activity,1F468 1F3FB 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👨🏻‍🦽‍➡️,man in manual wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F468 1F3FB 200D 1F9BD 200D 27A1,minimally-qualified,👨🏻‍🦽‍➡,man in manual wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F468 1F3FC 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👨🏼‍🦽‍➡️,man in manual wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F468 1F3FC 200D 1F9BD 200D 27A1,minimally-qualified,👨🏼‍🦽‍➡,man in manual wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F468 1F3FD 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👨🏽‍🦽‍➡️,man in manual wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F468 1F3FD 200D 1F9BD 200D 27A1,minimally-qualified,👨🏽‍🦽‍➡,man in manual wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F468 1F3FE 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👨🏾‍🦽‍➡️,man in manual wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F468 1F3FE 200D 1F9BD 200D 27A1,minimally-qualified,👨🏾‍🦽‍➡,man in manual wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F468 1F3FF 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👨🏿‍🦽‍➡️,man in manual wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F468 1F3FF 200D 1F9BD 200D 27A1,minimally-qualified,👨🏿‍🦽‍➡,man in manual wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F469 200D 1F9BD,fully-qualified,👩‍🦽,woman in manual wheelchair,E12.0
People-Body,person-activity,1F469 1F3FB 200D 1F9BD,fully-qualified,👩🏻‍🦽,woman in manual wheelchair light skin tone,E12.0
People-Body,person-activity,1F469 1F3FC 200D 1F9BD,fully-qualified,👩🏼‍🦽,woman in manual wheelchair medium-light skin tone,E12.0
People-Body,person-activity,1F469 1F3FD 200D 1F9BD,fully-qualified,👩🏽‍🦽,woman in manual wheelchair medium skin tone,E12.0
People-Body,person-activity,1F469 1F3FE 200D 1F9BD,fully-qualified,👩🏾‍🦽,woman in manual wheelchair medium-dark skin tone,E12.0
People-Body,person-activity,1F469 1F3FF 200D 1F9BD,fully-qualified,👩🏿‍🦽,woman in manual wheelchair dark skin tone,E12.0
People-Body,person-activity,1F469 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👩‍🦽‍➡️,woman in manual wheelchair facing right,E15.1
People-Body,person-activity,1F469 200D 1F9BD 200D 27A1,minimally-qualified,👩‍🦽‍➡,woman in manual wheelchair facing right,E15.1
People-Body,person-activity,1F469 1F3FB 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👩🏻‍🦽‍➡️,woman in manual wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F469 1F3FB 200D 1F9BD 200D 27A1,minimally-qualified,👩🏻‍🦽‍➡,woman in manual wheelchair facing right light skin tone,E15.1
People-Body,person-activity,1F469 1F3FC 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👩🏼‍🦽‍➡️,woman in manual wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F469 1F3FC 200D 1F9BD 200D 27A1,minimally-qualified,👩🏼‍🦽‍➡,woman in manual wheelchair facing right medium-light skin tone,E15.1
People-Body,person-activity,1F469 1F3FD 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👩🏽‍🦽‍➡️,woman in manual wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F469 1F3FD 200D 1F9BD 200D 27A1,minimally-qualified,👩🏽‍🦽‍➡,woman in manual wheelchair facing right medium skin tone,E15.1
People-Body,person-activity,1F469 1F3FE 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👩🏾‍🦽‍➡️,woman in manual wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F469 1F3FE 200D 1F9BD 200D 27A1,minimally-qualified,👩🏾‍🦽‍➡,woman in manual wheelchair facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F469 1F3FF 200D 1F9BD 200D 27A1 FE0F,fully-qualified,👩🏿‍🦽‍➡️,woman in manual wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F469 1F3FF 200D 1F9BD 200D 27A1,minimally-qualified,👩🏿‍🦽‍➡,woman in manual wheelchair facing right dark skin tone,E15.1
People-Body,person-activity,1F3C3,fully-qualified,🏃,person running,E0.6
People-Body,person-activity,1F3C3 1F3FB,fully-qualified,🏃🏻,person running light skin tone,E1.0
People-Body,person-activity,1F3C3 1F3FC,fully-qualified,🏃🏼,person running medium-light skin tone,E1.0
People-Body,person-activity,1F3C3 1F3FD,fully-qualified,🏃🏽,person running medium skin tone,E1.0
People-Body,person-activity,1F3C3 1F3FE,fully-qualified,🏃🏾,person running medium-dark skin tone,E1.0
People-Body,person-activity,1F3C3 1F3FF,fully-qualified,🏃🏿,person running dark skin tone,E1.0
People-Body,person-activity,1F3C3 200D 2642 FE0F,fully-qualified,🏃‍♂️,man running,E4.0
People-Body,person-activity,1F3C3 200D 2642,minimally-qualified,🏃‍♂,man running,E4.0
People-Body,person-activity,1F3C3 1F3FB 200D 2642 FE0F,fully-qualified,🏃🏻‍♂️,man running light skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FB 200D 2642,minimally-qualified,🏃🏻‍♂,man running light skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FC 200D 2642 FE0F,fully-qualified,🏃🏼‍♂️,man running medium-light skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FC 200D 2642,minimally-qualified,🏃🏼‍♂,man running medium-light skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FD 200D 2642 FE0F,fully-qualified,🏃🏽‍♂️,man running medium skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FD 200D 2642,minimally-qualified,🏃🏽‍♂,man running medium skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FE 200D 2642 FE0F,fully-qualified,🏃🏾‍♂️,man running medium-dark skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FE 200D 2642,minimally-qualified,🏃🏾‍♂,man running medium-dark skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FF 200D 2642 FE0F,fully-qualified,🏃🏿‍♂️,man running dark skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FF 200D 2642,minimally-qualified,🏃🏿‍♂,man running dark skin tone,E4.0
People-Body,person-activity,1F3C3 200D 2640 FE0F,fully-qualified,🏃‍♀️,woman running,E4.0
People-Body,person-activity,1F3C3 200D 2640,minimally-qualified,🏃‍♀,woman running,E4.0
People-Body,person-activity,1F3C3 1F3FB 200D 2640 FE0F,fully-qualified,🏃🏻‍♀️,woman running light skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FB 200D 2640,minimally-qualified,🏃🏻‍♀,woman running light skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FC 200D 2640 FE0F,fully-qualified,🏃🏼‍♀️,woman running medium-light skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FC 200D 2640,minimally-qualified,🏃🏼‍♀,woman running medium-light skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FD 200D 2640 FE0F,fully-qualified,🏃🏽‍♀️,woman running medium skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FD 200D 2640,minimally-qualified,🏃🏽‍♀,woman running medium skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FE 200D 2640 FE0F,fully-qualified,🏃🏾‍♀️,woman running medium-dark skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FE 200D 2640,minimally-qualified,🏃🏾‍♀,woman running medium-dark skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FF 200D 2640 FE0F,fully-qualified,🏃🏿‍♀️,woman running dark skin tone,E4.0
People-Body,person-activity,1F3C3 1F3FF 200D 2640,minimally-qualified,🏃🏿‍♀,woman running dark skin tone,E4.0
People-Body,person-activity,1F3C3 200D 27A1 FE0F,fully-qualified,🏃‍➡️,person running facing right,E15.1
People-Body,person-activity,1F3C3 200D 27A1,minimally-qualified,🏃‍➡,person running facing right,E15.1
People-Body,person-activity,1F3C3 1F3FB 200D 27A1 FE0F,fully-qualified,🏃🏻‍➡️,person running facing right light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FB 200D 27A1,minimally-qualified,🏃🏻‍➡,person running facing right light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FC 200D 27A1 FE0F,fully-qualified,🏃🏼‍➡️,person running facing right medium-light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FC 200D 27A1,minimally-qualified,🏃🏼‍➡,person running facing right medium-light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FD 200D 27A1 FE0F,fully-qualified,🏃🏽‍➡️,person running facing right medium skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FD 200D 27A1,minimally-qualified,🏃🏽‍➡,person running facing right medium skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FE 200D 27A1 FE0F,fully-qualified,🏃🏾‍➡️,person running facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FE 200D 27A1,minimally-qualified,🏃🏾‍➡,person running facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FF 200D 27A1 FE0F,fully-qualified,🏃🏿‍➡️,person running facing right dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FF 200D 27A1,minimally-qualified,🏃🏿‍➡,person running facing right dark skin tone,E15.1
People-Body,person-activity,1F3C3 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🏃‍♀️‍➡️,woman running facing right,E15.1
People-Body,person-activity,1F3C3 200D 2640 200D 27A1 FE0F,minimally-qualified,🏃‍♀‍➡️,woman running facing right,E15.1
People-Body,person-activity,1F3C3 200D 2640 FE0F 200D 27A1,minimally-qualified,🏃‍♀️‍➡,woman running facing right,E15.1
People-Body,person-activity,1F3C3 200D 2640 200D 27A1,minimally-qualified,🏃‍♀‍➡,woman running facing right,E15.1
People-Body,person-activity,1F3C3 1F3FB 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🏃🏻‍♀️‍➡️,woman running facing right light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FB 200D 2640 200D 27A1 FE0F,minimally-qualified,🏃🏻‍♀‍➡️,woman running facing right light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FB 200D 2640 FE0F 200D 27A1,minimally-qualified,🏃🏻‍♀️‍➡,woman running facing right light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FB 200D 2640 200D 27A1,minimally-qualified,🏃🏻‍♀‍➡,woman running facing right light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FC 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🏃🏼‍♀️‍➡️,woman running facing right medium-light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FC 200D 2640 200D 27A1 FE0F,minimally-qualified,🏃🏼‍♀‍➡️,woman running facing right medium-light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FC 200D 2640 FE0F 200D 27A1,minimally-qualified,🏃🏼‍♀️‍➡,woman running facing right medium-light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FC 200D 2640 200D 27A1,minimally-qualified,🏃🏼‍♀‍➡,woman running facing right medium-light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FD 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🏃🏽‍♀️‍➡️,woman running facing right medium skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FD 200D 2640 200D 27A1 FE0F,minimally-qualified,🏃🏽‍♀‍➡️,woman running facing right medium skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FD 200D 2640 FE0F 200D 27A1,minimally-qualified,🏃🏽‍♀️‍➡,woman running facing right medium skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FD 200D 2640 200D 27A1,minimally-qualified,🏃🏽‍♀‍➡,woman running facing right medium skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FE 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🏃🏾‍♀️‍➡️,woman running facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FE 200D 2640 200D 27A1 FE0F,minimally-qualified,🏃🏾‍♀‍➡️,woman running facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FE 200D 2640 FE0F 200D 27A1,minimally-qualified,🏃🏾‍♀️‍➡,woman running facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FE 200D 2640 200D 27A1,minimally-qualified,🏃🏾‍♀‍➡,woman running facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FF 200D 2640 FE0F 200D 27A1 FE0F,fully-qualified,🏃🏿‍♀️‍➡️,woman running facing right dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FF 200D 2640 200D 27A1 FE0F,minimally-qualified,🏃🏿‍♀‍➡️,woman running facing right dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FF 200D 2640 FE0F 200D 27A1,minimally-qualified,🏃🏿‍♀️‍➡,woman running facing right dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FF 200D 2640 200D 27A1,minimally-qualified,🏃🏿‍♀‍➡,woman running facing right dark skin tone,E15.1
People-Body,person-activity,1F3C3 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🏃‍♂️‍➡️,man running facing right,E15.1
People-Body,person-activity,1F3C3 200D 2642 200D 27A1 FE0F,minimally-qualified,🏃‍♂‍➡️,man running facing right,E15.1
People-Body,person-activity,1F3C3 200D 2642 FE0F 200D 27A1,minimally-qualified,🏃‍♂️‍➡,man running facing right,E15.1
People-Body,person-activity,1F3C3 200D 2642 200D 27A1,minimally-qualified,🏃‍♂‍➡,man running facing right,E15.1
People-Body,person-activity,1F3C3 1F3FB 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🏃🏻‍♂️‍➡️,man running facing right light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FB 200D 2642 200D 27A1 FE0F,minimally-qualified,🏃🏻‍♂‍➡️,man running facing right light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FB 200D 2642 FE0F 200D 27A1,minimally-qualified,🏃🏻‍♂️‍➡,man running facing right light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FB 200D 2642 200D 27A1,minimally-qualified,🏃🏻‍♂‍➡,man running facing right light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FC 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🏃🏼‍♂️‍➡️,man running facing right medium-light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FC 200D 2642 200D 27A1 FE0F,minimally-qualified,🏃🏼‍♂‍➡️,man running facing right medium-light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FC 200D 2642 FE0F 200D 27A1,minimally-qualified,🏃🏼‍♂️‍➡,man running facing right medium-light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FC 200D 2642 200D 27A1,minimally-qualified,🏃🏼‍♂‍➡,man running facing right medium-light skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FD 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🏃🏽‍♂️‍➡️,man running facing right medium skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FD 200D 2642 200D 27A1 FE0F,minimally-qualified,🏃🏽‍♂‍➡️,man running facing right medium skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FD 200D 2642 FE0F 200D 27A1,minimally-qualified,🏃🏽‍♂️‍➡,man running facing right medium skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FD 200D 2642 200D 27A1,minimally-qualified,🏃🏽‍♂‍➡,man running facing right medium skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FE 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🏃🏾‍♂️‍➡️,man running facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FE 200D 2642 200D 27A1 FE0F,minimally-qualified,🏃🏾‍♂‍➡️,man running facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FE 200D 2642 FE0F 200D 27A1,minimally-qualified,🏃🏾‍♂️‍➡,man running facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FE 200D 2642 200D 27A1,minimally-qualified,🏃🏾‍♂‍➡,man running facing right medium-dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FF 200D 2642 FE0F 200D 27A1 FE0F,fully-qualified,🏃🏿‍♂️‍➡️,man running facing right dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FF 200D 2642 200D 27A1 FE0F,minimally-qualified,🏃🏿‍♂‍➡️,man running facing right dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FF 200D 2642 FE0F 200D 27A1,minimally-qualified,🏃🏿‍♂️‍➡,man running facing right dark skin tone,E15.1
People-Body,person-activity,1F3C3 1F3FF 200D 2642 200D 27A1,minimally-qualified,🏃🏿‍♂‍➡,man running facing right dark skin tone,E15.1
People-Body,person-activity,1F483,fully-qualified,💃,woman dancing,E0.6
People-Body,person-activity,1F483 1F3FB,fully-qualified,💃🏻,woman dancing light skin tone,E1.0
People-Body,person-activity,1F483 1F3FC,fully-qualified,💃🏼,woman dancing medium-light skin tone,E1.0
People-Body,person-activity,1F483 1F3FD,fully-qualified,💃🏽,woman dancing medium skin tone,E1.0
People-Body,person-activity,1F483 1F3FE,fully-qualified,💃🏾,woman dancing medium-dark skin tone,E1.0
People-Body,person-activity,1F483 1F3FF,fully-qualified,💃🏿,woman dancing dark skin tone,E1.0
People-Body,person-activity,1F57A,fully-qualified,🕺,man dancing,E3.0
People-Body,person-activity,1F57A 1F3FB,fully-qualified,🕺🏻,man dancing light skin tone,E3.0
People-Body,person-activity,1F57A 1F3FC,fully-qualified,🕺🏼,man dancing medium-light skin tone,E3.0
People-Body,person-activity,1F57A 1F3FD,fully-qualified,🕺🏽,man dancing medium skin tone,E3.0
People-Body,person-activity,1F57A 1F3FE,fully-qualified,🕺🏾,man dancing medium-dark skin tone,E3.0
People-Body,person-activity,1F57A 1F3FF,fully-qualified,🕺🏿,man dancing dark skin tone,E3.0
People-Body,person-activity,1F574 FE0F,fully-qualified,🕴️,person in suit levitating,E0.7
People-Body,person-activity,1F574,unqualified,🕴,person in suit levitating,E0.7
People-Body,person-activity,1F574 1F3FB,fully-qualified,🕴🏻,person in suit levitating light skin tone,E4.0
People-Body,person-activity,1F574 1F3FC,fully-qualified,🕴🏼,person in suit levitating medium-light skin tone,E4.0
People-Body,person-activity,1F574 1F3FD,fully-qualified,🕴🏽,person in suit levitating medium skin tone,E4.0
People-Body,person-activity,1F574 1F3FE,fully-qualified,🕴🏾,person in suit levitating medium-dark skin tone,E4.0
People-Body,person-activity,1F574 1F3FF,fully-qualified,🕴🏿,person in suit levitating dark skin tone,E4.0
People-Body,person-activity,1F46F,fully-qualified,👯,people with bunny ears,E0.6
People-Body,person-activity,1F46F 200D 2642 FE0F,fully-qualified,👯‍♂️,men with bunny ears,E4.0
People-Body,person-activity,1F46F 200D 2642,minimally-qualified,👯‍♂,men with bunny ears,E4.0
People-Body,person-activity,1F46F 200D 2640 FE0F,fully-qualified,👯‍♀️,women with bunny ears,E4.0
People-Body,person-activity,1F46F 200D 2640,minimally-qualified,👯‍♀,women with bunny ears,E4.0
People-Body,person-activity,1F9D6,fully-qualified,🧖,person in steamy room,E5.0
People-Body,person-activity,1F9D6 1F3FB,fully-qualified,🧖🏻,person in steamy room light skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FC,fully-qualified,🧖🏼,person in steamy room medium-light skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FD,fully-qualified,🧖🏽,person in steamy room medium skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FE,fully-qualified,🧖🏾,person in steamy room medium-dark skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FF,fully-qualified,🧖🏿,person in steamy room dark skin tone,E5.0
People-Body,person-activity,1F9D6 200D 2642 FE0F,fully-qualified,🧖‍♂️,man in steamy room,E5.0
People-Body,person-activity,1F9D6 200D 2642,minimally-qualified,🧖‍♂,man in steamy room,E5.0
People-Body,person-activity,1F9D6 1F3FB 200D 2642 FE0F,fully-qualified,🧖🏻‍♂️,man in steamy room light skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FB 200D 2642,minimally-qualified,🧖🏻‍♂,man in steamy room light skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FC 200D 2642 FE0F,fully-qualified,🧖🏼‍♂️,man in steamy room medium-light skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FC 200D 2642,minimally-qualified,🧖🏼‍♂,man in steamy room medium-light skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FD 200D 2642 FE0F,fully-qualified,🧖🏽‍♂️,man in steamy room medium skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FD 200D 2642,minimally-qualified,🧖🏽‍♂,man in steamy room medium skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FE 200D 2642 FE0F,fully-qualified,🧖🏾‍♂️,man in steamy room medium-dark skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FE 200D 2642,minimally-qualified,🧖🏾‍♂,man in steamy room medium-dark skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FF 200D 2642 FE0F,fully-qualified,🧖🏿‍♂️,man in steamy room dark skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FF 200D 2642,minimally-qualified,🧖🏿‍♂,man in steamy room dark skin tone,E5.0
People-Body,person-activity,1F9D6 200D 2640 FE0F,fully-qualified,🧖‍♀️,woman in steamy room,E5.0
People-Body,person-activity,1F9D6 200D 2640,minimally-qualified,🧖‍♀,woman in steamy room,E5.0
People-Body,person-activity,1F9D6 1F3FB 200D 2640 FE0F,fully-qualified,🧖🏻‍♀️,woman in steamy room light skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FB 200D 2640,minimally-qualified,🧖🏻‍♀,woman in steamy room light skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FC 200D 2640 FE0F,fully-qualified,🧖🏼‍♀️,woman in steamy room medium-light skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FC 200D 2640,minimally-qualified,🧖🏼‍♀,woman in steamy room medium-light skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FD 200D 2640 FE0F,fully-qualified,🧖🏽‍♀️,woman in steamy room medium skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FD 200D 2640,minimally-qualified,🧖🏽‍♀,woman in steamy room medium skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FE 200D 2640 FE0F,fully-qualified,🧖🏾‍♀️,woman in steamy room medium-dark skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FE 200D 2640,minimally-qualified,🧖🏾‍♀,woman in steamy room medium-dark skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FF 200D 2640 FE0F,fully-qualified,🧖🏿‍♀️,woman in steamy room dark skin tone,E5.0
People-Body,person-activity,1F9D6 1F3FF 200D 2640,minimally-qualified,🧖🏿‍♀,woman in steamy room dark skin tone,E5.0
People-Body,person-activity,1F9D7,fully-qualified,🧗,person climbing,E5.0
People-Body,person-activity,1F9D7 1F3FB,fully-qualified,🧗🏻,person climbing light skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FC,fully-qualified,🧗🏼,person climbing medium-light skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FD,fully-qualified,🧗🏽,person climbing medium skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FE,fully-qualified,🧗🏾,person climbing medium-dark skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FF,fully-qualified,🧗🏿,person climbing dark skin tone,E5.0
People-Body,person-activity,1F9D7 200D 2642 FE0F,fully-qualified,🧗‍♂️,man climbing,E5.0
People-Body,person-activity,1F9D7 200D 2642,minimally-qualified,🧗‍♂,man climbing,E5.0
People-Body,person-activity,1F9D7 1F3FB 200D 2642 FE0F,fully-qualified,🧗🏻‍♂️,man climbing light skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FB 200D 2642,minimally-qualified,🧗🏻‍♂,man climbing light skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FC 200D 2642 FE0F,fully-qualified,🧗🏼‍♂️,man climbing medium-light skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FC 200D 2642,minimally-qualified,🧗🏼‍♂,man climbing medium-light skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FD 200D 2642 FE0F,fully-qualified,🧗🏽‍♂️,man climbing medium skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FD 200D 2642,minimally-qualified,🧗🏽‍♂,man climbing medium skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FE 200D 2642 FE0F,fully-qualified,🧗🏾‍♂️,man climbing medium-dark skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FE 200D 2642,minimally-qualified,🧗🏾‍♂,man climbing medium-dark skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FF 200D 2642 FE0F,fully-qualified,🧗🏿‍♂️,man climbing dark skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FF 200D 2642,minimally-qualified,🧗🏿‍♂,man climbing dark skin tone,E5.0
People-Body,person-activity,1F9D7 200D 2640 FE0F,fully-qualified,🧗‍♀️,woman climbing,E5.0
People-Body,person-activity,1F9D7 200D 2640,minimally-qualified,🧗‍♀,woman climbing,E5.0
People-Body,person-activity,1F9D7 1F3FB 200D 2640 FE0F,fully-qualified,🧗🏻‍♀️,woman climbing light skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FB 200D 2640,minimally-qualified,🧗🏻‍♀,woman climbing light skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FC 200D 2640 FE0F,fully-qualified,🧗🏼‍♀️,woman climbing medium-light skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FC 200D 2640,minimally-qualified,🧗🏼‍♀,woman climbing medium-light skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FD 200D 2640 FE0F,fully-qualified,🧗🏽‍♀️,woman climbing medium skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FD 200D 2640,minimally-qualified,🧗🏽‍♀,woman climbing medium skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FE 200D 2640 FE0F,fully-qualified,🧗🏾‍♀️,woman climbing medium-dark skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FE 200D 2640,minimally-qualified,🧗🏾‍♀,woman climbing medium-dark skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FF 200D 2640 FE0F,fully-qualified,🧗🏿‍♀️,woman climbing dark skin tone,E5.0
People-Body,person-activity,1F9D7 1F3FF 200D 2640,minimally-qualified,🧗🏿‍♀,woman climbing dark skin tone,E5.0
People-Body,person-sport,1F93A,fully-qualified,🤺,person fencing,E3.0
People-Body,person-sport,1F3C7,fully-qualified,🏇,horse racing,E1.0
People-Body,person-sport,1F3C7 1F3FB,fully-qualified,🏇🏻,horse racing light skin tone,E1.0
People-Body,person-sport,1F3C7 1F3FC,fully-qualified,🏇🏼,horse racing medium-light skin tone,E1.0
People-Body,person-sport,1F3C7 1F3FD,fully-qualified,🏇🏽,horse racing medium skin tone,E1.0
People-Body,person-sport,1F3C7 1F3FE,fully-qualified,🏇🏾,horse racing medium-dark skin tone,E1.0
People-Body,person-sport,1F3C7 1F3FF,fully-qualified,🏇🏿,horse racing dark skin tone,E1.0
People-Body,person-sport,26F7 FE0F,fully-qualified,⛷️,skier,E0.7
People-Body,person-sport,26F7,unqualified,⛷,skier,E0.7
People-Body,person-sport,1F3C2,fully-qualified,🏂,snowboarder,E0.6
People-Body,person-sport,1F3C2 1F3FB,fully-qualified,🏂🏻,snowboarder light skin tone,E1.0
People-Body,person-sport,1F3C2 1F3FC,fully-qualified,🏂🏼,snowboarder medium-light skin tone,E1.0
People-Body,person-sport,1F3C2 1F3FD,fully-qualified,🏂🏽,snowboarder medium skin tone,E1.0
People-Body,person-sport,1F3C2 1F3FE,fully-qualified,🏂🏾,snowboarder medium-dark skin tone,E1.0
People-Body,person-sport,1F3C2 1F3FF,fully-qualified,🏂🏿,snowboarder dark skin tone,E1.0
People-Body,person-sport,1F3CC FE0F,fully-qualified,🏌️,person golfing,E0.7
People-Body,person-sport,1F3CC,unqualified,🏌,person golfing,E0.7
People-Body,person-sport,1F3CC 1F3FB,fully-qualified,🏌🏻,person golfing light skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FC,fully-qualified,🏌🏼,person golfing medium-light skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FD,fully-qualified,🏌🏽,person golfing medium skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FE,fully-qualified,🏌🏾,person golfing medium-dark skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FF,fully-qualified,🏌🏿,person golfing dark skin tone,E4.0
People-Body,person-sport,1F3CC FE0F 200D 2642 FE0F,fully-qualified,🏌️‍♂️,man golfing,E4.0
People-Body,person-sport,1F3CC 200D 2642 FE0F,unqualified,🏌‍♂️,man golfing,E4.0
People-Body,person-sport,1F3CC FE0F 200D 2642,minimally-qualified,🏌️‍♂,man golfing,E4.0
People-Body,person-sport,1F3CC 200D 2642,unqualified,🏌‍♂,man golfing,E4.0
People-Body,person-sport,1F3CC 1F3FB 200D 2642 FE0F,fully-qualified,🏌🏻‍♂️,man golfing light skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FB 200D 2642,minimally-qualified,🏌🏻‍♂,man golfing light skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FC 200D 2642 FE0F,fully-qualified,🏌🏼‍♂️,man golfing medium-light skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FC 200D 2642,minimally-qualified,🏌🏼‍♂,man golfing medium-light skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FD 200D 2642 FE0F,fully-qualified,🏌🏽‍♂️,man golfing medium skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FD 200D 2642,minimally-qualified,🏌🏽‍♂,man golfing medium skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FE 200D 2642 FE0F,fully-qualified,🏌🏾‍♂️,man golfing medium-dark skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FE 200D 2642,minimally-qualified,🏌🏾‍♂,man golfing medium-dark skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FF 200D 2642 FE0F,fully-qualified,🏌🏿‍♂️,man golfing dark skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FF 200D 2642,minimally-qualified,🏌🏿‍♂,man golfing dark skin tone,E4.0
People-Body,person-sport,1F3CC FE0F 200D 2640 FE0F,fully-qualified,🏌️‍♀️,woman golfing,E4.0
People-Body,person-sport,1F3CC 200D 2640 FE0F,unqualified,🏌‍♀️,woman golfing,E4.0
People-Body,person-sport,1F3CC FE0F 200D 2640,minimally-qualified,🏌️‍♀,woman golfing,E4.0
People-Body,person-sport,1F3CC 200D 2640,unqualified,🏌‍♀,woman golfing,E4.0
People-Body,person-sport,1F3CC 1F3FB 200D 2640 FE0F,fully-qualified,🏌🏻‍♀️,woman golfing light skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FB 200D 2640,minimally-qualified,🏌🏻‍♀,woman golfing light skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FC 200D 2640 FE0F,fully-qualified,🏌🏼‍♀️,woman golfing medium-light skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FC 200D 2640,minimally-qualified,🏌🏼‍♀,woman golfing medium-light skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FD 200D 2640 FE0F,fully-qualified,🏌🏽‍♀️,woman golfing medium skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FD 200D 2640,minimally-qualified,🏌🏽‍♀,woman golfing medium skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FE 200D 2640 FE0F,fully-qualified,🏌🏾‍♀️,woman golfing medium-dark skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FE 200D 2640,minimally-qualified,🏌🏾‍♀,woman golfing medium-dark skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FF 200D 2640 FE0F,fully-qualified,🏌🏿‍♀️,woman golfing dark skin tone,E4.0
People-Body,person-sport,1F3CC 1F3FF 200D 2640,minimally-qualified,🏌🏿‍♀,woman golfing dark skin tone,E4.0
People-Body,person-sport,1F3C4,fully-qualified,🏄,person surfing,E0.6
People-Body,person-sport,1F3C4 1F3FB,fully-qualified,🏄🏻,person surfing light skin tone,E1.0
People-Body,person-sport,1F3C4 1F3FC,fully-qualified,🏄🏼,person surfing medium-light skin tone,E1.0
People-Body,person-sport,1F3C4 1F3FD,fully-qualified,🏄🏽,person surfing medium skin tone,E1.0
People-Body,person-sport,1F3C4 1F3FE,fully-qualified,🏄🏾,person surfing medium-dark skin tone,E1.0
People-Body,person-sport,1F3C4 1F3FF,fully-qualified,🏄🏿,person surfing dark skin tone,E1.0
People-Body,person-sport,1F3C4 200D 2642 FE0F,fully-qualified,🏄‍♂️,man surfing,E4.0
People-Body,person-sport,1F3C4 200D 2642,minimally-qualified,🏄‍♂,man surfing,E4.0
People-Body,person-sport,1F3C4 1F3FB 200D 2642 FE0F,fully-qualified,🏄🏻‍♂️,man surfing light skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FB 200D 2642,minimally-qualified,🏄🏻‍♂,man surfing light skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FC 200D 2642 FE0F,fully-qualified,🏄🏼‍♂️,man surfing medium-light skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FC 200D 2642,minimally-qualified,🏄🏼‍♂,man surfing medium-light skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FD 200D 2642 FE0F,fully-qualified,🏄🏽‍♂️,man surfing medium skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FD 200D 2642,minimally-qualified,🏄🏽‍♂,man surfing medium skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FE 200D 2642 FE0F,fully-qualified,🏄🏾‍♂️,man surfing medium-dark skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FE 200D 2642,minimally-qualified,🏄🏾‍♂,man surfing medium-dark skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FF 200D 2642 FE0F,fully-qualified,🏄🏿‍♂️,man surfing dark skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FF 200D 2642,minimally-qualified,🏄🏿‍♂,man surfing dark skin tone,E4.0
People-Body,person-sport,1F3C4 200D 2640 FE0F,fully-qualified,🏄‍♀️,woman surfing,E4.0
People-Body,person-sport,1F3C4 200D 2640,minimally-qualified,🏄‍♀,woman surfing,E4.0
People-Body,person-sport,1F3C4 1F3FB 200D 2640 FE0F,fully-qualified,🏄🏻‍♀️,woman surfing light skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FB 200D 2640,minimally-qualified,🏄🏻‍♀,woman surfing light skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FC 200D 2640 FE0F,fully-qualified,🏄🏼‍♀️,woman surfing medium-light skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FC 200D 2640,minimally-qualified,🏄🏼‍♀,woman surfing medium-light skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FD 200D 2640 FE0F,fully-qualified,🏄🏽‍♀️,woman surfing medium skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FD 200D 2640,minimally-qualified,🏄🏽‍♀,woman surfing medium skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FE 200D 2640 FE0F,fully-qualified,🏄🏾‍♀️,woman surfing medium-dark skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FE 200D 2640,minimally-qualified,🏄🏾‍♀,woman surfing medium-dark skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FF 200D 2640 FE0F,fully-qualified,🏄🏿‍♀️,woman surfing dark skin tone,E4.0
People-Body,person-sport,1F3C4 1F3FF 200D 2640,minimally-qualified,🏄🏿‍♀,woman surfing dark skin tone,E4.0
People-Body,person-sport,1F6A3,fully-qualified,🚣,person rowing boat,E1.0
People-Body,person-sport,1F6A3 1F3FB,fully-qualified,🚣🏻,person rowing boat light skin tone,E1.0
People-Body,person-sport,1F6A3 1F3FC,fully-qualified,🚣🏼,person rowing boat medium-light skin tone,E1.0
People-Body,person-sport,1F6A3 1F3FD,fully-qualified,🚣🏽,person rowing boat medium skin tone,E1.0
People-Body,person-sport,1F6A3 1F3FE,fully-qualified,🚣🏾,person rowing boat medium-dark skin tone,E1.0
People-Body,person-sport,1F6A3 1F3FF,fully-qualified,🚣🏿,person rowing boat dark skin tone,E1.0
People-Body,person-sport,1F6A3 200D 2642 FE0F,fully-qualified,🚣‍♂️,man rowing boat,E4.0
People-Body,person-sport,1F6A3 200D 2642,minimally-qualified,🚣‍♂,man rowing boat,E4.0
People-Body,person-sport,1F6A3 1F3FB 200D 2642 FE0F,fully-qualified,🚣🏻‍♂️,man rowing boat light skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FB 200D 2642,minimally-qualified,🚣🏻‍♂,man rowing boat light skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FC 200D 2642 FE0F,fully-qualified,🚣🏼‍♂️,man rowing boat medium-light skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FC 200D 2642,minimally-qualified,🚣🏼‍♂,man rowing boat medium-light skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FD 200D 2642 FE0F,fully-qualified,🚣🏽‍♂️,man rowing boat medium skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FD 200D 2642,minimally-qualified,🚣🏽‍♂,man rowing boat medium skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FE 200D 2642 FE0F,fully-qualified,🚣🏾‍♂️,man rowing boat medium-dark skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FE 200D 2642,minimally-qualified,🚣🏾‍♂,man rowing boat medium-dark skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FF 200D 2642 FE0F,fully-qualified,🚣🏿‍♂️,man rowing boat dark skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FF 200D 2642,minimally-qualified,🚣🏿‍♂,man rowing boat dark skin tone,E4.0
People-Body,person-sport,1F6A3 200D 2640 FE0F,fully-qualified,🚣‍♀️,woman rowing boat,E4.0
People-Body,person-sport,1F6A3 200D 2640,minimally-qualified,🚣‍♀,woman rowing boat,E4.0
People-Body,person-sport,1F6A3 1F3FB 200D 2640 FE0F,fully-qualified,🚣🏻‍♀️,woman rowing boat light skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FB 200D 2640,minimally-qualified,🚣🏻‍♀,woman rowing boat light skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FC 200D 2640 FE0F,fully-qualified,🚣🏼‍♀️,woman rowing boat medium-light skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FC 200D 2640,minimally-qualified,🚣🏼‍♀,woman rowing boat medium-light skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FD 200D 2640 FE0F,fully-qualified,🚣🏽‍♀️,woman rowing boat medium skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FD 200D 2640,minimally-qualified,🚣🏽‍♀,woman rowing boat medium skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FE 200D 2640 FE0F,fully-qualified,🚣🏾‍♀️,woman rowing boat medium-dark skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FE 200D 2640,minimally-qualified,🚣🏾‍♀,woman rowing boat medium-dark skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FF 200D 2640 FE0F,fully-qualified,🚣🏿‍♀️,woman rowing boat dark skin tone,E4.0
People-Body,person-sport,1F6A3 1F3FF 200D 2640,minimally-qualified,🚣🏿‍♀,woman rowing boat dark skin tone,E4.0
People-Body,person-sport,1F3CA,fully-qualified,🏊,person swimming,E0.6
People-Body,person-sport,1F3CA 1F3FB,fully-qualified,🏊🏻,person swimming light skin tone,E1.0
People-Body,person-sport,1F3CA 1F3FC,fully-qualified,🏊🏼,person swimming medium-light skin tone,E1.0
People-Body,person-sport,1F3CA 1F3FD,fully-qualified,🏊🏽,person swimming medium skin tone,E1.0
People-Body,person-sport,1F3CA 1F3FE,fully-qualified,🏊🏾,person swimming medium-dark skin tone,E1.0
People-Body,person-sport,1F3CA 1F3FF,fully-qualified,🏊🏿,person swimming dark skin tone,E1.0
People-Body,person-sport,1F3CA 200D 2642 FE0F,fully-qualified,🏊‍♂️,man swimming,E4.0
People-Body,person-sport,1F3CA 200D 2642,minimally-qualified,🏊‍♂,man swimming,E4.0
People-Body,person-sport,1F3CA 1F3FB 200D 2642 FE0F,fully-qualified,🏊🏻‍♂️,man swimming light skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FB 200D 2642,minimally-qualified,🏊🏻‍♂,man swimming light skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FC 200D 2642 FE0F,fully-qualified,🏊🏼‍♂️,man swimming medium-light skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FC 200D 2642,minimally-qualified,🏊🏼‍♂,man swimming medium-light skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FD 200D 2642 FE0F,fully-qualified,🏊🏽‍♂️,man swimming medium skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FD 200D 2642,minimally-qualified,🏊🏽‍♂,man swimming medium skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FE 200D 2642 FE0F,fully-qualified,🏊🏾‍♂️,man swimming medium-dark skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FE 200D 2642,minimally-qualified,🏊🏾‍♂,man swimming medium-dark skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FF 200D 2642 FE0F,fully-qualified,🏊🏿‍♂️,man swimming dark skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FF 200D 2642,minimally-qualified,🏊🏿‍♂,man swimming dark skin tone,E4.0
People-Body,person-sport,1F3CA 200D 2640 FE0F,fully-qualified,🏊‍♀️,woman swimming,E4.0
People-Body,person-sport,1F3CA 200D 2640,minimally-qualified,🏊‍♀,woman swimming,E4.0
People-Body,person-sport,1F3CA 1F3FB 200D 2640 FE0F,fully-qualified,🏊🏻‍♀️,woman swimming light skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FB 200D 2640,minimally-qualified,🏊🏻‍♀,woman swimming light skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FC 200D 2640 FE0F,fully-qualified,🏊🏼‍♀️,woman swimming medium-light skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FC 200D 2640,minimally-qualified,🏊🏼‍♀,woman swimming medium-light skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FD 200D 2640 FE0F,fully-qualified,🏊🏽‍♀️,woman swimming medium skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FD 200D 2640,minimally-qualified,🏊🏽‍♀,woman swimming medium skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FE 200D 2640 FE0F,fully-qualified,🏊🏾‍♀️,woman swimming medium-dark skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FE 200D 2640,minimally-qualified,🏊🏾‍♀,woman swimming medium-dark skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FF 200D 2640 FE0F,fully-qualified,🏊🏿‍♀️,woman swimming dark skin tone,E4.0
People-Body,person-sport,1F3CA 1F3FF 200D 2640,minimally-qualified,🏊🏿‍♀,woman swimming dark skin tone,E4.0
People-Body,person-sport,26F9 FE0F,fully-qualified,⛹️,person bouncing ball,E0.7
People-Body,person-sport,26F9,unqualified,⛹,person bouncing ball,E0.7
People-Body,person-sport,26F9 1F3FB,fully-qualified,⛹🏻,person bouncing ball light skin tone,E2.0
People-Body,person-sport,26F9 1F3FC,fully-qualified,⛹🏼,person bouncing ball medium-light skin tone,E2.0
People-Body,person-sport,26F9 1F3FD,fully-qualified,⛹🏽,person bouncing ball medium skin tone,E2.0
People-Body,person-sport,26F9 1F3FE,fully-qualified,⛹🏾,person bouncing ball medium-dark skin tone,E2.0
People-Body,person-sport,26F9 1F3FF,fully-qualified,⛹🏿,person bouncing ball dark skin tone,E2.0
People-Body,person-sport,26F9 FE0F 200D 2642 FE0F,fully-qualified,⛹️‍♂️,man bouncing ball,E4.0
People-Body,person-sport,26F9 200D 2642 FE0F,unqualified,⛹‍♂️,man bouncing ball,E4.0
People-Body,person-sport,26F9 FE0F 200D 2642,minimally-qualified,⛹️‍♂,man bouncing ball,E4.0
People-Body,person-sport,26F9 200D 2642,unqualified,⛹‍♂,man bouncing ball,E4.0
People-Body,person-sport,26F9 1F3FB 200D 2642 FE0F,fully-qualified,⛹🏻‍♂️,man bouncing ball light skin tone,E4.0
People-Body,person-sport,26F9 1F3FB 200D 2642,minimally-qualified,⛹🏻‍♂,man bouncing ball light skin tone,E4.0
People-Body,person-sport,26F9 1F3FC 200D 2642 FE0F,fully-qualified,⛹🏼‍♂️,man bouncing ball medium-light skin tone,E4.0
People-Body,person-sport,26F9 1F3FC 200D 2642,minimally-qualified,⛹🏼‍♂,man bouncing ball medium-light skin tone,E4.0
People-Body,person-sport,26F9 1F3FD 200D 2642 FE0F,fully-qualified,⛹🏽‍♂️,man bouncing ball medium skin tone,E4.0
People-Body,person-sport,26F9 1F3FD 200D 2642,minimally-qualified,⛹🏽‍♂,man bouncing ball medium skin tone,E4.0
People-Body,person-sport,26F9 1F3FE 200D 2642 FE0F,fully-qualified,⛹🏾‍♂️,man bouncing ball medium-dark skin tone,E4.0
People-Body,person-sport,26F9 1F3FE 200D 2642,minimally-qualified,⛹🏾‍♂,man bouncing ball medium-dark skin tone,E4.0
People-Body,person-sport,26F9 1F3FF 200D 2642 FE0F,fully-qualified,⛹🏿‍♂️,man bouncing ball dark skin tone,E4.0
People-Body,person-sport,26F9 1F3FF 200D 2642,minimally-qualified,⛹🏿‍♂,man bouncing ball dark skin tone,E4.0
People-Body,person-sport,26F9 FE0F 200D 2640 FE0F,fully-qualified,⛹️‍♀️,woman bouncing ball,E4.0
People-Body,person-sport,26F9 200D 2640 FE0F,unqualified,⛹‍♀️,woman bouncing ball,E4.0
People-Body,person-sport,26F9 FE0F 200D 2640,minimally-qualified,⛹️‍♀,woman bouncing ball,E4.0
People-Body,person-sport,26F9 200D 2640,unqualified,⛹‍♀,woman bouncing ball,E4.0
People-Body,person-sport,26F9 1F3FB 200D 2640 FE0F,fully-qualified,⛹🏻‍♀️,woman bouncing ball light skin tone,E4.0
People-Body,person-sport,26F9 1F3FB 200D 2640,minimally-qualified,⛹🏻‍♀,woman bouncing ball light skin tone,E4.0
People-Body,person-sport,26F9 1F3FC 200D 2640 FE0F,fully-qualified,⛹🏼‍♀️,woman bouncing ball medium-light skin tone,E4.0
People-Body,person-sport,26F9 1F3FC 200D 2640,minimally-qualified,⛹🏼‍♀,woman bouncing ball medium-light skin tone,E4.0
People-Body,person-sport,26F9 1F3FD 200D 2640 FE0F,fully-qualified,⛹🏽‍♀️,woman bouncing ball medium skin tone,E4.0
People-Body,person-sport,26F9 1F3FD 200D 2640,minimally-qualified,⛹🏽‍♀,woman bouncing ball medium skin tone,E4.0
People-Body,person-sport,26F9 1F3FE 200D 2640 FE0F,fully-qualified,⛹🏾‍♀️,woman bouncing ball medium-dark skin tone,E4.0
People-Body,person-sport,26F9 1F3FE 200D 2640,minimally-qualified,⛹🏾‍♀,woman bouncing ball medium-dark skin tone,E4.0
People-Body,person-sport,26F9 1F3FF 200D 2640 FE0F,fully-qualified,⛹🏿‍♀️,woman bouncing ball dark skin tone,E4.0
People-Body,person-sport,26F9 1F3FF 200D 2640,minimally-qualified,⛹🏿‍♀,woman bouncing ball dark skin tone,E4.0
People-Body,person-sport,1F3CB FE0F,fully-qualified,🏋️,person lifting weights,E0.7
People-Body,person-sport,1F3CB,unqualified,🏋,person lifting weights,E0.7
People-Body,person-sport,1F3CB 1F3FB,fully-qualified,🏋🏻,person lifting weights light skin tone,E2.0
People-Body,person-sport,1F3CB 1F3FC,fully-qualified,🏋🏼,person lifting weights medium-light skin tone,E2.0
People-Body,person-sport,1F3CB 1F3FD,fully-qualified,🏋🏽,person lifting weights medium skin tone,E2.0
People-Body,person-sport,1F3CB 1F3FE,fully-qualified,🏋🏾,person lifting weights medium-dark skin tone,E2.0
People-Body,person-sport,1F3CB 1F3FF,fully-qualified,🏋🏿,person lifting weights dark skin tone,E2.0
People-Body,person-sport,1F3CB FE0F 200D 2642 FE0F,fully-qualified,🏋️‍♂️,man lifting weights,E4.0
People-Body,person-sport,1F3CB 200D 2642 FE0F,unqualified,🏋‍♂️,man lifting weights,E4.0
People-Body,person-sport,1F3CB FE0F 200D 2642,minimally-qualified,🏋️‍♂,man lifting weights,E4.0
People-Body,person-sport,1F3CB 200D 2642,unqualified,🏋‍♂,man lifting weights,E4.0
People-Body,person-sport,1F3CB 1F3FB 200D 2642 FE0F,fully-qualified,🏋🏻‍♂️,man lifting weights light skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FB 200D 2642,minimally-qualified,🏋🏻‍♂,man lifting weights light skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FC 200D 2642 FE0F,fully-qualified,🏋🏼‍♂️,man lifting weights medium-light skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FC 200D 2642,minimally-qualified,🏋🏼‍♂,man lifting weights medium-light skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FD 200D 2642 FE0F,fully-qualified,🏋🏽‍♂️,man lifting weights medium skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FD 200D 2642,minimally-qualified,🏋🏽‍♂,man lifting weights medium skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FE 200D 2642 FE0F,fully-qualified,🏋🏾‍♂️,man lifting weights medium-dark skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FE 200D 2642,minimally-qualified,🏋🏾‍♂,man lifting weights medium-dark skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FF 200D 2642 FE0F,fully-qualified,🏋🏿‍♂️,man lifting weights dark skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FF 200D 2642,minimally-qualified,🏋🏿‍♂,man lifting weights dark skin tone,E4.0
People-Body,person-sport,1F3CB FE0F 200D 2640 FE0F,fully-qualified,🏋️‍♀️,woman lifting weights,E4.0
People-Body,person-sport,1F3CB 200D 2640 FE0F,unqualified,🏋‍♀️,woman lifting weights,E4.0
People-Body,person-sport,1F3CB FE0F 200D 2640,minimally-qualified,🏋️‍♀,woman lifting weights,E4.0
People-Body,person-sport,1F3CB 200D 2640,unqualified,🏋‍♀,woman lifting weights,E4.0
People-Body,person-sport,1F3CB 1F3FB 200D 2640 FE0F,fully-qualified,🏋🏻‍♀️,woman lifting weights light skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FB 200D 2640,minimally-qualified,🏋🏻‍♀,woman lifting weights light skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FC 200D 2640 FE0F,fully-qualified,🏋🏼‍♀️,woman lifting weights medium-light skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FC 200D 2640,minimally-qualified,🏋🏼‍♀,woman lifting weights medium-light skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FD 200D 2640 FE0F,fully-qualified,🏋🏽‍♀️,woman lifting weights medium skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FD 200D 2640,minimally-qualified,🏋🏽‍♀,woman lifting weights medium skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FE 200D 2640 FE0F,fully-qualified,🏋🏾‍♀️,woman lifting weights medium-dark skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FE 200D 2640,minimally-qualified,🏋🏾‍♀,woman lifting weights medium-dark skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FF 200D 2640 FE0F,fully-qualified,🏋🏿‍♀️,woman lifting weights dark skin tone,E4.0
People-Body,person-sport,1F3CB 1F3FF 200D 2640,minimally-qualified,🏋🏿‍♀,woman lifting weights dark skin tone,E4.0
People-Body,person-sport,1F6B4,fully-qualified,🚴,person biking,E1.0
People-Body,person-sport,1F6B4 1F3FB,fully-qualified,🚴🏻,person biking light skin tone,E1.0
People-Body,person-sport,1F6B4 1F3FC,fully-qualified,🚴🏼,person biking medium-light skin tone,E1.0
People-Body,person-sport,1F6B4 1F3FD,fully-qualified,🚴🏽,person biking medium skin tone,E1.0
People-Body,person-sport,1F6B4 1F3FE,fully-qualified,🚴🏾,person biking medium-dark skin tone,E1.0
People-Body,person-sport,1F6B4 1F3FF,fully-qualified,🚴🏿,person biking dark skin tone,E1.0
People-Body,person-sport,1F6B4 200D 2642 FE0F,fully-qualified,🚴‍♂️,man biking,E4.0
People-Body,person-sport,1F6B4 200D 2642,minimally-qualified,🚴‍♂,man biking,E4.0
People-Body,person-sport,1F6B4 1F3FB 200D 2642 FE0F,fully-qualified,🚴🏻‍♂️,man biking light skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FB 200D 2642,minimally-qualified,🚴🏻‍♂,man biking light skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FC 200D 2642 FE0F,fully-qualified,🚴🏼‍♂️,man biking medium-light skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FC 200D 2642,minimally-qualified,🚴🏼‍♂,man biking medium-light skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FD 200D 2642 FE0F,fully-qualified,🚴🏽‍♂️,man biking medium skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FD 200D 2642,minimally-qualified,🚴🏽‍♂,man biking medium skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FE 200D 2642 FE0F,fully-qualified,🚴🏾‍♂️,man biking medium-dark skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FE 200D 2642,minimally-qualified,🚴🏾‍♂,man biking medium-dark skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FF 200D 2642 FE0F,fully-qualified,🚴🏿‍♂️,man biking dark skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FF 200D 2642,minimally-qualified,🚴🏿‍♂,man biking dark skin tone,E4.0
People-Body,person-sport,1F6B4 200D 2640 FE0F,fully-qualified,🚴‍♀️,woman biking,E4.0
People-Body,person-sport,1F6B4 200D 2640,minimally-qualified,🚴‍♀,woman biking,E4.0
People-Body,person-sport,1F6B4 1F3FB 200D 2640 FE0F,fully-qualified,🚴🏻‍♀️,woman biking light skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FB 200D 2640,minimally-qualified,🚴🏻‍♀,woman biking light skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FC 200D 2640 FE0F,fully-qualified,🚴🏼‍♀️,woman biking medium-light skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FC 200D 2640,minimally-qualified,🚴🏼‍♀,woman biking medium-light skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FD 200D 2640 FE0F,fully-qualified,🚴🏽‍♀️,woman biking medium skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FD 200D 2640,minimally-qualified,🚴🏽‍♀,woman biking medium skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FE 200D 2640 FE0F,fully-qualified,🚴🏾‍♀️,woman biking medium-dark skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FE 200D 2640,minimally-qualified,🚴🏾‍♀,woman biking medium-dark skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FF 200D 2640 FE0F,fully-qualified,🚴🏿‍♀️,woman biking dark skin tone,E4.0
People-Body,person-sport,1F6B4 1F3FF 200D 2640,minimally-qualified,🚴🏿‍♀,woman biking dark skin tone,E4.0
People-Body,person-sport,1F6B5,fully-qualified,🚵,person mountain biking,E1.0
People-Body,person-sport,1F6B5 1F3FB,fully-qualified,🚵🏻,person mountain biking light skin tone,E1.0
People-Body,person-sport,1F6B5 1F3FC,fully-qualified,🚵🏼,person mountain biking medium-light skin tone,E1.0
People-Body,person-sport,1F6B5 1F3FD,fully-qualified,🚵🏽,person mountain biking medium skin tone,E1.0
People-Body,person-sport,1F6B5 1F3FE,fully-qualified,🚵🏾,person mountain biking medium-dark skin tone,E1.0
People-Body,person-sport,1F6B5 1F3FF,fully-qualified,🚵🏿,person mountain biking dark skin tone,E1.0
People-Body,person-sport,1F6B5 200D 2642 FE0F,fully-qualified,🚵‍♂️,man mountain biking,E4.0
People-Body,person-sport,1F6B5 200D 2642,minimally-qualified,🚵‍♂,man mountain biking,E4.0
People-Body,person-sport,1F6B5 1F3FB 200D 2642 FE0F,fully-qualified,🚵🏻‍♂️,man mountain biking light skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FB 200D 2642,minimally-qualified,🚵🏻‍♂,man mountain biking light skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FC 200D 2642 FE0F,fully-qualified,🚵🏼‍♂️,man mountain biking medium-light skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FC 200D 2642,minimally-qualified,🚵🏼‍♂,man mountain biking medium-light skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FD 200D 2642 FE0F,fully-qualified,🚵🏽‍♂️,man mountain biking medium skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FD 200D 2642,minimally-qualified,🚵🏽‍♂,man mountain biking medium skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FE 200D 2642 FE0F,fully-qualified,🚵🏾‍♂️,man mountain biking medium-dark skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FE 200D 2642,minimally-qualified,🚵🏾‍♂,man mountain biking medium-dark skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FF 200D 2642 FE0F,fully-qualified,🚵🏿‍♂️,man mountain biking dark skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FF 200D 2642,minimally-qualified,🚵🏿‍♂,man mountain biking dark skin tone,E4.0
People-Body,person-sport,1F6B5 200D 2640 FE0F,fully-qualified,🚵‍♀️,woman mountain biking,E4.0
People-Body,person-sport,1F6B5 200D 2640,minimally-qualified,🚵‍♀,woman mountain biking,E4.0
People-Body,person-sport,1F6B5 1F3FB 200D 2640 FE0F,fully-qualified,🚵🏻‍♀️,woman mountain biking light skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FB 200D 2640,minimally-qualified,🚵🏻‍♀,woman mountain biking light skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FC 200D 2640 FE0F,fully-qualified,🚵🏼‍♀️,woman mountain biking medium-light skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FC 200D 2640,minimally-qualified,🚵🏼‍♀,woman mountain biking medium-light skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FD 200D 2640 FE0F,fully-qualified,🚵🏽‍♀️,woman mountain biking medium skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FD 200D 2640,minimally-qualified,🚵🏽‍♀,woman mountain biking medium skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FE 200D 2640 FE0F,fully-qualified,🚵🏾‍♀️,woman mountain biking medium-dark skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FE 200D 2640,minimally-qualified,🚵🏾‍♀,woman mountain biking medium-dark skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FF 200D 2640 FE0F,fully-qualified,🚵🏿‍♀️,woman mountain biking dark skin tone,E4.0
People-Body,person-sport,1F6B5 1F3FF 200D 2640,minimally-qualified,🚵🏿‍♀,woman mountain biking dark skin tone,E4.0
People-Body,person-sport,1F938,fully-qualified,🤸,person cartwheeling,E3.0
People-Body,person-sport,1F938 1F3FB,fully-qualified,🤸🏻,person cartwheeling light skin tone,E3.0
People-Body,person-sport,1F938 1F3FC,fully-qualified,🤸🏼,person cartwheeling medium-light skin tone,E3.0
People-Body,person-sport,1F938 1F3FD,fully-qualified,🤸🏽,person cartwheeling medium skin tone,E3.0
People-Body,person-sport,1F938 1F3FE,fully-qualified,🤸🏾,person cartwheeling medium-dark skin tone,E3.0
People-Body,person-sport,1F938 1F3FF,fully-qualified,🤸🏿,person cartwheeling dark skin tone,E3.0
People-Body,person-sport,1F938 200D 2642 FE0F,fully-qualified,🤸‍♂️,man cartwheeling,E4.0
People-Body,person-sport,1F938 200D 2642,minimally-qualified,🤸‍♂,man cartwheeling,E4.0
People-Body,person-sport,1F938 1F3FB 200D 2642 FE0F,fully-qualified,🤸🏻‍♂️,man cartwheeling light skin tone,E4.0
People-Body,person-sport,1F938 1F3FB 200D 2642,minimally-qualified,🤸🏻‍♂,man cartwheeling light skin tone,E4.0
People-Body,person-sport,1F938 1F3FC 200D 2642 FE0F,fully-qualified,🤸🏼‍♂️,man cartwheeling medium-light skin tone,E4.0
People-Body,person-sport,1F938 1F3FC 200D 2642,minimally-qualified,🤸🏼‍♂,man cartwheeling medium-light skin tone,E4.0
People-Body,person-sport,1F938 1F3FD 200D 2642 FE0F,fully-qualified,🤸🏽‍♂️,man cartwheeling medium skin tone,E4.0
People-Body,person-sport,1F938 1F3FD 200D 2642,minimally-qualified,🤸🏽‍♂,man cartwheeling medium skin tone,E4.0
People-Body,person-sport,1F938 1F3FE 200D 2642 FE0F,fully-qualified,🤸🏾‍♂️,man cartwheeling medium-dark skin tone,E4.0
People-Body,person-sport,1F938 1F3FE 200D 2642,minimally-qualified,🤸🏾‍♂,man cartwheeling medium-dark skin tone,E4.0
People-Body,person-sport,1F938 1F3FF 200D 2642 FE0F,fully-qualified,🤸🏿‍♂️,man cartwheeling dark skin tone,E4.0
People-Body,person-sport,1F938 1F3FF 200D 2642,minimally-qualified,🤸🏿‍♂,man cartwheeling dark skin tone,E4.0
People-Body,person-sport,1F938 200D 2640 FE0F,fully-qualified,🤸‍♀️,woman cartwheeling,E4.0
People-Body,person-sport,1F938 200D 2640,minimally-qualified,🤸‍♀,woman cartwheeling,E4.0
People-Body,person-sport,1F938 1F3FB 200D 2640 FE0F,fully-qualified,🤸🏻‍♀️,woman cartwheeling light skin tone,E4.0
People-Body,person-sport,1F938 1F3FB 200D 2640,minimally-qualified,🤸🏻‍♀,woman cartwheeling light skin tone,E4.0
People-Body,person-sport,1F938 1F3FC 200D 2640 FE0F,fully-qualified,🤸🏼‍♀️,woman cartwheeling medium-light skin tone,E4.0
People-Body,person-sport,1F938 1F3FC 200D 2640,minimally-qualified,🤸🏼‍♀,woman cartwheeling medium-light skin tone,E4.0
People-Body,person-sport,1F938 1F3FD 200D 2640 FE0F,fully-qualified,🤸🏽‍♀️,woman cartwheeling medium skin tone,E4.0
People-Body,person-sport,1F938 1F3FD 200D 2640,minimally-qualified,🤸🏽‍♀,woman cartwheeling medium skin tone,E4.0
People-Body,person-sport,1F938 1F3FE 200D 2640 FE0F,fully-qualified,🤸🏾‍♀️,woman cartwheeling medium-dark skin tone,E4.0
People-Body,person-sport,1F938 1F3FE 200D 2640,minimally-qualified,🤸🏾‍♀,woman cartwheeling medium-dark skin tone,E4.0
People-Body,person-sport,1F938 1F3FF 200D 2640 FE0F,fully-qualified,🤸🏿‍♀️,woman cartwheeling dark skin tone,E4.0
People-Body,person-sport,1F938 1F3FF 200D 2640,minimally-qualified,🤸🏿‍♀,woman cartwheeling dark skin tone,E4.0
People-Body,person-sport,1F93C,fully-qualified,🤼,people wrestling,E3.0
People-Body,person-sport,1F93C 200D 2642 FE0F,fully-qualified,🤼‍♂️,men wrestling,E4.0
People-Body,person-sport,1F93C 200D 2642,minimally-qualified,🤼‍♂,men wrestling,E4.0
People-Body,person-sport,1F93C 200D 2640 FE0F,fully-qualified,🤼‍♀️,women wrestling,E4.0
People-Body,person-sport,1F93C 200D 2640,minimally-qualified,🤼‍♀,women wrestling,E4.0
People-Body,person-sport,1F93D,fully-qualified,🤽,person playing water polo,E3.0
People-Body,person-sport,1F93D 1F3FB,fully-qualified,🤽🏻,person playing water polo light skin tone,E3.0
People-Body,person-sport,1F93D 1F3FC,fully-qualified,🤽🏼,person playing water polo medium-light skin tone,E3.0
People-Body,person-sport,1F93D 1F3FD,fully-qualified,🤽🏽,person playing water polo medium skin tone,E3.0
People-Body,person-sport,1F93D 1F3FE,fully-qualified,🤽🏾,person playing water polo medium-dark skin tone,E3.0
People-Body,person-sport,1F93D 1F3FF,fully-qualified,🤽🏿,person playing water polo dark skin tone,E3.0
People-Body,person-sport,1F93D 200D 2642 FE0F,fully-qualified,🤽‍♂️,man playing water polo,E4.0
People-Body,person-sport,1F93D 200D 2642,minimally-qualified,🤽‍♂,man playing water polo,E4.0
People-Body,person-sport,1F93D 1F3FB 200D 2642 FE0F,fully-qualified,🤽🏻‍♂️,man playing water polo light skin tone,E4.0
People-Body,person-sport,1F93D 1F3FB 200D 2642,minimally-qualified,🤽🏻‍♂,man playing water polo light skin tone,E4.0
People-Body,person-sport,1F93D 1F3FC 200D 2642 FE0F,fully-qualified,🤽🏼‍♂️,man playing water polo medium-light skin tone,E4.0
People-Body,person-sport,1F93D 1F3FC 200D 2642,minimally-qualified,🤽🏼‍♂,man playing water polo medium-light skin tone,E4.0
People-Body,person-sport,1F93D 1F3FD 200D 2642 FE0F,fully-qualified,🤽🏽‍♂️,man playing water polo medium skin tone,E4.0
People-Body,person-sport,1F93D 1F3FD 200D 2642,minimally-qualified,🤽🏽‍♂,man playing water polo medium skin tone,E4.0
People-Body,person-sport,1F93D 1F3FE 200D 2642 FE0F,fully-qualified,🤽🏾‍♂️,man playing water polo medium-dark skin tone,E4.0
People-Body,person-sport,1F93D 1F3FE 200D 2642,minimally-qualified,🤽🏾‍♂,man playing water polo medium-dark skin tone,E4.0
People-Body,person-sport,1F93D 1F3FF 200D 2642 FE0F,fully-qualified,🤽🏿‍♂️,man playing water polo dark skin tone,E4.0
People-Body,person-sport,1F93D 1F3FF 200D 2642,minimally-qualified,🤽🏿‍♂,man playing water polo dark skin tone,E4.0
People-Body,person-sport,1F93D 200D 2640 FE0F,fully-qualified,🤽‍♀️,woman playing water polo,E4.0
People-Body,person-sport,1F93D 200D 2640,minimally-qualified,🤽‍♀,woman playing water polo,E4.0
People-Body,person-sport,1F93D 1F3FB 200D 2640 FE0F,fully-qualified,🤽🏻‍♀️,woman playing water polo light skin tone,E4.0
People-Body,person-sport,1F93D 1F3FB 200D 2640,minimally-qualified,🤽🏻‍♀,woman playing water polo light skin tone,E4.0
People-Body,person-sport,1F93D 1F3FC 200D 2640 FE0F,fully-qualified,🤽🏼‍♀️,woman playing water polo medium-light skin tone,E4.0
People-Body,person-sport,1F93D 1F3FC 200D 2640,minimally-qualified,🤽🏼‍♀,woman playing water polo medium-light skin tone,E4.0
People-Body,person-sport,1F93D 1F3FD 200D 2640 FE0F,fully-qualified,🤽🏽‍♀️,woman playing water polo medium skin tone,E4.0
People-Body,person-sport,1F93D 1F3FD 200D 2640,minimally-qualified,🤽🏽‍♀,woman playing water polo medium skin tone,E4.0
People-Body,person-sport,1F93D 1F3FE 200D 2640 FE0F,fully-qualified,🤽🏾‍♀️,woman playing water polo medium-dark skin tone,E4.0
People-Body,person-sport,1F93D 1F3FE 200D 2640,minimally-qualified,🤽🏾‍♀,woman playing water polo medium-dark skin tone,E4.0
People-Body,person-sport,1F93D 1F3FF 200D 2640 FE0F,fully-qualified,🤽🏿‍♀️,woman playing water polo dark skin tone,E4.0
People-Body,person-sport,1F93D 1F3FF 200D 2640,minimally-qualified,🤽🏿‍♀,woman playing water polo dark skin tone,E4.0
People-Body,person-sport,1F93E,fully-qualified,🤾,person playing handball,E3.0
People-Body,person-sport,1F93E 1F3FB,fully-qualified,🤾🏻,person playing handball light skin tone,E3.0
People-Body,person-sport,1F93E 1F3FC,fully-qualified,🤾🏼,person playing handball medium-light skin tone,E3.0
People-Body,person-sport,1F93E 1F3FD,fully-qualified,🤾🏽,person playing handball medium skin tone,E3.0
People-Body,person-sport,1F93E 1F3FE,fully-qualified,🤾🏾,person playing handball medium-dark skin tone,E3.0
People-Body,person-sport,1F93E 1F3FF,fully-qualified,🤾🏿,person playing handball dark skin tone,E3.0
People-Body,person-sport,1F93E 200D 2642 FE0F,fully-qualified,🤾‍♂️,man playing handball,E4.0
People-Body,person-sport,1F93E 200D 2642,minimally-qualified,🤾‍♂,man playing handball,E4.0
People-Body,person-sport,1F93E 1F3FB 200D 2642 FE0F,fully-qualified,🤾🏻‍♂️,man playing handball light skin tone,E4.0
People-Body,person-sport,1F93E 1F3FB 200D 2642,minimally-qualified,🤾🏻‍♂,man playing handball light skin tone,E4.0
People-Body,person-sport,1F93E 1F3FC 200D 2642 FE0F,fully-qualified,🤾🏼‍♂️,man playing handball medium-light skin tone,E4.0
People-Body,person-sport,1F93E 1F3FC 200D 2642,minimally-qualified,🤾🏼‍♂,man playing handball medium-light skin tone,E4.0
People-Body,person-sport,1F93E 1F3FD 200D 2642 FE0F,fully-qualified,🤾🏽‍♂️,man playing handball medium skin tone,E4.0
People-Body,person-sport,1F93E 1F3FD 200D 2642,minimally-qualified,🤾🏽‍♂,man playing handball medium skin tone,E4.0
People-Body,person-sport,1F93E 1F3FE 200D 2642 FE0F,fully-qualified,🤾🏾‍♂️,man playing handball medium-dark skin tone,E4.0
People-Body,person-sport,1F93E 1F3FE 200D 2642,minimally-qualified,🤾🏾‍♂,man playing handball medium-dark skin tone,E4.0
People-Body,person-sport,1F93E 1F3FF 200D 2642 FE0F,fully-qualified,🤾🏿‍♂️,man playing handball dark skin tone,E4.0
People-Body,person-sport,1F93E 1F3FF 200D 2642,minimally-qualified,🤾🏿‍♂,man playing handball dark skin tone,E4.0
People-Body,person-sport,1F93E 200D 2640 FE0F,fully-qualified,🤾‍♀️,woman playing handball,E4.0
People-Body,person-sport,1F93E 200D 2640,minimally-qualified,🤾‍♀,woman playing handball,E4.0
People-Body,person-sport,1F93E 1F3FB 200D 2640 FE0F,fully-qualified,🤾🏻‍♀️,woman playing handball light skin tone,E4.0
People-Body,person-sport,1F93E 1F3FB 200D 2640,minimally-qualified,🤾🏻‍♀,woman playing handball light skin tone,E4.0
People-Body,person-sport,1F93E 1F3FC 200D 2640 FE0F,fully-qualified,🤾🏼‍♀️,woman playing handball medium-light skin tone,E4.0
People-Body,person-sport,1F93E 1F3FC 200D 2640,minimally-qualified,🤾🏼‍♀,woman playing handball medium-light skin tone,E4.0
People-Body,person-sport,1F93E 1F3FD 200D 2640 FE0F,fully-qualified,🤾🏽‍♀️,woman playing handball medium skin tone,E4.0
People-Body,person-sport,1F93E 1F3FD 200D 2640,minimally-qualified,🤾🏽‍♀,woman playing handball medium skin tone,E4.0
People-Body,person-sport,1F93E 1F3FE 200D 2640 FE0F,fully-qualified,🤾🏾‍♀️,woman playing handball medium-dark skin tone,E4.0
People-Body,person-sport,1F93E 1F3FE 200D 2640,minimally-qualified,🤾🏾‍♀,woman playing handball medium-dark skin tone,E4.0
People-Body,person-sport,1F93E 1F3FF 200D 2640 FE0F,fully-qualified,🤾🏿‍♀️,woman playing handball dark skin tone,E4.0
People-Body,person-sport,1F93E 1F3FF 200D 2640,minimally-qualified,🤾🏿‍♀,woman playing handball dark skin tone,E4.0
People-Body,person-sport,1F939,fully-qualified,🤹,person juggling,E3.0
People-Body,person-sport,1F939 1F3FB,fully-qualified,🤹🏻,person juggling light skin tone,E3.0
People-Body,person-sport,1F939 1F3FC,fully-qualified,🤹🏼,person juggling medium-light skin tone,E3.0
People-Body,person-sport,1F939 1F3FD,fully-qualified,🤹🏽,person juggling medium skin tone,E3.0
People-Body,person-sport,1F939 1F3FE,fully-qualified,🤹🏾,person juggling medium-dark skin tone,E3.0
People-Body,person-sport,1F939 1F3FF,fully-qualified,🤹🏿,person juggling dark skin tone,E3.0
People-Body,person-sport,1F939 200D 2642 FE0F,fully-qualified,🤹‍♂️,man juggling,E4.0
People-Body,person-sport,1F939 200D 2642,minimally-qualified,🤹‍♂,man juggling,E4.0
People-Body,person-sport,1F939 1F3FB 200D 2642 FE0F,fully-qualified,🤹🏻‍♂️,man juggling light skin tone,E4.0
People-Body,person-sport,1F939 1F3FB 200D 2642,minimally-qualified,🤹🏻‍♂,man juggling light skin tone,E4.0
People-Body,person-sport,1F939 1F3FC 200D 2642 FE0F,fully-qualified,🤹🏼‍♂️,man juggling medium-light skin tone,E4.0
People-Body,person-sport,1F939 1F3FC 200D 2642,minimally-qualified,🤹🏼‍♂,man juggling medium-light skin tone,E4.0
People-Body,person-sport,1F939 1F3FD 200D 2642 FE0F,fully-qualified,🤹🏽‍♂️,man juggling medium skin tone,E4.0
People-Body,person-sport,1F939 1F3FD 200D 2642,minimally-qualified,🤹🏽‍♂,man juggling medium skin tone,E4.0
People-Body,person-sport,1F939 1F3FE 200D 2642 FE0F,fully-qualified,🤹🏾‍♂️,man juggling medium-dark skin tone,E4.0
People-Body,person-sport,1F939 1F3FE 200D 2642,minimally-qualified,🤹🏾‍♂,man juggling medium-dark skin tone,E4.0
People-Body,person-sport,1F939 1F3FF 200D 2642 FE0F,fully-qualified,🤹🏿‍♂️,man juggling dark skin tone,E4.0
People-Body,person-sport,1F939 1F3FF 200D 2642,minimally-qualified,🤹🏿‍♂,man juggling dark skin tone,E4.0
People-Body,person-sport,1F939 200D 2640 FE0F,fully-qualified,🤹‍♀️,woman juggling,E4.0
People-Body,person-sport,1F939 200D 2640,minimally-qualified,🤹‍♀,woman juggling,E4.0
People-Body,person-sport,1F939 1F3FB 200D 2640 FE0F,fully-qualified,🤹🏻‍♀️,woman juggling light skin tone,E4.0
People-Body,person-sport,1F939 1F3FB 200D 2640,minimally-qualified,🤹🏻‍♀,woman juggling light skin tone,E4.0
People-Body,person-sport,1F939 1F3FC 200D 2640 FE0F,fully-qualified,🤹🏼‍♀️,woman juggling medium-light skin tone,E4.0
People-Body,person-sport,1F939 1F3FC 200D 2640,minimally-qualified,🤹🏼‍♀,woman juggling medium-light skin tone,E4.0
People-Body,person-sport,1F939 1F3FD 200D 2640 FE0F,fully-qualified,🤹🏽‍♀️,woman juggling medium skin tone,E4.0
People-Body,person-sport,1F939 1F3FD 200D 2640,minimally-qualified,🤹🏽‍♀,woman juggling medium skin tone,E4.0
People-Body,person-sport,1F939 1F3FE 200D 2640 FE0F,fully-qualified,🤹🏾‍♀️,woman juggling medium-dark skin tone,E4.0
People-Body,person-sport,1F939 1F3FE 200D 2640,minimally-qualified,🤹🏾‍♀,woman juggling medium-dark skin tone,E4.0
People-Body,person-sport,1F939 1F3FF 200D 2640 FE0F,fully-qualified,🤹🏿‍♀️,woman juggling dark skin tone,E4.0
People-Body,person-sport,1F939 1F3FF 200D 2640,minimally-qualified,🤹🏿‍♀,woman juggling dark skin tone,E4.0
People-Body,person-resting,1F9D8,fully-qualified,🧘,person in lotus position,E5.0
People-Body,person-resting,1F9D8 1F3FB,fully-qualified,🧘🏻,person in lotus position light skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FC,fully-qualified,🧘🏼,person in lotus position medium-light skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FD,fully-qualified,🧘🏽,person in lotus position medium skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FE,fully-qualified,🧘🏾,person in lotus position medium-dark skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FF,fully-qualified,🧘🏿,person in lotus position dark skin tone,E5.0
People-Body,person-resting,1F9D8 200D 2642 FE0F,fully-qualified,🧘‍♂️,man in lotus position,E5.0
People-Body,person-resting,1F9D8 200D 2642,minimally-qualified,🧘‍♂,man in lotus position,E5.0
People-Body,person-resting,1F9D8 1F3FB 200D 2642 FE0F,fully-qualified,🧘🏻‍♂️,man in lotus position light skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FB 200D 2642,minimally-qualified,🧘🏻‍♂,man in lotus position light skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FC 200D 2642 FE0F,fully-qualified,🧘🏼‍♂️,man in lotus position medium-light skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FC 200D 2642,minimally-qualified,🧘🏼‍♂,man in lotus position medium-light skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FD 200D 2642 FE0F,fully-qualified,🧘🏽‍♂️,man in lotus position medium skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FD 200D 2642,minimally-qualified,🧘🏽‍♂,man in lotus position medium skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FE 200D 2642 FE0F,fully-qualified,🧘🏾‍♂️,man in lotus position medium-dark skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FE 200D 2642,minimally-qualified,🧘🏾‍♂,man in lotus position medium-dark skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FF 200D 2642 FE0F,fully-qualified,🧘🏿‍♂️,man in lotus position dark skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FF 200D 2642,minimally-qualified,🧘🏿‍♂,man in lotus position dark skin tone,E5.0
People-Body,person-resting,1F9D8 200D 2640 FE0F,fully-qualified,🧘‍♀️,woman in lotus position,E5.0
People-Body,person-resting,1F9D8 200D 2640,minimally-qualified,🧘‍♀,woman in lotus position,E5.0
People-Body,person-resting,1F9D8 1F3FB 200D 2640 FE0F,fully-qualified,🧘🏻‍♀️,woman in lotus position light skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FB 200D 2640,minimally-qualified,🧘🏻‍♀,woman in lotus position light skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FC 200D 2640 FE0F,fully-qualified,🧘🏼‍♀️,woman in lotus position medium-light skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FC 200D 2640,minimally-qualified,🧘🏼‍♀,woman in lotus position medium-light skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FD 200D 2640 FE0F,fully-qualified,🧘🏽‍♀️,woman in lotus position medium skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FD 200D 2640,minimally-qualified,🧘🏽‍♀,woman in lotus position medium skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FE 200D 2640 FE0F,fully-qualified,🧘🏾‍♀️,woman in lotus position medium-dark skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FE 200D 2640,minimally-qualified,🧘🏾‍♀,woman in lotus position medium-dark skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FF 200D 2640 FE0F,fully-qualified,🧘🏿‍♀️,woman in lotus position dark skin tone,E5.0
People-Body,person-resting,1F9D8 1F3FF 200D 2640,minimally-qualified,🧘🏿‍♀,woman in lotus position dark skin tone,E5.0
People-Body,person-resting,1F6C0,fully-qualified,🛀,person taking bath,E0.6
People-Body,person-resting,1F6C0 1F3FB,fully-qualified,🛀🏻,person taking bath light skin tone,E1.0
People-Body,person-resting,1F6C0 1F3FC,fully-qualified,🛀🏼,person taking bath medium-light skin tone,E1.0
People-Body,person-resting,1F6C0 1F3FD,fully-qualified,🛀🏽,person taking bath medium skin tone,E1.0
People-Body,person-resting,1F6C0 1F3FE,fully-qualified,🛀🏾,person taking bath medium-dark skin tone,E1.0
People-Body,person-resting,1F6C0 1F3FF,fully-qualified,🛀🏿,person taking bath dark skin tone,E1.0
People-Body,person-resting,1F6CC,fully-qualified,🛌,person in bed,E1.0
People-Body,person-resting,1F6CC 1F3FB,fully-qualified,🛌🏻,person in bed light skin tone,E4.0
People-Body,person-resting,1F6CC 1F3FC,fully-qualified,🛌🏼,person in bed medium-light skin tone,E4.0
People-Body,person-resting,1F6CC 1F3FD,fully-qualified,🛌🏽,person in bed medium skin tone,E4.0
People-Body,person-resting,1F6CC 1F3FE,fully-qualified,🛌🏾,person in bed medium-dark skin tone,E4.0
People-Body,person-resting,1F6CC 1F3FF,fully-qualified,🛌🏿,person in bed dark skin tone,E4.0
People-Body,family,1F9D1 200D 1F91D 200D 1F9D1,fully-qualified,🧑‍🤝‍🧑,people holding hands,E12.0
People-Body,family,1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FB,fully-qualified,🧑🏻‍🤝‍🧑🏻,people holding hands light skin tone,E12.0
People-Body,family,1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FC,fully-qualified,🧑🏻‍🤝‍🧑🏼,people holding hands light skin tone medium-light skin tone,E12.1
People-Body,family,1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FD,fully-qualified,🧑🏻‍🤝‍🧑🏽,people holding hands light skin tone medium skin tone,E12.1
People-Body,family,1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FE,fully-qualified,🧑🏻‍🤝‍🧑🏾,people holding hands light skin tone medium-dark skin tone,E12.1
People-Body,family,1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FF,fully-qualified,🧑🏻‍🤝‍🧑🏿,people holding hands light skin tone dark skin tone,E12.1
People-Body,family,1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FB,fully-qualified,🧑🏼‍🤝‍🧑🏻,people holding hands medium-light skin tone light skin tone,E12.0
People-Body,family,1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FC,fully-qualified,🧑🏼‍🤝‍🧑🏼,people holding hands medium-light skin tone,E12.0
People-Body,family,1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FD,fully-qualified,🧑🏼‍🤝‍🧑🏽,people holding hands medium-light skin tone medium skin tone,E12.1
People-Body,family,1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FE,fully-qualified,🧑🏼‍🤝‍🧑🏾,people holding hands medium-light skin tone medium-dark skin tone,E12.1
People-Body,family,1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FF,fully-qualified,🧑🏼‍🤝‍🧑🏿,people holding hands medium-light skin tone dark skin tone,E12.1
People-Body,family,1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FB,fully-qualified,🧑🏽‍🤝‍🧑🏻,people holding hands medium skin tone light skin tone,E12.0
People-Body,family,1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FC,fully-qualified,🧑🏽‍🤝‍🧑🏼,people holding hands medium skin tone medium-light skin tone,E12.0
People-Body,family,1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FD,fully-qualified,🧑🏽‍🤝‍🧑🏽,people holding hands medium skin tone,E12.0
People-Body,family,1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FE,fully-qualified,🧑🏽‍🤝‍🧑🏾,people holding hands medium skin tone medium-dark skin tone,E12.1
People-Body,family,1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FF,fully-qualified,🧑🏽‍🤝‍🧑🏿,people holding hands medium skin tone dark skin tone,E12.1
People-Body,family,1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FB,fully-qualified,🧑🏾‍🤝‍🧑🏻,people holding hands medium-dark skin tone light skin tone,E12.0
People-Body,family,1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FC,fully-qualified,🧑🏾‍🤝‍🧑🏼,people holding hands medium-dark skin tone medium-light skin tone,E12.0
People-Body,family,1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FD,fully-qualified,🧑🏾‍🤝‍🧑🏽,people holding hands medium-dark skin tone medium skin tone,E12.0
People-Body,family,1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FE,fully-qualified,🧑🏾‍🤝‍🧑🏾,people holding hands medium-dark skin tone,E12.0
People-Body,family,1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FF,fully-qualified,🧑🏾‍🤝‍🧑🏿,people holding hands medium-dark skin tone dark skin tone,E12.1
People-Body,family,1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FB,fully-qualified,🧑🏿‍🤝‍🧑🏻,people holding hands dark skin tone light skin tone,E12.0
People-Body,family,1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FC,fully-qualified,🧑🏿‍🤝‍🧑🏼,people holding hands dark skin tone medium-light skin tone,E12.0
People-Body,family,1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FD,fully-qualified,🧑🏿‍🤝‍🧑🏽,people holding hands dark skin tone medium skin tone,E12.0
People-Body,family,1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FE,fully-qualified,🧑🏿‍🤝‍🧑🏾,people holding hands dark skin tone medium-dark skin tone,E12.0
People-Body,family,1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FF,fully-qualified,🧑🏿‍🤝‍🧑🏿,people holding hands dark skin tone,E12.0
People-Body,family,1F46D,fully-qualified,👭,women holding hands,E1.0
People-Body,family,1F46D 1F3FB,fully-qualified,👭🏻,women holding hands light skin tone,E12.0
People-Body,family,1F469 1F3FB 200D 1F91D 200D 1F469 1F3FC,fully-qualified,👩🏻‍🤝‍👩🏼,women holding hands light skin tone medium-light skin tone,E12.1
People-Body,family,1F469 1F3FB 200D 1F91D 200D 1F469 1F3FD,fully-qualified,👩🏻‍🤝‍👩🏽,women holding hands light skin tone medium skin tone,E12.1
People-Body,family,1F469 1F3FB 200D 1F91D 200D 1F469 1F3FE,fully-qualified,👩🏻‍🤝‍👩🏾,women holding hands light skin tone medium-dark skin tone,E12.1
People-Body,family,1F469 1F3FB 200D 1F91D 200D 1F469 1F3FF,fully-qualified,👩🏻‍🤝‍👩🏿,women holding hands light skin tone dark skin tone,E12.1
People-Body,family,1F469 1F3FC 200D 1F91D 200D 1F469 1F3FB,fully-qualified,👩🏼‍🤝‍👩🏻,women holding hands medium-light skin tone light skin tone,E12.0
People-Body,family,1F46D 1F3FC,fully-qualified,👭🏼,women holding hands medium-light skin tone,E12.0
People-Body,family,1F469 1F3FC 200D 1F91D 200D 1F469 1F3FD,fully-qualified,👩🏼‍🤝‍👩🏽,women holding hands medium-light skin tone medium skin tone,E12.1
People-Body,family,1F469 1F3FC 200D 1F91D 200D 1F469 1F3FE,fully-qualified,👩🏼‍🤝‍👩🏾,women holding hands medium-light skin tone medium-dark skin tone,E12.1
People-Body,family,1F469 1F3FC 200D 1F91D 200D 1F469 1F3FF,fully-qualified,👩🏼‍🤝‍👩🏿,women holding hands medium-light skin tone dark skin tone,E12.1
People-Body,family,1F469 1F3FD 200D 1F91D 200D 1F469 1F3FB,fully-qualified,👩🏽‍🤝‍👩🏻,women holding hands medium skin tone light skin tone,E12.0
People-Body,family,1F469 1F3FD 200D 1F91D 200D 1F469 1F3FC,fully-qualified,👩🏽‍🤝‍👩🏼,women holding hands medium skin tone medium-light skin tone,E12.0
People-Body,family,1F46D 1F3FD,fully-qualified,👭🏽,women holding hands medium skin tone,E12.0
People-Body,family,1F469 1F3FD 200D 1F91D 200D 1F469 1F3FE,fully-qualified,👩🏽‍🤝‍👩🏾,women holding hands medium skin tone medium-dark skin tone,E12.1
People-Body,family,1F469 1F3FD 200D 1F91D 200D 1F469 1F3FF,fully-qualified,👩🏽‍🤝‍👩🏿,women holding hands medium skin tone dark skin tone,E12.1
People-Body,family,1F469 1F3FE 200D 1F91D 200D 1F469 1F3FB,fully-qualified,👩🏾‍🤝‍👩🏻,women holding hands medium-dark skin tone light skin tone,E12.0
People-Body,family,1F469 1F3FE 200D 1F91D 200D 1F469 1F3FC,fully-qualified,👩🏾‍🤝‍👩🏼,women holding hands medium-dark skin tone medium-light skin tone,E12.0
People-Body,family,1F469 1F3FE 200D 1F91D 200D 1F469 1F3FD,fully-qualified,👩🏾‍🤝‍👩🏽,women holding hands medium-dark skin tone medium skin tone,E12.0
People-Body,family,1F46D 1F3FE,fully-qualified,👭🏾,women holding hands medium-dark skin tone,E12.0
People-Body,family,1F469 1F3FE 200D 1F91D 200D 1F469 1F3FF,fully-qualified,👩🏾‍🤝‍👩🏿,women holding hands medium-dark skin tone dark skin tone,E12.1
People-Body,family,1F469 1F3FF 200D 1F91D 200D 1F469 1F3FB,fully-qualified,👩🏿‍🤝‍👩🏻,women holding hands dark skin tone light skin tone,E12.0
People-Body,family,1F469 1F3FF 200D 1F91D 200D 1F469 1F3FC,fully-qualified,👩🏿‍🤝‍👩🏼,women holding hands dark skin tone medium-light skin tone,E12.0
People-Body,family,1F469 1F3FF 200D 1F91D 200D 1F469 1F3FD,fully-qualified,👩🏿‍🤝‍👩🏽,women holding hands dark skin tone medium skin tone,E12.0
People-Body,family,1F469 1F3FF 200D 1F91D 200D 1F469 1F3FE,fully-qualified,👩🏿‍🤝‍👩🏾,women holding hands dark skin tone medium-dark skin tone,E12.0
People-Body,family,1F46D 1F3FF,fully-qualified,👭🏿,women holding hands dark skin tone,E12.0
People-Body,family,1F46B,fully-qualified,👫,woman and man holding hands,E0.6
People-Body,family,1F46B 1F3FB,fully-qualified,👫🏻,woman and man holding hands light skin tone,E12.0
People-Body,family,1F469 1F3FB 200D 1F91D 200D 1F468 1F3FC,fully-qualified,👩🏻‍🤝‍👨🏼,woman and man holding hands light skin tone medium-light skin tone,E12.0
People-Body,family,1F469 1F3FB 200D 1F91D 200D 1F468 1F3FD,fully-qualified,👩🏻‍🤝‍👨🏽,woman and man holding hands light skin tone medium skin tone,E12.0
People-Body,family,1F469 1F3FB 200D 1F91D 200D 1F468 1F3FE,fully-qualified,👩🏻‍🤝‍👨🏾,woman and man holding hands light skin tone medium-dark skin tone,E12.0
People-Body,family,1F469 1F3FB 200D 1F91D 200D 1F468 1F3FF,fully-qualified,👩🏻‍🤝‍👨🏿,woman and man holding hands light skin tone dark skin tone,E12.0
People-Body,family,1F469 1F3FC 200D 1F91D 200D 1F468 1F3FB,fully-qualified,👩🏼‍🤝‍👨🏻,woman and man holding hands medium-light skin tone light skin tone,E12.0
People-Body,family,1F46B 1F3FC,fully-qualified,👫🏼,woman and man holding hands medium-light skin tone,E12.0
People-Body,family,1F469 1F3FC 200D 1F91D 200D 1F468 1F3FD,fully-qualified,👩🏼‍🤝‍👨🏽,woman and man holding hands medium-light skin tone medium skin tone,E12.0
People-Body,family,1F469 1F3FC 200D 1F91D 200D 1F468 1F3FE,fully-qualified,👩🏼‍🤝‍👨🏾,woman and man holding hands medium-light skin tone medium-dark skin tone,E12.0
People-Body,family,1F469 1F3FC 200D 1F91D 200D 1F468 1F3FF,fully-qualified,👩🏼‍🤝‍👨🏿,woman and man holding hands medium-light skin tone dark skin tone,E12.0
People-Body,family,1F469 1F3FD 200D 1F91D 200D 1F468 1F3FB,fully-qualified,👩🏽‍🤝‍👨🏻,woman and man holding hands medium skin tone light skin tone,E12.0
People-Body,family,1F469 1F3FD 200D 1F91D 200D 1F468 1F3FC,fully-qualified,👩🏽‍🤝‍👨🏼,woman and man holding hands medium skin tone medium-light skin tone,E12.0
People-Body,family,1F46B 1F3FD,fully-qualified,👫🏽,woman and man holding hands medium skin tone,E12.0
People-Body,family,1F469 1F3FD 200D 1F91D 200D 1F468 1F3FE,fully-qualified,👩🏽‍🤝‍👨🏾,woman and man holding hands medium skin tone medium-dark skin tone,E12.0
People-Body,family,1F469 1F3FD 200D 1F91D 200D 1F468 1F3FF,fully-qualified,👩🏽‍🤝‍👨🏿,woman and man holding hands medium skin tone dark skin tone,E12.0
People-Body,family,1F469 1F3FE 200D 1F91D 200D 1F468 1F3FB,fully-qualified,👩🏾‍🤝‍👨🏻,woman and man holding hands medium-dark skin tone light skin tone,E12.0
People-Body,family,1F469 1F3FE 200D 1F91D 200D 1F468 1F3FC,fully-qualified,👩🏾‍🤝‍👨🏼,woman and man holding hands medium-dark skin tone medium-light skin tone,E12.0
People-Body,family,1F469 1F3FE 200D 1F91D 200D 1F468 1F3FD,fully-qualified,👩🏾‍🤝‍👨🏽,woman and man holding hands medium-dark skin tone medium skin tone,E12.0
People-Body,family,1F46B 1F3FE,fully-qualified,👫🏾,woman and man holding hands medium-dark skin tone,E12.0
People-Body,family,1F469 1F3FE 200D 1F91D 200D 1F468 1F3FF,fully-qualified,👩🏾‍🤝‍👨🏿,woman and man holding hands medium-dark skin tone dark skin tone,E12.0
People-Body,family,1F469 1F3FF 200D 1F91D 200D 1F468 1F3FB,fully-qualified,👩🏿‍🤝‍👨🏻,woman and man holding hands dark skin tone light skin tone,E12.0
People-Body,family,1F469 1F3FF 200D 1F91D 200D 1F468 1F3FC,fully-qualified,👩🏿‍🤝‍👨🏼,woman and man holding hands dark skin tone medium-light skin tone,E12.0
People-Body,family,1F469 1F3FF 200D 1F91D 200D 1F468 1F3FD,fully-qualified,👩🏿‍🤝‍👨🏽,woman and man holding hands dark skin tone medium skin tone,E12.0
People-Body,family,1F469 1F3FF 200D 1F91D 200D 1F468 1F3FE,fully-qualified,👩🏿‍🤝‍👨🏾,woman and man holding hands dark skin tone medium-dark skin tone,E12.0
People-Body,family,1F46B 1F3FF,fully-qualified,👫🏿,woman and man holding hands dark skin tone,E12.0
People-Body,family,1F46C,fully-qualified,👬,men holding hands,E1.0
People-Body,family,1F46C 1F3FB,fully-qualified,👬🏻,men holding hands light skin tone,E12.0
People-Body,family,1F468 1F3FB 200D 1F91D 200D 1F468 1F3FC,fully-qualified,👨🏻‍🤝‍👨🏼,men holding hands light skin tone medium-light skin tone,E12.1
People-Body,family,1F468 1F3FB 200D 1F91D 200D 1F468 1F3FD,fully-qualified,👨🏻‍🤝‍👨🏽,men holding hands light skin tone medium skin tone,E12.1
People-Body,family,1F468 1F3FB 200D 1F91D 200D 1F468 1F3FE,fully-qualified,👨🏻‍🤝‍👨🏾,men holding hands light skin tone medium-dark skin tone,E12.1
People-Body,family,1F468 1F3FB 200D 1F91D 200D 1F468 1F3FF,fully-qualified,👨🏻‍🤝‍👨🏿,men holding hands light skin tone dark skin tone,E12.1
People-Body,family,1F468 1F3FC 200D 1F91D 200D 1F468 1F3FB,fully-qualified,👨🏼‍🤝‍👨🏻,men holding hands medium-light skin tone light skin tone,E12.0
People-Body,family,1F46C 1F3FC,fully-qualified,👬🏼,men holding hands medium-light skin tone,E12.0
People-Body,family,1F468 1F3FC 200D 1F91D 200D 1F468 1F3FD,fully-qualified,👨🏼‍🤝‍👨🏽,men holding hands medium-light skin tone medium skin tone,E12.1
People-Body,family,1F468 1F3FC 200D 1F91D 200D 1F468 1F3FE,fully-qualified,👨🏼‍🤝‍👨🏾,men holding hands medium-light skin tone medium-dark skin tone,E12.1
People-Body,family,1F468 1F3FC 200D 1F91D 200D 1F468 1F3FF,fully-qualified,👨🏼‍🤝‍👨🏿,men holding hands medium-light skin tone dark skin tone,E12.1
People-Body,family,1F468 1F3FD 200D 1F91D 200D 1F468 1F3FB,fully-qualified,👨🏽‍🤝‍👨🏻,men holding hands medium skin tone light skin tone,E12.0
People-Body,family,1F468 1F3FD 200D 1F91D 200D 1F468 1F3FC,fully-qualified,👨🏽‍🤝‍👨🏼,men holding hands medium skin tone medium-light skin tone,E12.0
People-Body,family,1F46C 1F3FD,fully-qualified,👬🏽,men holding hands medium skin tone,E12.0
People-Body,family,1F468 1F3FD 200D 1F91D 200D 1F468 1F3FE,fully-qualified,👨🏽‍🤝‍👨🏾,men holding hands medium skin tone medium-dark skin tone,E12.1
People-Body,family,1F468 1F3FD 200D 1F91D 200D 1F468 1F3FF,fully-qualified,👨🏽‍🤝‍👨🏿,men holding hands medium skin tone dark skin tone,E12.1
People-Body,family,1F468 1F3FE 200D 1F91D 200D 1F468 1F3FB,fully-qualified,👨🏾‍🤝‍👨🏻,men holding hands medium-dark skin tone light skin tone,E12.0
People-Body,family,1F468 1F3FE 200D 1F91D 200D 1F468 1F3FC,fully-qualified,👨🏾‍🤝‍👨🏼,men holding hands medium-dark skin tone medium-light skin tone,E12.0
People-Body,family,1F468 1F3FE 200D 1F91D 200D 1F468 1F3FD,fully-qualified,👨🏾‍🤝‍👨🏽,men holding hands medium-dark skin tone medium skin tone,E12.0
People-Body,family,1F46C 1F3FE,fully-qualified,👬🏾,men holding hands medium-dark skin tone,E12.0
People-Body,family,1F468 1F3FE 200D 1F91D 200D 1F468 1F3FF,fully-qualified,👨🏾‍🤝‍👨🏿,men holding hands medium-dark skin tone dark skin tone,E12.1
People-Body,family,1F468 1F3FF 200D 1F91D 200D 1F468 1F3FB,fully-qualified,👨🏿‍🤝‍👨🏻,men holding hands dark skin tone light skin tone,E12.0
People-Body,family,1F468 1F3FF 200D 1F91D 200D 1F468 1F3FC,fully-qualified,👨🏿‍🤝‍👨🏼,men holding hands dark skin tone medium-light skin tone,E12.0
People-Body,family,1F468 1F3FF 200D 1F91D 200D 1F468 1F3FD,fully-qualified,👨🏿‍🤝‍👨🏽,men holding hands dark skin tone medium skin tone,E12.0
People-Body,family,1F468 1F3FF 200D 1F91D 200D 1F468 1F3FE,fully-qualified,👨🏿‍🤝‍👨🏾,men holding hands dark skin tone medium-dark skin tone,E12.0
People-Body,family,1F46C 1F3FF,fully-qualified,👬🏿,men holding hands dark skin tone,E12.0
People-Body,family,1F48F,fully-qualified,💏,kiss,E0.6
People-Body,family,1F48F 1F3FB,fully-qualified,💏🏻,kiss light skin tone,E13.1
People-Body,family,1F48F 1F3FC,fully-qualified,💏🏼,kiss medium-light skin tone,E13.1
People-Body,family,1F48F 1F3FD,fully-qualified,💏🏽,kiss medium skin tone,E13.1
People-Body,family,1F48F 1F3FE,fully-qualified,💏🏾,kiss medium-dark skin tone,E13.1
People-Body,family,1F48F 1F3FF,fully-qualified,💏🏿,kiss dark skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FC,fully-qualified,🧑🏻‍❤️‍💋‍🧑🏼,kiss person person light skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 200D 1F48B 200D 1F9D1 1F3FC,minimally-qualified,🧑🏻‍❤‍💋‍🧑🏼,kiss person person light skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FD,fully-qualified,🧑🏻‍❤️‍💋‍🧑🏽,kiss person person light skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 200D 1F48B 200D 1F9D1 1F3FD,minimally-qualified,🧑🏻‍❤‍💋‍🧑🏽,kiss person person light skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FE,fully-qualified,🧑🏻‍❤️‍💋‍🧑🏾,kiss person person light skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 200D 1F48B 200D 1F9D1 1F3FE,minimally-qualified,🧑🏻‍❤‍💋‍🧑🏾,kiss person person light skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FF,fully-qualified,🧑🏻‍❤️‍💋‍🧑🏿,kiss person person light skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 200D 1F48B 200D 1F9D1 1F3FF,minimally-qualified,🧑🏻‍❤‍💋‍🧑🏿,kiss person person light skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FB,fully-qualified,🧑🏼‍❤️‍💋‍🧑🏻,kiss person person medium-light skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 200D 1F48B 200D 1F9D1 1F3FB,minimally-qualified,🧑🏼‍❤‍💋‍🧑🏻,kiss person person medium-light skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FD,fully-qualified,🧑🏼‍❤️‍💋‍🧑🏽,kiss person person medium-light skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 200D 1F48B 200D 1F9D1 1F3FD,minimally-qualified,🧑🏼‍❤‍💋‍🧑🏽,kiss person person medium-light skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FE,fully-qualified,🧑🏼‍❤️‍💋‍🧑🏾,kiss person person medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 200D 1F48B 200D 1F9D1 1F3FE,minimally-qualified,🧑🏼‍❤‍💋‍🧑🏾,kiss person person medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FF,fully-qualified,🧑🏼‍❤️‍💋‍🧑🏿,kiss person person medium-light skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 200D 1F48B 200D 1F9D1 1F3FF,minimally-qualified,🧑🏼‍❤‍💋‍🧑🏿,kiss person person medium-light skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FB,fully-qualified,🧑🏽‍❤️‍💋‍🧑🏻,kiss person person medium skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 200D 1F48B 200D 1F9D1 1F3FB,minimally-qualified,🧑🏽‍❤‍💋‍🧑🏻,kiss person person medium skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FC,fully-qualified,🧑🏽‍❤️‍💋‍🧑🏼,kiss person person medium skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 200D 1F48B 200D 1F9D1 1F3FC,minimally-qualified,🧑🏽‍❤‍💋‍🧑🏼,kiss person person medium skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FE,fully-qualified,🧑🏽‍❤️‍💋‍🧑🏾,kiss person person medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 200D 1F48B 200D 1F9D1 1F3FE,minimally-qualified,🧑🏽‍❤‍💋‍🧑🏾,kiss person person medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FF,fully-qualified,🧑🏽‍❤️‍💋‍🧑🏿,kiss person person medium skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 200D 1F48B 200D 1F9D1 1F3FF,minimally-qualified,🧑🏽‍❤‍💋‍🧑🏿,kiss person person medium skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FB,fully-qualified,🧑🏾‍❤️‍💋‍🧑🏻,kiss person person medium-dark skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 200D 1F48B 200D 1F9D1 1F3FB,minimally-qualified,🧑🏾‍❤‍💋‍🧑🏻,kiss person person medium-dark skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FC,fully-qualified,🧑🏾‍❤️‍💋‍🧑🏼,kiss person person medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 200D 1F48B 200D 1F9D1 1F3FC,minimally-qualified,🧑🏾‍❤‍💋‍🧑🏼,kiss person person medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FD,fully-qualified,🧑🏾‍❤️‍💋‍🧑🏽,kiss person person medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 200D 1F48B 200D 1F9D1 1F3FD,minimally-qualified,🧑🏾‍❤‍💋‍🧑🏽,kiss person person medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FF,fully-qualified,🧑🏾‍❤️‍💋‍🧑🏿,kiss person person medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 200D 1F48B 200D 1F9D1 1F3FF,minimally-qualified,🧑🏾‍❤‍💋‍🧑🏿,kiss person person medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FB,fully-qualified,🧑🏿‍❤️‍💋‍🧑🏻,kiss person person dark skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 200D 1F48B 200D 1F9D1 1F3FB,minimally-qualified,🧑🏿‍❤‍💋‍🧑🏻,kiss person person dark skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FC,fully-qualified,🧑🏿‍❤️‍💋‍🧑🏼,kiss person person dark skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 200D 1F48B 200D 1F9D1 1F3FC,minimally-qualified,🧑🏿‍❤‍💋‍🧑🏼,kiss person person dark skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FD,fully-qualified,🧑🏿‍❤️‍💋‍🧑🏽,kiss person person dark skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 200D 1F48B 200D 1F9D1 1F3FD,minimally-qualified,🧑🏿‍❤‍💋‍🧑🏽,kiss person person dark skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FE,fully-qualified,🧑🏿‍❤️‍💋‍🧑🏾,kiss person person dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 200D 1F48B 200D 1F9D1 1F3FE,minimally-qualified,🧑🏿‍❤‍💋‍🧑🏾,kiss person person dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 200D 2764 FE0F 200D 1F48B 200D 1F468,fully-qualified,👩‍❤️‍💋‍👨,kiss woman man,E2.0
People-Body,family,1F469 200D 2764 200D 1F48B 200D 1F468,minimally-qualified,👩‍❤‍💋‍👨,kiss woman man,E2.0
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB,fully-qualified,👩🏻‍❤️‍💋‍👨🏻,kiss woman man light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FB,minimally-qualified,👩🏻‍❤‍💋‍👨🏻,kiss woman man light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC,fully-qualified,👩🏻‍❤️‍💋‍👨🏼,kiss woman man light skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FC,minimally-qualified,👩🏻‍❤‍💋‍👨🏼,kiss woman man light skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD,fully-qualified,👩🏻‍❤️‍💋‍👨🏽,kiss woman man light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FD,minimally-qualified,👩🏻‍❤‍💋‍👨🏽,kiss woman man light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE,fully-qualified,👩🏻‍❤️‍💋‍👨🏾,kiss woman man light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FE,minimally-qualified,👩🏻‍❤‍💋‍👨🏾,kiss woman man light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF,fully-qualified,👩🏻‍❤️‍💋‍👨🏿,kiss woman man light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FF,minimally-qualified,👩🏻‍❤‍💋‍👨🏿,kiss woman man light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB,fully-qualified,👩🏼‍❤️‍💋‍👨🏻,kiss woman man medium-light skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FB,minimally-qualified,👩🏼‍❤‍💋‍👨🏻,kiss woman man medium-light skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC,fully-qualified,👩🏼‍❤️‍💋‍👨🏼,kiss woman man medium-light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FC,minimally-qualified,👩🏼‍❤‍💋‍👨🏼,kiss woman man medium-light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD,fully-qualified,👩🏼‍❤️‍💋‍👨🏽,kiss woman man medium-light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FD,minimally-qualified,👩🏼‍❤‍💋‍👨🏽,kiss woman man medium-light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE,fully-qualified,👩🏼‍❤️‍💋‍👨🏾,kiss woman man medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FE,minimally-qualified,👩🏼‍❤‍💋‍👨🏾,kiss woman man medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF,fully-qualified,👩🏼‍❤️‍💋‍👨🏿,kiss woman man medium-light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FF,minimally-qualified,👩🏼‍❤‍💋‍👨🏿,kiss woman man medium-light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB,fully-qualified,👩🏽‍❤️‍💋‍👨🏻,kiss woman man medium skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FB,minimally-qualified,👩🏽‍❤‍💋‍👨🏻,kiss woman man medium skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC,fully-qualified,👩🏽‍❤️‍💋‍👨🏼,kiss woman man medium skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FC,minimally-qualified,👩🏽‍❤‍💋‍👨🏼,kiss woman man medium skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD,fully-qualified,👩🏽‍❤️‍💋‍👨🏽,kiss woman man medium skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FD,minimally-qualified,👩🏽‍❤‍💋‍👨🏽,kiss woman man medium skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE,fully-qualified,👩🏽‍❤️‍💋‍👨🏾,kiss woman man medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FE,minimally-qualified,👩🏽‍❤‍💋‍👨🏾,kiss woman man medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF,fully-qualified,👩🏽‍❤️‍💋‍👨🏿,kiss woman man medium skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FF,minimally-qualified,👩🏽‍❤‍💋‍👨🏿,kiss woman man medium skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB,fully-qualified,👩🏾‍❤️‍💋‍👨🏻,kiss woman man medium-dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FB,minimally-qualified,👩🏾‍❤‍💋‍👨🏻,kiss woman man medium-dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC,fully-qualified,👩🏾‍❤️‍💋‍👨🏼,kiss woman man medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FC,minimally-qualified,👩🏾‍❤‍💋‍👨🏼,kiss woman man medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD,fully-qualified,👩🏾‍❤️‍💋‍👨🏽,kiss woman man medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FD,minimally-qualified,👩🏾‍❤‍💋‍👨🏽,kiss woman man medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE,fully-qualified,👩🏾‍❤️‍💋‍👨🏾,kiss woman man medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FE,minimally-qualified,👩🏾‍❤‍💋‍👨🏾,kiss woman man medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF,fully-qualified,👩🏾‍❤️‍💋‍👨🏿,kiss woman man medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FF,minimally-qualified,👩🏾‍❤‍💋‍👨🏿,kiss woman man medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB,fully-qualified,👩🏿‍❤️‍💋‍👨🏻,kiss woman man dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FB,minimally-qualified,👩🏿‍❤‍💋‍👨🏻,kiss woman man dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC,fully-qualified,👩🏿‍❤️‍💋‍👨🏼,kiss woman man dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FC,minimally-qualified,👩🏿‍❤‍💋‍👨🏼,kiss woman man dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD,fully-qualified,👩🏿‍❤️‍💋‍👨🏽,kiss woman man dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FD,minimally-qualified,👩🏿‍❤‍💋‍👨🏽,kiss woman man dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE,fully-qualified,👩🏿‍❤️‍💋‍👨🏾,kiss woman man dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FE,minimally-qualified,👩🏿‍❤‍💋‍👨🏾,kiss woman man dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF,fully-qualified,👩🏿‍❤️‍💋‍👨🏿,kiss woman man dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FF,minimally-qualified,👩🏿‍❤‍💋‍👨🏿,kiss woman man dark skin tone,E13.1
People-Body,family,1F468 200D 2764 FE0F 200D 1F48B 200D 1F468,fully-qualified,👨‍❤️‍💋‍👨,kiss man man,E2.0
People-Body,family,1F468 200D 2764 200D 1F48B 200D 1F468,minimally-qualified,👨‍❤‍💋‍👨,kiss man man,E2.0
People-Body,family,1F468 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB,fully-qualified,👨🏻‍❤️‍💋‍👨🏻,kiss man man light skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FB,minimally-qualified,👨🏻‍❤‍💋‍👨🏻,kiss man man light skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC,fully-qualified,👨🏻‍❤️‍💋‍👨🏼,kiss man man light skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FC,minimally-qualified,👨🏻‍❤‍💋‍👨🏼,kiss man man light skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD,fully-qualified,👨🏻‍❤️‍💋‍👨🏽,kiss man man light skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FD,minimally-qualified,👨🏻‍❤‍💋‍👨🏽,kiss man man light skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE,fully-qualified,👨🏻‍❤️‍💋‍👨🏾,kiss man man light skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FE,minimally-qualified,👨🏻‍❤‍💋‍👨🏾,kiss man man light skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF,fully-qualified,👨🏻‍❤️‍💋‍👨🏿,kiss man man light skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FF,minimally-qualified,👨🏻‍❤‍💋‍👨🏿,kiss man man light skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB,fully-qualified,👨🏼‍❤️‍💋‍👨🏻,kiss man man medium-light skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FB,minimally-qualified,👨🏼‍❤‍💋‍👨🏻,kiss man man medium-light skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC,fully-qualified,👨🏼‍❤️‍💋‍👨🏼,kiss man man medium-light skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FC,minimally-qualified,👨🏼‍❤‍💋‍👨🏼,kiss man man medium-light skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD,fully-qualified,👨🏼‍❤️‍💋‍👨🏽,kiss man man medium-light skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FD,minimally-qualified,👨🏼‍❤‍💋‍👨🏽,kiss man man medium-light skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE,fully-qualified,👨🏼‍❤️‍💋‍👨🏾,kiss man man medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FE,minimally-qualified,👨🏼‍❤‍💋‍👨🏾,kiss man man medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF,fully-qualified,👨🏼‍❤️‍💋‍👨🏿,kiss man man medium-light skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FF,minimally-qualified,👨🏼‍❤‍💋‍👨🏿,kiss man man medium-light skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB,fully-qualified,👨🏽‍❤️‍💋‍👨🏻,kiss man man medium skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FB,minimally-qualified,👨🏽‍❤‍💋‍👨🏻,kiss man man medium skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC,fully-qualified,👨🏽‍❤️‍💋‍👨🏼,kiss man man medium skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FC,minimally-qualified,👨🏽‍❤‍💋‍👨🏼,kiss man man medium skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD,fully-qualified,👨🏽‍❤️‍💋‍👨🏽,kiss man man medium skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FD,minimally-qualified,👨🏽‍❤‍💋‍👨🏽,kiss man man medium skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE,fully-qualified,👨🏽‍❤️‍💋‍👨🏾,kiss man man medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FE,minimally-qualified,👨🏽‍❤‍💋‍👨🏾,kiss man man medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF,fully-qualified,👨🏽‍❤️‍💋‍👨🏿,kiss man man medium skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FF,minimally-qualified,👨🏽‍❤‍💋‍👨🏿,kiss man man medium skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB,fully-qualified,👨🏾‍❤️‍💋‍👨🏻,kiss man man medium-dark skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FB,minimally-qualified,👨🏾‍❤‍💋‍👨🏻,kiss man man medium-dark skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC,fully-qualified,👨🏾‍❤️‍💋‍👨🏼,kiss man man medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FC,minimally-qualified,👨🏾‍❤‍💋‍👨🏼,kiss man man medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD,fully-qualified,👨🏾‍❤️‍💋‍👨🏽,kiss man man medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FD,minimally-qualified,👨🏾‍❤‍💋‍👨🏽,kiss man man medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE,fully-qualified,👨🏾‍❤️‍💋‍👨🏾,kiss man man medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FE,minimally-qualified,👨🏾‍❤‍💋‍👨🏾,kiss man man medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF,fully-qualified,👨🏾‍❤️‍💋‍👨🏿,kiss man man medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FF,minimally-qualified,👨🏾‍❤‍💋‍👨🏿,kiss man man medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB,fully-qualified,👨🏿‍❤️‍💋‍👨🏻,kiss man man dark skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FB,minimally-qualified,👨🏿‍❤‍💋‍👨🏻,kiss man man dark skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC,fully-qualified,👨🏿‍❤️‍💋‍👨🏼,kiss man man dark skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FC,minimally-qualified,👨🏿‍❤‍💋‍👨🏼,kiss man man dark skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD,fully-qualified,👨🏿‍❤️‍💋‍👨🏽,kiss man man dark skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FD,minimally-qualified,👨🏿‍❤‍💋‍👨🏽,kiss man man dark skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE,fully-qualified,👨🏿‍❤️‍💋‍👨🏾,kiss man man dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FE,minimally-qualified,👨🏿‍❤‍💋‍👨🏾,kiss man man dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF,fully-qualified,👨🏿‍❤️‍💋‍👨🏿,kiss man man dark skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FF,minimally-qualified,👨🏿‍❤‍💋‍👨🏿,kiss man man dark skin tone,E13.1
People-Body,family,1F469 200D 2764 FE0F 200D 1F48B 200D 1F469,fully-qualified,👩‍❤️‍💋‍👩,kiss woman woman,E2.0
People-Body,family,1F469 200D 2764 200D 1F48B 200D 1F469,minimally-qualified,👩‍❤‍💋‍👩,kiss woman woman,E2.0
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FB,fully-qualified,👩🏻‍❤️‍💋‍👩🏻,kiss woman woman light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F48B 200D 1F469 1F3FB,minimally-qualified,👩🏻‍❤‍💋‍👩🏻,kiss woman woman light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FC,fully-qualified,👩🏻‍❤️‍💋‍👩🏼,kiss woman woman light skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F48B 200D 1F469 1F3FC,minimally-qualified,👩🏻‍❤‍💋‍👩🏼,kiss woman woman light skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FD,fully-qualified,👩🏻‍❤️‍💋‍👩🏽,kiss woman woman light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F48B 200D 1F469 1F3FD,minimally-qualified,👩🏻‍❤‍💋‍👩🏽,kiss woman woman light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FE,fully-qualified,👩🏻‍❤️‍💋‍👩🏾,kiss woman woman light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F48B 200D 1F469 1F3FE,minimally-qualified,👩🏻‍❤‍💋‍👩🏾,kiss woman woman light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FF,fully-qualified,👩🏻‍❤️‍💋‍👩🏿,kiss woman woman light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F48B 200D 1F469 1F3FF,minimally-qualified,👩🏻‍❤‍💋‍👩🏿,kiss woman woman light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FB,fully-qualified,👩🏼‍❤️‍💋‍👩🏻,kiss woman woman medium-light skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F48B 200D 1F469 1F3FB,minimally-qualified,👩🏼‍❤‍💋‍👩🏻,kiss woman woman medium-light skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FC,fully-qualified,👩🏼‍❤️‍💋‍👩🏼,kiss woman woman medium-light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F48B 200D 1F469 1F3FC,minimally-qualified,👩🏼‍❤‍💋‍👩🏼,kiss woman woman medium-light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FD,fully-qualified,👩🏼‍❤️‍💋‍👩🏽,kiss woman woman medium-light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F48B 200D 1F469 1F3FD,minimally-qualified,👩🏼‍❤‍💋‍👩🏽,kiss woman woman medium-light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FE,fully-qualified,👩🏼‍❤️‍💋‍👩🏾,kiss woman woman medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F48B 200D 1F469 1F3FE,minimally-qualified,👩🏼‍❤‍💋‍👩🏾,kiss woman woman medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FF,fully-qualified,👩🏼‍❤️‍💋‍👩🏿,kiss woman woman medium-light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F48B 200D 1F469 1F3FF,minimally-qualified,👩🏼‍❤‍💋‍👩🏿,kiss woman woman medium-light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FB,fully-qualified,👩🏽‍❤️‍💋‍👩🏻,kiss woman woman medium skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F48B 200D 1F469 1F3FB,minimally-qualified,👩🏽‍❤‍💋‍👩🏻,kiss woman woman medium skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FC,fully-qualified,👩🏽‍❤️‍💋‍👩🏼,kiss woman woman medium skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F48B 200D 1F469 1F3FC,minimally-qualified,👩🏽‍❤‍💋‍👩🏼,kiss woman woman medium skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FD,fully-qualified,👩🏽‍❤️‍💋‍👩🏽,kiss woman woman medium skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F48B 200D 1F469 1F3FD,minimally-qualified,👩🏽‍❤‍💋‍👩🏽,kiss woman woman medium skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FE,fully-qualified,👩🏽‍❤️‍💋‍👩🏾,kiss woman woman medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F48B 200D 1F469 1F3FE,minimally-qualified,👩🏽‍❤‍💋‍👩🏾,kiss woman woman medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FF,fully-qualified,👩🏽‍❤️‍💋‍👩🏿,kiss woman woman medium skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F48B 200D 1F469 1F3FF,minimally-qualified,👩🏽‍❤‍💋‍👩🏿,kiss woman woman medium skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FB,fully-qualified,👩🏾‍❤️‍💋‍👩🏻,kiss woman woman medium-dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F48B 200D 1F469 1F3FB,minimally-qualified,👩🏾‍❤‍💋‍👩🏻,kiss woman woman medium-dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FC,fully-qualified,👩🏾‍❤️‍💋‍👩🏼,kiss woman woman medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F48B 200D 1F469 1F3FC,minimally-qualified,👩🏾‍❤‍💋‍👩🏼,kiss woman woman medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FD,fully-qualified,👩🏾‍❤️‍💋‍👩🏽,kiss woman woman medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F48B 200D 1F469 1F3FD,minimally-qualified,👩🏾‍❤‍💋‍👩🏽,kiss woman woman medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FE,fully-qualified,👩🏾‍❤️‍💋‍👩🏾,kiss woman woman medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F48B 200D 1F469 1F3FE,minimally-qualified,👩🏾‍❤‍💋‍👩🏾,kiss woman woman medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FF,fully-qualified,👩🏾‍❤️‍💋‍👩🏿,kiss woman woman medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F48B 200D 1F469 1F3FF,minimally-qualified,👩🏾‍❤‍💋‍👩🏿,kiss woman woman medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FB,fully-qualified,👩🏿‍❤️‍💋‍👩🏻,kiss woman woman dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F48B 200D 1F469 1F3FB,minimally-qualified,👩🏿‍❤‍💋‍👩🏻,kiss woman woman dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FC,fully-qualified,👩🏿‍❤️‍💋‍👩🏼,kiss woman woman dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F48B 200D 1F469 1F3FC,minimally-qualified,👩🏿‍❤‍💋‍👩🏼,kiss woman woman dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FD,fully-qualified,👩🏿‍❤️‍💋‍👩🏽,kiss woman woman dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F48B 200D 1F469 1F3FD,minimally-qualified,👩🏿‍❤‍💋‍👩🏽,kiss woman woman dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FE,fully-qualified,👩🏿‍❤️‍💋‍👩🏾,kiss woman woman dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F48B 200D 1F469 1F3FE,minimally-qualified,👩🏿‍❤‍💋‍👩🏾,kiss woman woman dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FF,fully-qualified,👩🏿‍❤️‍💋‍👩🏿,kiss woman woman dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F48B 200D 1F469 1F3FF,minimally-qualified,👩🏿‍❤‍💋‍👩🏿,kiss woman woman dark skin tone,E13.1
People-Body,family,1F491,fully-qualified,💑,couple with heart,E0.6
People-Body,family,1F491 1F3FB,fully-qualified,💑🏻,couple with heart light skin tone,E13.1
People-Body,family,1F491 1F3FC,fully-qualified,💑🏼,couple with heart medium-light skin tone,E13.1
People-Body,family,1F491 1F3FD,fully-qualified,💑🏽,couple with heart medium skin tone,E13.1
People-Body,family,1F491 1F3FE,fully-qualified,💑🏾,couple with heart medium-dark skin tone,E13.1
People-Body,family,1F491 1F3FF,fully-qualified,💑🏿,couple with heart dark skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 FE0F 200D 1F9D1 1F3FC,fully-qualified,🧑🏻‍❤️‍🧑🏼,couple with heart person person light skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 200D 1F9D1 1F3FC,minimally-qualified,🧑🏻‍❤‍🧑🏼,couple with heart person person light skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 FE0F 200D 1F9D1 1F3FD,fully-qualified,🧑🏻‍❤️‍🧑🏽,couple with heart person person light skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 200D 1F9D1 1F3FD,minimally-qualified,🧑🏻‍❤‍🧑🏽,couple with heart person person light skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 FE0F 200D 1F9D1 1F3FE,fully-qualified,🧑🏻‍❤️‍🧑🏾,couple with heart person person light skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 200D 1F9D1 1F3FE,minimally-qualified,🧑🏻‍❤‍🧑🏾,couple with heart person person light skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 FE0F 200D 1F9D1 1F3FF,fully-qualified,🧑🏻‍❤️‍🧑🏿,couple with heart person person light skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FB 200D 2764 200D 1F9D1 1F3FF,minimally-qualified,🧑🏻‍❤‍🧑🏿,couple with heart person person light skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 FE0F 200D 1F9D1 1F3FB,fully-qualified,🧑🏼‍❤️‍🧑🏻,couple with heart person person medium-light skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 200D 1F9D1 1F3FB,minimally-qualified,🧑🏼‍❤‍🧑🏻,couple with heart person person medium-light skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 FE0F 200D 1F9D1 1F3FD,fully-qualified,🧑🏼‍❤️‍🧑🏽,couple with heart person person medium-light skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 200D 1F9D1 1F3FD,minimally-qualified,🧑🏼‍❤‍🧑🏽,couple with heart person person medium-light skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 FE0F 200D 1F9D1 1F3FE,fully-qualified,🧑🏼‍❤️‍🧑🏾,couple with heart person person medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 200D 1F9D1 1F3FE,minimally-qualified,🧑🏼‍❤‍🧑🏾,couple with heart person person medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 FE0F 200D 1F9D1 1F3FF,fully-qualified,🧑🏼‍❤️‍🧑🏿,couple with heart person person medium-light skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FC 200D 2764 200D 1F9D1 1F3FF,minimally-qualified,🧑🏼‍❤‍🧑🏿,couple with heart person person medium-light skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 FE0F 200D 1F9D1 1F3FB,fully-qualified,🧑🏽‍❤️‍🧑🏻,couple with heart person person medium skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 200D 1F9D1 1F3FB,minimally-qualified,🧑🏽‍❤‍🧑🏻,couple with heart person person medium skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 FE0F 200D 1F9D1 1F3FC,fully-qualified,🧑🏽‍❤️‍🧑🏼,couple with heart person person medium skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 200D 1F9D1 1F3FC,minimally-qualified,🧑🏽‍❤‍🧑🏼,couple with heart person person medium skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 FE0F 200D 1F9D1 1F3FE,fully-qualified,🧑🏽‍❤️‍🧑🏾,couple with heart person person medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 200D 1F9D1 1F3FE,minimally-qualified,🧑🏽‍❤‍🧑🏾,couple with heart person person medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 FE0F 200D 1F9D1 1F3FF,fully-qualified,🧑🏽‍❤️‍🧑🏿,couple with heart person person medium skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FD 200D 2764 200D 1F9D1 1F3FF,minimally-qualified,🧑🏽‍❤‍🧑🏿,couple with heart person person medium skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 FE0F 200D 1F9D1 1F3FB,fully-qualified,🧑🏾‍❤️‍🧑🏻,couple with heart person person medium-dark skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 200D 1F9D1 1F3FB,minimally-qualified,🧑🏾‍❤‍🧑🏻,couple with heart person person medium-dark skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 FE0F 200D 1F9D1 1F3FC,fully-qualified,🧑🏾‍❤️‍🧑🏼,couple with heart person person medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 200D 1F9D1 1F3FC,minimally-qualified,🧑🏾‍❤‍🧑🏼,couple with heart person person medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 FE0F 200D 1F9D1 1F3FD,fully-qualified,🧑🏾‍❤️‍🧑🏽,couple with heart person person medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 200D 1F9D1 1F3FD,minimally-qualified,🧑🏾‍❤‍🧑🏽,couple with heart person person medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 FE0F 200D 1F9D1 1F3FF,fully-qualified,🧑🏾‍❤️‍🧑🏿,couple with heart person person medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FE 200D 2764 200D 1F9D1 1F3FF,minimally-qualified,🧑🏾‍❤‍🧑🏿,couple with heart person person medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 FE0F 200D 1F9D1 1F3FB,fully-qualified,🧑🏿‍❤️‍🧑🏻,couple with heart person person dark skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 200D 1F9D1 1F3FB,minimally-qualified,🧑🏿‍❤‍🧑🏻,couple with heart person person dark skin tone light skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 FE0F 200D 1F9D1 1F3FC,fully-qualified,🧑🏿‍❤️‍🧑🏼,couple with heart person person dark skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 200D 1F9D1 1F3FC,minimally-qualified,🧑🏿‍❤‍🧑🏼,couple with heart person person dark skin tone medium-light skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 FE0F 200D 1F9D1 1F3FD,fully-qualified,🧑🏿‍❤️‍🧑🏽,couple with heart person person dark skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 200D 1F9D1 1F3FD,minimally-qualified,🧑🏿‍❤‍🧑🏽,couple with heart person person dark skin tone medium skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 FE0F 200D 1F9D1 1F3FE,fully-qualified,🧑🏿‍❤️‍🧑🏾,couple with heart person person dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F9D1 1F3FF 200D 2764 200D 1F9D1 1F3FE,minimally-qualified,🧑🏿‍❤‍🧑🏾,couple with heart person person dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 200D 2764 FE0F 200D 1F468,fully-qualified,👩‍❤️‍👨,couple with heart woman man,E2.0
People-Body,family,1F469 200D 2764 200D 1F468,minimally-qualified,👩‍❤‍👨,couple with heart woman man,E2.0
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F468 1F3FB,fully-qualified,👩🏻‍❤️‍👨🏻,couple with heart woman man light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F468 1F3FB,minimally-qualified,👩🏻‍❤‍👨🏻,couple with heart woman man light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F468 1F3FC,fully-qualified,👩🏻‍❤️‍👨🏼,couple with heart woman man light skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F468 1F3FC,minimally-qualified,👩🏻‍❤‍👨🏼,couple with heart woman man light skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F468 1F3FD,fully-qualified,👩🏻‍❤️‍👨🏽,couple with heart woman man light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F468 1F3FD,minimally-qualified,👩🏻‍❤‍👨🏽,couple with heart woman man light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F468 1F3FE,fully-qualified,👩🏻‍❤️‍👨🏾,couple with heart woman man light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F468 1F3FE,minimally-qualified,👩🏻‍❤‍👨🏾,couple with heart woman man light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F468 1F3FF,fully-qualified,👩🏻‍❤️‍👨🏿,couple with heart woman man light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F468 1F3FF,minimally-qualified,👩🏻‍❤‍👨🏿,couple with heart woman man light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F468 1F3FB,fully-qualified,👩🏼‍❤️‍👨🏻,couple with heart woman man medium-light skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F468 1F3FB,minimally-qualified,👩🏼‍❤‍👨🏻,couple with heart woman man medium-light skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F468 1F3FC,fully-qualified,👩🏼‍❤️‍👨🏼,couple with heart woman man medium-light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F468 1F3FC,minimally-qualified,👩🏼‍❤‍👨🏼,couple with heart woman man medium-light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F468 1F3FD,fully-qualified,👩🏼‍❤️‍👨🏽,couple with heart woman man medium-light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F468 1F3FD,minimally-qualified,👩🏼‍❤‍👨🏽,couple with heart woman man medium-light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F468 1F3FE,fully-qualified,👩🏼‍❤️‍👨🏾,couple with heart woman man medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F468 1F3FE,minimally-qualified,👩🏼‍❤‍👨🏾,couple with heart woman man medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F468 1F3FF,fully-qualified,👩🏼‍❤️‍👨🏿,couple with heart woman man medium-light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F468 1F3FF,minimally-qualified,👩🏼‍❤‍👨🏿,couple with heart woman man medium-light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F468 1F3FB,fully-qualified,👩🏽‍❤️‍👨🏻,couple with heart woman man medium skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F468 1F3FB,minimally-qualified,👩🏽‍❤‍👨🏻,couple with heart woman man medium skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F468 1F3FC,fully-qualified,👩🏽‍❤️‍👨🏼,couple with heart woman man medium skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F468 1F3FC,minimally-qualified,👩🏽‍❤‍👨🏼,couple with heart woman man medium skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F468 1F3FD,fully-qualified,👩🏽‍❤️‍👨🏽,couple with heart woman man medium skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F468 1F3FD,minimally-qualified,👩🏽‍❤‍👨🏽,couple with heart woman man medium skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F468 1F3FE,fully-qualified,👩🏽‍❤️‍👨🏾,couple with heart woman man medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F468 1F3FE,minimally-qualified,👩🏽‍❤‍👨🏾,couple with heart woman man medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F468 1F3FF,fully-qualified,👩🏽‍❤️‍👨🏿,couple with heart woman man medium skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F468 1F3FF,minimally-qualified,👩🏽‍❤‍👨🏿,couple with heart woman man medium skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F468 1F3FB,fully-qualified,👩🏾‍❤️‍👨🏻,couple with heart woman man medium-dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F468 1F3FB,minimally-qualified,👩🏾‍❤‍👨🏻,couple with heart woman man medium-dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F468 1F3FC,fully-qualified,👩🏾‍❤️‍👨🏼,couple with heart woman man medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F468 1F3FC,minimally-qualified,👩🏾‍❤‍👨🏼,couple with heart woman man medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F468 1F3FD,fully-qualified,👩🏾‍❤️‍👨🏽,couple with heart woman man medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F468 1F3FD,minimally-qualified,👩🏾‍❤‍👨🏽,couple with heart woman man medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F468 1F3FE,fully-qualified,👩🏾‍❤️‍👨🏾,couple with heart woman man medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F468 1F3FE,minimally-qualified,👩🏾‍❤‍👨🏾,couple with heart woman man medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F468 1F3FF,fully-qualified,👩🏾‍❤️‍👨🏿,couple with heart woman man medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F468 1F3FF,minimally-qualified,👩🏾‍❤‍👨🏿,couple with heart woman man medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F468 1F3FB,fully-qualified,👩🏿‍❤️‍👨🏻,couple with heart woman man dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F468 1F3FB,minimally-qualified,👩🏿‍❤‍👨🏻,couple with heart woman man dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F468 1F3FC,fully-qualified,👩🏿‍❤️‍👨🏼,couple with heart woman man dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F468 1F3FC,minimally-qualified,👩🏿‍❤‍👨🏼,couple with heart woman man dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F468 1F3FD,fully-qualified,👩🏿‍❤️‍👨🏽,couple with heart woman man dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F468 1F3FD,minimally-qualified,👩🏿‍❤‍👨🏽,couple with heart woman man dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F468 1F3FE,fully-qualified,👩🏿‍❤️‍👨🏾,couple with heart woman man dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F468 1F3FE,minimally-qualified,👩🏿‍❤‍👨🏾,couple with heart woman man dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F468 1F3FF,fully-qualified,👩🏿‍❤️‍👨🏿,couple with heart woman man dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F468 1F3FF,minimally-qualified,👩🏿‍❤‍👨🏿,couple with heart woman man dark skin tone,E13.1
People-Body,family,1F468 200D 2764 FE0F 200D 1F468,fully-qualified,👨‍❤️‍👨,couple with heart man man,E2.0
People-Body,family,1F468 200D 2764 200D 1F468,minimally-qualified,👨‍❤‍👨,couple with heart man man,E2.0
People-Body,family,1F468 1F3FB 200D 2764 FE0F 200D 1F468 1F3FB,fully-qualified,👨🏻‍❤️‍👨🏻,couple with heart man man light skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 200D 1F468 1F3FB,minimally-qualified,👨🏻‍❤‍👨🏻,couple with heart man man light skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 FE0F 200D 1F468 1F3FC,fully-qualified,👨🏻‍❤️‍👨🏼,couple with heart man man light skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 200D 1F468 1F3FC,minimally-qualified,👨🏻‍❤‍👨🏼,couple with heart man man light skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 FE0F 200D 1F468 1F3FD,fully-qualified,👨🏻‍❤️‍👨🏽,couple with heart man man light skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 200D 1F468 1F3FD,minimally-qualified,👨🏻‍❤‍👨🏽,couple with heart man man light skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 FE0F 200D 1F468 1F3FE,fully-qualified,👨🏻‍❤️‍👨🏾,couple with heart man man light skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 200D 1F468 1F3FE,minimally-qualified,👨🏻‍❤‍👨🏾,couple with heart man man light skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 FE0F 200D 1F468 1F3FF,fully-qualified,👨🏻‍❤️‍👨🏿,couple with heart man man light skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FB 200D 2764 200D 1F468 1F3FF,minimally-qualified,👨🏻‍❤‍👨🏿,couple with heart man man light skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 FE0F 200D 1F468 1F3FB,fully-qualified,👨🏼‍❤️‍👨🏻,couple with heart man man medium-light skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 200D 1F468 1F3FB,minimally-qualified,👨🏼‍❤‍👨🏻,couple with heart man man medium-light skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 FE0F 200D 1F468 1F3FC,fully-qualified,👨🏼‍❤️‍👨🏼,couple with heart man man medium-light skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 200D 1F468 1F3FC,minimally-qualified,👨🏼‍❤‍👨🏼,couple with heart man man medium-light skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 FE0F 200D 1F468 1F3FD,fully-qualified,👨🏼‍❤️‍👨🏽,couple with heart man man medium-light skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 200D 1F468 1F3FD,minimally-qualified,👨🏼‍❤‍👨🏽,couple with heart man man medium-light skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 FE0F 200D 1F468 1F3FE,fully-qualified,👨🏼‍❤️‍👨🏾,couple with heart man man medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 200D 1F468 1F3FE,minimally-qualified,👨🏼‍❤‍👨🏾,couple with heart man man medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 FE0F 200D 1F468 1F3FF,fully-qualified,👨🏼‍❤️‍👨🏿,couple with heart man man medium-light skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FC 200D 2764 200D 1F468 1F3FF,minimally-qualified,👨🏼‍❤‍👨🏿,couple with heart man man medium-light skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 FE0F 200D 1F468 1F3FB,fully-qualified,👨🏽‍❤️‍👨🏻,couple with heart man man medium skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 200D 1F468 1F3FB,minimally-qualified,👨🏽‍❤‍👨🏻,couple with heart man man medium skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 FE0F 200D 1F468 1F3FC,fully-qualified,👨🏽‍❤️‍👨🏼,couple with heart man man medium skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 200D 1F468 1F3FC,minimally-qualified,👨🏽‍❤‍👨🏼,couple with heart man man medium skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 FE0F 200D 1F468 1F3FD,fully-qualified,👨🏽‍❤️‍👨🏽,couple with heart man man medium skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 200D 1F468 1F3FD,minimally-qualified,👨🏽‍❤‍👨🏽,couple with heart man man medium skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 FE0F 200D 1F468 1F3FE,fully-qualified,👨🏽‍❤️‍👨🏾,couple with heart man man medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 200D 1F468 1F3FE,minimally-qualified,👨🏽‍❤‍👨🏾,couple with heart man man medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 FE0F 200D 1F468 1F3FF,fully-qualified,👨🏽‍❤️‍👨🏿,couple with heart man man medium skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FD 200D 2764 200D 1F468 1F3FF,minimally-qualified,👨🏽‍❤‍👨🏿,couple with heart man man medium skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 FE0F 200D 1F468 1F3FB,fully-qualified,👨🏾‍❤️‍👨🏻,couple with heart man man medium-dark skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 200D 1F468 1F3FB,minimally-qualified,👨🏾‍❤‍👨🏻,couple with heart man man medium-dark skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 FE0F 200D 1F468 1F3FC,fully-qualified,👨🏾‍❤️‍👨🏼,couple with heart man man medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 200D 1F468 1F3FC,minimally-qualified,👨🏾‍❤‍👨🏼,couple with heart man man medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 FE0F 200D 1F468 1F3FD,fully-qualified,👨🏾‍❤️‍👨🏽,couple with heart man man medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 200D 1F468 1F3FD,minimally-qualified,👨🏾‍❤‍👨🏽,couple with heart man man medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 FE0F 200D 1F468 1F3FE,fully-qualified,👨🏾‍❤️‍👨🏾,couple with heart man man medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 200D 1F468 1F3FE,minimally-qualified,👨🏾‍❤‍👨🏾,couple with heart man man medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 FE0F 200D 1F468 1F3FF,fully-qualified,👨🏾‍❤️‍👨🏿,couple with heart man man medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FE 200D 2764 200D 1F468 1F3FF,minimally-qualified,👨🏾‍❤‍👨🏿,couple with heart man man medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 FE0F 200D 1F468 1F3FB,fully-qualified,👨🏿‍❤️‍👨🏻,couple with heart man man dark skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 200D 1F468 1F3FB,minimally-qualified,👨🏿‍❤‍👨🏻,couple with heart man man dark skin tone light skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 FE0F 200D 1F468 1F3FC,fully-qualified,👨🏿‍❤️‍👨🏼,couple with heart man man dark skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 200D 1F468 1F3FC,minimally-qualified,👨🏿‍❤‍👨🏼,couple with heart man man dark skin tone medium-light skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 FE0F 200D 1F468 1F3FD,fully-qualified,👨🏿‍❤️‍👨🏽,couple with heart man man dark skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 200D 1F468 1F3FD,minimally-qualified,👨🏿‍❤‍👨🏽,couple with heart man man dark skin tone medium skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 FE0F 200D 1F468 1F3FE,fully-qualified,👨🏿‍❤️‍👨🏾,couple with heart man man dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 200D 1F468 1F3FE,minimally-qualified,👨🏿‍❤‍👨🏾,couple with heart man man dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 FE0F 200D 1F468 1F3FF,fully-qualified,👨🏿‍❤️‍👨🏿,couple with heart man man dark skin tone,E13.1
People-Body,family,1F468 1F3FF 200D 2764 200D 1F468 1F3FF,minimally-qualified,👨🏿‍❤‍👨🏿,couple with heart man man dark skin tone,E13.1
People-Body,family,1F469 200D 2764 FE0F 200D 1F469,fully-qualified,👩‍❤️‍👩,couple with heart woman woman,E2.0
People-Body,family,1F469 200D 2764 200D 1F469,minimally-qualified,👩‍❤‍👩,couple with heart woman woman,E2.0
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F469 1F3FB,fully-qualified,👩🏻‍❤️‍👩🏻,couple with heart woman woman light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F469 1F3FB,minimally-qualified,👩🏻‍❤‍👩🏻,couple with heart woman woman light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F469 1F3FC,fully-qualified,👩🏻‍❤️‍👩🏼,couple with heart woman woman light skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F469 1F3FC,minimally-qualified,👩🏻‍❤‍👩🏼,couple with heart woman woman light skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F469 1F3FD,fully-qualified,👩🏻‍❤️‍👩🏽,couple with heart woman woman light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F469 1F3FD,minimally-qualified,👩🏻‍❤‍👩🏽,couple with heart woman woman light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F469 1F3FE,fully-qualified,👩🏻‍❤️‍👩🏾,couple with heart woman woman light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F469 1F3FE,minimally-qualified,👩🏻‍❤‍👩🏾,couple with heart woman woman light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 FE0F 200D 1F469 1F3FF,fully-qualified,👩🏻‍❤️‍👩🏿,couple with heart woman woman light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FB 200D 2764 200D 1F469 1F3FF,minimally-qualified,👩🏻‍❤‍👩🏿,couple with heart woman woman light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F469 1F3FB,fully-qualified,👩🏼‍❤️‍👩🏻,couple with heart woman woman medium-light skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F469 1F3FB,minimally-qualified,👩🏼‍❤‍👩🏻,couple with heart woman woman medium-light skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F469 1F3FC,fully-qualified,👩🏼‍❤️‍👩🏼,couple with heart woman woman medium-light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F469 1F3FC,minimally-qualified,👩🏼‍❤‍👩🏼,couple with heart woman woman medium-light skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F469 1F3FD,fully-qualified,👩🏼‍❤️‍👩🏽,couple with heart woman woman medium-light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F469 1F3FD,minimally-qualified,👩🏼‍❤‍👩🏽,couple with heart woman woman medium-light skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F469 1F3FE,fully-qualified,👩🏼‍❤️‍👩🏾,couple with heart woman woman medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F469 1F3FE,minimally-qualified,👩🏼‍❤‍👩🏾,couple with heart woman woman medium-light skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 FE0F 200D 1F469 1F3FF,fully-qualified,👩🏼‍❤️‍👩🏿,couple with heart woman woman medium-light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FC 200D 2764 200D 1F469 1F3FF,minimally-qualified,👩🏼‍❤‍👩🏿,couple with heart woman woman medium-light skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F469 1F3FB,fully-qualified,👩🏽‍❤️‍👩🏻,couple with heart woman woman medium skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F469 1F3FB,minimally-qualified,👩🏽‍❤‍👩🏻,couple with heart woman woman medium skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F469 1F3FC,fully-qualified,👩🏽‍❤️‍👩🏼,couple with heart woman woman medium skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F469 1F3FC,minimally-qualified,👩🏽‍❤‍👩🏼,couple with heart woman woman medium skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F469 1F3FD,fully-qualified,👩🏽‍❤️‍👩🏽,couple with heart woman woman medium skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F469 1F3FD,minimally-qualified,👩🏽‍❤‍👩🏽,couple with heart woman woman medium skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F469 1F3FE,fully-qualified,👩🏽‍❤️‍👩🏾,couple with heart woman woman medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F469 1F3FE,minimally-qualified,👩🏽‍❤‍👩🏾,couple with heart woman woman medium skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 FE0F 200D 1F469 1F3FF,fully-qualified,👩🏽‍❤️‍👩🏿,couple with heart woman woman medium skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FD 200D 2764 200D 1F469 1F3FF,minimally-qualified,👩🏽‍❤‍👩🏿,couple with heart woman woman medium skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F469 1F3FB,fully-qualified,👩🏾‍❤️‍👩🏻,couple with heart woman woman medium-dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F469 1F3FB,minimally-qualified,👩🏾‍❤‍👩🏻,couple with heart woman woman medium-dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F469 1F3FC,fully-qualified,👩🏾‍❤️‍👩🏼,couple with heart woman woman medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F469 1F3FC,minimally-qualified,👩🏾‍❤‍👩🏼,couple with heart woman woman medium-dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F469 1F3FD,fully-qualified,👩🏾‍❤️‍👩🏽,couple with heart woman woman medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F469 1F3FD,minimally-qualified,👩🏾‍❤‍👩🏽,couple with heart woman woman medium-dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F469 1F3FE,fully-qualified,👩🏾‍❤️‍👩🏾,couple with heart woman woman medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F469 1F3FE,minimally-qualified,👩🏾‍❤‍👩🏾,couple with heart woman woman medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 FE0F 200D 1F469 1F3FF,fully-qualified,👩🏾‍❤️‍👩🏿,couple with heart woman woman medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FE 200D 2764 200D 1F469 1F3FF,minimally-qualified,👩🏾‍❤‍👩🏿,couple with heart woman woman medium-dark skin tone dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F469 1F3FB,fully-qualified,👩🏿‍❤️‍👩🏻,couple with heart woman woman dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F469 1F3FB,minimally-qualified,👩🏿‍❤‍👩🏻,couple with heart woman woman dark skin tone light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F469 1F3FC,fully-qualified,👩🏿‍❤️‍👩🏼,couple with heart woman woman dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F469 1F3FC,minimally-qualified,👩🏿‍❤‍👩🏼,couple with heart woman woman dark skin tone medium-light skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F469 1F3FD,fully-qualified,👩🏿‍❤️‍👩🏽,couple with heart woman woman dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F469 1F3FD,minimally-qualified,👩🏿‍❤‍👩🏽,couple with heart woman woman dark skin tone medium skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F469 1F3FE,fully-qualified,👩🏿‍❤️‍👩🏾,couple with heart woman woman dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F469 1F3FE,minimally-qualified,👩🏿‍❤‍👩🏾,couple with heart woman woman dark skin tone medium-dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 FE0F 200D 1F469 1F3FF,fully-qualified,👩🏿‍❤️‍👩🏿,couple with heart woman woman dark skin tone,E13.1
People-Body,family,1F469 1F3FF 200D 2764 200D 1F469 1F3FF,minimally-qualified,👩🏿‍❤‍👩🏿,couple with heart woman woman dark skin tone,E13.1
People-Body,family,1F468 200D 1F469 200D 1F466,fully-qualified,👨‍👩‍👦,family man woman boy,E2.0
People-Body,family,1F468 200D 1F469 200D 1F467,fully-qualified,👨‍👩‍👧,family man woman girl,E2.0
People-Body,family,1F468 200D 1F469 200D 1F467 200D 1F466,fully-qualified,👨‍👩‍👧‍👦,family man woman girl boy,E2.0
People-Body,family,1F468 200D 1F469 200D 1F466 200D 1F466,fully-qualified,👨‍👩‍👦‍👦,family man woman boy boy,E2.0
People-Body,family,1F468 200D 1F469 200D 1F467 200D 1F467,fully-qualified,👨‍👩‍👧‍👧,family man woman girl girl,E2.0
People-Body,family,1F468 200D 1F468 200D 1F466,fully-qualified,👨‍👨‍👦,family man man boy,E2.0
People-Body,family,1F468 200D 1F468 200D 1F467,fully-qualified,👨‍👨‍👧,family man man girl,E2.0
People-Body,family,1F468 200D 1F468 200D 1F467 200D 1F466,fully-qualified,👨‍👨‍👧‍👦,family man man girl boy,E2.0
People-Body,family,1F468 200D 1F468 200D 1F466 200D 1F466,fully-qualified,👨‍👨‍👦‍👦,family man man boy boy,E2.0
People-Body,family,1F468 200D 1F468 200D 1F467 200D 1F467,fully-qualified,👨‍👨‍👧‍👧,family man man girl girl,E2.0
People-Body,family,1F469 200D 1F469 200D 1F466,fully-qualified,👩‍👩‍👦,family woman woman boy,E2.0
People-Body,family,1F469 200D 1F469 200D 1F467,fully-qualified,👩‍👩‍👧,family woman woman girl,E2.0
People-Body,family,1F469 200D 1F469 200D 1F467 200D 1F466,fully-qualified,👩‍👩‍👧‍👦,family woman woman girl boy,E2.0
People-Body,family,1F469 200D 1F469 200D 1F466 200D 1F466,fully-qualified,👩‍👩‍👦‍👦,family woman woman boy boy,E2.0
People-Body,family,1F469 200D 1F469 200D 1F467 200D 1F467,fully-qualified,👩‍👩‍👧‍👧,family woman woman girl girl,E2.0
People-Body,family,1F468 200D 1F466,fully-qualified,👨‍👦,family man boy,E4.0
People-Body,family,1F468 200D 1F466 200D 1F466,fully-qualified,👨‍👦‍👦,family man boy boy,E4.0
People-Body,family,1F468 200D 1F467,fully-qualified,👨‍👧,family man girl,E4.0
People-Body,family,1F468 200D 1F467 200D 1F466,fully-qualified,👨‍👧‍👦,family man girl boy,E4.0
People-Body,family,1F468 200D 1F467 200D 1F467,fully-qualified,👨‍👧‍👧,family man girl girl,E4.0
People-Body,family,1F469 200D 1F466,fully-qualified,👩‍👦,family woman boy,E4.0
People-Body,family,1F469 200D 1F466 200D 1F466,fully-qualified,👩‍👦‍👦,family woman boy boy,E4.0
People-Body,family,1F469 200D 1F467,fully-qualified,👩‍👧,family woman girl,E4.0
People-Body,family,1F469 200D 1F467 200D 1F466,fully-qualified,👩‍👧‍👦,family woman girl boy,E4.0
People-Body,family,1F469 200D 1F467 200D 1F467,fully-qualified,👩‍👧‍👧,family woman girl girl,E4.0
People-Body,person-symbol,1F5E3 FE0F,fully-qualified,🗣️,speaking head,E0.7
People-Body,person-symbol,1F5E3,unqualified,🗣,speaking head,E0.7
People-Body,person-symbol,1F464,fully-qualified,👤,bust in silhouette,E0.6
People-Body,person-symbol,1F465,fully-qualified,👥,busts in silhouette,E1.0
People-Body,person-symbol,1FAC2,fully-qualified,🫂,people hugging,E13.0
People-Body,person-symbol,1F46A,fully-qualified,👪,family,E0.6
People-Body,person-symbol,1F9D1 200D 1F9D1 200D 1F9D2,fully-qualified,🧑‍🧑‍🧒,family adult adult child,E15.1
People-Body,person-symbol,1F9D1 200D 1F9D1 200D 1F9D2 200D 1F9D2,fully-qualified,🧑‍🧑‍🧒‍🧒,family adult adult child child,E15.1
People-Body,person-symbol,1F9D1 200D 1F9D2,fully-qualified,🧑‍🧒,family adult child,E15.1
People-Body,person-symbol,1F9D1 200D 1F9D2 200D 1F9D2,fully-qualified,🧑‍🧒‍🧒,family adult child child,E15.1
People-Body,person-symbol,1F463,fully-qualified,👣,footprints,E0.6
Smileys-Emotion,face-smiling,1F600,fully-qualified,😀,grinning face,E1.0
Smileys-Emotion,face-smiling,1F603,fully-qualified,😃,grinning face with big eyes,E0.6
Smileys-Emotion,face-smiling,1F604,fully-qualified,😄,grinning face with smiling eyes,E0.6
Smileys-Emotion,face-smiling,1F601,fully-qualified,😁,beaming face with smiling eyes,E0.6
Smileys-Emotion,face-smiling,1F606,fully-qualified,😆,grinning squinting face,E0.6
Smileys-Emotion,face-smiling,1F605,fully-qualified,😅,grinning face with sweat,E0.6
Smileys-Emotion,face-smiling,1F923,fully-qualified,🤣,rolling on the floor laughing,E3.0
Smileys-Emotion,face-smiling,1F602,fully-qualified,😂,face with tears of joy,E0.6
Smileys-Emotion,face-smiling,1F642,fully-qualified,🙂,slightly smiling face,E1.0
Smileys-Emotion,face-smiling,1F643,fully-qualified,🙃,upside-down face,E1.0
Smileys-Emotion,face-smiling,1FAE0,fully-qualified,🫠,melting face,E14.0
Smileys-Emotion,face-smiling,1F609,fully-qualified,😉,winking face,E0.6
Smileys-Emotion,face-smiling,1F60A,fully-qualified,😊,smiling face with smiling eyes,E0.6
Smileys-Emotion,face-smiling,1F607,fully-qualified,😇,smiling face with halo,E1.0
Smileys-Emotion,face-affection,1F970,fully-qualified,🥰,smiling face with hearts,E11.0
Smileys-Emotion,face-affection,1F60D,fully-qualified,😍,smiling face with heart-eyes,E0.6
Smileys-Emotion,face-affection,1F929,fully-qualified,🤩,star-struck,E5.0
Smileys-Emotion,face-affection,1F618,fully-qualified,😘,face blowing a kiss,E0.6
Smileys-Emotion,face-affection,1F617,fully-qualified,😗,kissing face,E1.0
Smileys-Emotion,face-affection,263A FE0F,fully-qualified,☺️,smiling face,E0.6
Smileys-Emotion,face-affection,263A,unqualified,☺,smiling face,E0.6
Smileys-Emotion,face-affection,1F61A,fully-qualified,😚,kissing face with closed eyes,E0.6
Smileys-Emotion,face-affection,1F619,fully-qualified,😙,kissing face with smiling eyes,E1.0
Smileys-Emotion,face-affection,1F972,fully-qualified,🥲,smiling face with tear,E13.0
Smileys-Emotion,face-tongue,1F60B,fully-qualified,😋,face savoring food,E0.6
Smileys-Emotion,face-tongue,1F61B,fully-qualified,😛,face with tongue,E1.0
Smileys-Emotion,face-tongue,1F61C,fully-qualified,😜,winking face with tongue,E0.6
Smileys-Emotion,face-tongue,1F92A,fully-qualified,🤪,zany face,E5.0
Smileys-Emotion,face-tongue,1F61D,fully-qualified,😝,squinting face with tongue,E0.6
Smileys-Emotion,face-tongue,1F911,fully-qualified,🤑,money-mouth face,E1.0
Smileys-Emotion,face-hand,1F917,fully-qualified,🤗,smiling face with open hands,E1.0
Smileys-Emotion,face-hand,1F92D,fully-qualified,🤭,face with hand over mouth,E5.0
Smileys-Emotion,face-hand,1FAE2,fully-qualified,🫢,face with open eyes and hand over mouth,E14.0
Smileys-Emotion,face-hand,1FAE3,fully-qualified,🫣,face with peeking eye,E14.0
Smileys-Emotion,face-hand,1F92B,fully-qualified,🤫,shushing face,E5.0
Smileys-Emotion,face-hand,1F914,fully-qualified,🤔,thinking face,E1.0
Smileys-Emotion,face-hand,1FAE1,fully-qualified,🫡,saluting face,E14.0
Smileys-Emotion,face-neutral-skeptical,1F910,fully-qualified,🤐,zipper-mouth face,E1.0
Smileys-Emotion,face-neutral-skeptical,1F928,fully-qualified,🤨,face with raised eyebrow,E5.0
Smileys-Emotion,face-neutral-skeptical,1F610,fully-qualified,😐,neutral face,E0.7
Smileys-Emotion,face-neutral-skeptical,1F611,fully-qualified,😑,expressionless face,E1.0
Smileys-Emotion,face-neutral-skeptical,1F636,fully-qualified,😶,face without mouth,E1.0
Smileys-Emotion,face-neutral-skeptical,1FAE5,fully-qualified,🫥,dotted line face,E14.0
Smileys-Emotion,face-neutral-skeptical,1F636 200D 1F32B FE0F,fully-qualified,😶‍🌫️,face in clouds,E13.1
Smileys-Emotion,face-neutral-skeptical,1F636 200D 1F32B,minimally-qualified,😶‍🌫,face in clouds,E13.1
Smileys-Emotion,face-neutral-skeptical,1F60F,fully-qualified,😏,smirking face,E0.6
Smileys-Emotion,face-neutral-skeptical,1F612,fully-qualified,😒,unamused face,E0.6
Smileys-Emotion,face-neutral-skeptical,1F644,fully-qualified,🙄,face with rolling eyes,E1.0
Smileys-Emotion,face-neutral-skeptical,1F62C,fully-qualified,😬,grimacing face,E1.0
Smileys-Emotion,face-neutral-skeptical,1F62E 200D 1F4A8,fully-qualified,😮‍💨,face exhaling,E13.1
Smileys-Emotion,face-neutral-skeptical,1F925,fully-qualified,🤥,lying face,E3.0
Smileys-Emotion,face-neutral-skeptical,1FAE8,fully-qualified,🫨,shaking face,E15.0
Smileys-Emotion,face-neutral-skeptical,1F642 200D 2194 FE0F,fully-qualified,🙂‍↔️,head shaking horizontally,E15.1
Smileys-Emotion,face-neutral-skeptical,1F642 200D 2194,minimally-qualified,🙂‍↔,head shaking horizontally,E15.1
Smileys-Emotion,face-neutral-skeptical,1F642 200D 2195 FE0F,fully-qualified,🙂‍↕️,head shaking vertically,E15.1
Smileys-Emotion,face-neutral-skeptical,1F642 200D 2195,minimally-qualified,🙂‍↕,head shaking vertically,E15.1
Smileys-Emotion,face-sleepy,1F60C,fully-qualified,😌,relieved face,E0.6
Smileys-Emotion,face-sleepy,1F614,fully-qualified,😔,pensive face,E0.6
Smileys-Emotion,face-sleepy,1F62A,fully-qualified,😪,sleepy face,E0.6
Smileys-Emotion,face-sleepy,1F924,fully-qualified,🤤,drooling face,E3.0
Smileys-Emotion,face-sleepy,1F634,fully-qualified,😴,sleeping face,E1.0
Smileys-Emotion,face-unwell,1F637,fully-qualified,😷,face with medical mask,E0.6
Smileys-Emotion,face-unwell,1F912,fully-qualified,🤒,face with thermometer,E1.0
Smileys-Emotion,face-unwell,1F915,fully-qualified,🤕,face with head-bandage,E1.0
Smileys-Emotion,face-unwell,1F922,fully-qualified,🤢,nauseated face,E3.0
Smileys-Emotion,face-unwell,1F92E,fully-qualified,🤮,face vomiting,E5.0
Smileys-Emotion,face-unwell,1F927,fully-qualified,🤧,sneezing face,E3.0
Smileys-Emotion,face-unwell,1F975,fully-qualified,🥵,hot face,E11.0
Smileys-Emotion,face-unwell,1F976,fully-qualified,🥶,cold face,E11.0
Smileys-Emotion,face-unwell,1F974,fully-qualified,🥴,woozy face,E11.0
Smileys-Emotion,face-unwell,1F635,fully-qualified,😵,face with crossed-out eyes,E0.6
Smileys-Emotion,face-unwell,1F635 200D 1F4AB,fully-qualified,😵‍💫,face with spiral eyes,E13.1
Smileys-Emotion,face-unwell,1F92F,fully-qualified,🤯,exploding head,E5.0
Smileys-Emotion,face-hat,1F920,fully-qualified,🤠,cowboy hat face,E3.0
Smileys-Emotion,face-hat,1F973,fully-qualified,🥳,partying face,E11.0
Smileys-Emotion,face-hat,1F978,fully-qualified,🥸,disguised face,E13.0
Smileys-Emotion,face-glasses,1F60E,fully-qualified,😎,smiling face with sunglasses,E1.0
Smileys-Emotion,face-glasses,1F913,fully-qualified,🤓,nerd face,E1.0
Smileys-Emotion,face-glasses,1F9D0,fully-qualified,🧐,face with monocle,E5.0
Smileys-Emotion,face-concerned,1F615,fully-qualified,😕,confused face,E1.0
Smileys-Emotion,face-concerned,1FAE4,fully-qualified,🫤,face with diagonal mouth,E14.0
Smileys-Emotion,face-concerned,1F61F,fully-qualified,😟,worried face,E1.0
Smileys-Emotion,face-concerned,1F641,fully-qualified,🙁,slightly frowning face,E1.0
Smileys-Emotion,face-concerned,2639 FE0F,fully-qualified,☹️,frowning face,E0.7
Smileys-Emotion,face-concerned,2639,unqualified,☹,frowning face,E0.7
Smileys-Emotion,face-concerned,1F62E,fully-qualified,😮,face with open mouth,E1.0
Smileys-Emotion,face-concerned,1F62F,fully-qualified,😯,hushed face,E1.0
Smileys-Emotion,face-concerned,1F632,fully-qualified,😲,astonished face,E0.6
Smileys-Emotion,face-concerned,1F633,fully-qualified,😳,flushed face,E0.6
Smileys-Emotion,face-concerned,1F97A,fully-qualified,🥺,pleading face,E11.0
Smileys-Emotion,face-concerned,1F979,fully-qualified,🥹,face holding back tears,E14.0
Smileys-Emotion,face-concerned,1F626,fully-qualified,😦,frowning face with open mouth,E1.0
Smileys-Emotion,face-concerned,1F627,fully-qualified,😧,anguished face,E1.0
Smileys-Emotion,face-concerned,1F628,fully-qualified,😨,fearful face,E0.6
Smileys-Emotion,face-concerned,1F630,fully-qualified,😰,anxious face with sweat,E0.6
Smileys-Emotion,face-concerned,1F625,fully-qualified,😥,sad but relieved face,E0.6
Smileys-Emotion,face-concerned,1F622,fully-qualified,😢,crying face,E0.6
Smileys-Emotion,face-concerned,1F62D,fully-qualified,😭,loudly crying face,E0.6
Smileys-Emotion,face-concerned,1F631,fully-qualified,😱,face screaming in fear,E0.6
Smileys-Emotion,face-concerned,1F616,fully-qualified,😖,confounded face,E0.6
Smileys-Emotion,face-concerned,1F623,fully-qualified,😣,persevering face,E0.6
Smileys-Emotion,face-concerned,1F61E,fully-qualified,😞,disappointed face,E0.6
Smileys-Emotion,face-concerned,1F613,fully-qualified,😓,downcast face with sweat,E0.6
Smileys-Emotion,face-concerned,1F629,fully-qualified,😩,weary face,E0.6
Smileys-Emotion,face-concerned,1F62B,fully-qualified,😫,tired face,E0.6
Smileys-Emotion,face-concerned,1F971,fully-qualified,🥱,yawning face,E12.0
Smileys-Emotion,face-negative,1F624,fully-qualified,😤,face with steam from nose,E0.6
Smileys-Emotion,face-negative,1F621,fully-qualified,😡,enraged face,E0.6
Smileys-Emotion,face-negative,1F620,fully-qualified,😠,angry face,E0.6
Smileys-Emotion,face-negative,1F92C,fully-qualified,🤬,face with symbols on mouth,E5.0
Smileys-Emotion,face-negative,1F608,fully-qualified,😈,smiling face with horns,E1.0
Smileys-Emotion,face-negative,1F47F,fully-qualified,👿,angry face with horns,E0.6
Smileys-Emotion,face-negative,1F480,fully-qualified,💀,skull,E0.6
Smileys-Emotion,face-negative,2620 FE0F,fully-qualified,☠️,skull and crossbones,E1.0
Smileys-Emotion,face-negative,2620,unqualified,☠,skull and crossbones,E1.0
Smileys-Emotion,face-costume,1F4A9,fully-qualified,💩,pile of poo,E0.6
Smileys-Emotion,face-costume,1F921,fully-qualified,🤡,clown face,E3.0
Smileys-Emotion,face-costume,1F479,fully-qualified,👹,ogre,E0.6
Smileys-Emotion,face-costume,1F47A,fully-qualified,👺,goblin,E0.6
Smileys-Emotion,face-costume,1F47B,fully-qualified,👻,ghost,E0.6
Smileys-Emotion,face-costume,1F47D,fully-qualified,👽,alien,E0.6
Smileys-Emotion,face-costume,1F47E,fully-qualified,👾,alien monster,E0.6
Smileys-Emotion,face-costume,1F916,fully-qualified,🤖,robot,E1.0
Smileys-Emotion,cat-face,1F63A,fully-qualified,😺,grinning cat,E0.6
Smileys-Emotion,cat-face,1F638,fully-qualified,😸,grinning cat with smiling eyes,E0.6
Smileys-Emotion,cat-face,1F639,fully-qualified,😹,cat with tears of joy,E0.6
Smileys-Emotion,cat-face,1F63B,fully-qualified,😻,smiling cat with heart-eyes,E0.6
Smileys-Emotion,cat-face,1F63C,fully-qualified,😼,cat with wry smile,E0.6
Smileys-Emotion,cat-face,1F63D,fully-qualified,😽,kissing cat,E0.6
Smileys-Emotion,cat-face,1F640,fully-qualified,🙀,weary cat,E0.6
Smileys-Emotion,cat-face,1F63F,fully-qualified,😿,crying cat,E0.6
Smileys-Emotion,cat-face,1F63E,fully-qualified,😾,pouting cat,E0.6
Smileys-Emotion,monkey-face,1F648,fully-qualified,🙈,see-no-evil monkey,E0.6
Smileys-Emotion,monkey-face,1F649,fully-qualified,🙉,hear-no-evil monkey,E0.6
Smileys-Emotion,monkey-face,1F64A,fully-qualified,🙊,speak-no-evil monkey,E0.6
Smileys-Emotion,heart,1F48C,fully-qualified,💌,love letter,E0.6
Smileys-Emotion,heart,1F498,fully-qualified,💘,heart with arrow,E0.6
Smileys-Emotion,heart,1F49D,fully-qualified,💝,heart with ribbon,E0.6
Smileys-Emotion,heart,1F496,fully-qualified,💖,sparkling heart,E0.6
Smileys-Emotion,heart,1F497,fully-qualified,💗,growing heart,E0.6
Smileys-Emotion,heart,1F493,fully-qualified,💓,beating heart,E0.6
Smileys-Emotion,heart,1F49E,fully-qualified,💞,revolving hearts,E0.6
Smileys-Emotion,heart,1F495,fully-qualified,💕,two hearts,E0.6
Smileys-Emotion,heart,1F49F,fully-qualified,💟,heart decoration,E0.6
Smileys-Emotion,heart,2763 FE0F,fully-qualified,❣️,heart exclamation,E1.0
Smileys-Emotion,heart,2763,unqualified,❣,heart exclamation,E1.0
Smileys-Emotion,heart,1F494,fully-qualified,💔,broken heart,E0.6
Smileys-Emotion,heart,2764 FE0F 200D 1F525,fully-qualified,❤️‍🔥,heart on fire,E13.1
Smileys-Emotion,heart,2764 200D 1F525,unqualified,❤‍🔥,heart on fire,E13.1
Smileys-Emotion,heart,2764 FE0F 200D 1FA79,fully-qualified,❤️‍🩹,mending heart,E13.1
Smileys-Emotion,heart,2764 200D 1FA79,unqualified,❤‍🩹,mending heart,E13.1
Smileys-Emotion,heart,2764 FE0F,fully-qualified,❤️,red heart,E0.6
Smileys-Emotion,heart,2764,unqualified,❤,red heart,E0.6
Smileys-Emotion,heart,1FA77,fully-qualified,🩷,pink heart,E15.0
Smileys-Emotion,heart,1F9E1,fully-qualified,🧡,orange heart,E5.0
Smileys-Emotion,heart,1F49B,fully-qualified,💛,yellow heart,E0.6
Smileys-Emotion,heart,1F49A,fully-qualified,💚,green heart,E0.6
Smileys-Emotion,heart,1F499,fully-qualified,💙,blue heart,E0.6
Smileys-Emotion,heart,1FA75,fully-qualified,🩵,light blue heart,E15.0
Smileys-Emotion,heart,1F49C,fully-qualified,💜,purple heart,E0.6
Smileys-Emotion,heart,1F90E,fully-qualified,🤎,brown heart,E12.0
Smileys-Emotion,heart,1F5A4,fully-qualified,🖤,black heart,E3.0
Smileys-Emotion,heart,1FA76,fully-qualified,🩶,grey heart,E15.0
Smileys-Emotion,heart,1F90D,fully-qualified,🤍,white heart,E12.0
Smileys-Emotion,emotion,1F48B,fully-qualified,💋,kiss mark,E0.6
Smileys-Emotion,emotion,1F4AF,fully-qualified,💯,hundred points,E0.6
Smileys-Emotion,emotion,1F4A2,fully-qualified,💢,anger symbol,E0.6
Smileys-Emotion,emotion,1F4A5,fully-qualified,💥,collision,E0.6
Smileys-Emotion,emotion,1F4AB,fully-qualified,💫,dizzy,E0.6
Smileys-Emotion,emotion,1F4A6,fully-qualified,💦,sweat droplets,E0.6
Smileys-Emotion,emotion,1F4A8,fully-qualified,💨,dashing away,E0.6
Smileys-Emotion,emotion,1F573 FE0F,fully-qualified,🕳️,hole,E0.7
Smileys-Emotion,emotion,1F573,unqualified,🕳,hole,E0.7
Smileys-Emotion,emotion,1F4AC,fully-qualified,💬,speech balloon,E0.6
Smileys-Emotion,emotion,1F441 FE0F 200D 1F5E8 FE0F,fully-qualified,👁️‍🗨️,eye in speech bubble,E2.0
Smileys-Emotion,emotion,1F441 200D 1F5E8 FE0F,unqualified,👁‍🗨️,eye in speech bubble,E2.0
Smileys-Emotion,emotion,1F441 FE0F 200D 1F5E8,minimally-qualified,👁️‍🗨,eye in speech bubble,E2.0
Smileys-Emotion,emotion,1F441 200D 1F5E8,unqualified,👁‍🗨,eye in speech bubble,E2.0
Smileys-Emotion,emotion,1F5E8 FE0F,fully-qualified,🗨️,left speech bubble,E2.0
Smileys-Emotion,emotion,1F5E8,unqualified,🗨,left speech bubble,E2.0
Smileys-Emotion,emotion,1F5EF FE0F,fully-qualified,🗯️,right anger bubble,E0.7
Smileys-Emotion,emotion,1F5EF,unqualified,🗯,right anger bubble,E0.7
Smileys-Emotion,emotion,1F4AD,fully-qualified,💭,thought balloon,E1.0
Smileys-Emotion,emotion,1F4A4,fully-qualified,💤,ZZZ,E0.6
Symbols,transport-sign,1F3E7,fully-qualified,🏧,ATM sign,E0.6
Symbols,transport-sign,1F6AE,fully-qualified,🚮,litter in bin sign,E1.0
Symbols,transport-sign,1F6B0,fully-qualified,🚰,potable water,E1.0
Symbols,transport-sign,267F,fully-qualified,♿,wheelchair symbol,E0.6
Symbols,transport-sign,1F6B9,fully-qualified,🚹,mens room,E0.6
Symbols,transport-sign,1F6BA,fully-qualified,🚺,womens room,E0.6
Symbols,transport-sign,1F6BB,fully-qualified,🚻,restroom,E0.6
Symbols,transport-sign,1F6BC,fully-qualified,🚼,baby symbol,E0.6
Symbols,transport-sign,1F6BE,fully-qualified,🚾,water closet,E0.6
Symbols,transport-sign,1F6C2,fully-qualified,🛂,passport control,E1.0
Symbols,transport-sign,1F6C3,fully-qualified,🛃,customs,E1.0
Symbols,transport-sign,1F6C4,fully-qualified,🛄,baggage claim,E1.0
Symbols,transport-sign,1F6C5,fully-qualified,🛅,left luggage,E1.0
Symbols,warning,26A0 FE0F,fully-qualified,⚠️,warning,E0.6
Symbols,warning,26A0,unqualified,⚠,warning,E0.6
Symbols,warning,1F6B8,fully-qualified,🚸,children crossing,E1.0
Symbols,warning,26D4,fully-qualified,⛔,no entry,E0.6
Symbols,warning,1F6AB,fully-qualified,🚫,prohibited,E0.6
Symbols,warning,1F6B3,fully-qualified,🚳,no bicycles,E1.0
Symbols,warning,1F6AD,fully-qualified,🚭,no smoking,E0.6
Symbols,warning,1F6AF,fully-qualified,🚯,no littering,E1.0
Symbols,warning,1F6B1,fully-qualified,🚱,non-potable water,E1.0
Symbols,warning,1F6B7,fully-qualified,🚷,no pedestrians,E1.0
Symbols,warning,1F4F5,fully-qualified,📵,no mobile phones,E1.0
Symbols,warning,1F51E,fully-qualified,🔞,no one under eighteen,E0.6
Symbols,warning,2622 FE0F,fully-qualified,☢️,radioactive,E1.0
Symbols,warning,2622,unqualified,☢,radioactive,E1.0
Symbols,warning,2623 FE0F,fully-qualified,☣️,biohazard,E1.0
Symbols,warning,2623,unqualified,☣,biohazard,E1.0
Symbols,arrow,2B06 FE0F,fully-qualified,⬆️,up arrow,E0.6
Symbols,arrow,2B06,unqualified,⬆,up arrow,E0.6
Symbols,arrow,2197 FE0F,fully-qualified,↗️,up-right arrow,E0.6
Symbols,arrow,2197,unqualified,↗,up-right arrow,E0.6
Symbols,arrow,27A1 FE0F,fully-qualified,➡️,right arrow,E0.6
Symbols,arrow,27A1,unqualified,➡,right arrow,E0.6
Symbols,arrow,2198 FE0F,fully-qualified,↘️,down-right arrow,E0.6
Symbols,arrow,2198,unqualified,↘,down-right arrow,E0.6
Symbols,arrow,2B07 FE0F,fully-qualified,⬇️,down arrow,E0.6
Symbols,arrow,2B07,unqualified,⬇,down arrow,E0.6
Symbols,arrow,2199 FE0F,fully-qualified,↙️,down-left arrow,E0.6
Symbols,arrow,2199,unqualified,↙,down-left arrow,E0.6
Symbols,arrow,2B05 FE0F,fully-qualified,⬅️,left arrow,E0.6
Symbols,arrow,2B05,unqualified,⬅,left arrow,E0.6
Symbols,arrow,2196 FE0F,fully-qualified,↖️,up-left arrow,E0.6
Symbols,arrow,2196,unqualified,↖,up-left arrow,E0.6
Symbols,arrow,2195 FE0F,fully-qualified,↕️,up-down arrow,E0.6
Symbols,arrow,2195,unqualified,↕,up-down arrow,E0.6
Symbols,arrow,2194 FE0F,fully-qualified,↔️,left-right arrow,E0.6
Symbols,arrow,2194,unqualified,↔,left-right arrow,E0.6
Symbols,arrow,21A9 FE0F,fully-qualified,↩️,right arrow curving left,E0.6
Symbols,arrow,21A9,unqualified,↩,right arrow curving left,E0.6
Symbols,arrow,21AA FE0F,fully-qualified,↪️,left arrow curving right,E0.6
Symbols,arrow,21AA,unqualified,↪,left arrow curving right,E0.6
Symbols,arrow,2934 FE0F,fully-qualified,⤴️,right arrow curving up,E0.6
Symbols,arrow,2934,unqualified,⤴,right arrow curving up,E0.6
Symbols,arrow,2935 FE0F,fully-qualified,⤵️,right arrow curving down,E0.6
Symbols,arrow,2935,unqualified,⤵,right arrow curving down,E0.6
Symbols,arrow,1F503,fully-qualified,🔃,clockwise vertical arrows,E0.6
Symbols,arrow,1F504,fully-qualified,🔄,counterclockwise arrows button,E1.0
Symbols,arrow,1F519,fully-qualified,🔙,BACK arrow,E0.6
Symbols,arrow,1F51A,fully-qualified,🔚,END arrow,E0.6
Symbols,arrow,1F51B,fully-qualified,🔛,ON arrow,E0.6
Symbols,arrow,1F51C,fully-qualified,🔜,SOON arrow,E0.6
Symbols,arrow,1F51D,fully-qualified,🔝,TOP arrow,E0.6
Symbols,religion,1F6D0,fully-qualified,🛐,place of worship,E1.0
Symbols,religion,269B FE0F,fully-qualified,⚛️,atom symbol,E1.0
Symbols,religion,269B,unqualified,⚛,atom symbol,E1.0
Symbols,religion,1F549 FE0F,fully-qualified,🕉️,om,E0.7
Symbols,religion,1F549,unqualified,🕉,om,E0.7
Symbols,religion,2721 FE0F,fully-qualified,✡️,star of David,E0.7
Symbols,religion,2721,unqualified,✡,star of David,E0.7
Symbols,religion,2638 FE0F,fully-qualified,☸️,wheel of dharma,E0.7
Symbols,religion,2638,unqualified,☸,wheel of dharma,E0.7
Symbols,religion,262F FE0F,fully-qualified,☯️,yin yang,E0.7
Symbols,religion,262F,unqualified,☯,yin yang,E0.7
Symbols,religion,271D FE0F,fully-qualified,✝️,latin cross,E0.7
Symbols,religion,271D,unqualified,✝,latin cross,E0.7
Symbols,religion,2626 FE0F,fully-qualified,☦️,orthodox cross,E1.0
Symbols,religion,2626,unqualified,☦,orthodox cross,E1.0
Symbols,religion,262A FE0F,fully-qualified,☪️,star and crescent,E0.7
Symbols,religion,262A,unqualified,☪,star and crescent,E0.7
Symbols,religion,262E FE0F,fully-qualified,☮️,peace symbol,E1.0
Symbols,religion,262E,unqualified,☮,peace symbol,E1.0
Symbols,religion,1F54E,fully-qualified,🕎,menorah,E1.0
Symbols,religion,1F52F,fully-qualified,🔯,dotted six-pointed star,E0.6
Symbols,religion,1FAAF,fully-qualified,🪯,khanda,E15.0
Symbols,zodiac,2648,fully-qualified,♈,Aries,E0.6
Symbols,zodiac,2649,fully-qualified,♉,Taurus,E0.6
Symbols,zodiac,264A,fully-qualified,♊,Gemini,E0.6
Symbols,zodiac,264B,fully-qualified,♋,Cancer,E0.6
Symbols,zodiac,264C,fully-qualified,♌,Leo,E0.6
Symbols,zodiac,264D,fully-qualified,♍,Virgo,E0.6
Symbols,zodiac,264E,fully-qualified,♎,Libra,E0.6
Symbols,zodiac,264F,fully-qualified,♏,Scorpio,E0.6
Symbols,zodiac,2650,fully-qualified,♐,Sagittarius,E0.6
Symbols,zodiac,2651,fully-qualified,♑,Capricorn,E0.6
Symbols,zodiac,2652,fully-qualified,♒,Aquarius,E0.6
Symbols,zodiac,2653,fully-qualified,♓,Pisces,E0.6
Symbols,zodiac,26CE,fully-qualified,⛎,Ophiuchus,E0.6
Symbols,av-symbol,1F500,fully-qualified,🔀,shuffle tracks button,E1.0
Symbols,av-symbol,1F501,fully-qualified,🔁,repeat button,E1.0
Symbols,av-symbol,1F502,fully-qualified,🔂,repeat single button,E1.0
Symbols,av-symbol,25B6 FE0F,fully-qualified,▶️,play button,E0.6
Symbols,av-symbol,25B6,unqualified,▶,play button,E0.6
Symbols,av-symbol,23E9,fully-qualified,⏩,fast-forward button,E0.6
Symbols,av-symbol,23ED FE0F,fully-qualified,⏭️,next track button,E0.7
Symbols,av-symbol,23ED,unqualified,⏭,next track button,E0.7
Symbols,av-symbol,23EF FE0F,fully-qualified,⏯️,play or pause button,E1.0
Symbols,av-symbol,23EF,unqualified,⏯,play or pause button,E1.0
Symbols,av-symbol,25C0 FE0F,fully-qualified,◀️,reverse button,E0.6
Symbols,av-symbol,25C0,unqualified,◀,reverse button,E0.6
Symbols,av-symbol,23EA,fully-qualified,⏪,fast reverse button,E0.6
Symbols,av-symbol,23EE FE0F,fully-qualified,⏮️,last track button,E0.7
Symbols,av-symbol,23EE,unqualified,⏮,last track button,E0.7
Symbols,av-symbol,1F53C,fully-qualified,🔼,upwards button,E0.6
Symbols,av-symbol,23EB,fully-qualified,⏫,fast up button,E0.6
Symbols,av-symbol,1F53D,fully-qualified,🔽,downwards button,E0.6
Symbols,av-symbol,23EC,fully-qualified,⏬,fast down button,E0.6
Symbols,av-symbol,23F8 FE0F,fully-qualified,⏸️,pause button,E0.7
Symbols,av-symbol,23F8,unqualified,⏸,pause button,E0.7
Symbols,av-symbol,23F9 FE0F,fully-qualified,⏹️,stop button,E0.7
Symbols,av-symbol,23F9,unqualified,⏹,stop button,E0.7
Symbols,av-symbol,23FA FE0F,fully-qualified,⏺️,record button,E0.7
Symbols,av-symbol,23FA,unqualified,⏺,record button,E0.7
Symbols,av-symbol,23CF FE0F,fully-qualified,⏏️,eject button,E1.0
Symbols,av-symbol,23CF,unqualified,⏏,eject button,E1.0
Symbols,av-symbol,1F3A6,fully-qualified,🎦,cinema,E0.6
Symbols,av-symbol,1F505,fully-qualified,🔅,dim button,E1.0
Symbols,av-symbol,1F506,fully-qualified,🔆,bright button,E1.0
Symbols,av-symbol,1F4F6,fully-qualified,📶,antenna bars,E0.6
Symbols,av-symbol,1F6DC,fully-qualified,🛜,wireless,E15.0
Symbols,av-symbol,1F4F3,fully-qualified,📳,vibration mode,E0.6
Symbols,av-symbol,1F4F4,fully-qualified,📴,mobile phone off,E0.6
Symbols,gender,2640 FE0F,fully-qualified,♀️,female sign,E4.0
Symbols,gender,2640,unqualified,♀,female sign,E4.0
Symbols,gender,2642 FE0F,fully-qualified,♂️,male sign,E4.0
Symbols,gender,2642,unqualified,♂,male sign,E4.0
Symbols,gender,26A7 FE0F,fully-qualified,⚧️,transgender symbol,E13.0
Symbols,gender,26A7,unqualified,⚧,transgender symbol,E13.0
Symbols,math,2716 FE0F,fully-qualified,✖️,multiply,E0.6
Symbols,math,2716,unqualified,✖,multiply,E0.6
Symbols,math,2795,fully-qualified,➕,plus,E0.6
Symbols,math,2796,fully-qualified,➖,minus,E0.6
Symbols,math,2797,fully-qualified,➗,divide,E0.6
Symbols,math,1F7F0,fully-qualified,🟰,heavy equals sign,E14.0
Symbols,math,267E FE0F,fully-qualified,♾️,infinity,E11.0
Symbols,math,267E,unqualified,♾,infinity,E11.0
Symbols,punctuation,203C FE0F,fully-qualified,‼️,double exclamation mark,E0.6
Symbols,punctuation,203C,unqualified,‼,double exclamation mark,E0.6
Symbols,punctuation,2049 FE0F,fully-qualified,⁉️,exclamation question mark,E0.6
Symbols,punctuation,2049,unqualified,⁉,exclamation question mark,E0.6
Symbols,punctuation,2753,fully-qualified,❓,red question mark,E0.6
Symbols,punctuation,2754,fully-qualified,❔,white question mark,E0.6
Symbols,punctuation,2755,fully-qualified,❕,white exclamation mark,E0.6
Symbols,punctuation,2757,fully-qualified,❗,red exclamation mark,E0.6
Symbols,punctuation,3030 FE0F,fully-qualified,〰️,wavy dash,E0.6
Symbols,punctuation,3030,unqualified,〰,wavy dash,E0.6
Symbols,currency,1F4B1,fully-qualified,💱,currency exchange,E0.6
Symbols,currency,1F4B2,fully-qualified,💲,heavy dollar sign,E0.6
Symbols,other-symbol,2695 FE0F,fully-qualified,⚕️,medical symbol,E4.0
Symbols,other-symbol,2695,unqualified,⚕,medical symbol,E4.0
Symbols,other-symbol,267B FE0F,fully-qualified,♻️,recycling symbol,E0.6
Symbols,other-symbol,267B,unqualified,♻,recycling symbol,E0.6
Symbols,other-symbol,269C FE0F,fully-qualified,⚜️,fleur-de-lis,E1.0
Symbols,other-symbol,269C,unqualified,⚜,fleur-de-lis,E1.0
Symbols,other-symbol,1F531,fully-qualified,🔱,trident emblem,E0.6
Symbols,other-symbol,1F4DB,fully-qualified,📛,name badge,E0.6
Symbols,other-symbol,1F530,fully-qualified,🔰,Japanese symbol for beginner,E0.6
Symbols,other-symbol,2B55,fully-qualified,⭕,hollow red circle,E0.6
Symbols,other-symbol,2705,fully-qualified,✅,check mark button,E0.6
Symbols,other-symbol,2611 FE0F,fully-qualified,☑️,check box with check,E0.6
Symbols,other-symbol,2611,unqualified,☑,check box with check,E0.6
Symbols,other-symbol,2714 FE0F,fully-qualified,✔️,check mark,E0.6
Symbols,other-symbol,2714,unqualified,✔,check mark,E0.6
Symbols,other-symbol,274C,fully-qualified,❌,cross mark,E0.6
Symbols,other-symbol,274E,fully-qualified,❎,cross mark button,E0.6
Symbols,other-symbol,27B0,fully-qualified,➰,curly loop,E0.6
Symbols,other-symbol,27BF,fully-qualified,➿,double curly loop,E1.0
Symbols,other-symbol,303D FE0F,fully-qualified,〽️,part alternation mark,E0.6
Symbols,other-symbol,303D,unqualified,〽,part alternation mark,E0.6
Symbols,other-symbol,2733 FE0F,fully-qualified,✳️,eight-spoked asterisk,E0.6
Symbols,other-symbol,2733,unqualified,✳,eight-spoked asterisk,E0.6
Symbols,other-symbol,2734 FE0F,fully-qualified,✴️,eight-pointed star,E0.6
Symbols,other-symbol,2734,unqualified,✴,eight-pointed star,E0.6
Symbols,other-symbol,2747 FE0F,fully-qualified,❇️,sparkle,E0.6
Symbols,other-symbol,2747,unqualified,❇,sparkle,E0.6
Symbols,other-symbol,00A9 FE0F,fully-qualified,©️,copyright,E0.6
Symbols,other-symbol,00A9,unqualified,©,copyright,E0.6
Symbols,other-symbol,00AE FE0F,fully-qualified,®️,registered,E0.6
Symbols,other-symbol,00AE,unqualified,®,registered,E0.6
Symbols,other-symbol,2122 FE0F,fully-qualified,™️,trade mark,E0.6
Symbols,other-symbol,2122,unqualified,™,trade mark,E0.6
Symbols,keycap,0023 FE0F 20E3,fully-qualified,#️⃣,keycap,E0.6
Symbols,keycap,0023 20E3,unqualified,#⃣,keycap,E0.6
Symbols,keycap,002A FE0F 20E3,fully-qualified,*️⃣,keycap,E2.0
Symbols,keycap,002A 20E3,unqualified,*⃣,keycap,E2.0
Symbols,keycap,0030 FE0F 20E3,fully-qualified,0️⃣,keycap 0,E0.6
Symbols,keycap,0030 20E3,unqualified,0⃣,keycap 0,E0.6
Symbols,keycap,0031 FE0F 20E3,fully-qualified,1️⃣,keycap 1,E0.6
Symbols,keycap,0031 20E3,unqualified,1⃣,keycap 1,E0.6
Symbols,keycap,0032 FE0F 20E3,fully-qualified,2️⃣,keycap 2,E0.6
Symbols,keycap,0032 20E3,unqualified,2⃣,keycap 2,E0.6
Symbols,keycap,0033 FE0F 20E3,fully-qualified,3️⃣,keycap 3,E0.6
Symbols,keycap,0033 20E3,unqualified,3⃣,keycap 3,E0.6
Symbols,keycap,0034 FE0F 20E3,fully-qualified,4️⃣,keycap 4,E0.6
Symbols,keycap,0034 20E3,unqualified,4⃣,keycap 4,E0.6
Symbols,keycap,0035 FE0F 20E3,fully-qualified,5️⃣,keycap 5,E0.6
Symbols,keycap,0035 20E3,unqualified,5⃣,keycap 5,E0.6
Symbols,keycap,0036 FE0F 20E3,fully-qualified,6️⃣,keycap 6,E0.6
Symbols,keycap,0036 20E3,unqualified,6⃣,keycap 6,E0.6
Symbols,keycap,0037 FE0F 20E3,fully-qualified,7️⃣,keycap 7,E0.6
Symbols,keycap,0037 20E3,unqualified,7⃣,keycap 7,E0.6
Symbols,keycap,0038 FE0F 20E3,fully-qualified,8️⃣,keycap 8,E0.6
Symbols,keycap,0038 20E3,unqualified,8⃣,keycap 8,E0.6
Symbols,keycap,0039 FE0F 20E3,fully-qualified,9️⃣,keycap 9,E0.6
Symbols,keycap,0039 20E3,unqualified,9⃣,keycap 9,E0.6
Symbols,keycap,1F51F,fully-qualified,🔟,keycap 10,E0.6
Symbols,alphanum,1F520,fully-qualified,🔠,input latin uppercase,E0.6
Symbols,alphanum,1F521,fully-qualified,🔡,input latin lowercase,E0.6
Symbols,alphanum,1F522,fully-qualified,🔢,input numbers,E0.6
Symbols,alphanum,1F523,fully-qualified,🔣,input symbols,E0.6
Symbols,alphanum,1F524,fully-qualified,🔤,input latin letters,E0.6
Symbols,alphanum,1F170 FE0F,fully-qualified,🅰️,A button blood type,E0.6
Symbols,alphanum,1F170,unqualified,🅰,A button blood type,E0.6
Symbols,alphanum,1F18E,fully-qualified,🆎,AB button blood type,E0.6
Symbols,alphanum,1F171 FE0F,fully-qualified,🅱️,B button blood type,E0.6
Symbols,alphanum,1F171,unqualified,🅱,B button blood type,E0.6
Symbols,alphanum,1F191,fully-qualified,🆑,CL button,E0.6
Symbols,alphanum,1F192,fully-qualified,🆒,COOL button,E0.6
Symbols,alphanum,1F193,fully-qualified,🆓,FREE button,E0.6
Symbols,alphanum,2139 FE0F,fully-qualified,ℹ️,information,E0.6
Symbols,alphanum,2139,unqualified,ℹ,information,E0.6
Symbols,alphanum,1F194,fully-qualified,🆔,ID button,E0.6
Symbols,alphanum,24C2 FE0F,fully-qualified,Ⓜ️,circled M,E0.6
Symbols,alphanum,24C2,unqualified,Ⓜ,circled M,E0.6
Symbols,alphanum,1F195,fully-qualified,🆕,NEW button,E0.6
Symbols,alphanum,1F196,fully-qualified,🆖,NG button,E0.6
Symbols,alphanum,1F17E FE0F,fully-qualified,🅾️,O button blood type,E0.6
Symbols,alphanum,1F17E,unqualified,🅾,O button blood type,E0.6
Symbols,alphanum,1F197,fully-qualified,🆗,OK button,E0.6
Symbols,alphanum,1F17F FE0F,fully-qualified,🅿️,P button,E0.6
Symbols,alphanum,1F17F,unqualified,🅿,P button,E0.6
Symbols,alphanum,1F198,fully-qualified,🆘,SOS button,E0.6
Symbols,alphanum,1F199,fully-qualified,🆙,UP button,E0.6
Symbols,alphanum,1F19A,fully-qualified,🆚,VS button,E0.6
Symbols,alphanum,1F201,fully-qualified,🈁,Japanese here button,E0.6
Symbols,alphanum,1F202 FE0F,fully-qualified,🈂️,Japanese service charge button,E0.6
Symbols,alphanum,1F202,unqualified,🈂,Japanese service charge button,E0.6
Symbols,alphanum,1F237 FE0F,fully-qualified,🈷️,Japanese monthly amount button,E0.6
Symbols,alphanum,1F237,unqualified,🈷,Japanese monthly amount button,E0.6
Symbols,alphanum,1F236,fully-qualified,🈶,Japanese not free of charge button,E0.6
Symbols,alphanum,1F22F,fully-qualified,🈯,Japanese reserved button,E0.6
Symbols,alphanum,1F250,fully-qualified,🉐,Japanese bargain button,E0.6
Symbols,alphanum,1F239,fully-qualified,🈹,Japanese discount button,E0.6
Symbols,alphanum,1F21A,fully-qualified,🈚,Japanese free of charge button,E0.6
Symbols,alphanum,1F232,fully-qualified,🈲,Japanese prohibited button,E0.6
Symbols,alphanum,1F251,fully-qualified,🉑,Japanese acceptable button,E0.6
Symbols,alphanum,1F238,fully-qualified,🈸,Japanese application button,E0.6
Symbols,alphanum,1F234,fully-qualified,🈴,Japanese passing grade button,E0.6
Symbols,alphanum,1F233,fully-qualified,🈳,Japanese vacancy button,E0.6
Symbols,alphanum,3297 FE0F,fully-qualified,㊗️,Japanese congratulations button,E0.6
Symbols,alphanum,3297,unqualified,㊗,Japanese congratulations button,E0.6
Symbols,alphanum,3299 FE0F,fully-qualified,㊙️,Japanese secret button,E0.6
Symbols,alphanum,3299,unqualified,㊙,Japanese secret button,E0.6
Symbols,alphanum,1F23A,fully-qualified,🈺,Japanese open for business button,E0.6
Symbols,alphanum,1F235,fully-qualified,🈵,Japanese no vacancy button,E0.6
Symbols,geometric,1F534,fully-qualified,🔴,red circle,E0.6
Symbols,geometric,1F7E0,fully-qualified,🟠,orange circle,E12.0
Symbols,geometric,1F7E1,fully-qualified,🟡,yellow circle,E12.0
Symbols,geometric,1F7E2,fully-qualified,🟢,green circle,E12.0
Symbols,geometric,1F535,fully-qualified,🔵,blue circle,E0.6
Symbols,geometric,1F7E3,fully-qualified,🟣,purple circle,E12.0
Symbols,geometric,1F7E4,fully-qualified,🟤,brown circle,E12.0
Symbols,geometric,26AB,fully-qualified,⚫,black circle,E0.6
Symbols,geometric,26AA,fully-qualified,⚪,white circle,E0.6
Symbols,geometric,1F7E5,fully-qualified,🟥,red square,E12.0
Symbols,geometric,1F7E7,fully-qualified,🟧,orange square,E12.0
Symbols,geometric,1F7E8,fully-qualified,🟨,yellow square,E12.0
Symbols,geometric,1F7E9,fully-qualified,🟩,green square,E12.0
Symbols,geometric,1F7E6,fully-qualified,🟦,blue square,E12.0
Symbols,geometric,1F7EA,fully-qualified,🟪,purple square,E12.0
Symbols,geometric,1F7EB,fully-qualified,🟫,brown square,E12.0
Symbols,geometric,2B1B,fully-qualified,⬛,black large square,E0.6
Symbols,geometric,2B1C,fully-qualified,⬜,white large square,E0.6
Symbols,geometric,25FC FE0F,fully-qualified,◼️,black medium square,E0.6
Symbols,geometric,25FC,unqualified,◼,black medium square,E0.6
Symbols,geometric,25FB FE0F,fully-qualified,◻️,white medium square,E0.6
Symbols,geometric,25FB,unqualified,◻,white medium square,E0.6
Symbols,geometric,25FE,fully-qualified,◾,black medium-small square,E0.6
Symbols,geometric,25FD,fully-qualified,◽,white medium-small square,E0.6
Symbols,geometric,25AA FE0F,fully-qualified,▪️,black small square,E0.6
Symbols,geometric,25AA,unqualified,▪,black small square,E0.6
Symbols,geometric,25AB FE0F,fully-qualified,▫️,white small square,E0.6
Symbols,geometric,25AB,unqualified,▫,white small square,E0.6
Symbols,geometric,1F536,fully-qualified,🔶,large orange diamond,E0.6
Symbols,geometric,1F537,fully-qualified,🔷,large blue diamond,E0.6
Symbols,geometric,1F538,fully-qualified,🔸,small orange diamond,E0.6
Symbols,geometric,1F539,fully-qualified,🔹,small blue diamond,E0.6
Symbols,geometric,1F53A,fully-qualified,🔺,red triangle pointed up,E0.6
Symbols,geometric,1F53B,fully-qualified,🔻,red triangle pointed down,E0.6
Symbols,geometric,1F4A0,fully-qualified,💠,diamond with a dot,E0.6
Symbols,geometric,1F518,fully-qualified,🔘,radio button,E0.6
Symbols,geometric,1F533,fully-qualified,🔳,white square button,E0.6
Symbols,geometric,1F532,fully-qualified,🔲,black square button,E0.6
Travel-Places,place-map,1F30D,fully-qualified,🌍,globe showing Europe-Africa,E0.7
Travel-Places,place-map,1F30E,fully-qualified,🌎,globe showing Americas,E0.7
Travel-Places,place-map,1F30F,fully-qualified,🌏,globe showing Asia-Australia,E0.6
Travel-Places,place-map,1F310,fully-qualified,🌐,globe with meridians,E1.0
Travel-Places,place-map,1F5FA FE0F,fully-qualified,🗺️,world map,E0.7
Travel-Places,place-map,1F5FA,unqualified,🗺,world map,E0.7
Travel-Places,place-map,1F5FE,fully-qualified,🗾,map of Japan,E0.6
Travel-Places,place-map,1F9ED,fully-qualified,🧭,compass,E11.0
Travel-Places,place-geographic,1F3D4 FE0F,fully-qualified,🏔️,snow-capped mountain,E0.7
Travel-Places,place-geographic,1F3D4,unqualified,🏔,snow-capped mountain,E0.7
Travel-Places,place-geographic,26F0 FE0F,fully-qualified,⛰️,mountain,E0.7
Travel-Places,place-geographic,26F0,unqualified,⛰,mountain,E0.7
Travel-Places,place-geographic,1F30B,fully-qualified,🌋,volcano,E0.6
Travel-Places,place-geographic,1F5FB,fully-qualified,🗻,mount fuji,E0.6
Travel-Places,place-geographic,1F3D5 FE0F,fully-qualified,🏕️,camping,E0.7
Travel-Places,place-geographic,1F3D5,unqualified,🏕,camping,E0.7
Travel-Places,place-geographic,1F3D6 FE0F,fully-qualified,🏖️,beach with umbrella,E0.7
Travel-Places,place-geographic,1F3D6,unqualified,🏖,beach with umbrella,E0.7
Travel-Places,place-geographic,1F3DC FE0F,fully-qualified,🏜️,desert,E0.7
Travel-Places,place-geographic,1F3DC,unqualified,🏜,desert,E0.7
Travel-Places,place-geographic,1F3DD FE0F,fully-qualified,🏝️,desert island,E0.7
Travel-Places,place-geographic,1F3DD,unqualified,🏝,desert island,E0.7
Travel-Places,place-geographic,1F3DE FE0F,fully-qualified,🏞️,national park,E0.7
Travel-Places,place-geographic,1F3DE,unqualified,🏞,national park,E0.7
Travel-Places,place-building,1F3DF FE0F,fully-qualified,🏟️,stadium,E0.7
Travel-Places,place-building,1F3DF,unqualified,🏟,stadium,E0.7
Travel-Places,place-building,1F3DB FE0F,fully-qualified,🏛️,classical building,E0.7
Travel-Places,place-building,1F3DB,unqualified,🏛,classical building,E0.7
Travel-Places,place-building,1F3D7 FE0F,fully-qualified,🏗️,building construction,E0.7
Travel-Places,place-building,1F3D7,unqualified,🏗,building construction,E0.7
Travel-Places,place-building,1F9F1,fully-qualified,🧱,brick,E11.0
Travel-Places,place-building,1FAA8,fully-qualified,🪨,rock,E13.0
Travel-Places,place-building,1FAB5,fully-qualified,🪵,wood,E13.0
Travel-Places,place-building,1F6D6,fully-qualified,🛖,hut,E13.0
Travel-Places,place-building,1F3D8 FE0F,fully-qualified,🏘️,houses,E0.7
Travel-Places,place-building,1F3D8,unqualified,🏘,houses,E0.7
Travel-Places,place-building,1F3DA FE0F,fully-qualified,🏚️,derelict house,E0.7
Travel-Places,place-building,1F3DA,unqualified,🏚,derelict house,E0.7
Travel-Places,place-building,1F3E0,fully-qualified,🏠,house,E0.6
Travel-Places,place-building,1F3E1,fully-qualified,🏡,house with garden,E0.6
Travel-Places,place-building,1F3E2,fully-qualified,🏢,office building,E0.6
Travel-Places,place-building,1F3E3,fully-qualified,🏣,Japanese post office,E0.6
Travel-Places,place-building,1F3E4,fully-qualified,🏤,post office,E1.0
Travel-Places,place-building,1F3E5,fully-qualified,🏥,hospital,E0.6
Travel-Places,place-building,1F3E6,fully-qualified,🏦,bank,E0.6
Travel-Places,place-building,1F3E8,fully-qualified,🏨,hotel,E0.6
Travel-Places,place-building,1F3E9,fully-qualified,🏩,love hotel,E0.6
Travel-Places,place-building,1F3EA,fully-qualified,🏪,convenience store,E0.6
Travel-Places,place-building,1F3EB,fully-qualified,🏫,school,E0.6
Travel-Places,place-building,1F3EC,fully-qualified,🏬,department store,E0.6
Travel-Places,place-building,1F3ED,fully-qualified,🏭,factory,E0.6
Travel-Places,place-building,1F3EF,fully-qualified,🏯,Japanese castle,E0.6
Travel-Places,place-building,1F3F0,fully-qualified,🏰,castle,E0.6
Travel-Places,place-building,1F492,fully-qualified,💒,wedding,E0.6
Travel-Places,place-building,1F5FC,fully-qualified,🗼,Tokyo tower,E0.6
Travel-Places,place-building,1F5FD,fully-qualified,🗽,Statue of Liberty,E0.6
Travel-Places,place-religious,26EA,fully-qualified,⛪,church,E0.6
Travel-Places,place-religious,1F54C,fully-qualified,🕌,mosque,E1.0
Travel-Places,place-religious,1F6D5,fully-qualified,🛕,hindu temple,E12.0
Travel-Places,place-religious,1F54D,fully-qualified,🕍,synagogue,E1.0
Travel-Places,place-religious,26E9 FE0F,fully-qualified,⛩️,shinto shrine,E0.7
Travel-Places,place-religious,26E9,unqualified,⛩,shinto shrine,E0.7
Travel-Places,place-religious,1F54B,fully-qualified,🕋,kaaba,E1.0
Travel-Places,place-other,26F2,fully-qualified,⛲,fountain,E0.6
Travel-Places,place-other,26FA,fully-qualified,⛺,tent,E0.6
Travel-Places,place-other,1F301,fully-qualified,🌁,foggy,E0.6
Travel-Places,place-other,1F303,fully-qualified,🌃,night with stars,E0.6
Travel-Places,place-other,1F3D9 FE0F,fully-qualified,🏙️,cityscape,E0.7
Travel-Places,place-other,1F3D9,unqualified,🏙,cityscape,E0.7
Travel-Places,place-other,1F304,fully-qualified,🌄,sunrise over mountains,E0.6
Travel-Places,place-other,1F305,fully-qualified,🌅,sunrise,E0.6
Travel-Places,place-other,1F306,fully-qualified,🌆,cityscape at dusk,E0.6
Travel-Places,place-other,1F307,fully-qualified,🌇,sunset,E0.6
Travel-Places,place-other,1F309,fully-qualified,🌉,bridge at night,E0.6
Travel-Places,place-other,2668 FE0F,fully-qualified,♨️,hot springs,E0.6
Travel-Places,place-other,2668,unqualified,♨,hot springs,E0.6
Travel-Places,place-other,1F3A0,fully-qualified,🎠,carousel horse,E0.6
Travel-Places,place-other,1F6DD,fully-qualified,🛝,playground slide,E14.0
Travel-Places,place-other,1F3A1,fully-qualified,🎡,ferris wheel,E0.6
Travel-Places,place-other,1F3A2,fully-qualified,🎢,roller coaster,E0.6
Travel-Places,place-other,1F488,fully-qualified,💈,barber pole,E0.6
Travel-Places,place-other,1F3AA,fully-qualified,🎪,circus tent,E0.6
Travel-Places,transport-ground,1F682,fully-qualified,🚂,locomotive,E1.0
Travel-Places,transport-ground,1F683,fully-qualified,🚃,railway car,E0.6
Travel-Places,transport-ground,1F684,fully-qualified,🚄,high-speed train,E0.6
Travel-Places,transport-ground,1F685,fully-qualified,🚅,bullet train,E0.6
Travel-Places,transport-ground,1F686,fully-qualified,🚆,train,E1.0
Travel-Places,transport-ground,1F687,fully-qualified,🚇,metro,E0.6
Travel-Places,transport-ground,1F688,fully-qualified,🚈,light rail,E1.0
Travel-Places,transport-ground,1F689,fully-qualified,🚉,station,E0.6
Travel-Places,transport-ground,1F68A,fully-qualified,🚊,tram,E1.0
Travel-Places,transport-ground,1F69D,fully-qualified,🚝,monorail,E1.0
Travel-Places,transport-ground,1F69E,fully-qualified,🚞,mountain railway,E1.0
Travel-Places,transport-ground,1F68B,fully-qualified,🚋,tram car,E1.0
Travel-Places,transport-ground,1F68C,fully-qualified,🚌,bus,E0.6
Travel-Places,transport-ground,1F68D,fully-qualified,🚍,oncoming bus,E0.7
Travel-Places,transport-ground,1F68E,fully-qualified,🚎,trolleybus,E1.0
Travel-Places,transport-ground,1F690,fully-qualified,🚐,minibus,E1.0
Travel-Places,transport-ground,1F691,fully-qualified,🚑,ambulance,E0.6
Travel-Places,transport-ground,1F692,fully-qualified,🚒,fire engine,E0.6
Travel-Places,transport-ground,1F693,fully-qualified,🚓,police car,E0.6
Travel-Places,transport-ground,1F694,fully-qualified,🚔,oncoming police car,E0.7
Travel-Places,transport-ground,1F695,fully-qualified,🚕,taxi,E0.6
Travel-Places,transport-ground,1F696,fully-qualified,🚖,oncoming taxi,E1.0
Travel-Places,transport-ground,1F697,fully-qualified,🚗,automobile,E0.6
Travel-Places,transport-ground,1F698,fully-qualified,🚘,oncoming automobile,E0.7
Travel-Places,transport-ground,1F699,fully-qualified,🚙,sport utility vehicle,E0.6
Travel-Places,transport-ground,1F6FB,fully-qualified,🛻,pickup truck,E13.0
Travel-Places,transport-ground,1F69A,fully-qualified,🚚,delivery truck,E0.6
Travel-Places,transport-ground,1F69B,fully-qualified,🚛,articulated lorry,E1.0
Travel-Places,transport-ground,1F69C,fully-qualified,🚜,tractor,E1.0
Travel-Places,transport-ground,1F3CE FE0F,fully-qualified,🏎️,racing car,E0.7
Travel-Places,transport-ground,1F3CE,unqualified,🏎,racing car,E0.7
Travel-Places,transport-ground,1F3CD FE0F,fully-qualified,🏍️,motorcycle,E0.7
Travel-Places,transport-ground,1F3CD,unqualified,🏍,motorcycle,E0.7
Travel-Places,transport-ground,1F6F5,fully-qualified,🛵,motor scooter,E3.0
Travel-Places,transport-ground,1F9BD,fully-qualified,🦽,manual wheelchair,E12.0
Travel-Places,transport-ground,1F9BC,fully-qualified,🦼,motorized wheelchair,E12.0
Travel-Places,transport-ground,1F6FA,fully-qualified,🛺,auto rickshaw,E12.0
Travel-Places,transport-ground,1F6B2,fully-qualified,🚲,bicycle,E0.6
Travel-Places,transport-ground,1F6F4,fully-qualified,🛴,kick scooter,E3.0
Travel-Places,transport-ground,1F6F9,fully-qualified,🛹,skateboard,E11.0
Travel-Places,transport-ground,1F6FC,fully-qualified,🛼,roller skate,E13.0
Travel-Places,transport-ground,1F68F,fully-qualified,🚏,bus stop,E0.6
Travel-Places,transport-ground,1F6E3 FE0F,fully-qualified,🛣️,motorway,E0.7
Travel-Places,transport-ground,1F6E3,unqualified,🛣,motorway,E0.7
Travel-Places,transport-ground,1F6E4 FE0F,fully-qualified,🛤️,railway track,E0.7
Travel-Places,transport-ground,1F6E4,unqualified,🛤,railway track,E0.7
Travel-Places,transport-ground,1F6E2 FE0F,fully-qualified,🛢️,oil drum,E0.7
Travel-Places,transport-ground,1F6E2,unqualified,🛢,oil drum,E0.7
Travel-Places,transport-ground,26FD,fully-qualified,⛽,fuel pump,E0.6
Travel-Places,transport-ground,1F6DE,fully-qualified,🛞,wheel,E14.0
Travel-Places,transport-ground,1F6A8,fully-qualified,🚨,police car light,E0.6
Travel-Places,transport-ground,1F6A5,fully-qualified,🚥,horizontal traffic light,E0.6
Travel-Places,transport-ground,1F6A6,fully-qualified,🚦,vertical traffic light,E1.0
Travel-Places,transport-ground,1F6D1,fully-qualified,🛑,stop sign,E3.0
Travel-Places,transport-ground,1F6A7,fully-qualified,🚧,construction,E0.6
Travel-Places,transport-water,2693,fully-qualified,⚓,anchor,E0.6
Travel-Places,transport-water,1F6DF,fully-qualified,🛟,ring buoy,E14.0
Travel-Places,transport-water,26F5,fully-qualified,⛵,sailboat,E0.6
Travel-Places,transport-water,1F6F6,fully-qualified,🛶,canoe,E3.0
Travel-Places,transport-water,1F6A4,fully-qualified,🚤,speedboat,E0.6
Travel-Places,transport-water,1F6F3 FE0F,fully-qualified,🛳️,passenger ship,E0.7
Travel-Places,transport-water,1F6F3,unqualified,🛳,passenger ship,E0.7
Travel-Places,transport-water,26F4 FE0F,fully-qualified,⛴️,ferry,E0.7
Travel-Places,transport-water,26F4,unqualified,⛴,ferry,E0.7
Travel-Places,transport-water,1F6E5 FE0F,fully-qualified,🛥️,motor boat,E0.7
Travel-Places,transport-water,1F6E5,unqualified,🛥,motor boat,E0.7
Travel-Places,transport-water,1F6A2,fully-qualified,🚢,ship,E0.6
Travel-Places,transport-air,2708 FE0F,fully-qualified,✈️,airplane,E0.6
Travel-Places,transport-air,2708,unqualified,✈,airplane,E0.6
Travel-Places,transport-air,1F6E9 FE0F,fully-qualified,🛩️,small airplane,E0.7
Travel-Places,transport-air,1F6E9,unqualified,🛩,small airplane,E0.7
Travel-Places,transport-air,1F6EB,fully-qualified,🛫,airplane departure,E1.0
Travel-Places,transport-air,1F6EC,fully-qualified,🛬,airplane arrival,E1.0
Travel-Places,transport-air,1FA82,fully-qualified,🪂,parachute,E12.0
Travel-Places,transport-air,1F4BA,fully-qualified,💺,seat,E0.6
Travel-Places,transport-air,1F681,fully-qualified,🚁,helicopter,E1.0
Travel-Places,transport-air,1F69F,fully-qualified,🚟,suspension railway,E1.0
Travel-Places,transport-air,1F6A0,fully-qualified,🚠,mountain cableway,E1.0
Travel-Places,transport-air,1F6A1,fully-qualified,🚡,aerial tramway,E1.0
Travel-Places,transport-air,1F6F0 FE0F,fully-qualified,🛰️,satellite,E0.7
Travel-Places,transport-air,1F6F0,unqualified,🛰,satellite,E0.7
Travel-Places,transport-air,1F680,fully-qualified,🚀,rocket,E0.6
Travel-Places,transport-air,1F6F8,fully-qualified,🛸,flying saucer,E5.0
Travel-Places,hotel,1F6CE FE0F,fully-qualified,🛎️,bellhop bell,E0.7
Travel-Places,hotel,1F6CE,unqualified,🛎,bellhop bell,E0.7
Travel-Places,hotel,1F9F3,fully-qualified,🧳,luggage,E11.0
Travel-Places,time,231B,fully-qualified,⌛,hourglass done,E0.6
Travel-Places,time,23F3,fully-qualified,⏳,hourglass not done,E0.6
Travel-Places,time,231A,fully-qualified,⌚,watch,E0.6
Travel-Places,time,23F0,fully-qualified,⏰,alarm clock,E0.6
Travel-Places,time,23F1 FE0F,fully-qualified,⏱️,stopwatch,E1.0
Travel-Places,time,23F1,unqualified,⏱,stopwatch,E1.0
Travel-Places,time,23F2 FE0F,fully-qualified,⏲️,timer clock,E1.0
Travel-Places,time,23F2,unqualified,⏲,timer clock,E1.0
Travel-Places,time,1F570 FE0F,fully-qualified,🕰️,mantelpiece clock,E0.7
Travel-Places,time,1F570,unqualified,🕰,mantelpiece clock,E0.7
Travel-Places,time,1F55B,fully-qualified,🕛,twelve oclock,E0.6
Travel-Places,time,1F567,fully-qualified,🕧,twelve-thirty,E0.7
Travel-Places,time,1F550,fully-qualified,🕐,one oclock,E0.6
Travel-Places,time,1F55C,fully-qualified,🕜,one-thirty,E0.7
Travel-Places,time,1F551,fully-qualified,🕑,two oclock,E0.6
Travel-Places,time,1F55D,fully-qualified,🕝,two-thirty,E0.7
Travel-Places,time,1F552,fully-qualified,🕒,three oclock,E0.6
Travel-Places,time,1F55E,fully-qualified,🕞,three-thirty,E0.7
Travel-Places,time,1F553,fully-qualified,🕓,four oclock,E0.6
Travel-Places,time,1F55F,fully-qualified,🕟,four-thirty,E0.7
Travel-Places,time,1F554,fully-qualified,🕔,five oclock,E0.6
Travel-Places,time,1F560,fully-qualified,🕠,five-thirty,E0.7
Travel-Places,time,1F555,fully-qualified,🕕,six oclock,E0.6
Travel-Places,time,1F561,fully-qualified,🕡,six-thirty,E0.7
Travel-Places,time,1F556,fully-qualified,🕖,seven oclock,E0.6
Travel-Places,time,1F562,fully-qualified,🕢,seven-thirty,E0.7
Travel-Places,time,1F557,fully-qualified,🕗,eight oclock,E0.6
Travel-Places,time,1F563,fully-qualified,🕣,eight-thirty,E0.7
Travel-Places,time,1F558,fully-qualified,🕘,nine oclock,E0.6
Travel-Places,time,1F564,fully-qualified,🕤,nine-thirty,E0.7
Travel-Places,time,1F559,fully-qualified,🕙,ten oclock,E0.6
Travel-Places,time,1F565,fully-qualified,🕥,ten-thirty,E0.7
Travel-Places,time,1F55A,fully-qualified,🕚,eleven oclock,E0.6
Travel-Places,time,1F566,fully-qualified,🕦,eleven-thirty,E0.7
Travel-Places,sky-weather,1F311,fully-qualified,🌑,new moon,E0.6
Travel-Places,sky-weather,1F312,fully-qualified,🌒,waxing crescent moon,E1.0
Travel-Places,sky-weather,1F313,fully-qualified,🌓,first quarter moon,E0.6
Travel-Places,sky-weather,1F314,fully-qualified,🌔,waxing gibbous moon,E0.6
Travel-Places,sky-weather,1F315,fully-qualified,🌕,full moon,E0.6
Travel-Places,sky-weather,1F316,fully-qualified,🌖,waning gibbous moon,E1.0
Travel-Places,sky-weather,1F317,fully-qualified,🌗,last quarter moon,E1.0
Travel-Places,sky-weather,1F318,fully-qualified,🌘,waning crescent moon,E1.0
Travel-Places,sky-weather,1F319,fully-qualified,🌙,crescent moon,E0.6
Travel-Places,sky-weather,1F31A,fully-qualified,🌚,new moon face,E1.0
Travel-Places,sky-weather,1F31B,fully-qualified,🌛,first quarter moon face,E0.6
Travel-Places,sky-weather,1F31C,fully-qualified,🌜,last quarter moon face,E0.7
Travel-Places,sky-weather,1F321 FE0F,fully-qualified,🌡️,thermometer,E0.7
Travel-Places,sky-weather,1F321,unqualified,🌡,thermometer,E0.7
Travel-Places,sky-weather,2600 FE0F,fully-qualified,☀️,sun,E0.6
Travel-Places,sky-weather,2600,unqualified,☀,sun,E0.6
Travel-Places,sky-weather,1F31D,fully-qualified,🌝,full moon face,E1.0
Travel-Places,sky-weather,1F31E,fully-qualified,🌞,sun with face,E1.0
Travel-Places,sky-weather,1FA90,fully-qualified,🪐,ringed planet,E12.0
Travel-Places,sky-weather,2B50,fully-qualified,⭐,star,E0.6
Travel-Places,sky-weather,1F31F,fully-qualified,🌟,glowing star,E0.6
Travel-Places,sky-weather,1F320,fully-qualified,🌠,shooting star,E0.6
Travel-Places,sky-weather,1F30C,fully-qualified,🌌,milky way,E0.6
Travel-Places,sky-weather,2601 FE0F,fully-qualified,☁️,cloud,E0.6
Travel-Places,sky-weather,2601,unqualified,☁,cloud,E0.6
Travel-Places,sky-weather,26C5,fully-qualified,⛅,sun behind cloud,E0.6
Travel-Places,sky-weather,26C8 FE0F,fully-qualified,⛈️,cloud with lightning and rain,E0.7
Travel-Places,sky-weather,26C8,unqualified,⛈,cloud with lightning and rain,E0.7
Travel-Places,sky-weather,1F324 FE0F,fully-qualified,🌤️,sun behind small cloud,E0.7
Travel-Places,sky-weather,1F324,unqualified,🌤,sun behind small cloud,E0.7
Travel-Places,sky-weather,1F325 FE0F,fully-qualified,🌥️,sun behind large cloud,E0.7
Travel-Places,sky-weather,1F325,unqualified,🌥,sun behind large cloud,E0.7
Travel-Places,sky-weather,1F326 FE0F,fully-qualified,🌦️,sun behind rain cloud,E0.7
Travel-Places,sky-weather,1F326,unqualified,🌦,sun behind rain cloud,E0.7
Travel-Places,sky-weather,1F327 FE0F,fully-qualified,🌧️,cloud with rain,E0.7
Travel-Places,sky-weather,1F327,unqualified,🌧,cloud with rain,E0.7
Travel-Places,sky-weather,1F328 FE0F,fully-qualified,🌨️,cloud with snow,E0.7
Travel-Places,sky-weather,1F328,unqualified,🌨,cloud with snow,E0.7
Travel-Places,sky-weather,1F329 FE0F,fully-qualified,🌩️,cloud with lightning,E0.7
Travel-Places,sky-weather,1F329,unqualified,🌩,cloud with lightning,E0.7
Travel-Places,sky-weather,1F32A FE0F,fully-qualified,🌪️,tornado,E0.7
Travel-Places,sky-weather,1F32A,unqualified,🌪,tornado,E0.7
Travel-Places,sky-weather,1F32B FE0F,fully-qualified,🌫️,fog,E0.7
Travel-Places,sky-weather,1F32B,unqualified,🌫,fog,E0.7
Travel-Places,sky-weather,1F32C FE0F,fully-qualified,🌬️,wind face,E0.7
Travel-Places,sky-weather,1F32C,unqualified,🌬,wind face,E0.7
Travel-Places,sky-weather,1F300,fully-qualified,🌀,cyclone,E0.6
Travel-Places,sky-weather,1F308,fully-qualified,🌈,rainbow,E0.6
Travel-Places,sky-weather,1F302,fully-qualified,🌂,closed umbrella,E0.6
Travel-Places,sky-weather,2602 FE0F,fully-qualified,☂️,umbrella,E0.7
Travel-Places,sky-weather,2602,unqualified,☂,umbrella,E0.7
Travel-Places,sky-weather,2614,fully-qualified,☔,umbrella with rain drops,E0.6
Travel-Places,sky-weather,26F1 FE0F,fully-qualified,⛱️,umbrella on ground,E0.7
Travel-Places,sky-weather,26F1,unqualified,⛱,umbrella on ground,E0.7
Travel-Places,sky-weather,26A1,fully-qualified,⚡,high voltage,E0.6
Travel-Places,sky-weather,2744 FE0F,fully-qualified,❄️,snowflake,E0.6
Travel-Places,sky-weather,2744,unqualified,❄,snowflake,E0.6
Travel-Places,sky-weather,2603 FE0F,fully-qualified,☃️,snowman,E0.7
Travel-Places,sky-weather,2603,unqualified,☃,snowman,E0.7
Travel-Places,sky-weather,26C4,fully-qualified,⛄,snowman without snow,E0.6
Travel-Places,sky-weather,2604 FE0F,fully-qualified,☄️,comet,E1.0
Travel-Places,sky-weather,2604,unqualified,☄,comet,E1.0
Travel-Places,sky-weather,1F525,fully-qualified,🔥,fire,E0.6
Travel-Places,sky-weather,1F4A7,fully-qualified,💧,droplet,E0.6
Travel-Places,sky-weather,1F30A,fully-qualified,🌊,water wave,E0.6
`;