import React, { useCallback, useState } from 'react';
import { Buffer } from 'buffer';
import { useDropzone } from 'react-dropzone'
import './DragDrop.css'
import { MetaChatFile } from '../models/models';
const DragDrop = ({onUpload}) => {
    const [error, setError] = useState<string | null>(null);

    const onDrop = useCallback(async (files) => {
        const jsonResult: MetaChatFile[] = [];
        try {
            for (const file of files) {
                const reader = new FileReader();    
                await new Promise<void>((resolve) => {
                    reader.onload = (event) => {
                        try {
                            const result = event.target?.result as ArrayBuffer;
                            const decoder = new TextDecoder('utf-8');
                            const decodedContent = decoder.decode(result);
                            const jsonData = JSON.parse(decodedContent, (key, value) => {
                                if (typeof value === 'string') {
                                    const buff = Buffer.from(value, 'latin1');
                                    return buff.toString('utf-8');
                                } else {
                                    return value;
                                }
                            });

                            jsonResult.push(jsonData);
                            setError("");
                            resolve();
                        } catch (error) {
                            setError("File cannot be analysed. Please try again or contact us at chat-stat@outlook.com")
                            resolve();
                        }
                    };
                    reader.readAsArrayBuffer(file);
                });
                
            }
            if (jsonResult[0].participants === undefined && jsonResult[0].messages === undefined) {
                setError("File cannot be analysed. Please try again or contact us at chat-stat@outlook.com")
            }
            onUpload(jsonResult);
        } catch (error) {
            setError("File cannot be analysed. Please try again or contact us at chat-stat@outlook.com")
        }
    // eslint-disable-next-line
    }, []);

    const {getRootProps, acceptedFiles, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <div className="drop-zone" {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ? (
                    <div className="drop-files">
                        <p>Drop the files here ...</p>
                    </div>
                )
                :
                (
                    <div className="drag-files">
                        <p>Drag your files here, or click to select files</p>
                    </div>
                )
            }
            {
                acceptedFiles.length > 0 ? (
                    <div className="file-count">
                        <p>Number of files: {acceptedFiles.length}</p>
                    </div>
                ) : <div/>
            }
            {
                error && (
                    <div className="error-message" style={{ color: 'red' }}>
                        <p>{error}</p>
                    </div>
                )
            }
        </div>
    );
}

export const DragDropSnap = ({onUpload}) => {
    const [error, setError] = useState<string | null>(null);

    const onDrop = useCallback(async (files) => {
        const jsonResult: object[] = [];
        for (const file of files) {
            try {
                const reader = new FileReader();    
                await new Promise<void>((resolve) => {
                    reader.onload = (event) => {
                        try {
                            const file = event.target?.result as ArrayBuffer;
                            const decoder = new TextDecoder('utf-8');
                            const decodedContent = decoder.decode(file);
                            const result = JSON.parse(decodedContent as string);
                            jsonResult.push(result);
                            setError("");
                            resolve();
                        } catch (error) {
                            setError("File is not in the correct format. Please try again or contact us at chat-stat@outlook.com")
                            resolve();
                        }
                    };
                    reader.readAsArrayBuffer(file);
                });
                onUpload(jsonResult);
            } catch (error) {
                setError("File is not in the correct format. Please check the instructions and try again or contact us at");
            }
        }
    // eslint-disable-next-line
    }, []);

    const {getRootProps, acceptedFiles, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <div className="drop-zone" {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ? (
                    <div className="drop-files">
                        <p>Drop the files here ...</p>
                    </div>
                )
                :
                (
                    <div className="drag-files">
                        <p>Drag your files here, or click to select files</p>
                    </div>
                )
            }
            {
                acceptedFiles.length > 0 ? (
                    <div className="file-count">
                        <p>Number of files: {acceptedFiles.length}</p>
                    </div>
                ) : <div/>
            }
            {
                error && (
                    <div className="error-message" style={{ color: 'red' }}>
                        <p>{error}</p>
                    </div>
                )
            }
        </div>
    );
}

export default DragDrop;
