import React, { useState } from 'react';
import processItem from '../../services/snap-query-processor.tsx';
import { Name, Result, SnapchatName, StatOption } from '../../models/models.tsx';
import ScrollTile from '../../components/scroll-tile/ScrollTile.tsx';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-MN8BNF9RPB";

type AnalyserProps = {
    jsonData: any;
    selectedOptions: StatOption[];
    selectedChat: SnapchatName;
}

const Analyser = ({jsonData, selectedOptions, selectedChat}: AnalyserProps) => {
    const [results, setResults] = useState<Result[]>([]);
    const [loading, setLoading] = useState(false); // Add loading state
    const [error, setError] = useState<string | null>(null); // Add error state

    const makeAnalysis = async (option: StatOption) => { // Make makeAnalysis async
        try {
            if (!jsonData) {
                throw new Error("Chat data is empty");
            }

            setLoading(true); // Set loading to true before making the analysis
            setTimeout(async () => {
                try {
                    const result = await processItem(option, jsonData, selectedChat); // Wait for the analysis to complete
                    setResults((prevResults) => [...prevResults, result]);
                }
                catch (error) {
                    console.error("Error processing item: " + error);
                    setError("There seems to be an issue with the file. Please try again or email support at chat-stat@outlook.com"); // Set the error message
                }
                setLoading(false); // Set loading to false after the analysis is done
            }, 500); // Set the timeout to 2000 milliseconds (2 seconds)
        } catch(error) {
            console.error("Cannot read json " + error);
            setError("There seems to be an issue with the file. Please try again or email support at chat-stat@outlook.com"); // Set the error message
            setLoading(false); // Set loading to false in case of error
        }
    };

    const checkDataFormat = () => {
        try {
            if (!jsonData) {
                throw new Error("Chat data is empty");
            }
            if (selectedChat.participants === undefined || jsonData[0][selectedChat.chatId] === "" || jsonData[0][selectedChat.chatId] === undefined || jsonData[0][selectedChat.chatId].length === 0 || jsonData[0][selectedChat.chatId][0].hasOwnProperty("From") === false) {
                throw new Error("Chat data or messages are in the incorrect format. Please check that you have followed the instructions or email support at chat-stat@outlook.com");
            }
            if(selectedOptions.length === 0) {
                throw new Error("Please select at least one option to analyse");
            }
        } catch (error) {
            console.error("Error checking data format: " + error);
            setError(error.message);
        }
    };

    function logAnalysis() {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.event({ category: "button", action: "snap-analyse", label: `Snapchat analysis started with ${selectedOptions.length} options` });
    }

    return (
        <div>
            <button className="analyse-button" onClick={() => {
                setResults([]);
                setError(null);
                checkDataFormat();
                logAnalysis();
                selectedOptions?.forEach((option) => {
                    makeAnalysis(option);
                });
            }}>Analyze</button>

            {loading && <div className="loader"/>}

            {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>} {/* Display the error message in red if it exists */}

            <div className="analyser-results">
                {results.length !== 0 && error === null && !loading && <ScrollTile direction="vertical" results={results} names={[]}/>}
            </div>
        </div>
    );
};

export const getNames = (chatName, jsonData) => {
    var participantList: Name[] = []
    jsonData[0]?.participants?.forEach((item) => {
        participantList.push({detectedName:item.name, preferredName:item.name})
    });
    return participantList
}

export default Analyser;
