import React from 'react';
import './footer.css';


const Footer: React.FC = () => {
    return (
        <footer>
            <p>2024 - ChatStat</p>
            <p>Contact Us -  chat-stat@outlook.com</p>
            <hr className="short-spacer" style={{ width: '10px' }} />
            <a href="/privacy-policy">Privacy Policy</a>
        </footer>
    );
};


export default Footer;