import React, { useEffect, useState } from "react";
import "./StatCheckBoxes.css"
import SelectBox from "../select-box/SelectBox.tsx";
import { StatOption } from "../../models/models.tsx";

const StatCheckBoxes = ({currentList, onChange, setOpen, chatType="meta"}) => {
    const [customWord, setCustomWord] = useState("")
    const [errorIndex, setErrorIndex] = useState(-1);

    const optionsList: StatOption[] = [
        {
            name: "Messages sent",
            query: "msgCount",
            display: "compare-bar",
            content: "",
            label: "Messages sent",
            measure: "messages",
            description: "Number of messages sent per person"
        },
        {
            name: "Top 10 emojis",
            query: "emojis",
            display: "top-10",
            content: "",
            label: "Top emojis",
            description: "Top 10 most used emojis in total"
        },
        {
            name: "Emoji count",
            query: "emojiCount",
            display: "top-10",
            content: "",
            label: "Emojis sent",
            measure: "",
            description: "Number of emojis sent per person"
        },
        {
            name: "Top 10 words",
            query: "words",
            display: "top-10",
            content: "",
            label: "Top words",
            measure: "",
            description: "Top 10 most used words in total"
        },
        {
            name: "Top hours of the day",
            query: "hour",
            display: "top-10",
            content: "",
            label: "Common hours of the day",
            measure: "",
            description: "Most common hours of the day to send a message"
        },
        {
            name: "Top days of the week",
            query: "day",
            display: "pie-chart",
            content: "",
            label: "Common days of the week",
            measure: "",
            description: "Most common days of the week to send a message"
        },
        {
            name: "Sends the first message",
            query: "firstSender",
            display: "compare-number",
            content: "",
            label: "Who sends the first message",
            measure: "messages",
            description: "Comparison of who sends the first message more (longer than 6 hours from the last message)"
        },
        {
            name: "Laughing count",
            query: "laughing",
            display: "number",
            content: "",
            label: "You laughed",
            measure: "times",
            description: "Number of times you laughed (saying words like 'haha', 'lol', 'lmao', 'rofl')"
        },
        {
            name: "First messages",
            query: "firstMsg",
            display: "message-pair",
            content: "",
            label: "First Messages",
            description: "First two messages exchanged in the chat history"
        },
        {
            name: "Photos sent",
            query: "photoCount",
            display: "compare-number",
            content: "",
            label: "Photos sent",
            measure: "photos",
            description: "Number of photos sent per person"
        },
        {
            name: "Most common word each",
            query: "wordEach",
            display: "compare-word",
            content: "",
            label: "Your favourite word",
            measure: "",
            description: "Most common word used per person"
        },
        {
            name: "Average message length",
            query: "avgMsgLength",
            display: "time",
            content: "",
            label: "Average message length",
            measure: "",
            description: "Average number of letters per message"
        },
        {
            name: "Average response time",
            query: "avgResponseTime",
            display: "time",
            content: "",
            label: "Average response time",
            measure: "",
            description: "Average time taken to respond (ignores longer than 32 hours for you slow people)"
        },
        
    ]

    if (chatType === "snap") {
        optionsList.push({
            name: "Average call length",
            query: "callDuration",
            display: "time",
            content: "",
            label: "Average call length",
            measure: "",
            description: "Average length of video calls"
        })
        optionsList.push({
            name: "Who calls first",
            query: "callerCount",
            display: "compare-number",
            content: "",
            label: "Calls made",
            measure: "calls",
            description: "Number of calls made per person"
        })
        optionsList.push({
            name: "Times video called",
            query: "videoCount",
            display: "number",
            content: "",
            label: "Number of video calls",
            measure: "calls",
            description: "Number of times a video call was made"
        })
    }

    optionsList.push({
        name: "Count custom word",
        query: "custom",
        display: "number",
        content: "",
        label: "You sent",
        measure: "times",
        description: "Number of times you sent a custom word"
    })
    
    const handleSelect = (newItem, index) => {
        if (currentList.length >= 9 && !currentList.some((item) => item.name === newItem.name)) {
            setErrorIndex(index);
            return;
        }

        onChange((prevSelect) => {
            if (prevSelect.some((item) => item.name === newItem.name) || prevSelect.length >= 9) {
                return prevSelect.filter((item) => item.name !== newItem.name);
            } else {
                return [...prevSelect, optionsList.filter((item) => item.name === newItem.name)[0] ?? null];
            }
        })
    }

    useEffect(() => {
        const customOption = optionsList.find((item) => item.query === 'custom');
        if (customOption) {
            customOption.content = customWord;
            onChange((prevList) => prevList.map((item) => (item.name === customOption.name ? customOption : item)));
        }
    // eslint-disable-next-line
    }, [customWord])

    return (
        <div>
            <h2> Select your chat stats</h2>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                {currentList.length >= 9 ? (
                    <p style={{color: "red", fontWeight: "bold"}}>Selected: 9 / 9</p>
                ) : (
                    <p>Selected: {currentList.length} / 9</p>
                )}
                <div className="home-uploadButton" onClick={() => setOpen(false)}>
                    Confirm
                </div>
            </div>
            
            <div className="scroll-list">
                <div className="spacer"/>
                {optionsList.map((option, index) => {
                    const isSelected = currentList.some((item) => item.name === option.name);
                    return (
                        <div key={index}>
                            <SelectBox option={option} isSelected={isSelected} onSelect={(newItem, index) => handleSelect(newItem, index)} index={index} setCustomWord={setCustomWord}/>
                            {errorIndex === index && <p style={{color: "red", margin: 0, fontSize: "12px", textAlign: "center"}}>You can only select 9 stats</p>}
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default StatCheckBoxes