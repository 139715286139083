import React from 'react';
import '../GuideModal.css';

const SnapGuide: React.FC = () => {
    return (
        <div className="guide-modal">
            <h2 className='snap'>Download from Snapchat</h2>
            <div style={{margin:"auto", paddingTop: "20px", fontWeight: "bold", width: "400px", textAlign: "center"}}>
                <div>
                    ** Note **
                </div>
                Downloading chats from Snapchat only includes messages that have been saved
            </div>
            <h2 className='subheading' style={{margin: 0, marginTop: "20px", width: "max-content"}}>
                Downloading on a phone
            </h2>
            <ol>
                <li>Open Snapchat</li>
                <li>Select on your profile</li>
                <li>Select on the <p className='p-snap'>settings cog</p> in the top right</li>
                <li>Scroll down to <p className='p-snap'>PRIVACY CONTROLS</p></li>
                <li>Select <p className='p-snap'>My Data</p></li>
                <li>Ensure only these two options are selected:
                    <p className='p-snap'>Export JSON files</p>
                    +
                    <p className='p-snap'>Chat History</p>
                </li>
                <li>Select <p className='p-snap'>Next</p></li>
                <li>Select the date range you want to analyse</li>
                <li>Select <p className='p-snap'>Submit</p></li>
                <li>Wait for the notification that your files are ready to download (this might take a while)</li> 
                <li>Download the files using the link in the email</li>
                <li>Select <p className='p-snap'>See exports</p></li>
                <li>Select <p className='p-snap'>Download</p> on the data file</li>
                <li>Navigate to your downloaded file and access the "json" folder</li>
                <li>Select or drag and drop the "chat_history.json" file into chatsstat.com upload box</li>
            </ol>

            <h2 className='subheading' style={{margin: 0, marginTop: "20px", width: "max-content"}}>
                Downloading on a web browser
            </h2>
            <ol>
                <li>Log into your account on</li>
                <a href="https://accounts.snapchat.com" style={{marginTop:"0px"}}>accounts.snapchat.com</a>
                <li>Select <p className='p-snap'>My Data</p></li>
                <li>From the list of data to include, ensure only these two options are selected:
                    
                </li>
                <div style={{flexDirection:"row", display: "flex"}}>
                    <p className='p-snap'>Export JSON files</p>
                    +
                    <p className='p-snap'>Chat History</p>
                </div>
                
                <li>Select <p className='p-snap'>Next</p></li>
                <li>Select the date range you want to analyse</li>
                <li>Select <p className='p-snap'>Submit</p></li>
                <li>Wait for the notification that your files are ready to download (this might take a while)</li> 
                <li>Download the files using the link in the email</li>
                <li>Select <p className='p-snap'>See exports</p></li>
                <li>Select <p className='p-snap'>Download</p> on the data file</li>
                <li>Navigate to your downloaded file and access the "json" folder</li>
                <li>Select or drag and drop the "chat_history.json" file into the chatsstat.com upload box</li>
            </ol>
        </div>
    );
};

export default SnapGuide;