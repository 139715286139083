import React from "react";

export interface StatOption {
    name: string,
    query: string,
    display: string,
    label: string,
    content: string,
    measure?: string,
    description?: string
}

export interface MetaChatFile {
    participants: {name: string}[];
    messages: any[];
}

export interface Name {
    detectedName: string,
    preferredName: string
}

export type SnapchatName = {
    name: string;
    participants: Name[];
    description: string;
    chatId: string;
};

// A data type for the processItems results to be returned as
export interface BaseResult {
    display: string,
    label: string,
    measure?: string
}

// Step 2: Use Union Types for Variants
export interface TopTenResult extends BaseResult {
    topResults?: { name: string; count: number }[];
}

export interface ComparisonResult extends BaseResult {
    personList?: { name: string; count: number }[];
}

export interface CountResult extends BaseResult {
    count?: {word?: string; count?: number;}
}

export interface NumberResult extends BaseResult {
    number?: number;
}

export interface MessagePairResult extends BaseResult {
    messages?: { name: string; message: string; time: string; }[];
}

export interface TimeResult extends BaseResult {
    time?: string;
}

export interface ComparisonStringResult extends BaseResult {
    personList?: { name: string; word: string; }[];
}

// Union type for all possible result types
export type Result = TopTenResult | ComparisonResult | CountResult | NumberResult | MessagePairResult | TimeResult | ComparisonStringResult;

function isTopTenResult(result: Result): result is TopTenResult {
    return (result as TopTenResult).topResults !== undefined;
}

function isComparisonResult(result: Result): result is ComparisonResult {
    if ((result as ComparisonResult).personList !== undefined) {
        return (result as ComparisonResult).personList![0].count !== undefined
    }
    return false
}

function isCountResult(result: Result): result is CountResult {
    return (result as CountResult).count?.count !== undefined;
}

function isNumberResult(result: Result): result is NumberResult {
    return (result as NumberResult).number !== undefined;
}

function isMessagePairResult(result: Result): result is MessagePairResult {
    return (result as MessagePairResult).messages !== undefined;
}

function isTimeResult(result: Result): result is TimeResult {
    return (result as TimeResult).time !== undefined;
}

function isComparisonStringResult(result: Result): result is ComparisonStringResult {
    if ((result as ComparisonStringResult).personList !== undefined) {
        return (result as ComparisonStringResult).personList![0].word !== undefined
    }
    return false
}

// Step 3: Implement display function for all variations of BaseResult
export const displayResult = (result: Result, names: Name[]): JSX.Element => {
    if (isTopTenResult(result)) {
        return (
            <div className="result-container">
                <h2 className="result-label">{result.label}</h2>
                {result.topResults?.map((item) => {
                    const preferredName = names.find((name) => name.detectedName === item.name)?.preferredName ?? item.name;
                    return (
                        <p key={item.name + item.count} className="result-item">
                            {preferredName}: {item.count}
                        </p>
                    );
                })}
            </div>
        );
    } else if (isComparisonResult(result)) {
        return (
            <div className="result-container">
            <h2 className="result-label">{result.label}</h2>
            {result.personList?.map((item) => {
                const preferredName = names.find((name) => name.detectedName === item.name)?.preferredName ?? item.name;
                return (
                    <div key={item.name+ item.count} className="result-item">
                        <p className="result-word">
                            {preferredName}:
                        </p>
                        <p className="result-count">
                            {item.count} {" "} {result.measure}
                        </p>
                    </div>
                );
            })}
            </div>
        );
    } else if (isCountResult(result)) {
        return (
            <div className="result-container">
                <h2 className="result-label">{result.label}</h2>
                <div className="result-item">
                    <p className="result-word">
                        {result.count?.word}
                    </p>
                    <p className="result-count">
                        {result.count?.count} {" "} {result.measure}
                    </p>
                </div>
            </div>
        );
    } else if (isNumberResult(result)) {
        return (
            <div className="result-container">
                <h2 className="result-label">{result.label}</h2>
                <p className="result-item">
                    {result.number} {" "} {result.measure}
                </p>
            </div>
        );
    } else if (isMessagePairResult(result)) {
        return (
            <div className="result-container">
            <h2 className="result-label">{result.label}</h2>
            {result.messages?.map((item) => {
                const preferredName = names.find((name) => name.detectedName === item.name)?.preferredName ?? item.name;
                return (
                    <div key={item.name + item.message}>
                        <p className="result-item-small">{preferredName} said:</p>
                        <div key={item.time} className="message-container">
                            <p className="result-word-small">{item.message}</p>
                            <p className="message-time">{item.time}</p>
                        </div>
                    </div>
                    );
                })}
            </div>
        );
    } else if (isTimeResult(result)) {
        return (
            <div className="result-container">
                <h2 className="result-label">{result.label}</h2>
                <p className="result-word">{result.time}</p>
            </div>
        );
    } else if (isComparisonStringResult(result)) {
        return (
            <div className="result-container">
            <h2 className="result-label">{result.label}</h2>
            {result.personList?.map((item) => {
                const preferredName = names.find((name) => name.detectedName === item.name)?.preferredName ?? item.name;
                return (
                    <p key={item.name + item.word} className="result-item">
                        {preferredName}: {item.word} {" "} {result.measure}
                    </p>
                );
            })}
            </div>
        );
    } else {
        return <></>;
    }
};