import React from 'react';
import { Link } from 'react-router-dom';
import './HomeScreen.css';
import ScrollTile, { generateRandomResults } from '../components/scroll-tile/ScrollTile.tsx';

const HomeScreen = () => {
    return (
        <div className="home">
            <div className="home-content">
                <h1>Your history in one place</h1>
                <h2 className='subheading'>Who's the more dedicated friend? Who's left who on read the most? Dig up the dark, funny, weird and lame secrets lost to time.</h2>
                <h2 className='subheading' style={{marginTop: "20px"}}> Get started: </h2>
                <div style={{ display: "flex", flexDirection: "row", width: "max-content", margin: "auto"}}>
                    <Link to="/analyse" className="home-uploadButton" style={{marginRight: "30px", backgroundColor: "#3b5998"}}>Facebook</Link>
                    <Link to="/analyse" className="home-uploadButton" style={{marginRight: "30px", backgroundColor: "#c1353e"}}>Instagram</Link>
                    <Link to="/snap" className="home-uploadButton" style={{backgroundColor: "#f6f62d", color: "grey"}}>Snapchat</Link>
                </div>
            </div>
            <ScrollTile direction="carousel-right" results={generateRandomResults()} names={[]}/>
            <ScrollTile direction="carousel-left" results={generateRandomResults()} names={[]}/>
            <div className="home-content" style={{paddingTop: "100px", paddingBottom: "80px"}}>
                <h1>Oh no! Not my data!</h1>
                <h2 className='subheading'>Don't worry. Everything is handled on the browser. Nothing is recorded or saved anywhere except for where you see it.</h2>
                <h2> </h2>
                <h2 className='subheading'>This is a service for you to have fun and not worry about who sees or what happens to your secret messages or weird choice of words. </h2>
                <a className="priv-link" href="/privacy-policy">Feel free to check out our Privacy Policy</a>
            </div>
        </div>
    )
}

export default HomeScreen;