import React from 'react';
import { useEffect, useState } from 'react';
import './GravityItems.css';

const itemHeight = 50; // Assuming item height from CSS
const itemWidth = 80; // Assuming item width from CSS

interface GravityItemsProps {
  items: {name:string,colour:string}[];
}

const GravityItems: React.FC<GravityItemsProps> = ({ items }) => {
  const [positions, setPositions] = useState<{ x: number; y: number, velocityx: number, velocityy: number }[]>([]);

  useEffect(() => {
    // Generate initial random positions for the items
    const initialPositions: { x: number; y: number, velocityx: number, velocityy: number }[] = [];
    items.forEach(() => {
      let newPosition;
      newPosition = {
        x: Math.random() * (window.innerWidth * 0.4 - itemWidth),
        y: Math.random() * (200 - itemHeight),
        velocityx: (Math.random() - 0.5) * 0.1,
        velocityy: (Math.random() - 0.5) * 0.1,
      };
      initialPositions.push(newPosition);
    });
    setPositions(initialPositions);

    const updatePositions = () => {
      setPositions((prevPositions) =>
        prevPositions.map((position, index) => {
          // Check for collisions with other items
          var newVY = position.velocityy;
          var newVX = position.velocityx;
          prevPositions.forEach((otherPosition, otherIndex) => {
            if (otherIndex !== index) {
              const dx = position.x - otherPosition.x;
              const dy = position.y - otherPosition.y;
              const distance = Math.sqrt(dx * dx + dy * dy);
              const minDistance = (itemHeight + itemWidth)*0.01; // Assuming the minimum distance for collision
              if (distance < minDistance) {
                newVX *= -1;
                newVY *= -1;
                otherPosition.velocityx *= -1;
                otherPosition.velocityy *= -1;
              }
            }
          });
      
          if (position.x + newVX >= window.innerWidth * 0.4 - itemWidth || position.x + newVX <= 0) {
            newVX *= -1; // Reverse the x velocity to simulate bouncing off the wall
          }
      
          if (position.y + newVY >= 200 - itemHeight || position.y + newVY <= 0) {
            newVY *= -1; // Reverse the y velocity to simulate bouncing off the wall
          }

          if (position.x >= window.innerWidth * 0.4 - itemWidth) {
            newVX *= -1; // Reverse the x velocity to simulate bouncing off the wall
          }

          if (position.y >= 200 - itemHeight) {
            newVY *= -1; // Reverse the y velocity to simulate bouncing off the wall
          }

          if (position.x <= 0) {
            newVX *= -1; // Reverse the x velocity to simulate bouncing off the wall
          }

          if (position.y <= 0) {
            newVY *= -1; // Reverse the y velocity to simulate bouncing off the wall
          }
      
          let newY = position.y + newVY;
          let newX = position.x + newVX;
      
          return { x: newX, y: newY, velocityx: newVX, velocityy: newVY };
        })
      );

      requestAnimationFrame(updatePositions); // Continue the animation loop
    };

    const animationId = requestAnimationFrame(updatePositions);

    // Clean up the animation frame on component unmount
    return () => cancelAnimationFrame(animationId);
  }, [items]);

  return (
    <div className="gravity-container" style={{ position: 'relative', height: "200px" }}>
      {items.map((item, index) => (
        <div
          className='gravity-item'
          key={index}
          style={{
            position: 'absolute',
            top: positions[index]?.y,
            left: positions[index]?.x,
            backgroundColor: item.colour,
          }}
        >
          <p className='gravity-item-content'>
            {item.name}
          </p>
        </div>
      ))}
    </div>
  );
};

export default GravityItems;