import React from 'react';
import { Name, Result, displayResult} from '../../models/models.tsx';
import './ScrollTile.css';
import extractEmojisFromCsv from '../../services/emojis.tsx';

interface ScrollTileProps {
    results: Result[];
    direction: string;
    names: Name[];
}

const ScrollTile: React.FC<ScrollTileProps> = ({ results, direction, names }) => {
    return (
        <div className={direction}>
            {results.map((result, index) => (
                <div
                    key={index}
                    className={`tile-item ${direction === 'vertical' ? 'vertical' : ''}`}
                    style={{ backgroundColor: getRandomColor() }}
                >
                    {displayResult(result, names)}
                </div>
            ))}
        </div>
    );
};

//get random colour from app.css for the tile
const getRandomColor = () => {
    const colors = [
        '#85c3da',
        '#de4d5986',
        '#3dc2a3',
        '#f3b258',
        '#c40fe02e',
    ];
    return colors[Math.floor(Math.random() * colors.length)];
};

// function to randomly generate a list of results from the generating functions
export const generateRandomResults = (): Result[] => {
    var results: Result[] = [];
    for (let i = 0; i < 100; i++) {
        const random = Math.floor(Math.random() * 5);
        if (random === 0) {
            results.push(generateEmojiResult());
        } if (random === 1) {
            results.push(generateComparisonResult());
        } if (random === 2) {
            results.push(generateMessagePairResult());
        } if (random === 3) {
            results.push(generateVideoDurationResult());
        }else {
            results.push(generateWordResult());
        }
    }
    return results;
};


//function to randomly generate a result the times an emoji has been used
const generateEmojiResult = (): Result => {
    const emojis = extractEmojisFromCsv();
    const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];

    return {
        count: {
            word: randomEmoji,
            count: Math.floor(Math.random() * 200),
        },
        label: 'You sent',
        display: 'number',
        measure: 'times'
    };
};

//function to randomly generate a result for the most common word
const generateWordResult = (): Result => {
    const words = ['Apple', 'Banana', 'Cherry', 'Date', 'Elderberry', 'Fig', 'Grape', 'Honeydew', 'Kiwi', 'Lemon', 'Mango', 'Nectarine', 'Orange', 'Peach', 'Quince',
        'Raspberry', 'Strawberry', 'Tangerine', 'Ugli fruit', 'Water','WOWOWO', 'Yuzu', 'Zucchini']
    const randomWord = words[Math.floor(Math.random() * words.length)];
    return {
        count: {
            word: randomWord,
            count: Math.floor(Math.random() * (3000 - 400 + 1)) + 400,
        },
        label: 'You\'ve said',
        display: 'number',
        measure: 'times'
    };
};

//function to randomly generate a comparison result for who calls first
const generateComparisonResult = (): Result => {
    const names1 = ['Alice', 'Bob', 'Charlie', 'David', 'Eve', 'Frank', 'Grace', 'Henry', 'Ivy', 'Jack'];
    const names2 = ['Fiona', 'George', 'Hannah', 'Isaac', 'Jane', 'Kevin', 'Liam', 'Megan', 'Nathan', 'Olivia', 'Peter', 'Quincy', 'Rachel', 'Samuel', 'Tina'];
    const randomName1 = names1[Math.floor(Math.random() * names1.length)];
    const randomName2 = names2[Math.floor(Math.random() * names2.length)];

    return {
        personList: [
            { name: randomName1, count: Math.floor(Math.random() * 1000) },
            { name: randomName2, count: Math.floor(Math.random() * 1000) },
        ],
        display: 'compare-number',
        label: 'Who calls first?',
        measure: 'times'
    };
};

// function to generate the first two messages sent
const generateMessagePairResult = (): Result => {
    const names1 = ['Alice', 'Bob', 'Charlie', 'David', 'Eve', 'Frank', 'Grace', 'Henry', 'Ivy', 'Jack'];
    const names2 = ['Fiona', 'George', 'Hannah', 'Isaac', 'Jane', 'Kevin', 'Liam', 'Megan', 'Nathan', 'Olivia', 'Peter', 'Quincy', 'Rachel', 'Samuel', 'Tina'];
    const randomName1 = names1[Math.floor(Math.random() * names1.length)];
    const randomName2 = names2[Math.floor(Math.random() * names2.length)];

    const conversationStarters = [
        'How are you?',
        'What have you been up to?',
        'Any exciting plans for the weekend?',
        'Did you watch the latest episode of that show?',
        'Have you tried that new restaurant in town?',
        'What do you think about the weather today?',
        'Any interesting books you\'ve been reading?',
        'What\'s your favorite hobby?',
        'Do you have any pets?',
        'Have you been on any vacations recently?',
        'Do you believe in aliens?',
        'What\'s the most embarrassing thing that has happened to you?',
        'If you could have any superpower, what would it be?',
        'What\'s the funniest joke you know?',
        'If you were stranded on a deserted island, what three things would you bring?',
        'Aren\'t you in my class on Mondays?',
        'heyyyyyyyyyyyy',
        'sup',
    ];

    const conversationResponses = [
        'What\'s up?',
        'This week been so boring. What have you been up to?',
        'What is even this lecture??',
        'What\'s it about?',
        'I\'m so tired',
        'k',
        '??',
        'right',
        'cool',
        'wtf',
        'I don\'t understand.',
        'That\'s interesting.',
        'What do you think?',
        'I\'m not sure.',
        'How was your day?',
    ];

    const randomResponse = conversationResponses[Math.floor(Math.random() * conversationResponses.length)];

    return {
        messages: [
            { time: '11:42pm, Monday 29th August, 2022', name: randomName1, message: conversationStarters[Math.floor(Math.random() * conversationStarters.length)] },
            { time: '9:21am, Tuesday 30th August, 2022', name: randomName2, message: randomResponse },
        ],
        display: 'message-pair',
        label: 'First messages',
    };
};

//function to generate average video duration
const generateVideoDurationResult = (): Result => {
    const avgLength = Math.floor(Math.random() * 1000)
    const hours = Math.floor(avgLength / 3600)
    const minutes = Math.floor((avgLength % 3600) / 60)
    const seconds = Math.floor(avgLength % 60)

    var average = ""
    // do not return hours or minutes if they are 0
    if(hours === 0 && minutes === 0) {
        average = seconds + " seconds"
    } else if(hours === 0) {
        average = minutes + " minutes " + seconds + " seconds"
    }
    else {
        average = hours + " hours " + minutes + " minutes " + seconds + " seconds"
    }
    
    return {
        time: average,
        label: 'Average call length',
        display: 'time',
    };
};

export default ScrollTile;