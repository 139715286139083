import React from "react";
import "./SelectBox.css"

const SelectBox = ({option, isSelected, onSelect, setCustomWord, index}) => {
    const renderBox = () => {
        const containerClassName = `checkbox-container${(option.query === "custom" && isSelected) ? 'selectedcustom': isSelected ? 'selected' : ''}`;
        return (
            <div
                className={containerClassName}
                onClick={() => onSelect(option, index)}
            >
                {
                    option.participants  ? (
                        <div className="checkbox-snap">
                            <p className="snap-chat-checkbox-title">{option.name}</p>
                            {option.title !== option.description && (
                                <div className="hover-participants">
                                    <p className="bubble-text">Participants</p>
                                    <p>{option.description}</p>
                                </div>
                            )}
                        </div>
                    ) :
                    (
                        <div>
                            <p style={{marginLeft: "20px"}}>{option.name}</p>
                            {(isSelected && option.query === "custom" && setCustomWord !== null) && (
                                <div key={option.query} style={{ marginTop: "-20px", opacity: isSelected ? 1 : 0, transition: "opacity 0.5s" }}>
                                    <input type="text" placeholder="Custom word" maxLength={50} onClick={(e) => e.stopPropagation()} onChange={(e) => setCustomWord(e.target.value)} />
                                </div>
                                )
                            }
                            {(option.query !== "custom" && option.query) && (
                                <div className="hover-message" style={{marginLeft: "20px"}}>
                                    <p style={{textAlign: "left"}}>{option.description}</p>
                                </div>
                            )}
                        </div>
                    )
                }
                
            </div>
        );
    }

    return (
        <div>
            {renderBox()}
        </div>
    );
}

export default SelectBox