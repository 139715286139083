import React from 'react';
import './PrivacyScreen.css'
const PrivacyScreen: React.FC = () => {
    return (
        <div className="privacy-content">
            <h1>Privacy Policy</h1>
            <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit <a href="https://chatsstat.com">https://chatsstat.com</a>.</p>

            <h2>PERSONAL INFORMATION WE COLLECT</h2>
            <p>
                When you visit our website, we automatically gather specific details about your device. This includes information about your web browser, IP address, time zone, and some of the cookies installed on your device. As you explore the site, we also collect data about the individual web pages you visit, the websites or search terms that led you to our site, and how you interact with it. All of this falls under the category of user device information. We employ the following technologies to collect this data:
            </p>
            <p>
                - Log files: These track actions occurring on the site and collect data such as your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
            </p>
            <p>
                - Cookies: These are data files placed on your device or computer, often containing an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>
            </p>
            <p>
                - Web beacons, tags, and pixels: These electronic files record information about your browsing behavior on the site
            </p>
            <p>
                We also collect Device Information to analyze customer interactions with the Site and evaluate the effectiveness of traffic and any marketing efforts.
            </p>

            <h2>GOOGLE</h2>
            <p>Third parties may be placing and reading cookies on your users' browsers, or using web beacons to collect information as a result of ad serving. If would like to know more about how Google uses your data from visiting this website, you can do so <a href="https://policies.google.com/privacy/partners">https://policies.google.com/privacy/partners</a>.</p>
            <h2>CHAT DATA</h2>
            <p>When you upload your chat file to our site, our program runs directly in your browser and gathers the necessary figures to calculate your chat statistics. WE DO NOT OBTAIN OR STORE YOUR CHAT FILE(s), and there is no infrastructure or functionality involved in storing or logging your chat data. We also do not store or log your stat results</p>
            <h2>SHARING YOUR PERSONAL INFORMATION</h2>
            <p>We do not store or share any personal information. We use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>
            <h2>DO NOT TRACK</h2>
            <p>Please be aware that we do not modify our website’s data collection and usage practices in response to Do Not Track signals from your browser.</p>
            <h2>CHANGES</h2>
            <p>Our privacy policy is subject to changes to reflect our practices, operational needs, and legal or regulatory requirements.</p>
            <h2>CONTACT US</h2>
            <p>If you would like to know more about our privacy practices or have any other questions, please contact us at <a href="mailto:chat-stat@outlook.com">chat-stat@outlook.com</a></p>
        </div>
    );
};

export default PrivacyScreen;