import React, {useState, useEffect} from 'react';
import './AnalysisScreen.css';
import DragDrop from '../components/DragDrop.tsx'
import Analyser, { getNames } from '../components/Analyser.tsx';
import StatCheckBoxes from "../components/stat-check-boxes/StatCheckBoxes.tsx";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { MetaChatFile, Name, StatOption } from '../models/models.tsx';
import GuideModal from './GuideModal.tsx';
import GravityItem from '../components/GravityItems.tsx';

const AnalysisScreen: React.FC = () => {    
    const [chatFile, setChatFile] = useState<MetaChatFile[]>([]);
    const [queryList, setQueryList] = useState<StatOption[]>([])
    const [nameList, setNameList] = useState<Name[]>(getNames(chatFile))
    const [open, setOpen] = useState<boolean>(false);
    const [guideOpen, setGuideOpen] = useState<boolean>(false);

    function canAnalyse() {
        return (chatFile[0]?.participants !== undefined && chatFile[0]?.messages !== undefined)
    }
    
    useEffect(() => {
        setNameList(getNames(chatFile))
    }, [chatFile])
    
    return (
        <div className="App" style={{paddingBottom: "150px"}}>
            <h1 className='facebook'>Facebook / Instagram</h1>
            <div className="home-uploadButton" style={{backgroundColor: "#f3b25845", outline: "0px", padding: "5px", color: "#33333363"}}onClick={() => setGuideOpen(!guideOpen)}>
                How to download your chat
            </div>
            <Modal open={guideOpen} onClose={() => setGuideOpen(!guideOpen)} center>
                <GuideModal/>
            </Modal>
            <p className='subheading'>1. Upload your chats</p>
            <div className='drop-container'>
                <DragDrop onUpload={setChatFile}/>
            </div>
            {canAnalyse() && 
                <div style={{marginTop: "50px"}}>
                    <Modal open={open} onClose={() => setOpen(!open)} center>
                        <StatCheckBoxes currentList={queryList} onChange={setQueryList} setOpen={setOpen}/>
                    </Modal>
                    <p className='subheading'>2. Select your stats</p>
                    <div className='query-list-container'>
                        <p className='query-list-count'>{queryList.length}/9</p>
                        <div className='hover-button'>
                            <div className="home-uploadButton" onClick={() => setOpen(!open)}>
                                Select stats
                            </div>
                        </div>
                        <GravityItem items={queryList.map(item => {return {name:item.name, colour:getRandomColor()}})}/>
                    </div>
                    <div>
                        <p>Detected Names (if you want to change them)</p>
                        <div className={nameList.length > 3 ? 'name-list-container-two-columns' : 'name-list-container'}>
                            {nameList.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <input type='text' value={item.preferredName} onChange={(e) => {
                                            let temp = [...nameList]
                                            temp[index].preferredName = e.target.value
                                            setNameList(temp)
                                        }}/>
                                    </div>
                                )
                            })}
                        </div>
                        <Analyser jsonData={chatFile} selectedOptions={queryList} names={nameList}/>
                    </div>
                </div>
            }
        </div>
    );
}

const getRandomColor = () => {
    const colors = [
        'rgba(133, 195, 218, 1)',
        'rgba(222, 77, 89, 1)',
        'rgba(61, 194, 163, 1)',
        'rgba(243, 178, 88, 1)',
        'rgb(249, 209, 255)'
    ];
    return colors[Math.floor(Math.random() * colors.length)];
};

export default AnalysisScreen;
